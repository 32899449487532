import React, { createContext, useContext, useState } from 'react';

const PrintContext = createContext();

export const PrintProvider = ({ children }) => {
  const [printData, setPrintData] = useState({
    problemIds: '',
    examInfo: {
      tree_name: '',
      edu_ver: '',
      exam_id: '',
      test_number: 'v7'
    },
    studentInfo: {
      student_name: ''
    },
    footerMessages: {
      left: '',
      right: '',
      memo: ''
    },
    isErrorNote: false
  });

  return (
    <PrintContext.Provider value={{ printData, setPrintData }}>
      {children}
    </PrintContext.Provider>
  );
};

export const usePrint = () => useContext(PrintContext); 