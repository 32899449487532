import { createEditor } from 'lexical';
import PlaygroundNodes from '../../editor/nodes/PlaygroundNodes';
import { processMathEquation } from '../utils/latexParser';
import { $generateHtmlFromNodes } from '@lexical/html';

// 전역 변수로 선언
let currentPageIndex = 0;

// 브라우저 환경 체크 함수 추가
const isBrowser = typeof window !== 'undefined';

const waitForRendering = () => {
    if (!isBrowser) return Promise.resolve();
    return new Promise(resolve => {
        setTimeout(resolve, 100);
    });
};

// 인쇄 스타일을 동적으로 추가
const printStyles = document.createElement('style');
printStyles.textContent = `
    @media screen {
      body { background: gray; }
      .pdf-container {
        width: 328mm;
        margin: 0 auto;
        background: white;
        box-shadow: 0 0 10px rgba(0,0,0,0.3);
      }
    }
    .print-header-first { 
        margin: 0;
        height: 120mm;
        background-image: url('${process.env.PUBLIC_URL}/assets/images/bg_test.png');
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;
        position: relative;
    }
    
    .print-header-rest {
        height: 15mm;
        background: none;
        margin: 0;
        position: relative;
    }

    .header-content {
      position: absolute;
      top: 16mm;
      left: 16mm;
      right: 16mm;
      z-index: 1;
    }
    .header-grid {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 20px;
    }
    .header-left .subject {
      font-size: 14pt;
      color: #333;  // 가독성을 위해 색상 조정
      margin: 0;
      font-weight: 500;
    }
    .header-left .chapter {
      font-size: 32pt;
      font-weight: 900;
      margin: 8mm 0;  // 여백 추가
      color: #4ABC85;
    }
    .header-left .scope {
      font-size: 10pt;
      color: gray;
      margin: 0;
    }
    .header-right {
      text-align: right;
      position: relative;
    }
    .test-number {
      font-size: 14pt;
      margin: 0;
      // position: absolute;
      top: 5mm;
      right: 73mm;
    }
    .student-name {
      font-size: 18pt;
      font-weight: bold;
      margin-top: 73mm;
      position: absolute;
      right: 30mm;
      color: #000;
    }
    .problem-number {
      font-size: 36px;
      font-weight: 900;
      min-width: 36px;
      white-space: nowrap;
      color: #4ABC85;
      margin: 0;
      line-height: 1;
      position: relative;
    }
    .editor-paragraph {
      position: relative;
    }
    .editor-text-bold {
      font-weight: bold;
    }
    .editor-text-italic {
      font-style: italic;
    }
    .editor-text-underline {
      text-decoration: underline;
    }
    .PlaygroundEditorTheme__paragraph {
      margin: 0;
      position: relative;
    }
    .PlaygroundEditorTheme__text-bold {
      font-weight: bold;
    }
    .page {
      width: 328mm; 
      min-height: 464mm; 
      background: white; 
      box-sizing: border-box;
      padding: 0;
      position: relative;
    }
    .problems-container {
      padding: 0 16mm;
      display: flex;
      flex-direction: row;
      gap: 16mm;
      min-height: 320.5mm;
      justify-content: space-between;
      position: relative;
    }
    .problems-container::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      width: 1px;
      background-color: #ccc;
      transform: translateX(-50%);
    }
    .problems-column {
      flex: 1;
      position: relative;
      display: block;
      width: calc(50% - 8mm);
      min-height: 320.5mm;
      overflow: visible;
    }
    .problem-item {
      width: 100%;
      max-width: 488px;
      overflow-wrap: break-word;
      text-align: left;
      display: flex;
      gap: 28px;
      align-items: flex-start;
      position: absolute;
      left: 0;
    }
    .problem-content .PlaygroundEditorTheme__layoutContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16mm;
      max-width: 100%;
      margin: 8mm 0;
      page-break-inside: avoid;
    }
    .problem-content .PlaygroundEditorTheme__layoutItem {
      width: 100%;
      min-width: 0;
      padding: 0;
      box-sizing: border-box;
      page-break-inside: avoid;
      overflow-wrap: break-word;
      border: none !important;
    }
    .problem-content img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 8mm auto;
    }
    .problem-content .math-element {
      font-size: 0.91em;
      display: inline-block;
      vertical-align: middle;
      margin: 0 2px;
    }
    .page:not(:first-child) .problems-container {
      margin-top: 30mm;
    }
    .PlaygroundEditorTheme__layoutItem > * {
      margin: 2mm 0;
    }
    .PlaygroundEditorTheme__imageContainer {
      text-align: center;
      margin: 8mm 0;
    }
    .PlaygroundEditorTheme__image {
      display: inline-block;
      max-width: 100%;
      vertical-align: middle;
    }
    .print-footer {
      position: absolute;
      bottom: 10mm;
      left: 16mm;
      right: 16mm;
      padding-bottom: 0;
    }
    .problem-content [data-lexical-layout-container] {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, auto);
      max-width: 100%;
    }
    .PlaygroundEditorTheme__boxContent {
      border: 1px solid black;
      padding: 10px;
      margin: 10px auto;
      max-width: 100%;
      border-radius: 4px;
      background-color: #ffffff;
      page-break-inside: avoid;
    }
    .problem-content {
      margin: 0;
      width: 100%;
      overflow-wrap: break-word;
      word-break: break-word;
    }
    .problem-item[data-full-column="true"] {
        height: 150mm;
        z-index: 1;
    }
    /* 첫 페이지 규칙 */
    div.page.first-child .problem-item[data-area="top"] {
        top: 0 !important;
    }
    div.page.first-child .problem-item[data-area="bottom"] {
        top: 160mm !important;
    }

    /* 두 번째 페이지 이후 규칙 */
    div.page:not(.first-child) .problem-item[data-area="top"] {
        top: 0mm !important;
    }
    div.page:not(.first-child) .problem-item[data-area="bottom"] {
        top: 205mm !important;
    }
    /* 첫 페이지의 구분선 */
    .page.first-child .problems-container::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      height: 320.5mm;  // footer까지 내려가도록 높이 증가
      width: 1px;
      background-color: #ccc;
      transform: translateX(-50%);
    }

    /* 두 번째 페이지 이후의 구분선 */
    .page:not(.first-child) .problems-container::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      height: 410mm;  // 문제 영역 높이만큼만
      width: 1px;
      background-color: #ccc;
      transform: translateX(-50%);
    }
    @media print {
        .print-header-first {
            -webkit-print-color-adjust: exact !important;
            print-color-adjust: exact !important;
            background-image: url('${process.env.PUBLIC_URL}/assets/images/bg_test.png') !important;
        }
        .page-break {
            display: none;
        }
        .page {
            margin-bottom: 0;
            box-shadow: none;
            page-break-after: always;  /* 각 페이지 후에 강제 페이지 나눔 */
            page-break-inside: avoid;  /* 페이지 내부 콘텐츠 분리 방지 */
        }
    }

    /* 첫 페이지의 problems-container 높이 (330mm = 1247.24px) */
    .page.first-child .problems-container {
        height: 320.5mm !important;
        min-height: 320.5mm !important;
        max-height: 320.5mm !important;
        margin: 0 !important;
        padding: 0 60.4724px !important;
    }

    /* 두 번째 페이지 이후의 problems-container 높이 */
    .page:not(.first-child) .problems-container {
        height: 410.5mm !important;
        min-height: 410.5mm !important;
        max-height: 410.5mm !important;
        margin-top: 57px !important;
        padding: 0 60.4724px !important;
    }

    /* 공통 스타일 */
    .problems-container {
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-between !important;
        gap: 60.4724px !important;
        position: relative !important;
        box-sizing: border-box !important;
    }

    /* 첫 페이지 푸터 */
    .page.first-child .print-footer {
        position: absolute;
        bottom: 10mm;
        left: 16mm;
        right: 16mm;
        padding-bottom: 0;
    }

    /* 두 번째 페이지부터의 푸터 */
    .page:not(.first-child) .print-footer {
        position: absolute;
        bottom: 10mm;  /* 10mm에서 5mm로 변경 */
        left: 16mm;
        right: 16mm;
        padding-bottom: 0;
    }
  `;
document.head.appendChild(printStyles);

// 스타일 정의 추가
const style = document.createElement('style');
style.textContent = `
    @media screen {
      body { background: gray; }
      .pdf-container {
        width: 328mm;
        margin: 0 auto;
        background: white;
        box-shadow: 0 0 10px rgba(0,0,0,0.3);
      }
    }
    // ... 기존 스타일 유지 ...
    /* 첫 페이지의 problems-container 높이 */
    .page.first-child .problems-container {
        height: 320.5mm !important;
        min-height: 320.5mm !important;
        max-height: 320.5mm !important;
        margin: 0 !important;
        padding: 0 60.4724px !important;
    }

    /* 두 번째 페이지 이후의 problems-container 높이 */
    .page:not(.first-child) .problems-container {
        height: 410.5mm !important;
        min-height: 410.5mm !important;
        max-height: 410.5mm !important;
        margin-top: 57px !important;
        padding: 0 60.4724px !important;
    }
`;

// generateProblemHTML 함수 추가
const generateProblemHTML = (problem) => {
    const isFullColumn = problem.useFullColumn;
    const columnClass = isFullColumn ? 'full-column' : '';
    
    return `
        <div class="problem-item ${columnClass}" 
             data-area="${problem.area}"
             style="position: absolute; top: ${problem.startHeight}mm;">
            <div class="problem-number">
                ${String(problem.problemNumber).padStart(2, '0')}
            </div>
            <div class="problem-content">${problem.content}</div>
        </div>
    `;
};

export const handlePDF = async (examId, stampId) => {
    if (!isBrowser) return;

    try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/exams/${stampId}/${examId}/print`, {
            headers: {
                'Authorization': token
            }
        });

        if (!response.ok) {
            if (response.status === 400) throw new Error('잘못된 요청입니다.');
            if (response.status === 401) throw new Error('인증되지 않은 사용자입니다.');
            if (response.status === 404) throw new Error('시험 정보를 찾을 수 없습니다.');
            throw new Error('시험지 데이터를 가져오는데 실패했습니다.');
        }

        const data = await response.json();
        if (data.status !== 'success') throw new Error('문제 로드 실패');

        // 문제 내용 처리
        const problemsWithHtml = await Promise.all(data.data.problem_list.map(async (problem) => {
            const lexicalData = problem.lexical_data.lexical_data;
            const editor = createEditor({
                nodes: [...PlaygroundNodes],
                theme: {
                    paragraph: 'editor-paragraph',
                    text: {
                        bold: 'editor-text-bold',
                        italic: 'editor-text-italic',
                        underline: 'editor-text-underline',
                    },
                    layoutContainer: 'PlaygroundEditorTheme__layoutContainer',
                    layoutItem: 'PlaygroundEditorTheme__layoutItem',
                    image: 'PlaygroundEditorTheme__image',
                    imageContainer: 'PlaygroundEditorTheme__imageContainer',
                    boxContent: 'PlaygroundEditorTheme__boxContent',
                }
            });

            const processNode = (node) => {
                if (node.type === 'equation') {
                    node.equation = processMathEquation(node.equation);
                } else if (node.type === 'box-content') {
                    node.className = 'PlaygroundEditorTheme__boxContent';
                }
                if (node.children && Array.isArray(node.children)) {
                    node.children = node.children.map(child => processNode(child));
                }
                return node;
            };

            lexicalData.root = processNode(lexicalData.root);
            const editorState = editor.parseEditorState(lexicalData);

            let content = '';
            editor.setEditorState(editorState);
            editor.update(() => {
                content = $generateHtmlFromNodes(editor);
            });

            return { ...problem, content };
        }));

        // 페이지 분배
        const distributedPages = distributeProblems(problemsWithHtml);
        const htmlPages = [];

        for (let pageIndex = 0; pageIndex < distributedPages.length; pageIndex++) {
            const page = distributedPages[pageIndex];
            const pageHtml = `
                <div class="page ${pageIndex === 0 ? 'first-child' : ''}">
                    ${pageIndex === 0 ? `
                        <div class="print-header-first">
                            <div class="header-content">
                                <div class="header-grid">
                                    <div class="header-left">
                                        <p class="subject">${data.data.exam_info.edu_ver}</p>
                                        <p class="chapter">${data.data.exam_info.tree_name}</p>
                                    </div>
                                    <div class="header-right">
                                        <p class="test-number">No. ${data.data.exam_info.exam_id}</p>
                                        <p class="student-name">${data.data.student_info.student_name}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ` : `
                        <div class="print-header-rest">
                            <div class="header-content"></div>
                        </div>
                    `}
                    <div class="problems-container">
                        <div class="problems-column">
                            ${page.left?.map(problem => generateProblemHTML(problem)).join('')}
                        </div>
                        <div class="problems-column">
                            ${page.right?.map(problem => generateProblemHTML(problem)).join('')}
                        </div>
                    </div>
                    <div class="print-footer">
                        <hr style="border: none; border-top: 1px solid #ccc; margin-bottom: 5mm;" />
                        <div style="position: relative; height: 24pt;">
                            <div style="display: flex; justify-content: space-between; align-items: center; color: #666; font-size: 10pt;">
                                <span style="font-weight: bold;">카이테스트 · ${data.data.exam_info.exam_type === 'alpha' ? 'α' : 
                                    data.data.exam_info.exam_type === 'beta' ? 'β' : 
                                    data.data.exam_info.exam_type === 'gamma' ? 'γ' :
                                    data.data.exam_info.exam_type === 'delta' ? 'δ' : 
                                    data.data.exam_info.exam_type}</span>
                                <span style="font-weight: bold;">${data.data.exam_info.edu_ver} · ${data.data.exam_info.tree_name}</span>
                            </div>
                            <span style="position: absolute; left: 50%; transform: translateX(-50%); font-size: 18pt; font-weight: bold; top: -4pt;">${String(pageIndex + 1).padStart(2, '0')}</span>
                        </div>
                    </div>
                </div>
            `;

            await waitForRendering();

            // 현재 페이지의 스타일시트 수집
            const existingStyles = Array.from(document.styleSheets)
                .map(sheet => {
                    try {
                        return Array.from(sheet.cssRules)
                            .map(rule => rule.cssText)
                            .join('\n');
                    } catch (e) {
                        return '';
                    }
                })
                .join('\n');

            htmlPages.push(`
                <html>
                <head>
                    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/katex.min.css">
                    <style>
                        ${existingStyles}
                        ${printStyles.textContent}
                        .MathJax { font-size: 100% !important; }
                        .MathJax_Display { overflow-x: auto; overflow-y: hidden; }
                        .katex { font-size: 1em !important; }
                        .katex-display { margin: 1em 0; }
                    </style>
                </head>
                <body>${pageHtml}</body>
                </html>
            `);
        }

        // HTML 미리보기 창 생성
        const previewWindow = window.open('', '_blank');
        const combinedHTML = `
        <html>
        <head>
          <style>
            @import url('https://hangeul.pstatic.net/hangeul_static/css/nanum-gothic.css');
            ${style.textContent}
            body { 
              margin: 0; 
              padding: 20px; 
              cursor: pointer;
              background: gray;
              -webkit-print-color-adjust: exact !important;
              print-color-adjust: exact !important;
            }
            .problem-content *:not(.katex):not(.katex *) {
              font-family: 'NanumGothic', sans-serif !important;
              font-weight: 700 !important;
              font-size: 18px !important;
            }
            .katex {
              font-size: 1em !important;
            }
            .page { 
              background: white;
              margin-bottom: 20px;
              box-shadow: 0 0 10px rgba(0,0,0,0.1);
            }
            .page-break { 
              height: 20px;
              background: gray;
            }
            .print-header-first,
            .print-footer,
            .problem-number,
            .header-left,
            .header-right {
              font-family: initial;
              font-weight: initial;
              font-size: initial;
            }
            .problem-number {
              font-size: 40px !important;
              font-weight: 900 !important;
            }
            .header-left .chapter {
              font-size: 32pt !important;
              font-weight: 900 !important;
            }
            @media print {
              .print-header-first {
                -webkit-print-color-adjust: exact !important;
                print-color-adjust: exact !important;
                background-image: url('${process.env.PUBLIC_URL}/assets/images/bg_test.png') !important;
              }
              .page-break {
                display: none;
              }
              .page {
                margin-bottom: 0;
                box-shadow: none;
              }
            }
          </style>
        </head>
        <body onclick="window.close()">
          ${htmlPages.join('<div class="page-break"></div>')}
        </body>
        </html>
      `;
        previewWindow.document.write(combinedHTML);
        previewWindow.document.close();

    } catch (error) {
        console.error('PDF 생성 중 오류 발생:', error);
        alert(error.message || 'PDF 생성 중 오류가 발생했습니다.');
    }
};

const calculateProblemHeight = (problem, index) => {
    if (!isBrowser) return 0;
    
    const tempDiv = document.createElement('div');
    tempDiv.style.cssText = `
        position: absolute;
        visibility: hidden;
        width: 488px;
        left: -9999px;
        display: block;
        opacity: 0;
    `;
    
    tempDiv.innerHTML = `
        <div class="problem-item" style="display: block; position: static;">
            <div class="problem-number" style="display: block;">${String(index + 1).padStart(2, '0')}</div>
            <div class="problem-content" style="display: block;">${problem.content}</div>
        </div>
    `;
    
    document.body.appendChild(tempDiv);
    const height = tempDiv.offsetHeight;
    document.body.removeChild(tempDiv);
    
    return height / 3.779527559055118;
};

const getAvailableHeight = (pageIndex) => {
    const PAGE_HEIGHT = 297; // A4 높이 (mm)
    
    if (pageIndex === 0) {
        // 첫 페이지: 전체 높이 - (헤더 120mm + 하단 footer 51.89px + 인쇄영역 10mm)
        const HEADER = 120;
        const FOOTER = 51.89 / 3.779527559055118; // px to mm
        const PRINT_AREA = 10;
        return PAGE_HEIGHT - (HEADER + FOOTER + PRINT_AREA);
    } else {
        // 두번째 페이지부터: 전체 높이 - (상단 56.69px + 마진 56.69px + 하단 51.89px + 인쇄영역 10mm)
        const TOP_SPACE = (56.69 * 2) / 3.779527559055118; // px to mm
        const FOOTER = 51.89 / 3.779527559055118; // px to mm
        const PRINT_AREA = 10;
        return PAGE_HEIGHT - (TOP_SPACE + FOOTER + PRINT_AREA);
    }
};

const distributeProblems = (problems) => {
    let pages = [];
    let currentPage = { left: [], right: [] };
    let currentPageIndex = 0;
    let problemCounter = 0;

    const getPositions = (pageIndex) => {
        if (pageIndex === 0) {
            return {
                startHeight: 0,
                midHeight: 160,
                singleAreaHeight: 160
            };
        } else {
            return {
                startHeight: 0,
                midHeight: 205,
                singleAreaHeight: 205
            };
        }
    };

    const processPage = (problem, index) => {
        const { startHeight, midHeight, singleAreaHeight } = getPositions(currentPageIndex);
        const problemHeight = calculateProblemHeight(problem, index);

        console.log(`Problem ${index + 1} Height:`, {
            problemId: problem.problem_id,
            heightInMM: problemHeight,
            heightInPX: problemHeight * 3.779527559055118,
            singleAreaHeight,  // 기준값도 함께 출력
            isLongProblem: problemHeight > singleAreaHeight
        });
        
        // 페이지 시작 조건 체크
        if (currentPage.left.length === 2 && currentPage.right.length === 2) {
            pages.push({ ...currentPage });
            currentPage = { left: [], right: [] };
            currentPageIndex++;
            return processPage(problem, index);
        }

        // 긴 문제 처리
        if (problemHeight > singleAreaHeight) {
            const longProblem = {
                ...problem,
                startHeight: 0,
                height: problemHeight,
                useFullColumn: true,
                area: 'full',
                problemNumber: ++problemCounter
            };

            // 현재 페이지의 상태 확인
            const leftEmpty = currentPage.left.length === 0;
            const rightEmpty = currentPage.right.length === 0;

            // 현재 페이지가 완전히 비어있는 경우
            if (leftEmpty && rightEmpty) {
                currentPage.left = [longProblem];
                currentPage.left[0].column = 'left';
                pages.push({ ...currentPage });
                currentPage = { left: [], right: [] };
                currentPageIndex++;
                return;
            }

            // 오른쪽 열이 비어있고 왼쪽 열에 문제가 있는 경우
            if (rightEmpty && currentPage.left.length > 0) {
                currentPage.right = [longProblem];
                currentPage.right[0].column = 'right';
                pages.push({ ...currentPage });
                currentPage = { left: [], right: [] };
                currentPageIndex++;
                return;
            }

            // 이미 문제가 �는 경우 새 페이지로
            pages.push({ ...currentPage });
            currentPage = { left: [], right: [] };
            currentPageIndex++;
            currentPage.left = [longProblem];
            currentPage.left[0].column = 'left';
            pages.push({ ...currentPage });
            currentPage = { left: [], right: [] };
            currentPageIndex++;
            return;
        }

        // 일반 문제 배치
        const addProblemToPage = (column) => {
            const isTop = column === 'left' ? currentPage.left.length === 0 : currentPage.right.length === 0;
            const height = isTop ? startHeight : midHeight;
    
            const normalProblem = {
              ...problem,
              startHeight: height,
              height: problemHeight,
              problemNumber: ++problemCounter,
              column: column
            };
    
            if (column === 'left') {
              currentPage.left.push(normalProblem);
            } else {
              currentPage.right.push(normalProblem);
            }
          };

        // 왼쪽 열부터 채우고, 꽉 차면 오른쪽 열로
        if (currentPage.left.length < 2) {
            addProblemToPage('left');
        } else if (currentPage.right.length < 2) {
            addProblemToPage('right');
        }
    };

    problems.forEach((problem, index) => {
        processPage(problem, index);
    });

    // 마지막 페이지 처리
    if (currentPage.left.length > 0 || currentPage.right.length > 0) {
        pages.push(currentPage);
    }

    return pages;
};