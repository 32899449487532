import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { useAuth } from '../context/AuthContext';
import ProblemEditModal from '../components/ProblemEdit/ProblemEditModal';
import ProblemPreview from '../components/ProblemPreview';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

function ProblemEdit() {
  const { getAccessToken, handleApiError } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const [showModal, setShowModal] = useState(false);
  const [selectedProblem, setSelectedProblem] = useState(null);
  const [problems, setProblems] = useState([]);
  const [page, setPage] = useState(parseInt(params.page_number) || 1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  // 필터 상태
  const [filters, setFilters] = useState({
    status: '',
    author: '',
    labels: ''
  });

  const [isFilterMode, setIsFilterMode] = useState(false);

  // state에 totalPages 추가
  const [totalPages, setTotalPages] = useState(1);

  // problem_id 검색을 위한 상태 추가
  const [problemIdSearch, setProblemIdSearch] = useState('');
  const [singleProblemResult, setSingleProblemResult] = useState(null);

  const [previewInfo, setPreviewInfo] = useState({
    isOpen: false,
    problemId: [],
    problemNumber: null,
    isCorrect: null
  });

  // fetchWithTokenRefresh 함수 수정
  const fetchWithTokenRefresh = useCallback(async (url, options = {}) => {
    try {
      const token = await getAccessToken();
      if (!token) {
        navigate('/signin');
        return null;
      }

      const response = await fetch(`${API_BASE_URL}${url}`, {
        ...options,
        headers: {
          ...options.headers,
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      // 401 응답 코드 체크 추가
      if (response.status === 401) {
        handleApiError(new Error('Invalid or expired token'));
        return null;
      }

      const data = await response.json();

      // 응답 데이터의 status_code도 체크
      if (data.status_code === 401) {
        handleApiError(new Error('Invalid or expired token'));
        return null;
      }

      return data;
    } catch (error) {
      console.error('API 호출 에러:', error);
      if (error.message.includes('token') || error.message.includes('unauthorized')) {
        handleApiError(error);
      }
      throw error;
    }
  }, [getAccessToken, navigate, handleApiError]);

  // API 호출 함수 수정
  const fetchProblems = useCallback(async (pageNum = 1, useFilter = false) => {
    if (loading) return;

    const shouldUseFilter = useFilter || isFilterMode;
    setLoading(true);

    try {
      const params = new URLSearchParams({
        page: pageNum,
        per_page: 20
      });

      if (shouldUseFilter) {
        if (filters.status) params.append('status', filters.status);
        if (filters.author) params.append('author', filters.author);
        if (filters.labels) params.append('labels', filters.labels);
      }

      const hasFilterValues = filters.status || filters.author || filters.labels;
      const endpoint = shouldUseFilter && hasFilterValues ?
        '/api/problems/filtered' :
        '/api/problems/';

      const response = await fetchWithTokenRefresh(`${endpoint}?${params}`);

      if (!response) return;

      if (response.status === 'success') {
        setProblems(response.data);
        setPage(pageNum);
        setTotalPages(response.total_pages); // 전체 페이지 수 저장
        setHasMore(pageNum < response.total_pages);
      }
    } catch (error) {
      console.error('문제 목록 불러오기 실패:', error);
    } finally {
      setLoading(false);
    }
  }, [filters, loading, isFilterMode, fetchWithTokenRefresh]);

  // 페이지 변경 핸들러 추가
  const handlePageChange = (newPage) => {
    navigate(`/problem-edit/${newPage}`);
    fetchProblems(newPage, isFilterMode);
  };

  // 초기화 핸들러
  const handleReset = () => {
    setIsFilterMode(false);
    setFilters({
      status: '',
      author: '',
      labels: ''
    });
    setProblemIdSearch(''); // problem_id 검색어 초기화
    setSingleProblemResult(null); // 검색 결과 초기화
    setProblems([]);
    navigate('/problem-edit/1');
    setHasMore(true);
    fetchProblems(1, false);
  };

  // 필터 조회 핸들러 수정
  const handleSearch = () => {
    const hasFilterValues = filters.status || filters.author || filters.labels;
    if (!hasFilterValues) {
      alert('상태, 작성자, 라벨 중 하나 이상을 입력해주세요.');
      return;
    }
    setIsFilterMode(true);
    setProblems([]);
    setPage(1);
    setHasMore(true);
    fetchProblems(1, true);
  };

  // 초기 데이터 로드 수정
  useEffect(() => {
    const checkAuthAndFetch = async () => {
      const token = await getAccessToken();
      if (!token) {
        navigate('/signin', { replace: true });
        return;
      }
      fetchProblems(parseInt(params.page_number) || 1, false);
    };

    checkAuthAndFetch();
  }, [params.page_number]);

  // 상태에 따른 데이터 상태 표시 함수
  const getStatusInfo = (status) => {
    const statusMap = {
      200: { image: true, ocr: false, editor: false },
      300: { image: true, ocr: true, editor: false },
      999: { image: true, ocr: true, editor: true }
    };
    return statusMap[status] || { image: false, ocr: false, editor: false };
  };

  // 필터 변경 핸들러 수정
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  // 키 입력 핸들러 추가
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  // 편집 버튼 핸들러
  const handleEdit = useCallback(async (problem) => {
    try {
      const response = await fetchWithTokenRefresh(`/api/problems/${problem.problem_id}?lexical=true&mathpix=true&base64=true`);

      if (!response) return;

      setSelectedProblem(response.data);
      setShowModal(true);
    } catch (error) {
      console.error('문제 데이터 불러오기 실패:', error);
      alert('문제 데이터를 불러오는데 실패했습니다.');
    }
  }, [fetchWithTokenRefresh]);


  // 날짜 포맷 함수
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // GMT+9 시간 적용
    const koreaDate = new Date(date.getTime() + (9 * 60 * 60 * 1000));
    return `${koreaDate.getFullYear()}-${String(koreaDate.getMonth() + 1).padStart(2, '0')}-${String(koreaDate.getDate()).padStart(2, '0')} ${String(koreaDate.getHours()).padStart(2, '0')}:${String(koreaDate.getMinutes()).padStart(2, '0')}`;
  };

  // 데이터 갱신 함수
  const refreshData = useCallback(() => {
    fetchProblems(page, isFilterMode);
  }, [fetchProblems, isFilterMode, page]);

  // handleDelete 함수 추가
  const handleDelete = useCallback(async (problemId) => {
    if (!window.confirm('정말로 이 문제를 삭제하시겠습니까?')) {
      return;
    }

    try {
      const response = await fetchWithTokenRefresh(`/api/problems/${problemId}`, {
        method: 'DELETE'
      });

      if (!response) return;

      if (response.status === 'success') {
        alert('문제가 성공적으로 삭제되었습니다.');
        refreshData();
      } else {
        // 에러 상태에 따른 메시지 처리
        switch (response.status_code) {
          case 400:
            alert('잘못된 문제 ID입니다.');
            break;
          case 409:
            alert('시험지에 등록된 문제는 삭제할 수 없습니다.');
            break;
          case 410:
            alert('이미 삭제된 문제입니다.');
            break;
          default:
            alert('문제 삭제에 실패했습니다.');
        }
      }
    } catch (error) {
      console.error('문제 삭제 실패:', error);
      alert('문제 삭제 중 오류가 발생했습니다.');
    }
  }, [fetchWithTokenRefresh, refreshData]);

  // 페이지네이션 렌더링 함수 추가
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisible = 5;
    let start = Math.max(1, page - Math.floor(maxVisible / 2));
    let end = Math.min(start + maxVisible - 1, totalPages);

    if (end - start + 1 < maxVisible) {
      start = Math.max(1, end - maxVisible + 1);
    }

    if (start > 1) {
      pageNumbers.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className="px-3 py-1 border rounded hover:bg-gray-100"
        >
          1
        </button>
      );
      if (start > 2) {
        pageNumbers.push(<span key="ellipsis1">...</span>);
      }
    }

    for (let i = start; i <= end; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 border rounded ${page === i ? 'bg-[#231F20] text-white' : 'hover:bg-gray-100'
            }`}
        >
          {i}
        </button>
      );
    }

    if (end < totalPages) {
      if (end < totalPages - 1) {
        pageNumbers.push(<span key="ellipsis2">...</span>);
      }
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className="px-3 py-1 border rounded hover:bg-gray-100"
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  // problem_id로 단일 문제 검색하는 함수 추가
  const handleProblemIdSearch = useCallback(async () => {
    if (!problemIdSearch || isNaN(problemIdSearch)) {
      alert('유효한 문제 ID를 입력해주세요.');
      return;
    }

    try {
      const response = await fetchWithTokenRefresh(`/api/problems/${problemIdSearch}`);
      
      if (!response) return;

      if (response.status === 'success') {
        // 응답 구조에 맞게 데이터 구조 변환
        const formattedProblem = {
          problem_id: response.data.problem_id,
          author: response.data.problem_info.author,
          status: response.data.problem_info.status,
          labels: response.data.problem_info.labels,
          created_at: response.data.problem_info.created_at,
          updated_at: response.data.problem_info.updated_at
        };
        
        setProblems([formattedProblem]); // 테이블에 단일 결과만 표시
        setSingleProblemResult(response.data);
        setHasMore(false);
        setTotalPages(1);
      } else {
        alert('문제를 찾을 수 없습니다.');
        setSingleProblemResult(null);
        setProblems([]);
      }
    } catch (error) {
      console.error('문제 검색 실패:', error);
      alert('문제 검색에 실패했습니다.');
    }
  }, [problemIdSearch, fetchWithTokenRefresh]);

  // 테이블의 ��리보기 버튼 클릭 핸들러 수정
  const handlePreview = (problem) => {
    setPreviewInfo({
      isOpen: true,
      problemId: [problem.problem_id],
      problemNumber: null,  // 문제 수정 페이지에서는 번호 표시 안 함
      isCorrect: null      // 문제 수정 페이지에서는 정답 여부 없음
    });
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 p-6 overflow-y-auto">
          {/* <h1 className="text-2xl font-semibold mb-6">문제 수정</h1> */}
          <nav className="mb-6">
            <div className="flex items-center text-sm text-gray-600">
              <Link to="/problem-edit" className="hover:text-purple-600">문제관리</Link>
            </div>
          </nav>
          {/* 필터 섹션 수정 */}
          <div className="mb-6 space-y-4">
            {/* Problem ID 검색 섹션 */}
            <div className="flex gap-4 mb-4">
              <input
                type="number"
                placeholder="문제 ID 검색"
                value={problemIdSearch}
                onChange={(e) => setProblemIdSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleProblemIdSearch();
                  }
                }}
                className="border rounded p-2 w-[200px]"
                min="1"
              />
              <button
                onClick={handleProblemIdSearch}
                className="bg-[#231F20] text-white px-4 py-2 rounded hover:bg-[#A3A1A2]"
              >
                ID 검색
              </button>
            </div>

            {/* 기존 필터 섹션 수정 */}
            <div className="flex gap-4 justify-between">
              <div className="flex gap-4">
                <input
                  type="text"
                  name="author"
                  placeholder="작성자"
                  value={filters.author}
                  onChange={handleFilterChange}
                  onKeyPress={handleKeyPress}
                  className="border rounded p-2"
                />
                <input
                  type="text"
                  name="labels"
                  placeholder="라벨"
                  value={filters.labels}
                  onChange={handleFilterChange}
                  onKeyPress={handleKeyPress}
                  className="border rounded p-2 w-[300px]"
                />
                <button
                  onClick={handleSearch}
                  className="bg-[#231F20] text-white px-4 py-2 rounded hover:bg-[#A3A1A2]"
                >
                  조회
                </button>
                <button
                  onClick={handleReset}
                  className="bg-[#D9D9D9] text-white px-4 py-2 rounded"
                >
                  초기화
                </button>
              </div>
              <button
                onClick={() => navigate('/problem-register')}
                className="bg-[#4ABC85] text-white px-4 py-2 rounded hover:bg-[#A3A1A2]"
              >
                문제등록
              </button>
            </div>
          </div>

          {/* 테이블 */}
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-sm rounded-lg">
              <thead className="bg-[#231F20] ">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">ID</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">상태</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">작성자</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">라벨</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">생성일</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">수정일</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">작업</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {problems.map((problem) => (
                  <tr key={problem.problem_id}>
                    <td className="px-6 py-4 whitespace-nowrap">{problem.problem_id}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex gap-2">
                        <span className={`h-2 w-2 rounded-full ${getStatusInfo(problem.status).image ? 'bg-green-500' : 'bg-red-500'}`} title="이미지" />
                        <span className={`h-2 w-2 rounded-full ${getStatusInfo(problem.status).ocr ? 'bg-green-500' : 'bg-red-500'}`} title="OCR" />
                        <span className={`h-2 w-2 rounded-full ${getStatusInfo(problem.status).editor ? 'bg-green-500' : 'bg-red-500'}`} title="에디터" />
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{problem.author}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{problem.labels}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{formatDate(problem.created_at)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{formatDate(problem.updated_at)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex gap-2">
                        <button
                          onClick={() => handleEdit(problem)}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          수정
                        </button>
                        <button
                          onClick={() => handleDelete(problem.problem_id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          삭제
                        </button>
                        <button
                          onClick={() => handlePreview(problem)}
                          className="text-green-600 hover:text-green-900"
                        >
                          미리보기
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* 페이지네이션 컴포넌트 추가 */}
          {!loading && problems.length > 0 && (
            <div className="flex justify-center mt-4">
              <div className="flex items-center gap-2">
                <button
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 1}
                  className="px-4 py-2 border rounded disabled:opacity-50"
                >
                  이전
                </button>
                {renderPageNumbers()}
                <button
                  onClick={() => handlePageChange(page + 1)}
                  disabled={!hasMore}
                  className="px-4 py-2 border rounded disabled:opacity-50"
                >
                  다음
                </button>
              </div>
            </div>
          )}

          {loading && <div className="text-center py-4">로딩 중...</div>}

          {/* ProblemPreview 컴포��트 수정 */}
          <ProblemPreview
            isOpen={previewInfo.isOpen}
            onClose={() => setPreviewInfo({
              isOpen: false,
              problemId: [],
              problemNumber: null,
              isCorrect: null
            })}
            problemIds={previewInfo.problemId}
            showNumbers={false}  // 문제 수정 페이지에서는 번호 표시 안 함
            correctList={[]}     // 문제 수정 페이지에서는 정답 여부 없음
            problemNumbers={[]}  // 문제 수정 페이지에서는 번호 없음
          />

          {/* 기존 ProblemEditModal */}
          {showModal && selectedProblem && (
            <ProblemEditModal
              problem={selectedProblem}
              onClose={() => {
                setShowModal(false);
                setSelectedProblem(null);
              }}
              onUpdate={refreshData}
            />
          )}
        </main>
      </div>
    </div>
  );
}

export default ProblemEdit;