import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import TestsetComponent from '../components/Testset/TestsetComponent';
import { useAuth } from '../context/AuthContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

function TestsetPage() {
  const { getAccessToken, handleApiError } = useAuth();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [treeData, setTreeData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newEduVer, setNewEduVer] = useState('');
  const [newTreeName, setNewTreeName] = useState('');
  const [error, setError] = useState('');
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateData, setUpdateData] = useState({
    tree_name: '',
    edu_ver: ''
  });
  const [successMessage, setSuccessMessage] = useState('');

  const fetchWithTokenRefresh = useCallback(async (url, options = {}) => {
    try {
      const token = await getAccessToken();
      if (!token) {
        navigate('/signin');
        return null;
      }
      
      const response = await fetch(`${API_BASE_URL}${url}`, {
        ...options,
        headers: {
          ...options.headers,
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      const data = await response.json();
      
      // 401 에러 먼저 체크하고 즉시 리다이렉트
      if (data.status === 'error' && 
          data.status_code === '401' && 
          data.message === 'Invalid or expired token') {
        handleApiError(new Error('Invalid or expired token'));
        navigate('/signin');
        return null;
      }

      return data;
    } catch (error) {
      console.error('API 호출 에러:', error);
      throw error;
    }
  }, [getAccessToken, navigate, handleApiError]);

  const fetchCategories = useCallback(async () => {
    try {
      const data = await fetchWithTokenRefresh('/api/testset/lists');
      // 401 에러로 인해 null이 반환된 경우 함수 종료
      if (!data) {
        return;
      }
      
      if (data.status === 'success' && Array.isArray(data.data)) {
        setCategories(data.data);
        if (data.data.length > 0 && activeTab === null) {
          setActiveTab(data.data[0].tree_id);
        }
      } else {
        setCategories([]);
      }
    } catch (error) {
      console.error('카테고리 불러오기 실패:', error);
      setError('카테고리를 불러오는 데 실패했습니다.');
      setCategories([]);
    }
  }, [fetchWithTokenRefresh, activeTab]);

  const fetchTreeData = useCallback(async (treeId) => {
    try {
      const response = await fetchWithTokenRefresh(`/api/testset/${treeId}/components/list`);
      // 401 에러나 다른 에러로 인해 null이 반환된 경우
      if (!response) {
        return;
      }

      if (response.data && response.data.structure) {
        setTreeData(response.data);
      } else {
        setTreeData({ structure: [] });
      }
      setError('');
    } catch (error) {
      console.error('트리 데이터 불러오기 실패:', error);
      setError('트리 데이터를 불러오는 데 실패했습니다');
      setTreeData({ structure: [] });
    }
  }, [fetchWithTokenRefresh]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    if (activeTab !== null) {
      fetchTreeData(activeTab);
    }
  }, [activeTab, fetchTreeData]);

  const handleAddCategory = async () => {
    try {
      const response = await fetchWithTokenRefresh('/api/testset/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ edu_ver: newEduVer, tree_name: newTreeName })
      });

      if (response.status === 'success') {
        setShowModal(false);
        setNewEduVer('');
        setNewTreeName('');
        fetchCategories();
      } else {
        alert(response.message || '카테고리 추가에 실패했습니다.');
        setShowModal(false);
      }
    } catch (error) {
      alert('카테고리 추가에 실패했습니다. 다시 시도해 주세요.');
      setShowModal(false);
    }
  };

  const handleDeleteCategory = async () => {
    if (!activeTab) {
      setError('삭제할 카테고리를 선택해주세요.');
      return;
    }

    const isConfirmed = window.confirm('정말로 이 카테고리를 삭제하시겠습까? 이 작업은 되돌릴 수 없습니다.');

    if (isConfirmed) {
      try {
        await fetchWithTokenRefresh(`/api/testset/${activeTab}/delete`, { method: 'DELETE' });
        fetchCategories();
        setActiveTab(null);
      } catch (error) {
        setError('카테고리 삭제에 실패했습니다.');
      }
    }
  };

  const handleUpdateClick = () => {
    const currentCategory = categories.find(cat => cat.tree_id === activeTab);
    if (currentCategory) {
      setUpdateData({
        tree_name: currentCategory.tree_name,
        edu_ver: currentCategory.edu_ver
      });
      setShowUpdateModal(true);
    } else {
      setError('수정할 카테고리를 선택해주세요.');
    }
  };

  const handleUpdateCategory = async () => {
    if (!activeTab) {
      setError('수정할 카테고리를 선택해세요.');
      return;
    }

    try {
      const data = await fetchWithTokenRefresh(`/api/testset/${activeTab}/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateData)
      });

      if (data.status === 'success') {
        setShowUpdateModal(false);
        fetchCategories();
        setError('');
        setSuccessMessage('카테고리가 성공적으로 수정되었습니다.');
        setTimeout(() => setSuccessMessage(''), 3000);
      } else {
        throw new Error(data.message || '카테고리 수정에 실패했습니다.');
      }
    } catch (error) {
      setError(error.message || '카테고리 수정에 실패했습니다.');
    }
  };

  const handleAddComponent = async (newComponent, parentComponent = null) => {
    try {
      const requestBody = {
        depth: newComponent.depth,
        priority: newComponent.priority,
        comp_name: newComponent.comp_name
      };

      if (parentComponent) {
        requestBody.parent_component = {
          depth: parentComponent.depth,
          priority: parentComponent.priority,
          comp_name: parentComponent.comp_name,
          path_id: parentComponent.path_id,
          order: parentComponent.order
        };
      }

      const response = await fetchWithTokenRefresh(`/api/testset/${activeTab}/components/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
      });

      if (response.status === 'success') {
        fetchTreeData(activeTab);
      } else {
        throw new Error(response.message || '컴포넌트 추가에 실패했습니다.');
      }
    } catch (error) {
      setError(error.message || '컴포넌트 추가에 실패습니다.');
    }
  };

  const handlePriorityUpdate = async () => {
    try {
      const response = await fetchWithTokenRefresh(`/api/testset/${activeTab}/priority`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      
      alert(response.message || '중요도가 갱신되었습니다.');
      // 중요도 갱신 후 트리 데이터 새로고침
      await fetchTreeData(activeTab);
    } catch (error) {
      alert(error.message || '중요도 갱신에 실패했습니다.');
    }
  };

  const handleCloseableCheck = async () => {
    try {
      const response = await fetchWithTokenRefresh(`/api/testset/${activeTab}/closeable`);
      alert(response.message || '확정 가능 여부를 확인했습니다.');
    } catch (error) {
      alert(error.message || '확정 가능 여부 확인에 실패했습니다.');
    }
  };

  const handleClose = async () => {
    try {
      const response = await fetchWithTokenRefresh(`/api/testset/${activeTab}/close`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      
      alert(response.message || '성공적으로 확정되었습니다.');
    } catch (error) {
      alert(error.message || '확정에 실패했습니다.');
    }
  };

  const memoizedTreeData = useMemo(() => treeData, [treeData]);

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
          <div className="container mx-auto px-6 py-8">
            <nav className="mb-6">
              <div className="flex items-center text-sm text-gray-600">
                <Link to="/testset" className="hover:text-[#4ABC85]">학습구성</Link>
              </div>
            </nav>
            <div className="mt-4">
              <button className="bg-blue-500 text-white px-4 py-2 rounded mr-2" onClick={() => setShowModal(true)}>
                새 교육과정 추가
              </button>
              <button className="bg-red-500 text-white px-4 py-2 rounded mr-2" onClick={handleDeleteCategory}>
                교육과정 삭제
              </button>
              <button className="bg-green-500 text-white px-4 py-2 rounded mr-2" onClick={handleUpdateClick}>
                교육과정 수정
              </button>
            </div>
            {error && <div className="bg-red-100 text-red-700 p-3 mt-4 rounded">{error}</div>}
            <div className="flex border-b border-gray-200 mt-4">
              {categories.map((category) => (
                <button
                  key={category.tree_id}
                  className={`px-4 py-2 flex flex-col items-center ${activeTab === category.tree_id ? 'border-b-2 border-blue-500' : ''}`}
                  onClick={() => setActiveTab(category.tree_id)}
                >
                  <span className="text-sm text-gray-600">{category.edu_ver}</span>
                  <span>{category.tree_name}</span>
                </button>
              ))}
            </div>
            <TestsetComponent 
              data={memoizedTreeData} 
              tree_id={activeTab} 
              fetchTreeData={fetchTreeData}
              onPriorityUpdate={handlePriorityUpdate}
              onCloseableCheck={handleCloseableCheck}
              onClose={handleClose}
            />
          </div>
        </main>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4">새 카테고리 추가</h2>
            <form onSubmit={(e) => { e.preventDefault(); handleAddCategory(); }}>
              <div className="mb-4">
                <label htmlFor="eduVer" className="block mb-2">교육과정</label>
                <input
                  type="text"
                  id="eduVer"
                  className="w-full p-2 border border-gray-300 rounded"
                  value={newEduVer}
                  onChange={(e) => setNewEduVer(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="treeName" className="block mb-2">과목 이름</label>
                <input
                  type="text"
                  id="treeName"
                  className="w-full p-2 border border-gray-300 rounded"
                  value={newTreeName}
                  onChange={(e) => setNewTreeName(e.target.value)}
                  required
                />
              </div>
              <div className="flex justify-end">
                <button type="button" className="bg-gray-500 text-white px-4 py-2 rounded mr-2" onClick={() => setShowModal(false)}>
                  취소
                </button>
                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                  추가
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showUpdateModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4">카테고리 수정</h2>
            <form onSubmit={(e) => { e.preventDefault(); handleUpdateCategory(); }}>
              <div className="mb-4">
                <label htmlFor="updateEduVer" className="block mb-2">교육과정</label>
                <input
                  type="text"
                  id="updateEduVer"
                  className="w-full p-2 border border-gray-300 rounded"
                  value={updateData.edu_ver}
                  onChange={(e) => setUpdateData({...updateData, edu_ver: e.target.value})}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="updateTreeName" className="block mb-2">과목 이름</label>
                <input
                  type="text"
                  id="updateTreeName"
                  className="w-full p-2 border border-gray-300 rounded"
                  value={updateData.tree_name}
                  onChange={(e) => setUpdateData({...updateData, tree_name: e.target.value})}
                  required
                />
              </div>
              <div className="flex justify-end">
                <button type="button" className="bg-gray-500 text-white px-4 py-2 rounded mr-2" onClick={() => setShowUpdateModal(false)}>
                  취소
                </button>
                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                  수정
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {successMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
          <span className="block sm:inline">{successMessage}</span>
        </div>
      )}
    </div>
  );
}

export default TestsetPage;
