import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useLocation } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

function AnswerModal({ problemId, solution, onClose, onUpdate }) {
  const { getAccessToken } = useAuth();
  const [answer, setAnswer] = useState(solution?.solution_info?.answer || '');
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const token = await getAccessToken();
      if (!token) throw new Error('인증 토큰이 없습니다.');

      const url = solution 
        ? `/api/solutions/${solution.solution_id}/answer`
        : '/api/solutions';

      const method = solution ? 'PUT' : 'POST';
      const body = solution 
        ? { answer }
        : { problem_id: problemId, answer };

      const response = await fetch(`${API_BASE_URL}${url}`, {
        method,
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('API 요청 실패');
      }

      alert(solution ? '정답이 수정되었습니다.' : '정답이 등록되었습니다.');
      onClose();

      // sessionStorage에서 이전 URL 가져오기
      const previousUrl = sessionStorage.getItem('previousUrl');
      if (previousUrl && previousUrl.includes('labels=')) {
        // 이전 페이지가 라벨 필터였던 경우 해당 URL로 이동
        window.location.href = previousUrl;
      } else {
        // 그 외의 경우 전체 페이지 새로고침
        window.location.reload();
      }
    } catch (error) {
      console.error('정답 처리 중 오류:', error);
      alert('정답 처리에 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-96">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">
            {solution ? '정답 수정' : '정답 등록'}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              정답
            </label>
            <input
              type="text"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              required
            />
          </div>

          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
            >
              취소
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
            >
              {isLoading ? '처리 중...' : (solution ? '수정' : '등록')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AnswerModal; 