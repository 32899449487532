import type { LexicalNode } from 'lexical';

import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { HashtagNode } from '@lexical/hashtag';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { MarkNode } from '@lexical/mark';
import { OverflowNode } from '@lexical/overflow';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { Class } from 'utility-types';

import { EmojiNode } from './EmojiNode';
import { ImageNode } from './ImageNode';
import { KeywordNode } from './KeywordNode';
import { MentionNode } from './MentionNode';
import { PollNode } from './PollNode';
import { StickyNode } from './StickyNode';
import { TweetNode } from './TweetNode';
import { TypeaheadNode } from './TypeaheadNode';
import { YouTubeNode } from './YouTubeNode';

import { EquationNode } from './EquationNode';
import { LayoutContainerNode } from './LayoutContainerNode';
import { LayoutItemNode } from './LayoutItemNode';
import {PageBreakNode} from './PageBreakNode';

import {CollapsibleContainerNode} from '../nodes/CollapsibleContainerNode';
import {CollapsibleContentNode} from '../nodes/CollapsibleContentNode';
import {CollapsibleTitleNode} from '../nodes/CollapsibleTitleNode';

import { BoxNode } from './BoxNode';
import { BoxContentNode } from './BoxContentNode';

const PlaygroundNodes: Array<Class<LexicalNode>> = [
  HeadingNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  CodeNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  HashtagNode,
  CodeHighlightNode,
  AutoLinkNode,
  LinkNode,
  OverflowNode,
  PollNode,
  StickyNode,
  ImageNode,
  MentionNode,
  EmojiNode,
  TypeaheadNode,
  KeywordNode,
  HorizontalRuleNode,
  TweetNode,
  YouTubeNode,
  MarkNode,
  EquationNode,
  LayoutContainerNode,
  LayoutItemNode,
  PageBreakNode,
  CollapsibleContainerNode,
  CollapsibleContentNode,
  CollapsibleTitleNode,
  BoxNode,
  BoxContentNode,
];

export default PlaygroundNodes;
