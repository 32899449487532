import React from 'react';

function SignUp() {
  return (
    <div>
      <h1>회원가입</h1>
      {/* 회원가입 폼 컴포넌트를 여기에 추가 */}
    </div>
  );
}

export default SignUp;
