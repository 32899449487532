import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { useAuth } from '../context/AuthContext';
import { ChevronUp, ChevronDown } from 'lucide-react';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

function PreReservationPage() {
  const { getAccessToken, handleApiError } = useAuth();
  const navigate = useNavigate();
  const [reservations, setReservations] = useState([]);
  const [error, setError] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'descending' });

  const fetchWithTokenRefresh = useCallback(async (url, options = {}) => {
    try {
      const token = await getAccessToken();
      if (!token) {
        navigate('/signin');
        return null;
      }
      
      const response = await fetch(`${API_BASE_URL}${url}`, {
        ...options,
        headers: {
          ...options.headers,
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      const data = await response.json();
      
      if (data.status_code === 401 && data.message === 'Invalid or expired token') {
        handleApiError(new Error('Invalid or expired token'));
        return null;
      }

      return data;
    } catch (error) {
      console.error('API 호출 에러:', error);
      throw error;
    }
  }, [getAccessToken, navigate, handleApiError]);

  const fetchReservations = useCallback(async () => {
    try {
      const response = await fetchWithTokenRefresh('/api/pre_reservations/');
      if (response && response.status === 'success') {
        setReservations(response.data);
        setError('');
      }
    } catch (error) {
      // 에러 처리만 하고 추가 리다이렉션은 하지 않음
      console.error('사전예약자 정보 불러오기 실패:', error);
      setError('사전예약자 정보를 불러오는데 실패했습니다.');
    }
  }, [fetchWithTokenRefresh]);

  const sortedReservations = useCallback(() => {
    const sortableReservations = [...reservations];
    if (sortConfig.key) {
      sortableReservations.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableReservations;
  }, [reservations, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    fetchReservations();
  }, [fetchReservations]);

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
          <div className="container mx-auto px-6 py-8">
            <h3 className="text-gray-700 text-3xl font-medium">사전예약자 목록</h3>
            {error && <div className="bg-red-100 text-red-700 p-3 mt-4 rounded">{error}</div>}
            <div className="mt-8">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      예약 ID
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      이름
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      전화번호
                    </th>
                    <th 
                      className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer"
                      onClick={() => requestSort('created_at')}
                    >
                      <div className="flex items-center justify-center">
                        예약 일시
                        {sortConfig.key === 'created_at' && (
                          sortConfig.direction === 'ascending' ? <ChevronUp className="ml-1 h-4 w-4" /> : <ChevronDown className="ml-1 h-4 w-4" />
                        )}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedReservations().map((reservation) => (
                    <tr key={reservation.reservation_id}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                        {reservation.reservation_id}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                        {reservation.user_name}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                        {reservation.mobile_number}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center">
                        {new Date(reservation.created_at).toLocaleString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default PreReservationPage;
