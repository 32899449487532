import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { usePrint } from '../../context/PrintContext';

function WrongAnswerModal({ isOpen, onClose, examList, stampId, tree_name, edu_ver, student_name }) {
  const [selectedExams, setSelectedExams] = useState([]);
  const { getAccessToken } = useAuth();
  const navigate = useNavigate();
  const { setPrintData } = usePrint();

  // 전체선택 상태 추가
  const [selectAll, setSelectAll] = useState(false);

  if (!isOpen) return null;

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleConfirm = async () => {
    if (selectedExams.length === 0) {
      alert('시험을 선택해주세요.');
      return;
    }

    if (selectedExams.length > 100) {
      alert('시험은 최대 100개까지만 선택 가능합니다.');
      return;
    }

    try {
      const token = await getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/customexams/temp/errnote?exam_ids=${selectedExams.join(',')}`,
        { headers: { 'Authorization': token } }
      );

      if (response.status === 204) {
        alert('선택한 시험들에서 오답을 찾을 수 없습니다.');
        return;
      }

      if (response.status === 422) {
        alert('오답 문제가 100개를 초과합니다.');
        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const wrongAnswers = data.data.failed_probs;
      
      // 클립보드에 복사
      await navigator.clipboard.writeText(wrongAnswers);
      alert('오답 문제 ID가 클립보드에 복사되었습니다.');
      onClose();
    } catch (error) {
      console.error('오답 수집 중 오류 발생:', error);
      alert('오답 수집 중 오류가 발생했습니다.');
    }
  };

  const handlePrint = async () => {
    if (selectedExams.length === 0) {
      alert('시험을 선택해주세요.');
      return;
    }

    if (selectedExams.length > 100) {
      alert('시험은 최대 100개까지만 선택 가능합니다.');
      return;
    }

    try {
      const token = await getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/customexams/temp/errnote?exam_ids=${selectedExams.join(',')}`,
        { headers: { 'Authorization': token } }
      );

      if (response.status === 204) {
        alert('선택한 시험들에서 오답을 찾을 수 없습니다.');
        return;
      }

      if (response.status === 422) {
        alert('오답 문제가 100개를 초과합니다.');
        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const wrongAnswers = data.data.failed_probs;
      
      // 선택된 시험 중 첫 번째 시험 정보 가져오기
      const selectedExam = examList.find(exam => exam.exam_id === selectedExams[0]);
      
      setPrintData({
        problemIds: wrongAnswers,
        examInfo: {
          tree_name: tree_name || selectedExam.tree_name || '기본 교육과정',
          edu_ver: edu_ver || selectedExam.edu_ver || '기본 과목',
          exam_id: selectedExams[0] + ' ...',
          test_number: 'v7'
        },
        studentInfo: {
          student_name: student_name || selectedExam.student_name || '학생'
        },
        footerMessages: {
          left: '카이테스트 ˑ 오답노트',
          right: edu_ver + ' · ' + tree_name || selectedExam.edu_ver + ' · ' + selectedExam.tree_name || '',
          memo: 'TID: ' + selectedExams.join(', ')
        },
        isErrorNote: true
      });

      navigate('/problem-print');
      onClose();
    } catch (error) {
      console.error('오답 수집 중 오류 발생:', error);
      alert('오답 수집 중 오류가 발생했습니다.');
    }
  };

  // 전체선택 처리 함수
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedExams(examList.map(exam => exam.exam_id));
      setSelectAll(true);
    } else {
      setSelectedExams([]);
      setSelectAll(false);
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={handleBackgroundClick}
    >
      <div 
        className="bg-white p-6 rounded-lg w-3/4 max-h-[80vh] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">오답노트 생성</h2>
          <div className="flex gap-2">
            {/* <button
              onClick={handleConfirm}
              className="px-4 py-2 bg-[#4ABC85] text-white rounded hover:bg-[#3da975]"
            >
              오답 ID 복사
            </button> */}
            <button
              onClick={handlePrint}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              오답 출력
            </button>
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 rounded"
            >
              닫기
            </button>
          </div>
        </div>
        
        {/* 전체선택 체크박스 */}
        <div className="mb-4 flex items-center">
          <label className="flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
              className="w-5 h-5 mr-2"
            />
            <span className="text-sm font-medium select-none">전체 선택</span>
          </label>
        </div>

        <div className="space-y-4">
          {examList.map((exam) => (
            <div
              key={exam.exam_id}
              className="flex items-center space-x-6 p-4 border rounded hover:bg-gray-50 cursor-pointer"
              onClick={(e) => {
                if (!e.target.matches('input[type="checkbox"]')) {
                  setSelectedExams(prev => {
                    const isSelected = prev.includes(exam.exam_id);
                    const newSelection = isSelected
                      ? prev.filter(id => id !== exam.exam_id)
                      : [...prev, exam.exam_id];
                    setSelectAll(newSelection.length === examList.length);
                    return newSelection;
                  });
                }
              }}
            >
              <div className="px-4">
                <input
                  type="checkbox"
                  checked={selectedExams.includes(exam.exam_id)}
                  onChange={(e) => {
                    setSelectedExams(prev => {
                      const newSelection = e.target.checked 
                        ? [...prev, exam.exam_id]
                        : prev.filter(id => id !== exam.exam_id);
                      
                      // 전체선택 상태 업데이트
                      setSelectAll(newSelection.length === examList.length);
                      
                      return newSelection;
                    });
                  }}
                  className="w-5 h-5"
                />
              </div>
              <div className="flex-1 px-6 py-2 border-r border-gray-200">
                <p className="text-sm text-gray-500 mb-1">TID</p>
                <p className="font-semibold">{exam.exam_id}</p>
              </div>
              <div className="flex-1 px-6 py-2 border-r border-gray-200">
                <p className="text-sm text-gray-500 mb-1">테스트 상태</p>
                <p className="font-semibold">
                  {exam.exam_status === 200 ? "결과입력필요" : 
                   exam.exam_status === 400 ? "결과입력완료" : 
                   exam.exam_status === 999 ? "결과확정완료" : "상태 미정"}
                </p>
              </div>
              <div className="flex-1 px-6 py-2 border-r border-gray-200">
                <p className="text-sm text-gray-500 mb-1">테스트 유형</p>
                <p className="font-semibold">{exam.exam_type}</p>
              </div>
              <div className="flex-1 px-6 py-2 border-r border-gray-200">
                <p className="text-sm text-gray-500 mb-1">생성일시</p>
                <p className="font-semibold">
                  {new Date(exam.exam_created_at).toLocaleDateString()}
                </p>
              </div>
              <div className="flex-1 px-6 py-2 border-r border-gray-200">
                <p className="text-sm text-gray-500 mb-1">수정일시</p>
                <p className="font-semibold">
                  {new Date(exam.exam_updated_at).toLocaleDateString()}
                </p>
              </div>
              <div className="flex-1 px-6 py-2 border-r border-gray-200">
                <p className="text-sm text-gray-500 mb-1">문제수</p>
                <p className="font-semibold">{exam.problems_count}</p>
              </div>
              <div className="flex-1 px-6 py-2">
                <p className="text-sm text-gray-500 mb-1">난이도</p>
                <p className="font-semibold">{exam.exam_difficulty}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default WrongAnswerModal; 