import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { useAuth } from '../context/AuthContext';
import { TrendingUp, TrendingDown, Minus } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart, Bar } from 'recharts';

// KST 시간으로 변환하는 함수 추가
const formatKSTDateTime = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const kstDate = new Date(date.getTime() + (9 * 60 * 60 * 1000)); // UTC to KST (+9 hours)
  
  const year = kstDate.getFullYear();
  const month = String(kstDate.getMonth() + 1).padStart(2, '0');
  const day = String(kstDate.getDate()).padStart(2, '0');
  const hours = String(kstDate.getHours()).padStart(2, '0');
  const minutes = String(kstDate.getMinutes()).padStart(2, '0');
  
  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

function Datapage() {
  const navigate = useNavigate();
  const { getAccessToken } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [eloData, setEloData] = useState(null);
  const [error, setError] = useState(null);
  const [selectedTreeId, setSelectedTreeId] = useState(null);
  const [simulationResult, setSimulationResult] = useState(null);
  const [simulationParams, setSimulationParams] = useState({
    k: 100,
    q: 4000,
    lv1: 2000,
    lv2: 4000,
    lv3: 6000,
    lv4: 8000,
    lv5: 10000,
    init_rating: 3000
  });
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const [modalSimulationParams, setModalSimulationParams] = useState({
    e_rating: 0,
    s_rating: 0,
    k: 100,
    q: 4000,
    lv1: 2000,
    lv2: 4000,
    lv3: 6000,
    lv4: 8000,
    lv5: 10000
  });
  const [selectedExamId, setSelectedExamId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const numValue = value === '' ? '' : Number(value);
    setSimulationParams(prev => ({
      ...prev,
      [name]: numValue
    }));
  };

  const handleCardClick = (treeId) => {
    setSelectedTreeId(treeId);
  };

  const handleSimulation = async () => {
    // 카드 선택 확인
    if (!selectedTreeId) {
      alert('시뮬레이션할 교재를 선택해주세요.');
        return;
      }
      
    // 입력값 검증
    const isValid = Object.values(simulationParams).every(value => 
      typeof value === 'number' && value !== 0
    );
    
    if (!isValid) {
      alert('모든 필드에 0이 아닌 숫자를 입력해주세요.');
      return;
    }

    try {
      const token = await getAccessToken();
      const queryParams = new URLSearchParams({
        tree_id: selectedTreeId,
        k_value: simulationParams.k,
        q_value: simulationParams.q,
        lv1: simulationParams.lv1,
        lv2: simulationParams.lv2,
        lv3: simulationParams.lv3,
        lv4: simulationParams.lv4,
        lv5: simulationParams.lv5,
        init_rating: simulationParams.init_rating
      }).toString();

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/elotest/list?${queryParams}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 404) {
        setSimulationResult(null);
        return;
      }

      if (!response.ok) {
        throw new Error('Simulation failed');
      }

      const data = await response.json();
      setSimulationResult(data);
      // 데이터가 있으면 첫 번째 학생 자동 선택
      if (data.data && data.data.length > 0) {
        setSelectedStudentId(data.data[0].student_id);
      }
    } catch (error) {
      console.error('Error running simulation:', error);
      alert('시뮬레이션 실행 중 오류가 발생했습니다.');
    }
  };

  const handleStudentClick = (studentId) => {
    setSelectedStudentId(studentId);
  };

  const handleRowClick = async (exam) => {
    try {
      const token = await getAccessToken();
      const scoreRef = [
        simulationParams.k,
        simulationParams.q,
        simulationParams.lv1,
        simulationParams.lv2,
        simulationParams.lv3,
        simulationParams.lv4,
        simulationParams.lv5
      ].join(',');

      const queryParams = new URLSearchParams({
        exam_id: exam.exam_id,
        e_rating: exam.rating_info.e_rating,
        s_rating: exam.rating_info.s_rating,
        e_delta: exam.rating_info.e_delta,
        s_delta: exam.rating_info.s_delta,
        score_ref: scoreRef
      }).toString();

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/elotest/detail?${queryParams}`,
        {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch detail data');
      }

      const data = await response.json();
      setDetailData(data);
      setSelectedExamId(exam.exam_id);
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching detail data:', error);
      alert('상세 정보를 불러오는 중 오류가 발생했습니다.');
    }
  };

  useEffect(() => {
    const fetchEloData = async () => {
      try {
        const token = await getAccessToken();
        if (!token) {
          navigate('/signin');
          return;
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/elotest`,
          {
          headers: {
              'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch ELO test data');
        }

        const data = await response.json();
        setEloData(data.data);
      } catch (error) {
        console.error('Error fetching ELO test data:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEloData();
  }, [getAccessToken, navigate]);

  // 숫자에 천 단위 콤마를 추가하는 함수
  const formatNumber = (number) => {
    if (number === undefined || number === null) {
      return '0';
    }
    return number.toLocaleString();
  };

  // 모달이 열릴 때만 초기값을 설정하도록 수정
  useEffect(() => {
    if (detailData && showModal) {  // showModal 조건 추가
      setModalSimulationParams(prev => ({
        ...prev,  // 기존 값 유지
        e_rating: Math.round(detailData.data.net_e_rating - detailData.data.net_e_delta),
        s_rating: Math.round(detailData.data.net_s_rating - detailData.data.net_s_delta),
        // 최초 모달 생성 시에만 시뮬레이션 파라미터 설정
        ...(prev.k === 0 ? {  // 초기 상태일 때만 값 설정
          k: Math.round(simulationParams.k),
          q: Math.round(simulationParams.q),
          lv1: Math.round(simulationParams.lv1),
          lv2: Math.round(simulationParams.lv2),
          lv3: Math.round(simulationParams.lv3),
          lv4: Math.round(simulationParams.lv4),
          lv5: Math.round(simulationParams.lv5)
        } : {})
      }));
    }
  }, [detailData, showModal]);  // showModal 의존성 추가

  // input 필드에 step="1" 추가하여 정수만 입력 가능하도록 수정
  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    // 빈 값이거나 숫자가 아닌 경우 처리
    const numValue = value === '' ? 0 : Math.round(Number(value));
    setModalSimulationParams(prev => ({
      ...prev,
      [name]: numValue
    }));
  };

  // API 호출 전 모든 파라미터를 정수로 변환
  const handleModalSimulation = async () => {
    try {
      const token = await getAccessToken();
      const params = {
        exam_id: selectedExamId,
        e_rating: Math.round(modalSimulationParams.e_rating),
        s_rating: Math.round(modalSimulationParams.s_rating),
        e_delta: 0,
        s_delta: 0,
        score_ref: [
          Math.round(modalSimulationParams.k),
          Math.round(modalSimulationParams.q),
          Math.round(modalSimulationParams.lv1),
          Math.round(modalSimulationParams.lv2),
          Math.round(modalSimulationParams.lv3),
          Math.round(modalSimulationParams.lv4),
          Math.round(modalSimulationParams.lv5)
        ].join(',')
      };

      const queryParams = new URLSearchParams(params).toString();

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/elotest/detail?${queryParams}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch detail data');
      }

      const data = await response.json();
      // rating 값들을 정수로 반올림하여 저장
      data.data.net_e_rating = Math.round(data.data.net_e_rating);
      data.data.net_s_rating = Math.round(data.data.net_s_rating);
      setDetailData(data);
    } catch (error) {
      console.error('Error running modal simulation:', error);
      alert('시뮬레이션 실행 중 오류가 발생했습니다.');
    }
  };

  // 검색어에 따라 필터링된 학생 목록을 반환하는 함수
  const filteredStudents = useMemo(() => {
    if (!simulationResult?.data) return [];
    return simulationResult.data.filter(student =>
      student.student_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [simulationResult?.data, searchQuery]);

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
          <div className="container mx-auto px-6 py-8">
            <h3 className="text-gray-700 text-3xl font-medium mb-4">데이터 관리</h3>
            
            {/* 상단 영역 - 높이 조정 */}
            <div className="flex gap-6 mb-6 h-[300px]"> {/* 높이 고정 */}
              {/* 좌상단 영역 - 1번 영역 수정 */}
              <div className="w-1/2 bg-white rounded-lg shadow-md p-4 overflow-auto"> {/* padding 축소 */}
                {isLoading ? (
                  <div className="flex justify-center items-center h-32"> {/* 로딩 영역 축소 */}
                    <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
                  </div>
                ) : error ? (
                  <div className="bg-red-100 border border-red-400 text-red-700 px-3 py-2 rounded text-sm">
                    {error}
                  </div>
                ) : (
                  <div className="grid grid-cols-2 gap-3 overflow-y-auto h-full"> {/* gap 축소, 스크롤 추가 */}
                    {eloData && eloData.map((item) => (
                      <div 
                        key={item.tree_id}
                        onClick={() => handleCardClick(item.tree_id)}
                        className={`
                          bg-gray-50 rounded-lg p-2 cursor-pointer
                          transition-all duration-200 text-center
                          ${selectedTreeId === item.tree_id 
                            ? 'ring-2 ring-blue-500 shadow-md bg-blue-50 border-blue-500' 
                            : 'hover:shadow border border-transparent'
                          }
                        `}
                      >
                        <h4 className="text-sm font-semibold text-gray-800">
                          {item.tree_name}
                        </h4>
                        <div className="mt-1 text-xs text-gray-600">
                          <p>교육과정: {item.edu_ver}</p>
                          <p>Tree ID: {item.tree_id}</p>
                        </div>
                      </div>
                    ))}
                </div>
                )}
                </div>

              {/* 우상단 영역 - 2번 영역 수정 */}
              <div className="w-1/2 bg-white rounded-lg shadow-md p-4 overflow-auto">
                <h4 className="text-sm font-semibold mb-3">시뮬레이션 설정</h4>
                <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                  {Object.entries(simulationParams).map(([key, value]) => (
                    <div key={key} className="flex items-center justify-between">
                      <label className="text-xs font-medium text-gray-700 w-20">
                        {key === 'init_rating' ? 'Initial Rating' : key.toUpperCase()}
                    </label>
                    <input
                      type="number"
                        name={key}
                        value={value}
                        onChange={handleInputChange}
                        className="border rounded-md px-2 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-24"
                        min="1"
                    />
                  </div>
                ))}
              </div>
              <button
                    onClick={handleSimulation}
                    className={`
                      mt-4 w-full py-1.5 rounded-md transition-colors text-sm
                      ${selectedTreeId 
                        ? 'bg-blue-600 hover:bg-blue-700 text-white' 
                        : 'bg-gray-300 cursor-not-allowed text-gray-500'
                      }
                    `}
                    disabled={!selectedTreeId}
                  >
                    시뮬레이션
                  </button>
                  {!selectedTreeId && (
                    <div className="mt-2 text-center text-sm text-gray-500">
                      왼쪽 Tree를 선택해주세요
                    </div>
                  )}
                </div>
            </div>

            {/* 하단 영역 */}
            <div className="flex gap-6 h-[calc(100vh-500px)]"> {/* 하단 영역 전체 높이 고정 */}
              {/* 좌측 학생 리스트 영역 */}
              <div className="w-1/3 bg-white rounded-lg shadow-md overflow-hidden">
                <div className="h-full flex flex-col">
                  <div className="p-4 bg-gray-50 border-b">
                    <h4 className="text-lg font-semibold mb-3">학생 목록</h4>
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="학생 이름으로 검색..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md 
                                   focus:outline-none focus:ring-2 focus:ring-blue-500 
                                   focus:border-blue-500 text-sm"
                      />
                      {searchQuery && (
                        <button
                          onClick={() => setSearchQuery('')}
                          className="absolute right-2 top-1/2 -translate-y-1/2 
                                     text-gray-400 hover:text-gray-600"
                        >
                          ✕
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="flex-1 overflow-y-auto p-4">
                    <div className="space-y-3">
                      {filteredStudents.map((student) => (
                        <div
                          key={student.student_id}
                          onClick={() => handleStudentClick(student.student_id)}
                          className={`
                            bg-gray-50 rounded-lg p-3 shadow-sm cursor-pointer
                            transition-all duration-200
                            ${selectedStudentId === student.student_id 
                              ? 'ring-2 ring-blue-500 shadow-md bg-blue-50' 
                              : 'hover:bg-gray-100'
                            }
                          `}
                        >
                          <div className="space-y-1">
                            <div className="font-semibold text-gray-800">
                              {student.student_name}
                            </div>
                            <div className="text-sm text-gray-600">
                              {student.school_name}({student.school_grade}학년)
                            </div>
                          </div>
                        </div>
                      ))}
                      {filteredStudents.length === 0 && (
                        <div className="text-center text-gray-500 py-4">
                          검색 결과가 없습니다
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* 우측 그래프+테이블 영역 */}
              <div className="w-2/3 bg-white rounded-lg shadow-md overflow-hidden">
                <div className="h-full flex flex-col">
                  {simulationResult?.data.map((student) => (
                    <div key={student.student_id} className={`h-full ${selectedStudentId === student.student_id ? 'block' : 'hidden'}`}>
                      {student.exams && student.exams.length > 0 ? (
                        <div className="h-full flex flex-col">
                          {/* 그래프 영역 - 고정 높이 */}
                          <div className="h-[300px] p-4">
                            <ResponsiveContainer width="100%" height="100%">
                              <ComposedChart
                                data={student.exams.slice().reverse()}
                                margin={{
                                  top: 5,
                                  right: 30,
                                  left: 20,
                                  bottom: 5,
                                }}
                              >
                                <XAxis 
                                  dataKey="exam_count" 
                                  axisLine={true}
                                  tickLine={true}
                                  label={false}
                                />
                                <YAxis 
                                  yAxisId="rating"
                                  axisLine={true}
                                  tickLine={true}
                                  label={false}
                                  domain={['auto', 'auto']}
                                  tickFormatter={(value) => formatNumber(value)}
                                />
                                <YAxis 
                                  yAxisId="progress"
                                  hide={true}
                                  domain={[0, dataMax => dataMax * 5]}
                                />
                                <Tooltip 
                                  formatter={(value, name) => {
                                    if (name === 'progress_time') {
                                      return [formatNumber(value), 'Progress'];
                                    }
                                    const label = name === 'rating_info.s_rating' ? 'Sequential' : 'Exp-wins';
                                    return [formatNumber(value), label];
                                  }}
                                  labelFormatter={(label, items) => {
                                    const exam = items[0]?.payload;
                                    if (!exam) return label;
                                    
                                    return [
                                      `${exam.exam_count}회차`,
                                      `난이도: ${(exam.difficulty / 100).toFixed(2)}`,
                                      `${formatKSTDateTime(exam.updated_at)}`
                                    ].join('\n');
                                  }}
                                />
                                <Legend 
                                  formatter={(value) => {
                                    if (value === 'progress_time') return 'Progress';
                                    return value === 'rating_info.s_rating' ? 'Sequential' : 'Exp-wins';
                                  }}
                                />
                                <Bar 
                                  yAxisId="progress"
                                  dataKey="progress_time"
                                  fill="#8884d8"
                                  opacity={0.3}
                                  barSize={10}
                                />
                                <Line
                                  yAxisId="rating"
                                  type="monotone"
                                  dataKey="rating_info.s_rating"
                                  name="rating_info.s_rating"
                                  stroke="#8884d8"
                                  dot={{ r: 3 }}
                                  activeDot={{ r: 5 }}
                                />
                                <Line
                                  yAxisId="rating"
                                  type="monotone"
                                  dataKey="rating_info.e_rating"
                                  name="rating_info.e_rating"
                                  stroke="#82ca9d"
                                  dot={{ r: 3 }}
                                  activeDot={{ r: 5 }}
                                />
                              </ComposedChart>
                            </ResponsiveContainer>
                          </div>
                          
                          {/* 테이블 영역 - 남은 공간 채우고 스크롤 */}
                          <div className="flex-1 overflow-y-auto border-t">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    TID
                                  </th>
                                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    회차
                                  </th>
                                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    난이도
                                  </th>
                                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    문항 수
                                  </th>
                                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Progress
                                  </th>
                                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider h-14">
                                    <div>Rating</div>
                                    <div className="text-[10px]">(Exp-wins)</div>
                                  </th>
                                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider h-14">
                                    <div>Rating</div>
                                    <div className="text-[10px]">(Sequencial)</div>
                                  </th>
                                  <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    일시
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="bg-white divide-y divide-gray-200">
                                {student.exams.map((exam) => (
                                  <tr 
                                    key={exam.exam_id} 
                                    className="hover:bg-gray-50 cursor-pointer" 
                                    onClick={() => handleRowClick(exam)}
                                  >
                                    <td className="px-4 py-3 text-center text-sm text-gray-900">
                                      {exam.exam_id}
                                    </td>
                                    <td className="px-4 py-3 text-center text-sm text-gray-900">
                                      {exam.exam_count}
                                    </td>
                                    <td className="px-4 py-3 text-center text-sm text-gray-900">
                                      <div>{(exam.difficulty / 100).toFixed(2)}</div>
                                      <div className="text-xs text-gray-500">
                                        {exam.rating_info.probs.map((count, i) => (
                                          `${count}${i < exam.rating_info.probs.length - 1 ? '-' : ''}`
                                        )).join('')}
                                      </div>
                                    </td>
                                    <td className="px-4 py-3 text-center text-sm text-gray-900">
                                      <div>{exam.length}</div>
                                      <div className="text-xs text-gray-500">
                                        {exam.correct_count}-{exam.wrong_count}
                                      </div>
                                    </td>
                                    <td className="px-4 py-3 text-center text-sm text-gray-900">
                                      {formatNumber(exam?.progress_time)}
                                    </td>
                                    <td className="px-4 py-3 text-center text-sm text-gray-900">
                                      <div className="font-bold">{formatNumber(exam?.rating_info?.e_rating)}</div>
                                      <div className={`text-xs ${
                                        exam?.rating_info?.e_delta > 0 ? 'text-red-500' : 
                                        exam?.rating_info?.e_delta < 0 ? 'text-blue-500' : 
                                        'text-gray-500'
                                      }`}>
                                        {exam?.rating_info?.e_delta !== 0 && (
                                          exam?.rating_info?.e_delta > 0 ? '▲' : '▼'
                                        )}
                                        {formatNumber(exam?.rating_info?.e_delta)}
                                      </div>
                                    </td>
                                    <td className="px-4 py-3 text-center text-sm text-gray-900">
                                      <div className="font-bold">{formatNumber(exam?.rating_info?.s_rating)}</div>
                                      <div className={`text-xs ${
                                        exam?.rating_info?.s_delta > 0 ? 'text-red-500' : 
                                        exam?.rating_info?.s_delta < 0 ? 'text-blue-500' : 
                                        'text-gray-500'
                                      }`}>
                                        {exam?.rating_info?.s_delta !== 0 && (
                                          exam?.rating_info?.s_delta > 0 ? '▲' : '▼'
                                        )}
                                        {formatNumber(exam?.rating_info?.s_delta)}
                                    </div>
                                    </td>
                                    <td className="px-4 py-3 text-center text-sm text-gray-900">
                                      {formatKSTDateTime(exam.updated_at)}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : (
                        <div className="flex justify-center items-center h-full text-gray-500">
                          조회된 데이터 없음
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-8 w-[160rem] h-[120rem] max-w-[90vw] max-h-[90vh]">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-xl font-semibold">상세 정보</h3>
              <button
                onClick={() => setShowModal(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                ✕
              </button>
            </div>
            
            <div className="flex gap-6 h-[calc(100%-4rem)]">
              {/* 왼쪽 영역 (70%) */}
              <div className="w-[70%] bg-gray-50 rounded-lg p-6 overflow-y-auto">
                <div className="space-y-6">
                  {/* 요약 정보 영역 */}
                  <div className="bg-white rounded-lg p-4 shadow-sm">
                    <h5 className="text-base font-semibold mb-3">시험 결과 요약</h5>
                    <div className="grid grid-cols-2 gap-4">
                      {/* Exp-wins 메소드 요약 */}
                      <div className="space-y-2">
                        <h6 className="text-sm font-medium text-gray-700">Exp-wins</h6>
                        <div className="grid grid-cols-1 gap-2">
                          <div className="text-center p-3 bg-gray-50 rounded-lg">
                            <div className="text-lg font-bold">
                              {detailData ? (
                                <>
                                  {formatNumber(detailData.data.net_e_rating - detailData.data.net_e_delta)}
                                  <span className="mx-2 text-gray-400">⟹</span>
                                  {formatNumber(detailData.data.net_e_rating)}
                                  <span className={`text-xs ml-1 ${
                                    detailData.data.net_e_delta > 0 ? 'text-red-500' : 'text-blue-500'
                                  }`}>
                                    {detailData.data.net_e_delta > 0 ? '▲' : '▼'}
                                    {formatNumber(detailData.data.net_e_delta)}
                                  </span>
                                </>
                              ) : '-'}
                            </div>
                          </div>
                          <div className="grid grid-cols-3 gap-2">
                            <div className="text-center p-3 bg-gray-50 rounded-lg">
                              <div className="text-sm text-gray-600">기대 정답 수</div>
                              <div className="text-lg font-bold">
                                {detailData ? formatNumber(detailData.data.net_e_win_count) : '-'}
                              </div>
                            </div>
                            <div className="text-center p-3 bg-gray-50 rounded-lg">
                              <div className="text-sm text-gray-600">실제 정답 수</div>
                              <div className="text-lg font-bold">
                                {detailData ? detailData.data.correct_count : '-'}
                              </div>
                            </div>
                            <div className="text-center p-3 bg-gray-50 rounded-lg">
                              <div className="text-sm text-gray-600">시험 Rating</div>
                              <div className="text-lg font-bold">
                                {detailData ? formatNumber(detailData.data.exam_rating) : '-'}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Sequential 메소드 요약 */}
                      <div className="space-y-2">
                        <h6 className="text-sm font-medium text-gray-700">Sequential</h6>
                        <div className="text-center p-3 bg-gray-50 rounded-lg">
                          <div className="text-lg font-bold">
                            {detailData ? (
                              <>
                                {formatNumber(detailData.data.net_s_rating - detailData.data.net_s_delta)}
                                <span className="mx-2 text-gray-400">⟹</span>
                                {formatNumber(detailData.data.net_s_rating)}
                                <span className={`text-xs ml-1 ${
                                  detailData.data.net_s_delta > 0 ? 'text-red-500' : 'text-blue-500'
                                }`}>
                                  {detailData.data.net_s_delta > 0 ? '▲' : '▼'}
                                  {formatNumber(detailData.data.net_s_delta)}
                                </span>
                              </>
                            ) : '-'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* 문제별 상세 결과 테이블 */}
                  <div className="bg-white rounded-lg shadow-sm overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-3 py-2 text-xs font-medium text-gray-500 text-center">ID</th>
                          <th className="px-3 py-2 text-xs font-medium text-gray-500 text-center">난이도</th>
                          <th className="px-3 py-2 text-xs font-medium text-gray-500 text-center">결과</th>
                          <th className="px-3 py-2 text-xs font-medium text-gray-500 text-center">문제 Rating</th>
                          <th className="px-3 py-2 text-xs font-medium text-gray-500 text-center border-l">
                            Exp-wins 승률
                          </th>
                          <th colSpan="2" className="px-3 py-2 text-xs font-medium text-gray-500 text-center border-l">
                            Sequential
                          </th>
                        </tr>
                        <tr>
                          <th className="px-3 py-2" colSpan="5"></th>
                          <th className="px-3 py-2 text-xs font-medium text-gray-500 text-center border-l">승률</th>
                          <th className="px-3 py-2 text-xs font-medium text-gray-500 text-center">Rating</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {detailData?.data.result.map((problem, index) => (
                          <tr key={problem.problem_id} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                            <td className="px-3 py-2 text-sm text-center">{problem.problem_id}</td>
                            <td className="px-3 py-2 text-sm text-center">{problem.level}</td>
                            <td className="px-3 py-2 text-sm text-center">
                              <span className={`font-medium ${problem.result ? 'text-blue-600' : 'text-red-600'}`}>
                                {problem.result ? 'O' : 'X'}
                              </span>
                            </td>
                            <td className="px-3 py-2 text-sm text-center">{formatNumber(problem.prob_rating)}</td>
                            <td className="px-3 py-2 text-sm text-center border-l">{problem.d_e_win_count.toFixed(1)}%</td>
                            <td className="px-3 py-2 text-sm text-center border-l">{problem.d_s_win_count.toFixed(1)}%</td>
                            <td className="px-3 py-2 text-sm text-center font-medium">
                              <div className="flex items-center justify-center gap-1">
                                {formatNumber(problem.d_s_rating)}
                                <span className={`text-xs ${
                                  problem.d_s_delta > 0 ? 'text-red-500' : 
                                  problem.d_s_delta < 0 ? 'text-blue-500' : 
                                  'text-gray-500'
                                }`}>
                                  {problem.d_s_delta !== 0 && (
                                    problem.d_s_delta > 0 ? '▲' : '▼'
                                  )}
                                  {formatNumber(problem.d_s_delta)}
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* 오른쪽 영역 (30%) */}
              <div className="w-[30%] bg-gray-50 rounded-lg p-6 overflow-y-auto">
                <h4 className="text-lg font-semibold mb-4">시뮬레이션 파라미터</h4>
                <div className="space-y-4">
                  <div className="grid grid-cols-2 gap-4">
                    {/* 새로 추가되는 Rating 필드들 */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Exp-wins Rating</label>
                      <input
                        type="number"
                        name="e_rating"
                        value={modalSimulationParams.e_rating}
                        onChange={handleModalInputChange}
                        step="1"  // 정수만 입력 가능
                        onBlur={(e) => {
                          // 소수점이 입력된 경우 정수로 반올림
                          const value = Math.round(Number(e.target.value));
                          setModalSimulationParams(prev => ({
                            ...prev,
                            [e.target.name]: value
                          }));
                        }}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Sequential Rating</label>
                      <input
                        type="number"
                        name="s_rating"
                        value={modalSimulationParams.s_rating}
                        onChange={handleModalInputChange}
                        step="1"  // 정수만 입력 가능
                        onBlur={(e) => {
                          // 소수점이 입력된 경우 정수로 반올림
                          const value = Math.round(Number(e.target.value));
                          setModalSimulationParams(prev => ({
                            ...prev,
                            [e.target.name]: value
                          }));
                        }}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">K value</label>
                      <input
                        type="number"
                        name="k"
                        value={modalSimulationParams.k}
                        onChange={handleModalInputChange}
                        step="1"  // 정수만 입력 가능
                        onBlur={(e) => {
                          // 소수점이 입력된 경우 정수로 반올림
                          const value = Math.round(Number(e.target.value));
                          setModalSimulationParams(prev => ({
                            ...prev,
                            [e.target.name]: value
                          }));
                        }}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Q value</label>
                      <input
                        type="number"
                        name="q"
                        value={modalSimulationParams.q}
                        onChange={handleModalInputChange}
                        step="1"  // 정수만 입력 가능
                        onBlur={(e) => {
                          // 소수점이 입력된 경우 정수로 반올림
                          const value = Math.round(Number(e.target.value));
                          setModalSimulationParams(prev => ({
                            ...prev,
                            [e.target.name]: value
                          }));
                        }}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Level 1</label>
                      <input
                        type="number"
                        name="lv1"
                        value={modalSimulationParams.lv1}
                        onChange={handleModalInputChange}
                        step="1"  // 정수만 입력 가능
                        onBlur={(e) => {
                          // 소수점이 입력된 경우 정수로 반올림
                          const value = Math.round(Number(e.target.value));
                          setModalSimulationParams(prev => ({
                            ...prev,
                            [e.target.name]: value
                          }));
                        }}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Level 2</label>
                      <input
                        type="number"
                        name="lv2"
                        value={modalSimulationParams.lv2}
                        onChange={handleModalInputChange}
                        step="1"  // 정수만 입력 가능
                        onBlur={(e) => {
                          // 소수점이 입력된 경우 정수로 반올림
                          const value = Math.round(Number(e.target.value));
                          setModalSimulationParams(prev => ({
                            ...prev,
                            [e.target.name]: value
                          }));
                        }}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Level 3</label>
                      <input
                        type="number"
                        name="lv3"
                        value={modalSimulationParams.lv3}
                        onChange={handleModalInputChange}
                        step="1"  // 정수만 입력 가능
                        onBlur={(e) => {
                          // 소수점이 입력된 경우 정수로 반올림
                          const value = Math.round(Number(e.target.value));
                          setModalSimulationParams(prev => ({
                            ...prev,
                            [e.target.name]: value
                          }));
                        }}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Level 4</label>
                      <input
                        type="number"
                        name="lv4"
                        value={modalSimulationParams.lv4}
                        onChange={handleModalInputChange}
                        step="1"  // 정수만 입력 가능
                        onBlur={(e) => {
                          // 소수점이 입력된 경우 정수로 반올림
                          const value = Math.round(Number(e.target.value));
                          setModalSimulationParams(prev => ({
                            ...prev,
                            [e.target.name]: value
                          }));
                        }}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Level 5</label>
                      <input
                        type="number"
                        name="lv5"
                        value={modalSimulationParams.lv5}
                        onChange={handleModalInputChange}
                        step="1"  // 정수만 입력 가능
                        onBlur={(e) => {
                          // 소수점이 입력된 경우 정수로 반올림
                          const value = Math.round(Number(e.target.value));
                          setModalSimulationParams(prev => ({
                            ...prev,
                            [e.target.name]: value
                          }));
                        }}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <button
                      onClick={handleModalSimulation}
                      className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      시뮬레이션 실행
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Datapage;
