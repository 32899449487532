import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { createEditor } from 'lexical';
import PlaygroundNodes from '../../editor/nodes/PlaygroundNodes';
import { processMathEquation } from '../utils/latexParser';
import { $generateHtmlFromNodes } from '@lexical/html';

const waitForRendering = () => {
    return new Promise(resolve => {
        // MathJax나 이미지 로딩을 위한 최소 대기 시간
        setTimeout(resolve, 100);
    });
};

export const handlePDF = async (examId, stampId) => {
    // 로딩 오버레이 생성
    const overlay = document.createElement('div');
    overlay.style.cssText = `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    `;
    
    const loadingContent = document.createElement('div');
    loadingContent.style.cssText = `
        background: white;
        padding: 20px 40px;
        border-radius: 8px;
        text-align: center;
    `;
    loadingContent.innerHTML = `
        <p style="margin: 0; font-size: 16px; color: #333;">PDF 생성 중입니다...</p>
    `;
    
    overlay.appendChild(loadingContent);
    document.body.appendChild(overlay);

    try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/exams/${stampId}/${examId}/print`, {
            headers: {
                'Authorization': token
            }
        });

        if (!response.ok) {
            if (response.status === 400) throw new Error('잘못된 요청입니다.');
            if (response.status === 401) throw new Error('인증되지 않은 사용자입니다.');
            if (response.status === 404) throw new Error('시험 정보를 찾을 수 없습니다.');
            throw new Error('시험지 데이터를 가져오는데 실패했습니다.');
        }

        const data = await response.json();
        if (data.status !== 'success') throw new Error('문제 로드 실패');

        // 문제 내용 처리
        const problemsWithHtml = await Promise.all(data.data.problem_list.map(async (problem) => {
            const lexicalData = problem.lexical_data.lexical_data;
            const editor = createEditor({
                nodes: [...PlaygroundNodes],
                theme: {
                    paragraph: 'editor-paragraph',
                    text: {
                        bold: 'editor-text-bold',
                        italic: 'editor-text-italic',
                        underline: 'editor-text-underline',
                    },
                    layoutContainer: 'PlaygroundEditorTheme__layoutContainer',
                    layoutItem: 'PlaygroundEditorTheme__layoutItem',
                    image: 'PlaygroundEditorTheme__image',
                    imageContainer: 'PlaygroundEditorTheme__imageContainer',
                    boxContent: 'PlaygroundEditorTheme__boxContent',
                }
            });

            const processNode = (node) => {
                if (node.type === 'equation') {
                    node.equation = processMathEquation(node.equation);
                } else if (node.type === 'box-content') {
                    node.className = 'PlaygroundEditorTheme__boxContent';
                }
                if (node.children && Array.isArray(node.children)) {
                    node.children = node.children.map(child => processNode(child));
                }
                return node;
            };

            lexicalData.root = processNode(lexicalData.root);
            const editorState = editor.parseEditorState(lexicalData);

            let content = '';
            editor.setEditorState(editorState);
            editor.update(() => {
                content = $generateHtmlFromNodes(editor);
            });

            return { ...problem, content };
        }));

        const pdf = await generatePDF(problemsWithHtml, data.data);
        const pdfBlob = pdf.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');

    } catch (error) {
        console.error('PDF 생성 중 오류 발생:', error);
        alert(error.message || 'PDF 생성 중 오류가 발생했습니다.');
    } finally {
        // 로딩 오버레이 제거
        document.body.removeChild(overlay);
    }
};

const generatePDF = async (problems, data) => {
    // 문제 HTML 생성 함수 정의
    const generateProblemHTML = (problem, index) => {
        return `
      <div class="problem-item">
        <div class="problem-number">${String(index + 1).padStart(2, '0')}</div>
        <div class="problem-content">${problem.content}</div>
      </div>
    `;
    };

    // 기존 스타일 저장
    const originalStyles = document.body.style.cssText;

    // 임시 컨테이너 생성
    const container = document.createElement('div');
    container.style.cssText = `
    position: fixed;  // absolute 대신 fixed 사용
    top: -9999px;    // 화면 밖으로 완전히 이동
    left: -9999px;   // 화면 밖으로 완전히 이동
    width: 328mm;
    background: white;
    z-index: -1;     // 가장 아래 레이어로 설정
    visibility: hidden; // 추가적인 숨김 처리
  `;

    // 스타일 요소 생성
    const style = document.createElement('style');
    style.textContent = `
    @media screen {
      body { background: gray; }
      .pdf-container {
        width: 328mm;
        margin: 0 auto;
        background: white;
        box-shadow: 0 0 10px rgba(0,0,0,0.3);
      }
    }
    .print-header { 
      margin: 0;
      height: 120mm;
      background-image: url('${process.env.PUBLIC_URL}/assets/images/bg_test.png');
      background-size: contain;
      background-position: top center;
      background-repeat: no-repeat;
      position: relative;
    }
    .header-content {
      position: absolute;
      top: 16mm;
      left: 16mm;
      right: 16mm;
      z-index: 1;
    }
    .header-grid {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 20px;
    }
    .header-left .subject {
      font-size: 14pt;
      color: #333;  // 가독성을 위해 색상 조정
      margin: 0;
      font-weight: 500;
    }
    .header-left .chapter {
      font-size: 32pt;
      font-weight: 900;
      margin: 8mm 0;  // 여백 추가
      color: #4ABC85;
    }
    .header-left .scope {
      font-size: 10pt;
      color: gray;
      margin: 0;
    }
    .header-right {
      text-align: right;
      position: relative;
    }
    .test-number {
      font-size: 14pt;
      margin: 0;
      position: absolute;  // 절대 위치로 설정
      top: -20mm;  // 위치 조정
      right: 30mm;
    }
    .student-name {
      font-size: 18pt;
      font-weight: bold;
      margin-top: 70mm;
      position: absolute;
      right: 30mm;
      color: #000;
    }
    .problem-number {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    .editor-paragraph {
      margin: 0;
      position: relative;
    //   font-size: 1.1em;
    }
    // .problem-content {
    //   font-size: 1.1em;
    // }
    .editor-text-bold {
      font-weight: bold;
    }
    .editor-text-italic {
      font-style: italic;
    }
    .editor-text-underline {
      text-decoration: underline;
    }
    .PlaygroundEditorTheme__paragraph {
      margin: 0;
      position: relative;
    }
    .PlaygroundEditorTheme__text-bold {
      font-weight: bold;
    }
    .page {
      width: 328mm; 
      min-height: 464mm; 
      background: white; 
      box-sizing: border-box;
      padding: 0;
      position: relative;
    }
    .problems-container {
      padding: 0 16mm;
      margin-top: 20mm;
      display: flex;
      flex-direction: row;
      gap: 16mm;
      min-height: 324mm;
      justify-content: space-between;
    }
    .problems-column {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 40mm;
      width: calc(50% - 8mm);
    }
    .problem-item {
      width: 100%;
      max-width: 488px;
      overflow-wrap: break-word;
      margin-bottom: 20mm;
      text-align: left;
    }
    .problem-content .PlaygroundEditorTheme__layoutContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16mm;
      max-width: 100%;
      margin: 8mm 0;
      page-break-inside: avoid;
    }
    .problem-content .PlaygroundEditorTheme__layoutItem {
      width: 100%;
      min-width: 0;
      padding: 0;
      box-sizing: border-box;
      page-break-inside: avoid;
      overflow-wrap: break-word;
      border: none !important;
    }
    .problem-content img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 8mm auto;
    }
    .problem-content .math-element {
      font-size: 0.91em;
      display: inline-block;
      vertical-align: middle;
      margin: 0 2px;
    }
    .editor-paragraph {
      margin: 4mm 0;
      line-height: 1.5;
    }
    .page:not(:first-child) .problems-container {
      margin-top: 40mm;  /* 헤더가 없는 페이지의 상단 여백 증가 */
    }
    .PlaygroundEditorTheme__layoutItem > * {
      margin: 2mm 0;
    }
    .PlaygroundEditorTheme__imageContainer {
      text-align: center;
      margin: 8mm 0;
    }
    .PlaygroundEditorTheme__image {
      display: inline-block;
      max-width: 100%;
      vertical-align: middle;
    }
    .print-footer {
      position: absolute;
      bottom: 10mm;  /* 위치 상향 조정 */
      left: 16mm;
      right: 16mm;
      padding-bottom: 0;
    }
    .problem-content [data-lexical-layout-container] {
      display: grid;
      grid-template-columns: repeat(2, 1fr);  /* 한 줄에 3개씩 */
      grid-template-rows: repeat(3, auto);    /* 2줄 */
      // gap: 2mm;
      max-width: 100%;
      // margin: 8mm 0;
      // border: none !important;
    }
    .PlaygroundEditorTheme__boxContent {
      border: 1px solid black;
      padding: 10px;
      margin: 10px auto;
      max-width: 100%;
      border-radius: 4px;
      background-color: #ffffff;
      page-break-inside: avoid;
    //   font-size: 1.1em;
    }
    .PlaygroundEditorTheme__boxContent .editor-paragraph {
      margin: 2mm 0;  // 박스 내부 문단 여백 조정
    }
    .katex-html {
      transform: translateY(-2px);
    }
    .katex-html .frac-line {
      transform: translateY(5px) !important;
    }
    .katex-html .mord.sqrt .hide-tail { 
      transform: translateY(5px) !important;
    }
  `;

    try {
        // 스타일 적용
        document.head.appendChild(style);
        document.body.appendChild(container);
        document.body.style.margin = '0';
        document.body.style.padding = '20px 0';
        document.body.style.background = 'gray';

        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4'
        });


        // 문제들을 페이지별로 분배하는 로직 수정
        let currentPage = [];
        let pages = [];
        let currentColumn = 'left'; // 현재 채우고 있는 열 추적

        for (let i = 0; i < problems.length; i++) {
            const problem = problems[i];
            const problemHeight = calculateProblemHeight(problem);
            const availableHeight = getAvailableHeight(pages.length);
            
            // 새 페이지 시작 여부 확인
            if (!currentPage.left) {
                currentPage = { left: [], right: [] };
                currentColumn = 'left';
            }

            // 현재 열의 높이 계산
            const currentHeight = currentColumn === 'left' 
                ? currentPage.left.reduce((sum, p) => sum + calculateProblemHeight(p), 0)
                : currentPage.right.reduce((sum, p) => sum + calculateProblemHeight(p), 0);

            // 현재 열에 문제를 추가할 수 있는지 확인
            if (currentHeight + problemHeight <= availableHeight) {
                // 현재 열에 문제 추가
                currentPage[currentColumn].push(problem);
            } else {
                // 현재 열이 가득 찼을 때
                if (currentColumn === 'left') {
                    // 오른쪽 열로 전환
                    currentColumn = 'right';
                    currentPage.right.push(problem);
                } else {
                    // 새 페이지 시작
                    pages.push(currentPage);
                    currentPage = { 
                        left: [problem],
                        right: []
                    };
                    currentColumn = 'left';
                }
            }
        }

        // 마지막 페이지 처리
        if (currentPage.left?.length > 0) {
            // 왼쪽 열에만 문제가 있고 오른쪽 열이 비어있는 경우
            // 마지막 문제를 오른쪽 열로 이동
            if (currentPage.right.length === 0 && currentPage.left.length > 1) {
                currentPage.right.push(currentPage.left.pop());
            }
            pages.push(currentPage);
        }

        // 페이지 렌더링 부분 수정
        let globalProblemIndex = 0;  // 초기화 위치 중요

        for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
            console.log(`[페이지 ${pageIndex + 1}]`);
            const leftNumbers = pages[pageIndex].left.map((_, i) => globalProblemIndex + i + 1);
            globalProblemIndex += pages[pageIndex].left.length;
            
            const rightNumbers = pages[pageIndex].right.map((_, i) => globalProblemIndex + i + 1);
            globalProblemIndex += pages[pageIndex].right.length;

            console.log('왼쪽 열:', leftNumbers);
            console.log('오른쪽 열:', rightNumbers);
            
            if (pageIndex > 0) {
                pdf.addPage();
            }

            const page = pages[pageIndex];
            const pageHtml = `
                <div class="page">
                    ${pageIndex === 0 ? `
                        <div class="print-header">
                            <div class="header-content">
                                <div class="header-grid">
                                    <div class="header-left">
                                        <p class="subject">${data.exam_info.edu_ver}</p>
                                        <p class="chapter" style="color: #4ABC85 !important;">${data.exam_info.tree_name}</p>
                                    </div>
                                    <div class="header-right">
                                        <p class="test-number">V2No. ${data.exam_info.exam_id}</p>
                                        <p class="student-name">${data.student_info.student_name}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ` : ''}
                    <div class="problems-container">
                        <div class="problems-column">
                            ${page.left?.map((problem, index) => {
                                const problemNumber = leftNumbers[index];  // leftNumbers 배열 사용
                                return generateProblemHTML(problem, problemNumber - 1);
                            }).join('')}
                        </div>
                        <div class="problems-column">
                            ${page.right?.map((problem, index) => {
                                const problemNumber = rightNumbers[index];  // rightNumbers 배열 사용
                                return generateProblemHTML(problem, problemNumber - 1);
                            }).join('')}
                        </div>
                    </div>
                    <div class="print-footer">
                        <hr style="border: none; border-top: 1px solid #ccc; margin-bottom: 5mm;" />
                        <div style="position: relative; height: 24pt;">
                            <div style="display: flex; justify-content: space-between; align-items: center; color: #666; font-size: 10pt;">
                                <span style="font-weight: bold;">카이테스트 · ${data.exam_info.exam_type === 'alpha' ? 'α' : 
                                    data.exam_info.exam_type === 'beta' ? 'β' : 
                                    data.exam_info.exam_type === 'gamma' ? 'γ' : 
                                    data.exam_info.exam_type}</span>
                                <span style="font-weight: bold;">${data.exam_info.tree_name} · ${data.exam_info.edu_ver}</span>
                            </div>
                            <span style="position: absolute; left: 50%; transform: translateX(-50%); font-size: 18pt; font-weight: bold; top: -4pt;">${String(pageIndex + 1).padStart(2, '0')}</span>
                        </div>
                    </div>
                </div>
            `;

            container.innerHTML = pageHtml;
            await waitForRendering();

            const canvas = await html2canvas(container.children[0], {
                scale: 2,
                useCORS: true,
                logging: true,
                allowTaint: true,
                foreignObjectRendering: false,
                width: 1240,
                height: 1754,
                backgroundColor: 'white',
                windowHeight: 1754,
                y: pageIndex === 0 ? 0 : 40,
                onclone: (clonedDoc) => {
                    const page = clonedDoc.querySelector('.page');
                    if (pageIndex > 0) {
                        page.style.paddingTop = '40mm';
                    }
                }
            });

            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297, undefined, 'FAST');

            canvas.remove();
        }

        return pdf;

    } finally {
        // 원래 상태로 복구
        document.head.removeChild(style);
        document.body.removeChild(container);
        document.body.style.cssText = originalStyles;
    }
};

const calculateProblemHeight = (problem) => {
    // 임시 div를 생성하여 문제 내용의 실제 높이를 측정
    const tempDiv = document.createElement('div');
    tempDiv.style.cssText = `
        position: absolute;
        visibility: hidden;
        width: 488px; // problem-item의 width와 동일
        left: -9999px;
    `;
    
    // 문제 내용을 임시 div에 삽입
    tempDiv.innerHTML = `
        <div class="problem-item">
            <div class="problem-number"></div>
            <div class="problem-content">${problem.content}</div>
        </div>
    `;
    
    document.body.appendChild(tempDiv);
    const height = tempDiv.offsetHeight;
    document.body.removeChild(tempDiv);
    
    // 여백을 포함한 높이 반환 (mm 단위)
    return (height / 3.779527559055118) + 20; // px to mm 변환 + 여백
};

const getAvailableHeight = (pageIndex) => {
    const PAGE_HEIGHT = 464; // A4 전체 높이 (mm)
    const HEADER_HEIGHT = 120; // 첫 페이지 헤더 높이 (mm)
    const BASE_HEADER_HEIGHT = 40;
    const FOOTER_HEIGHT = 30; // 푸터 높이 (mm)
    const PADDING = 16; // 상하 여백 (mm)
    const SAFETY_MARGIN = 50; // 안전 여백 추가 (mm)
    
    // 첫 페이지는 헤더가 있어서 가용 높이가 더 작음
    if (pageIndex === 0) {
        return PAGE_HEIGHT - HEADER_HEIGHT - FOOTER_HEIGHT - (PADDING * 2) - SAFETY_MARGIN;
    }
    
    // 두 번째 페이지부터는 헤더가 없음
    return PAGE_HEIGHT - BASE_HEADER_HEIGHT - FOOTER_HEIGHT - (PADDING * 2) - SAFETY_MARGIN;
};