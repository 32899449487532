import React, { useState, useEffect } from 'react';

function TeacherModal({ isOpen, onClose, onSubmit, initialData, mode = 'add' }) {
  const initialFormState = {
    user_id: '',
    user_pw: '',
    user_name: '',
    charge_work: ''
  };

  const [formData, setFormData] = useState(initialFormState);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if (isOpen) {
      if (mode === 'edit' && initialData) {
        setFormData({
          id: initialData.id,
          user_id: initialData.user_id,
          user_name: initialData.user_name,
          charge_work: initialData.charge_work
        });
      } else {
        setFormData(initialFormState);
      }
      setError('');
      setSuccess('');
    }
  }, [isOpen, initialData, mode]);

  const validateInput = (value, fieldName) => {
    if (fieldName === 'user_pw') {
      return /^[A-Za-z\d!@#$%^&*()]{8,20}$/.test(value);
    }
    return /^[가-힣a-zA-Z0-9]{1,100}$/.test(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    const fieldsToValidate = mode === 'edit' 
      ? Object.entries(formData).filter(([key, value]) => value !== initialData[key])
      : Object.entries(formData);

    if (mode === 'edit' && fieldsToValidate.length === 0) {
      setError('최소 하나의 필드를 수정해야 합니다.');
      return;
    }

    for (const [key, value] of fieldsToValidate) {
      if (!validateInput(value, key)) {
        if (key === 'user_pw') {
          setError('비밀번호는 8~20자의 영문, 숫자, 특수문자(!@#$%^&*())만 입력 가능합니다');
        } else {
          setError('필드는 1~100자의 한글, 영문, 숫자만 입력 가능합니다 (공백 및 특수문자 제외)');
        }
        return;
      }
    }

    try {
      const message = await onSubmit(formData);
      setSuccess(message);
      setTimeout(() => {
        handleClose();
      }, 1500);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleClose = () => {
    setError('');
    setSuccess('');
    setFormData(initialFormState);
    onClose();
  };

  const isFieldEditable = (fieldName) => {
    if (mode === 'add') return true;
    return initialData?.editField === fieldName;
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg w-96">
        {!success ? (
          <>
            <h2 className="text-xl font-bold mb-4">
              {mode === 'add' ? '강사 추가' : '강사 정보 수정'}
            </h2>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block mb-2">아이디</label>
                <input
                  type="text"
                  name="user_id"
                  value={formData.user_id || ''}
                  onChange={handleChange}
                  className={`w-full px-3 py-2 border rounded ${mode === 'edit' ? 'bg-gray-100' : ''}`}
                  disabled={mode === 'edit'}
                  required={mode === 'add'}
                />
              </div>
              
              {mode === 'add' && (
                <div className="mb-4">
                  <label className="block mb-2">비밀번호</label>
                  <input
                    type="password"
                    name="user_pw"
                    value={formData.user_pw || ''}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                </div>
              )}

              <div className="mb-4">
                <label className="block mb-2">이름</label>
                <input
                  type="text"
                  name="user_name"
                  value={formData.user_name || ''}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block mb-2">담당 업무</label>
                <input
                  type="text"
                  name="charge_work"
                  value={formData.charge_work || ''}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>
              
              <div className="flex justify-end gap-2">
                <button
                  type="button"
                  onClick={handleClose}
                  className="px-4 py-2 bg-gray-200 rounded"
                >
                  취소
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-[#4ABC85] text-white rounded"
                >
                  {mode === 'add' ? '추가' : '수정'}
                </button>
              </div>
            </form>
          </>
        ) : (
          <div className="text-center py-8">
            <p className="text-green-500 text-lg font-medium">{success}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default TeacherModal; 