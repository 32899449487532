import React, { useState, useCallback, useRef, useEffect } from 'react';
import { EditorComposer, Editor, Divider } from '../../editor';
import ToolbarPlugin from '../../editor/plugins/ToolbarPlugin/ToolbarPlugin';
import {
  AlignDropdown,
  BackgroundColorPicker,
  BlockFormatDropdown,
  BoldButton,
  CodeFormatButton,
  CodeLanguageDropdown,
  FloatingLinkEditor,
  FontFamilyDropdown,
  FontSizeDropdown,
  InsertDropdown,
  InsertLinkButton,
  ItalicButton,
  RedoButton,
  TextColorPicker,
  TextFormatDropdown,
  UnderlineButton,
  UndoButton,
} from '../../editor/plugins/ToolbarPlugin/components';
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createEquationNode } from '../../editor/nodes/EquationNode';
import { $createImageNode } from '../../editor/nodes/ImageNode';
import { useAuth } from '../../context/AuthContext';
import { createEditor } from 'lexical';
import PlaygroundNodes from '../../editor/nodes/PlaygroundNodes';
import { processMathEquation, splitTextAndMath } from '../utils/latexParser';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

// EditorComponent 수정
function EditorComponent({ onLoad, onSave }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const editorElement = document.querySelector('div.ContentEditable__root[contenteditable]');
    if (editorElement) {
      editorElement.style.paddingLeft = '376px';
      editorElement.style.paddingRight = '376px';
    }
  }, []);

  const handleMathData = useCallback(async (data) => {
    try {
      console.log("Received data in handleMathData:", data);
      
      editor.update(() => {
        const root = $getRoot();
        root.clear();
        
        let currentParagraph = $createParagraphNode();
        root.append(currentParagraph);

        const linedData = data.mathpix_data?.lined_data;
        if (!linedData) {
          console.error('No lined_data found in:', data);
          return;
        }

        linedData.forEach(item => {
          if ((item.type !== 'text' && item.type !== 'math' && item.type !== 'table') || 
              item.type === 'chart' || 
              item.type === 'diagram') {
            if (item.content && item.content[0] && 
                (item.content[0].startsWith('data:image') || item.is_base64)) {
              const imageNode = $createImageNode({
                altText: 'Solution Image',
                src: item.content[0],
                width: item.cnt ? Math.abs(item.cnt[2][0] - item.cnt[0][0]) : undefined,
                height: item.cnt ? Math.abs(item.cnt[2][1] - item.cnt[0][1]) : undefined,
                maxWidth: 500,
                showCaption: false
              });
              currentParagraph.append(imageNode);
              
              currentParagraph = $createParagraphNode();
              root.append(currentParagraph);
            }
          } else if (item.type === 'text') {
            item.content.forEach(text => {
              const mathSegments = splitTextAndMath(text);
              mathSegments.forEach(mathSegment => {
                if (mathSegment.type === 'text') {
                  const textNode = $createTextNode(mathSegment.content);
                  currentParagraph.append(textNode);
                } else {
                  const equation = processMathEquation(mathSegment.content);
                  const equationNode = $createEquationNode(equation, true);
                  currentParagraph.append(equationNode);
                }
              });
            });
            
            currentParagraph = $createParagraphNode();
            root.append(currentParagraph);
          } else if (item.type === 'math' || item.type === 'table') {
            const mathContent = item.content[0];
            let equation = mathContent.replace(/^\$\$(.*)\$\$/s, '$1');
            
            if (item.type === 'table') {
              equation = equation
                .replace(/\\begin\{tabular\}/g, '\\begin{array}')
                .replace(/\\end\{tabular\}/g, '\\end{array}')
                .replace(/\$/g, ' ')
                .replace(/\\hline(?!\s)/g, '\\hline ');
            }
            
            equation = processMathEquation(equation);
            const isDisplayMath = mathContent.startsWith('$$');
            const equationNode = $createEquationNode(equation, !isDisplayMath);
            currentParagraph.append(equationNode);
            
            currentParagraph = $createParagraphNode();
            root.append(currentParagraph);
          }
        });
      });
    } catch (error) {
      console.error('Math data handling error:', error);
      console.error('Error details:', error.message);
    }
  }, [editor]);

  useEffect(() => {
    if (onLoad) {
      onLoad(handleMathData);
    }
  }, [onLoad, handleMathData]);

  const saveEditorState = useCallback(() => {
    const editorState = editor.getEditorState();
    const json = editorState.toJSON();
    onSave(json);
  }, [editor, onSave]);

  return (
    <div>
      <div className="editor-container">
        <Editor>
          <ToolbarPlugin>
            <FontFamilyDropdown />
            <FontSizeDropdown />
            <Divider />
            <BoldButton />
            <ItalicButton />
            <UnderlineButton />
            <CodeFormatButton />
            <InsertLinkButton />
            <TextColorPicker />
            <BackgroundColorPicker />
            <TextFormatDropdown />
            <Divider />
            <InsertDropdown />
            <Divider />
            <AlignDropdown />
          </ToolbarPlugin>
        </Editor>
      </div>
      <div className="mt-4">
        <button
          onClick={saveEditorState}
          className="bg-purple-500 text-white px-4 py-2 rounded"
          disabled={!editor}
        >
          해답 저장
        </button>
      </div>
    </div>
  );
}

function SolutionEditModal({ solution, onClose, onUpdate }) {
  const { getAccessToken } = useAuth();
  const [imageUrl, setImageUrl] = useState(solution.base64format);
  const [isLoading, setIsLoading] = useState(false);
  const canvasRef = useRef(null);
  const mathDataHandlerRef = useRef(null);

  const getInitialEditorState = useCallback(() => {
    const editor = createEditor({
      nodes: [...PlaygroundNodes],
      theme: {
        paragraph: 'editor-paragraph',
        text: {
          bold: 'editor-text-bold',
          italic: 'editor-text-italic',
          underline: 'editor-text-underline',
        },
      }
    });
    
    if (solution.lexical_data?.lexical_data && 
        typeof solution.lexical_data.lexical_data === 'object') {
      try {
        const lexicalData = JSON.parse(JSON.stringify(solution.lexical_data.lexical_data));
        
        if (!lexicalData.root) {
          return createEmptyEditorState(editor);
        }

        const processNode = (node) => {
          node.style = node.style || "";
          node.format = node.format || "";
          node.indent = node.indent || 0;
          node.direction = node.direction || null;
          node.version = node.version || 1;

          if (node.type === 'equation') {
            node.equation = processMathEquation(node.equation);
          }

          if (node.children && Array.isArray(node.children)) {
            node.children = node.children.map(child => processNode(child));
          }

          return node;
        };

        lexicalData.root = processNode(lexicalData.root);
        return editor.parseEditorState(lexicalData);
        
      } catch (error) {
        console.error('Error parsing editor state:', error);
        return createEmptyEditorState(editor);
      }
    }
    
    return createEmptyEditorState(editor);
  }, [solution]);

    // 빈 에디터 상태 생성 함수
    const createEmptyEditorState = (editor) => {
        return editor.parseEditorState({
          root: {
            children: [
              {
                children: [],
                direction: null,
                format: "",
                indent: 0,
                type: "paragraph",
                version: 1,
                style: ""
              }
            ],
            direction: null,
            format: "",
            indent: 0,
            type: "root",
            version: 1,
            style: ""
          }
        });
      };

  const drawBoundingBoxes = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas || !imageUrl || !solution.mathpix_data) return;

    const img = new Image();
    img.src = imageUrl;
    
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      
      ctx.drawImage(img, 0, 0, img.width, img.height);
      
      solution.mathpix_data.lined_data.forEach(item => {
        if (item.cnt && item.cnt.length === 4) {
          ctx.strokeStyle = 'red';
          ctx.lineWidth = 2;
          
          const [[x1, y1], [x2, y2], [x3, y3], [x4, y4]] = item.cnt;
          
          ctx.beginPath();
          ctx.moveTo(x1, y1);
          ctx.lineTo(x2, y2);
          ctx.lineTo(x3, y3);
          ctx.lineTo(x4, y4);
          ctx.closePath();
          ctx.stroke();
        }
      });
    };
  }, [imageUrl, solution.mathpix_data]);

  const fetchWithTokenRefresh = useCallback(async (url, options = {}) => {
    try {
      const token = await getAccessToken();
      if (!token) {
        throw new Error('인증 토큰이 없습니다.');
      }

      const response = await fetch(`${API_BASE_URL}${url}`, {
        ...options,
        headers: {
          ...options.headers,
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('API 요청 실패');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('API 호출 에러:', error);
      throw error;
    }
  }, [getAccessToken]);

  const handleSave = async (lexicalJson) => {
    try {
      const response = await fetchWithTokenRefresh(`/api/solutions/${solution.solution_id}/lexical`, {
        method: 'POST',
        body: JSON.stringify({
          lexical_json: lexicalJson
        })
      });

      if (!response || (response.status_code !== 200 && response.status_code !== 201)) {
        throw new Error('에디터 데이터 저장 실패');
      }

      // 현재 URL의 검색 파라미터 확인
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has('labels')) {
        onUpdate(); // 라벨 필터가 있는 경우 기존 필터로 새로고침
        onClose();
      } else {
        // ID 검색이나 일반 목록인 경우
        onClose();
        window.location.reload(); // 전체 페이지 새로고침
      }
      
      alert('풀이가 성공적으로 저장되었습니다.');
    } catch (error) {
      console.error('에디터 데이터 저장 중 오류:', error);
      alert('풀이 저장에 실패했습니다.');
    }
  };

  const handleOcr = useCallback(async () => {
    if (!solution.solution_id || !mathDataHandlerRef.current) return;

    setIsLoading(true);
    try {
      const response = await fetchWithTokenRefresh(`/api/solutions/${solution.solution_id}/mathpix`, {
        method: 'POST',
        body: JSON.stringify({
          mathpix_data_option: true
        })
      });

      if (!response || response.status_code !== 201) {
        throw new Error('OCR 처리 실패');
      }

      drawBoundingBoxes(response.data);
      await mathDataHandlerRef.current(response.data);
      onUpdate();
      
      alert('OCR 처리가 완료되었습니다.');
    } catch (error) {
      console.error('OCR 처리 중 오류:', error);
      alert('OCR 처리에 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  }, [solution.solution_id, fetchWithTokenRefresh, drawBoundingBoxes, onUpdate]);

  const setMathDataHandler = useCallback((handler) => {
    mathDataHandlerRef.current = handler;
    if (solution.solution_info.status === 300 && solution.mathpix_data) {
      handler(solution.mathpix_data);
    }
  }, [solution]);

  const handleApplyOCR = useCallback(async () => {
    if (!solution.solution_id || !mathDataHandlerRef.current || !solution.mathpix_data) return;

    setIsLoading(true);
    try {
      await mathDataHandlerRef.current(solution);
      drawBoundingBoxes(solution);
      alert('OCR 데이터가 적용되었습니다.');
    } catch (error) {
      console.error('OCR 데이터 적용 중 오류:', error);
      alert('OCR 데이터 적용에 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  }, [solution, drawBoundingBoxes]);

  useEffect(() => {
    if (solution.solution_info.status >= 300) {
      drawBoundingBoxes();
    }
  }, [drawBoundingBoxes, solution.solution_info.status]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-[90vw] h-[90vh] flex flex-col relative">
        <div className="p-4 border-b flex justify-between items-center">
          <h2 className="text-xl font-semibold">풀이 수정</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="flex-1 p-6 overflow-y-auto flex flex-col">
          <div className="mb-6">
            <div className="relative max-h-[40vh] overflow-y-auto">
              <img 
                src={imageUrl} 
                alt="Solution" 
                className="w-auto h-auto max-w-full"
              />
              {solution.solution_info.status >= 300 && (
                <canvas
                  ref={canvasRef}
                  className="absolute top-0 left-0 pointer-events-none"
                />
              )}
            </div>

            {solution.solution_info.status === 200 && (
              <button
                onClick={handleOcr}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
                disabled={isLoading}
              >
                {isLoading ? '처리 중...' : 'OCR 처리 시작'}
              </button>
            )}

            {solution.solution_info.status === 300 && (
              <button
                onClick={handleApplyOCR}
                className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
                disabled={isLoading}
              >
                {isLoading ? '처리 중...' : 'OCR 데이터 적용'}
              </button>
            )}
          </div>

          <div className="flex-1 [&_.editor-container]:!font-inherit [&_span.editor-equation]:inline-block [&_div.editor-equation]:block">
            <EditorComposer initialEditorState={getInitialEditorState()}>
              <EditorComponent 
                onLoad={setMathDataHandler}
                onSave={handleSave}
              />
            </EditorComposer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SolutionEditModal;
