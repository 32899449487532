import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { useAuth } from '../context/AuthContext';
import { createEditor } from 'lexical';
import { $generateHtmlFromNodes } from '@lexical/html';
import PlaygroundNodes from '../editor/nodes/PlaygroundNodes';
import { processMathEquation } from '../components/utils/latexParser';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import PrintPreview from '../components/printPreview';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

// 브라우저 경 체크 함수 추가
const isBrowser = typeof window !== 'undefined';

function TextbookPage() {
  const { getAccessToken } = useAuth();
  const navigate = useNavigate();
  const [problems, setProblems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const perPage = 20;
  const [selectedProblems, setSelectedProblems] = useState([]);
  const [tree_name, setTreeName] = useState('');
  const [edu_ver, setEduVer] = useState('');
  const [student_name, setStudentName] = useState('');
  const [exam_id, setExamId] = useState('');
  const [printFunction, setPrintFunction] = useState(null);

  const convertLexicalToHtml = useCallback((lexicalData) => {
    const editor = createEditor({
      nodes: [...PlaygroundNodes]
    });

    const processNode = (node) => {
      if (node.type === 'equation') {
        node.equation = processMathEquation(node.equation);
      } else if (node.type === 'box-content') {
        node.className = 'PlaygroundEditorTheme__boxContent';
      }
      if (node.children && Array.isArray(node.children)) {
        node.children = node.children.map(child => processNode(child));
      }
      return node;
    };

    lexicalData.root = processNode(lexicalData.root);
    const editorState = editor.parseEditorState(lexicalData);

    let content = '';
    editor.setEditorState(editorState);
    editor.update(() => {
      content = $generateHtmlFromNodes(editor);
    });

    return content;
  }, []);

  const fetchProblems = useCallback(async () => {
    setLoading(true);
    try {
      const token = await getAccessToken();
      if (!token) {
        navigate('/signin');
        return;
      }

      const response = await fetch(`${API_BASE_URL}/api/problems/?page=${currentPage}&per_page=${perPage}`, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) throw new Error('API 요청 실패');

      const data = await response.json();
      console.log('API Response:', data);

      const problemsList = data.data || [];
      setTotalPages(data.total_pages || 1);
      
      const problemsWithHtml = await Promise.all(
        problemsList.map(async (problem) => {
          try {
            const detailResponse = await fetch(`${API_BASE_URL}/api/problems/${problem.problem_id}?lexical=true`, {
              headers: {
                'Authorization': token,
                'Content-Type': 'application/json',
              },
              credentials: 'include',
            });
            
            if (!detailResponse.ok) {
              console.error(`문제 ${problem.problem_id} 상세 정보 로드 실패`);
              return { ...problem, content: '문제 내용을 불러올 수 없습니다.' };
            }

            const detailData = await detailResponse.json();
            
            if (!detailData?.data?.lexical_data?.lexical_data) {
              console.error(`문제 ${problem.problem_id}의 lexical 데이터가 없습니다:`, detailData);
              return { ...problem, content: '문제 내용을 불러올 수 없습니다.' };
            }

            return {
              ...problem,
              content: convertLexicalToHtml(detailData.data.lexical_data.lexical_data)
            };
          } catch (error) {
            console.error(`문제 ${problem.problem_id} 처리 중 오류:`, error);
            return { ...problem, content: '문제 내용을 불러올 수 없습니다.' };
          }
        })
      );
      setProblems(problemsWithHtml);
    } catch (error) {
      console.error('문제 로드 실패:', error);
    } finally {
      setLoading(false);
    }
  }, [getAccessToken, navigate, convertLexicalToHtml, currentPage]);

  useEffect(() => {
    const checkAuth = async () => {
      const token = await getAccessToken();
      if (!token) {
        navigate('/signin');
      }
    };
    checkAuth();
  }, [getAccessToken, navigate]);

  useEffect(() => {
    fetchProblems();
  }, [fetchProblems, currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleProblemClick = (problem) => {
    setSelectedProblems(prev => {
      if (prev.some(p => p.problem_id === problem.problem_id)) {
        return prev.filter(p => p.problem_id !== problem.problem_id);
      }
      return [...prev, problem];
    });
  };

  const handlePrint = () => {
    if (printFunction) {
      printFunction();
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="no-print">
        <Header />
      </div>
      <div className="flex flex-1 overflow-hidden">
        <div className="no-print">
          <Sidebar />
        </div>
        <main className="flex-1 overflow-hidden bg-gray-200">
          <div className="container mx-auto px-6 py-8 h-full">
            <div className="flex justify-between items-center mb-8 no-print">
              <h3 className="text-gray-700 text-3xl font-medium">교재관리</h3>
              {selectedProblems.length > 0 && (
                <button
                  onClick={handlePrint}
                  className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                >
                  인쇄하기
                </button>
              )}
            </div>
            <div className="flex h-[calc(100vh-12rem)]">
              {/* 왼쪽 1/3 영역: 문제 리스트 */}
              <div className="w-1/3 pr-4 flex flex-col no-print">
                <div className="flex-1 overflow-y-auto bg-white rounded-lg shadow-md">
                  {loading ? (
                    <div className="flex justify-center items-center h-32">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                    </div>
                  ) : (
                    <div className="space-y-4 p-4">
                      {problems.map((problem, index) => (
                        <div 
                          key={problem.problem_id} 
                          className={`bg-white rounded-lg border p-4 cursor-pointer hover:bg-gray-50 
                            ${selectedProblems.some(p => p.problem_id === problem.problem_id) ? 'border-red-500' : ''}`}
                          onClick={() => handleProblemClick(problem)}
                        >
                          <div className="problem-content-wrapper">
                            <div 
                              className="problem-number text-2xl font-bold"
                              style={{ 
                                minWidth: '36px',
                                marginRight: '20px',
                                color: selectedProblems.some(p => p.problem_id === problem.problem_id) ? '#EF4444' : 'inherit'
                              }}
                            >
                              {String(index + 1).padStart(2, '0')}
                            </div>
                            <div 
                              className="problem-content"
                              dangerouslySetInnerHTML={{ __html: problem.content }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {/* 페이지네이션 */}
                <div className="mt-4 flex justify-center items-center space-x-2 bg-white p-4 rounded-lg shadow-md">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50"
                  >
                    <ChevronLeft size={20} />
                  </button>
                  <span className="mx-4">
                    {currentPage} / {totalPages}
                  </span>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="p-2 rounded-full hover:bg-gray-100 disabled:opacity-50"
                  >
                    <ChevronRight size={20} />
                  </button>
                </div>
              </div>
              
              {/* 우측 2/3 영역: 미리보기 */}
              <div className="w-2/3 pl-4 overflow-y-auto">
                <PrintPreview 
                  problems={selectedProblems}
                  mode="preview"
                  scale={0.7}
                  onProblemClick={handleProblemClick}
                  tree_name='수학1'
                  edu_ver='(2022)개정'
                  student_name='홍길동'
                  exam_id='123456'
                  onPrint={setPrintFunction}
                />
              </div>
            </div>
          </div>
        </main>
      </div>

      <style jsx>{`
        @media print {
          .no-print {
            display: none !important;
          }
          .preview-container {
            width: 100% !important;
            padding: 0 !important;
            overflow: visible !important;
            transform: scale(1) !important;
            transform-origin: top left !important;
          }
          main {
            background: none !important;
            overflow: visible !important;
            height: auto !important;
          }
          .container {
            padding: 0 !important;
            margin: 0 !important;
            max-width: none !important;
            height: auto !important;
          }
          .flex {
            display: block !important;
            height: auto !important;
          }
          body {
            background: white !important;
          }
        }
      `}</style>
    </div>
  );
}

export default TextbookPage;