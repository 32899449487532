import React, { useState, useCallback, useRef, useEffect } from 'react';
import { EditorComposer, Editor, Divider } from '../../editor';
import ToolbarPlugin from '../../editor/plugins/ToolbarPlugin/ToolbarPlugin';
import {
  AlignDropdown,
  BackgroundColorPicker,
  BlockFormatDropdown,
  BoldButton,
  CodeFormatButton,
  CodeLanguageDropdown,
  FloatingLinkEditor,
  FontFamilyDropdown,
  FontSizeDropdown,
  InsertDropdown,
  InsertLinkButton,
  ItalicButton,
  RedoButton,
  TextColorPicker,
  TextFormatDropdown,
  UnderlineButton,
  UndoButton,
} from '../../editor/plugins/ToolbarPlugin/components';
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createEquationNode } from '../../editor/nodes/EquationNode';
import { $createImageNode } from '../../editor/nodes/ImageNode';
import { useAuth } from '../../context/AuthContext';
import { createEditor } from 'lexical';
import PlaygroundNodes from '../../editor/nodes/PlaygroundNodes';
import { processMathEquation, splitTextAndMath } from '../utils/latexParser';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

// EditorComponent 수정
function EditorComponent({ onLoad, onSave }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    // ContentEditable__root 요소를 찾아서 스타일 적용
    const editorElement = document.querySelector('div.ContentEditable__root[contenteditable]');
    if (editorElement) {
      editorElement.style.paddingLeft = '376px';
      editorElement.style.paddingRight = '376px';
    }
  }, []);

  // saveEditorState 함수 추가
  const saveEditorState = useCallback(() => {
    const editorState = editor.getEditorState();
    const json = editorState.toJSON();
    onSave(json);
  }, [editor, onSave]);

  const handleMathData = useCallback(async (data) => {
    try {
      console.log("Received data in handleMathData:", data);
      
      editor.update(() => {
        const root = $getRoot();
        root.clear();
        
        let currentParagraph = $createParagraphNode();
        root.append(currentParagraph);

        // 데이터 구조 확인 및 수정
        const linedData = data.mathpix_data?.lined_data;
        if (!linedData) {
          console.error('No lined_data found in:', data);
          return;
        }

        linedData.forEach(item => {
          // chart나 diagram 타입 또는 text/math/table이 아닌 타입 처리
          if ((item.type !== 'text' && item.type !== 'math' && item.type !== 'table') || 
              item.type === 'chart' || 
              item.type === 'diagram') {
            // 이미지 데이터 처리
            if (item.content && item.content[0] && 
                (item.content[0].startsWith('data:image') || item.is_base64)) {
              // console.log('Creating image node with:', item.content[0]); // 디버깅용
              const imageNode = $createImageNode({
                altText: 'Problem Image',
                src: item.content[0],
                width: item.cnt ? Math.abs(item.cnt[2][0] - item.cnt[0][0]) : undefined,
                height: item.cnt ? Math.abs(item.cnt[2][1] - item.cnt[0][1]) : undefined,
                maxWidth: 500,
                showCaption: false
              });
              currentParagraph.append(imageNode);
              
              // 이미지 다음에 새 단락 추가
              currentParagraph = $createParagraphNode();
              root.append(currentParagraph);
            }
          } else if (item.type === 'text') {
            item.content.forEach(text => {
              // 수식과 텍스트를 분리
              const mathSegments = splitTextAndMath(text);
              mathSegments.forEach(mathSegment => {
                if (mathSegment.type === 'text') {
                  // 일반 텍스트에서 (숫자) 패턴을 찾아 변환
                  const segments = mathSegment.content.split(/(\(\d+\))/);
                  segments.forEach(segment => {
                    const numberMatch = segment.match(/\((\d+)\)/);
                    if (numberMatch) {
                      const num = parseInt(numberMatch[1]);
                      if (num >= 1 && num <= 5) {
                        const circleNumber = ['①', '②', '③', '④', '⑤'][num - 1];
                        const equationNode = $createEquationNode(circleNumber, true);
                        currentParagraph.append(equationNode);
                      } else {
                        const textNode = $createTextNode(segment);
                        currentParagraph.append(textNode);
                      }
                    } else {
                      const textNode = $createTextNode(segment);
                      currentParagraph.append(textNode);
                    }
                  });
                } else { // math
                  const equation = processMathEquation(mathSegment.content);
                  const equationNode = $createEquationNode(equation, true);
                  currentParagraph.append(equationNode);
                }
              });
          });
          
          // 텍스트 다음에 새 단락 추가
          currentParagraph = $createParagraphNode();
          root.append(currentParagraph);
        } else if (item.type === 'math' || item.type === 'table') {
          // 기존 수식/테이블 처리 로직 유지
          const mathContent = item.content[0];
          let equation = mathContent.replace(/^\$\$(.*)\$\$/s, '$1');
          
          if (item.type === 'table') {
            equation = equation
              .replace(/\\begin\{tabular\}/g, '\\begin{array}')
              .replace(/\\end\{tabular\}/g, '\\end{array}')
              .replace(/\$/g, ' ')
              .replace(/\\hline(?!\s)/g, '\\hline ');
          }
          
          equation = processMathEquation(equation);
          const isDisplayMath = mathContent.startsWith('$$');
          const equationNode = $createEquationNode(equation, !isDisplayMath);
          currentParagraph.append(equationNode);
          
          currentParagraph = $createParagraphNode();
          root.append(currentParagraph);
        }
      });
    });
  } catch (error) {
    console.error('Math data handling error:', error);
    console.error('Error details:', error.message);
  }
}, [editor]);

  useEffect(() => {
    if (onLoad) {
      onLoad(handleMathData);
    }
  }, [onLoad, handleMathData]);

  return (
    <div>
      <div className="editor-container">
        <Editor>
          <ToolbarPlugin>
            <FontFamilyDropdown />
            <FontSizeDropdown />
            <Divider />
            <BoldButton />
            <ItalicButton />
            <UnderlineButton />
            <CodeFormatButton />
            <InsertLinkButton />
            <TextColorPicker />
            <BackgroundColorPicker />
            <TextFormatDropdown />
            <Divider />
            <InsertDropdown />
            <Divider />
            <AlignDropdown />
          </ToolbarPlugin>
        </Editor>
      </div>
      <div className="mt-4">
        <button
          onClick={saveEditorState}
          className="bg-purple-500 text-white px-4 py-2 rounded"
          disabled={!editor}
        >
          문제 저장
        </button>
      </div>
    </div>
  );
}

function ProblemEditModal({ problem, onClose, onUpdate }) {
  const { getAccessToken } = useAuth();
  const [labels, setLabels] = useState(problem.problem_info.labels);
  const [imageUrl, setImageUrl] = useState(problem.base64format);
  const [isLoading, setIsLoading] = useState(false);
  const canvasRef = useRef(null);
  const mathDataHandlerRef = useRef(null);
  const [editingLabel, setEditingLabel] = useState(labels);
  const [isLabelEditing, setIsLabelEditing] = useState(false);

  // 상태에 따른 초기 에디터 상태 설정
  const getInitialEditorState = useCallback(() => {
    const editor = createEditor({
      nodes: [...PlaygroundNodes],
      theme: {
        paragraph: 'editor-paragraph',
        text: {
          bold: 'editor-text-bold',
          italic: 'editor-text-italic',
          underline: 'editor-text-underline',
        },
      }
    });
    
    if (problem.lexical_data?.lexical_data && 
        typeof problem.lexical_data.lexical_data === 'object') {
      try {
        const lexicalData = JSON.parse(JSON.stringify(problem.lexical_data.lexical_data));
        
        if (!lexicalData.root) {
          return createEmptyEditorState(editor);
        }

        // 모든 노드에 대해 처리를 수행하는 재귀 함수
        const processNode = (node) => {
          // 기본 속성 초기화
          node.style = node.style || "";
          node.format = node.format || "";
          node.indent = node.indent || 0;
          node.direction = node.direction || null;
          node.version = node.version || 1;

          // equation 타입 노드 처리
          if (node.type === 'equation') {
            node.equation = processMathEquation(node.equation);
          }

          // children이 있는 경우 재귀적으로 처리
          if (node.children && Array.isArray(node.children)) {
            node.children = node.children.map(child => processNode(child));
          }

          return node;
        };

        // root 노드부터 시작하여 모든 노드 처리
        lexicalData.root = processNode(lexicalData.root);

        const editorState = editor.parseEditorState(lexicalData);
        return editorState;
        
      } catch (error) {
        console.error('Error parsing editor state:', error);
        return createEmptyEditorState(editor);
      }
    }
    
    return createEmptyEditorState(editor);
  }, [problem]);

  // 빈 에디터 상태 생성 함수
  const createEmptyEditorState = (editor) => {
    return editor.parseEditorState({
      root: {
        children: [
          {
            children: [],
            direction: null,
            format: "",
            indent: 0,
            type: "paragraph",
            version: 1,
            style: ""
          }
        ],
        direction: null,
        format: "",
        indent: 0,
        type: "root",
        version: 1,
        style: ""
      }
    });
  };

  // 테두리 그리기 함수
  const drawBoundingBoxes = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas || !imageUrl || !problem.mathpix_data) return;

    const img = new Image();
    img.src = imageUrl;
    
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      
      ctx.drawImage(img, 0, 0, img.width, img.height);
      
      problem.mathpix_data.lined_data.forEach(item => {
        if (item.cnt && item.cnt.length === 4) {
          ctx.strokeStyle = 'red';
          ctx.lineWidth = 2;
          
          const [[x1, y1], [x2, y2], [x3, y3], [x4, y4]] = item.cnt;
          
          ctx.beginPath();
          ctx.moveTo(x1, y1);
          ctx.lineTo(x2, y2);
          ctx.lineTo(x3, y3);
          ctx.lineTo(x4, y4);
          ctx.closePath();
          ctx.stroke();
        }
      });
    };
  }, [imageUrl, problem.mathpix_data]);

  // 컴포넌트 마운트 시 테두리 그리기
  useEffect(() => {
    if (problem.problem_info.status >= 300) {
      drawBoundingBoxes();
    }
  }, [drawBoundingBoxes, problem.problem_info.status]);

  // mathpix 데이터를 에디터에 반영
  const setMathDataHandler = useCallback((handler) => {
    mathDataHandlerRef.current = handler;
    if (problem.problem_info.status === 300 && problem.mathpix_data) {
      handler(problem.mathpix_data);
    }
  }, [problem]);

  // 저장 처리 함수 수정
  const handleSave = async (lexicalJson) => {
    try {
      const response = await fetchWithTokenRefresh(`/api/problems/${problem.problem_id}/ocr/confirm`, {
        method: 'POST',
        body: JSON.stringify({
          lexical_json: lexicalJson,
          labels: labels
        })
      });

      if (!response || (response.status_code !== 200 && response.status_code !== 201)) {
        throw new Error('에디터 데이터 저장 실패');
      }

      console.log('Editor data saved successfully');
      onUpdate(); // 현재 페이지 데이터 업데이트
      onClose();
    } catch (error) {
      console.error('에디터 데이터 저장 중 오류:', error);
      alert('문제 수정에 실패했습니다.');
    }
  };

  // fetchWithTokenRefresh 함수 추가
  const fetchWithTokenRefresh = useCallback(async (url, options = {}) => {
    try {
      const token = await getAccessToken();
      if (!token) {
        throw new Error('인증 토큰이 없습니다.');
      }

      const response = await fetch(`${API_BASE_URL}${url}`, {
        ...options,
        headers: {
          ...options.headers,
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('API 요청 실패');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('API 호출 에러:', error);
      throw error;
    }
  }, [getAccessToken]);

  // OCR 처리 함수 수정
  const handleOcr = useCallback(async () => {
    if (!problem.problem_id || !mathDataHandlerRef.current) return;

    setIsLoading(true);
    try {
      const response = await fetchWithTokenRefresh(`/api/problems/${problem.problem_id}/ocr`, {
        method: 'POST',
        body: JSON.stringify({
          mathpix_data: true
        })
      });

      if (!response || response.status_code !== 201) {
        throw new Error('OCR 처리 실패');
      }

      // 테두리 그리기
      drawBoundingBoxes(response.data);
      
      // OCR 데이터 처리
      await mathDataHandlerRef.current(response.data);
      
      // 상태 업데이트를 위해 onUpdate 호출
      onUpdate();
      
      alert('OCR 처리가 완료되었습니다.');
    } catch (error) {
      console.error('OCR 처리 중 오류:', error);
      alert('OCR 처리에 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  }, [problem.problem_id, fetchWithTokenRefresh, drawBoundingBoxes, onUpdate]);

  // OCR 데이터 적용 함수 추가
  const handleApplyOCR = useCallback(async () => {
    if (!problem.problem_id || !mathDataHandlerRef.current || !problem.mathpix_data) return;

    setIsLoading(true);
    try {
      // 기존 OCR 데이터 적용
      await mathDataHandlerRef.current(problem);
      drawBoundingBoxes(problem);
      alert('OCR 데이터가 적용되었습니다.');
    } catch (error) {
      console.error('OCR 데이터 적용 중 오류:', error);
      alert('OCR 데이터 적용에 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  }, [problem, drawBoundingBoxes]);

  // 라벨 수정 핸들러 추가
  const handleLabelUpdate = async () => {
    try {
      const response = await fetchWithTokenRefresh('/api/problems/update', {
        method: 'PUT',
        body: JSON.stringify({
          problems: [{
            problem_id: problem.problem_id,
            labels: editingLabel
          }]
        })
      });

      if (!response) return;

      setLabels(editingLabel);
      setIsLabelEditing(false);
      alert('라벨이 수정되었습니다.');
    } catch (error) {
      console.error('라벨 수정 실패:', error);
      alert('라벨 수정에 실패했습니다.');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-[90vw] h-[90vh] flex flex-col relative">
        {/* 헤더 */}
        <div className="p-4 border-b flex justify-between items-center">
          <h2 className="text-xl font-semibold">문제 수정</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* 컨텐츠 영역 */}
        <div className="flex-1 p-6 overflow-y-auto flex flex-col">
          {/* 상단: 이미지 영역 */}
          <div className="mb-6">
            <div className="relative max-h-[40vh] overflow-y-auto">
              <img 
                src={imageUrl} 
                alt="Problem" 
                className="w-auto h-auto max-w-full"
              />
              {problem.problem_info.status >= 300 && (
                <canvas
                  ref={canvasRef}
                  className="absolute top-0 left-0 pointer-events-none"
                />
              )}
            </div>

            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">
                라벨 (쉼표로 구분)
              </label>
              <div className="flex gap-2 items-center mt-1">
                {isLabelEditing ? (
                  <>
                    <input
                      type="text"
                      value={editingLabel}
                      onChange={(e) => setEditingLabel(e.target.value)}
                      className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                    <button
                      onClick={handleLabelUpdate}
                      className="bg-green-500 text-white px-3 py-1 rounded text-sm hover:bg-green-600"
                    >
                      저장
                    </button>
                    <button
                      onClick={() => {
                        setEditingLabel(labels);
                        setIsLabelEditing(false);
                      }}
                      className="bg-gray-500 text-white px-3 py-1 rounded text-sm hover:bg-gray-600"
                    >
                      취소
                    </button>
                  </>
                ) : (
                  <>
                    <span className="flex-1 py-2">{labels}</span>
                    <button
                      onClick={() => setIsLabelEditing(true)}
                      className="bg-blue-500 text-white px-3 py-1 rounded text-sm hover:bg-blue-600"
                    >
                      수정
                    </button>
                  </>
                )}
              </div>
            </div>

            {problem.problem_info.status === 200 && (
              <button
                onClick={handleOcr}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
                disabled={isLoading}
              >
                {isLoading ? '처리 중...' : 'OCR 처리 시작'}
              </button>
            )}

            {problem.problem_info.status === 300 && (
              <button
                onClick={handleApplyOCR}
                className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
                disabled={isLoading}
              >
                {isLoading ? '처리 중...' : 'OCR 데이터 적용'}
              </button>
            )}
          </div>

          {/* 하단: 에디터 영역 */}
          <div className="flex-1 [&_.editor-container]:!font-inherit [&_span.editor-equation]:inline-block [&_div.editor-equation]:block">
            <EditorComposer initialEditorState={getInitialEditorState()}>
              <EditorComponent 
                onLoad={setMathDataHandler}
                onSave={handleSave}
              />
            </EditorComposer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProblemEditModal; 