import React, { useState } from 'react';
import { User, ListPlus, Home, Users, Settings, ChevronLeft, ChevronRight, BookOpen, FileText, Calendar, Edit, CheckSquare, Printer, UserPlus, BookOpenCheck, ClipboardList } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function Sidebar() {
  const [isExpanded, setIsExpanded] = useState(true);
  const location = useLocation();
  const { userType } = useAuth();

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`bg-white text-gray-800 transition-all duration-300 ${isExpanded ? 'w-40' : 'w-20'}`}>
      <div className="flex items-center justify-between p-4">
        {/* {isExpanded && <span className="text-2xl font-semibold">Menu</span>} */}
        <button onClick={toggleSidebar} className="p-1 rounded-full hover:bg-gray-200">
          {isExpanded ? <ChevronLeft size={24} /> : <ChevronRight size={24} />}
        </button>
      </div>
      <nav className="mt-5">
      <Link 
        to="/dashboard" 
        className={`flex items-center px-4 py-2 mt-2 hover:bg-[#231F20] hover:text-white
          ${location.pathname === '/dashboard' ? 'bg-[#231F20] text-white' : ''}`}
      >
          <Home size={24} />
          {isExpanded && <span className="ml-3">대시보드</span>}
      </Link>
        {/* <Link to="/users" className="flex items-center px-4 py-2 mt-2 hover:bg-gray-100">
          <Users size={24} />
          {isExpanded && <span className="ml-3">사용자 관리</span>}
        </Link>
        <Link to="/settings" className="flex items-center px-4 py-2 mt-2 hover:bg-gray-100">
          <Settings size={24} />
          {isExpanded && <span className="ml-3">설정</span>}
        </Link> */}
        {userType !== 'teacher' && (
          <button 
            onClick={() => {
              window.location.href = '/teachers';
            }}
            className={`flex items-center w-full px-4 py-2 mt-2 hover:bg-[#231F20] hover:text-white
              ${location.pathname.startsWith('/teachers') ? 'bg-[#231F20] text-white' : ''}`}
          >
            <User size={24} />
            {isExpanded && <span className="ml-3">강사관리</span>}
          </button>
        )}
        <button 
          onClick={() => {
            window.location.href = '/student/1';
          }}
          className={`flex items-center w-full px-4 py-2 mt-2 hover:bg-[#231F20] hover:text-white
            ${(location.pathname.startsWith('/student') || 
               location.pathname.startsWith('/test-papers/student') || 
               location.pathname.startsWith('/test-result') ||
               location.pathname.startsWith('/result')) 
              ? 'bg-[#231F20] text-white' 
              : ''}`}
        >
          <UserPlus size={24} />
          {isExpanded && <span className="ml-3">학생관리</span>}
        </button>

        <Link 
          to="/exam-list" 
          className={`flex items-center px-4 py-2 mt-2 hover:bg-[#231F20] hover:text-white
            ${location.pathname.startsWith('/exam-list') ? 'bg-[#231F20] text-white' : ''}`}
        >
          <ClipboardList size={24} />
          {isExpanded && <span className="ml-3">테스트관리</span>}
        </Link>

        <Link 
          to="/problem-print" 
          className={`flex items-center px-4 py-2 mt-2 hover:bg-[#231F20] hover:text-white
            ${location.pathname.startsWith('/problem-print') ? 'bg-[#231F20] text-white' : ''}`}
        >
          <Printer size={24} />
          {isExpanded && <span className="ml-3">시험지출력</span>}
        </Link>

        <button 
          onClick={() => {
            window.location.href = '/testset';
          }}
          className={`flex items-center w-full px-4 py-2 mt-10 hover:bg-[#231F20] hover:text-white
            ${(location.pathname.startsWith('/testset') || location.pathname.startsWith('/test-register')) ? 'bg-[#231F20] text-white' : ''}`}
        >
          <FileText size={24} />
          {isExpanded && <span className="ml-3">학습구성</span>}
        </button>
        {/* <button 
          onClick={() => {
            window.location.href = '/problem-register';  // 페이지 이동과 새로고침을 동시에 수행
          }} 
          className="flex items-center w-full px-4 py-2 mt-2 hover:bg-gray-100"
        >
          <BookOpen size={24} />
          {isExpanded && <span className="ml-3">문제등록</span>}
        </button> */}
        <button 
          onClick={() => {
            window.location.href = '/problem-edit';
          }} 
          className={`flex items-center w-full px-4 py-2 mt-2 hover:bg-[#231F20] hover:text-white
            ${location.pathname.startsWith('/problem-edit') ? 'bg-[#231F20] text-white' : ''}`}
        >
          <Edit size={24} />
          {isExpanded && <span className="ml-3">문제관리</span>}
        </button>
        <button 
          onClick={() => {
            window.location.href = '/problem-solution';
          }} 
          className={`flex items-center w-full px-4 py-2 mt-2 hover:bg-[#231F20] hover:text-white
            ${location.pathname.startsWith('/problem-solution') ? 'bg-[#231F20] text-white' : ''}`}
        >
          <BookOpenCheck size={24} />
          {isExpanded && <span className="ml-3">풀이관리</span>}
        </button>
        <button 
          onClick={() => {
            window.location.href = '/reservations';
          }}
          className={`flex items-center w-full px-4 py-2 mt-8 hover:bg-[#231F20] hover:text-white
            ${location.pathname.startsWith('/reservations') ? 'bg-[#231F20] text-white' : ''}`}
        >
          <ListPlus size={24} />
          {isExpanded && <span className="ml-3">예약관리</span>}
        </button>
        {/* <Link to="/test-register" className="flex items-center px-4 py-2 mt-2 hover:bg-gray-100">
          <FileText size={24} />
          {isExpanded && <span className="ml-3">문제편성</span>}
        </Link> */}
        {/* <Link to="/test-result" className="flex items-center px-4 py-2 mt-2 hover:bg-gray-100">
          <CheckSquare size={24} />
          {isExpanded && <span className="ml-3">결과(Test)</span>}
        </Link> */}
        {/* <Link to="/test-papers" className="flex items-center px-4 py-2 mt-2 hover:bg-gray-100">
          <FileText size={24} />
          {isExpanded && <span className="ml-3">시험지(Test)</span>}
        </Link> */}

        {/* 풀이등록 메뉴 주석처리
        <button 
          onClick={() => {
            window.location.href = '/solution-register';
          }} 
          className={`flex items-center w-full px-4 py-2 mt-2 hover:bg-[#231F20] hover:text-white
            ${location.pathname === '/solution-register' ? 'bg-[#231F20] text-white' : ''}`}
        >
          <Edit size={24} />
          {isExpanded && <span className="ml-3">풀이등록</span>}
        </button>
        */}
        {/* <Link to="/pre-reservations" className="flex items-center px-4 py-2 mt-2 hover:bg-gray-100">
          <Calendar size={24} />
          {isExpanded && <span className="ml-3">사전예약자</span>}
        </Link> */}
        {userType !== 'teacher' && (
          <button 
            onClick={() => {
              window.location.href = '/managers';
            }}
            className={`flex items-center w-full px-4 py-2 mt-2 hover:bg-[#231F20] hover:text-white
              ${location.pathname.startsWith('/managers') ? 'bg-[#231F20] text-white' : ''}`}
          >
            <Settings size={24} />
            {isExpanded && <span className="ml-3">관리자관리</span>}
          </button>
        )}



        {/* <Link 
          to="/textbook" 
          className={`flex items-center px-4 py-2 mt-2 hover:bg-[#231F20] hover:text-white
            ${location.pathname.startsWith('/textbook') ? 'bg-[#231F20] text-white' : ''}`}
        >
          <BookOpen size={24} />
          {isExpanded && <span className="ml-3">교재관리</span>}
        </Link> */}

        {userType !== 'teacher' && (
          <Link 
            to="/data" 
            className={`flex items-center px-4 py-2 mt-2 hover:bg-[#231F20] hover:text-white
              ${location.pathname.startsWith('/data') ? 'bg-[#231F20] text-white' : ''}`}
          >
            <FileText size={24} />
            {isExpanded && <span className="ml-3">데이터관리</span>}
          </Link>
        )}

      </nav>
    </div>
  );
}

export default Sidebar;
