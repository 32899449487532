import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { useAuth } from '../context/AuthContext';

function StampManagement() {
  const { getAccessToken } = useAuth();
  const navigate = useNavigate();
  const { student_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [stampList, setStampList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [treeList, setTreeList] = useState([]);
  const [selectedTreeId, setSelectedTreeId] = useState(null);

  const fetchStampList = async () => {
    setIsLoading(true);
    try {
      const token = await getAccessToken();
      const url = student_id 
        ? `${process.env.REACT_APP_API_BASE_URL}/api/exams?student_id=${student_id}`
        : `${process.env.REACT_APP_API_BASE_URL}/api/exams`;

      const response = await fetch(url, {
        headers: {
          'Authorization': token
        }
      });

      if (response.status === 204) {
        setStampList([]);
        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setStampList(data.data.stamps);
    } catch (error) {
      console.error('스탬프 목록 조회 오류:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTreeList = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/testset/lists`, {
        headers: { 'Authorization': token }
      });
      const data = await response.json();
      if (data.status === 'success') {
        setTreeList(data.data);
      }
    } catch (error) {
      console.error('교육과정 목록 조회 실패:', error);
    }
  };

  const handleCreateStamp = async () => {
    if (!selectedTreeId || !student_id) return;

    try {
      const token = await getAccessToken();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/exams`, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          tree_id: selectedTreeId,
          student_id: parseInt(student_id)
        })
      });

      if (response.status === 409) {
        alert('이미 존재하는 스탬프입니다.');
        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await fetchStampList();
      setIsModalOpen(false);
      setSelectedTreeId(null);
    } catch (error) {
      console.error('스탬프 생성 오류:', error);
    }
  };

  const handleDeleteStamp = async (stampId, examCount) => {
    if (examCount > 0) {
      alert('시험이 존재하는 스탬프는 삭제할 수 없습니다.');
      return;
    }

    if (window.confirm('스탬프를 삭제하시겠습니까?')) {
      try {
        const token = await getAccessToken();
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/exams`, {
          method: 'DELETE',
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            stamp_id: stampId
          })
        });

        if (response.status === 409) {
          alert('시험이 존재하는 스탬프는 삭제할 수 없습니다.');
          return;
        }

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        await fetchStampList();
      } catch (error) {
        console.error('스탬프 삭제 오류:', error);
      }
    }
  };

  useEffect(() => {
    const validateTokenAndFetchStamps = async () => {
      try {
        const token = await getAccessToken();
        if (!token) {
          navigate('/signin');
          return;
        }

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/auth/tokens/access/validate`, {
          method: 'GET',
          headers: {
            'Authorization': token
          }
        });

        if (response.status === 401) {
          navigate('/signin');
        } else {
          fetchStampList();  // 주석 해제
        }
      } catch (error) {
        console.error('토큰 검증 오류:', error);
        navigate('/signin');
      }
    };

    validateTokenAndFetchStamps();
  }, [getAccessToken, navigate]);

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
          <div className="container mx-auto px-6 py-8">
            <nav className="mb-6">
              <div className="flex items-center text-sm text-gray-600">
                <Link to="/student" className="hover:text-[#4ABC85]">학생관리</Link>
                <span className="mx-2">&gt;</span>
                <Link to={`/student/${student_id}/stamp`} className="text-[#4ABC85]">교육과정</Link>
              </div>
            </nav>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {stampList.map((stamp) => (
                <div 
                  key={stamp.stamp_id} 
                  className="bg-white p-4 rounded-lg shadow relative flex flex-col cursor-pointer hover:bg-gray-50"
                  onClick={() => navigate(`/student/${student_id}/stamp/${stamp.stamp_id}/result`)}
                >
                  <h3 className="font-bold text-lg mb-2">{stamp.tree_name}</h3>
                  <p className="text-gray-600">교육과정: {stamp.edu_ver}</p>
                  <p className="text-gray-600">테스트 횟수: {stamp.exam_count}</p>
                  {stamp.exam_count === 0 && (
                    <div className="mt-auto pt-4 flex justify-end">
                      <button
                        onClick={(e) => {
                            e.stopPropagation(); // 이벤트 전파 중단
                            handleDeleteStamp(stamp.stamp_id, stamp.exam_count);
                          }}
                        className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 text-sm flex items-center gap-1"
                      >
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          className="h-4 w-4" 
                          viewBox="0 0 20 20" 
                          fill="currentColor"
                        >
                          <path 
                            fillRule="evenodd" 
                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" 
                            clipRule="evenodd" 
                          />
                        </svg>
                        삭제
                      </button>
                    </div>
                  )}
                </div>
              ))}
              
              <div 
                onClick={() => {
                  fetchTreeList();
                  setIsModalOpen(true);
                }}
                className="bg-gray-50 p-4 rounded-lg shadow cursor-pointer hover:bg-gray-100 flex items-center justify-center"
              >
                <button className="text-4xl text-gray-400 hover:text-[#4ABC85]">+</button>
              </div>
            </div>

            {/* 스탬프 생성 모달 */}
            {isModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="bg-white p-6 rounded-lg w-96">
                  <h2 className="text-xl font-bold mb-4">스탬프 생성</h2>
                  <select
                    className="w-full p-2 border rounded mb-4"
                    onChange={(e) => setSelectedTreeId(parseInt(e.target.value))}
                    value={selectedTreeId || ''}
                  >
                    <option value="">교육과정을 선택하세요</option>
                    {treeList
                      .filter(tree => tree.is_closed)
                      .map((tree) => (
                        <option key={tree.tree_id} value={tree.tree_id}>
                          {tree.tree_name} ({tree.edu_ver})
                        </option>
                      ))}
                  </select>
                  <div className="flex justify-end gap-2">
                    <button
                      onClick={() => setIsModalOpen(false)}
                      className="px-4 py-2 bg-gray-200 rounded"
                    >
                      취소
                    </button>
                    <button
                      onClick={handleCreateStamp}
                      className="px-4 py-2 bg-[#4ABC85] text-white rounded"
                      disabled={!selectedTreeId}
                    >
                      생성
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default StampManagement;
