/**
 * LaTeX 수식을 처리하는 유틸리티 함수들
 */

/**
 * LaTeX 수식 처리를 위한 메인 함수
 * @param {string} equation - 처리할 LaTeX 수식
 * @returns {string} 처리된 LaTeX 수식
 */
export const processMathEquation = (equation) => {
  equation = equation.replace(/(\s)eq(\s)/g, '$1\\neq$2');
  
  let result = '';
  let i = 0;
  let inArray = false;

  while (i < equation.length) {
    if (equation.slice(i).startsWith('\\begin{array}')) {
      inArray = true;
      result += equation.slice(i, i + 14);
      i += 14;
      continue;
    } 
    if (equation.slice(i).startsWith('\\end{array}')) {
      inArray = false;
      result += equation.slice(i, i + 12);
      i += 12;
      continue;
    }

    if (equation[i] === '\\') {
      const nextChar = equation[i + 1];
      if (nextChar === '\\' || nextChar === '\n') {
        // 개행 문자나 이중 백슬래시는 \\ 로 변환
        result += '\\\\';
        i += 2;
      } else if (nextChar === ' ' || nextChar === '\r') {
        // 단일 백슬래시 뒤에 공백이나 캐리지 리턴이 오면 \\ 로 변환
        result += '\\\\';
        i += 2;
      } else if (nextChar && !/[a-zA-Z!{[(})\]]/.test(nextChar)) {
        // 명령어가 아닌 특수문자 앞에는 단일 백슬래시
        result += '\\' + nextChar;
        i += 2;
      } else {
        // LaTeX 명령어는 그대로 유지
        result += '\\' + nextChar;
        i += 2;
      }
      continue;
    }
    result += equation[i];
    i++;
  }
  
  return result;
};

/**
* 텍스트에서 수식 부분을 분리하는 함수
* @param {string} text - 분리할 텍스트
* @returns {Array} 텍스트와 수식이 분리된 배열
*/
export const splitTextAndMath = (text) => {
const result = [];
let currentIndex = 0;
let inMath = false;
let mathStart = 0;

for (let i = 0; i < text.length; i++) {
  if (text[i] === '$') {
    // 이스케이프된 $ 확인
    if (i > 0 && text[i-1] === '\\') {
      continue;
    }
    
    // 다음 문자가 $ 인지 확인 (디스플레이 모드)
    const isDisplayMode = text[i + 1] === '$';
    
    if (!inMath) {
      // 수식 시작
      if (currentIndex < i) {
        result.push({
          type: 'text',
          content: text.slice(currentIndex, i)
        });
      }
      mathStart = i;
      inMath = true;
      if (isDisplayMode) i++; // 두 번째 $ 건너뛰기
    } else {
      // 수식 끝
      inMath = false;
      const mathContent = text.slice(mathStart, isDisplayMode ? i + 2 : i + 1);
      result.push({
        type: 'math',
        content: mathContent,
        isDisplay: isDisplayMode
      });
      currentIndex = isDisplayMode ? i + 2 : i + 1;
      if (isDisplayMode) i++; // 두 번째 $ 건너뛰기
    }
  }
}

// 남은 텍스트 처리
if (currentIndex < text.length) {
  result.push({
    type: 'text',
    content: text.slice(currentIndex)
  });
}

return result;
};

/**
* 테이블 형식의 LaTeX을 처리하는 함수
* @param {string} tableEquation - 처리할 테이블 수식
* @returns {string} 처리된 테이블 수식
*/
export const processTableEquation = (tableEquation) => {
return tableEquation
  .replace(/\\begin\{tabular\}/g, '\\begin{array}')
  .replace(/\\end\{tabular\}/g, '\\end{array}')
  .replace(/\$/g, ' ')
  .replace(/\\hline(?!\s)/g, '\\hline ');
};

/**
* 수식에서 $$ 구분자를 제거하는 함수
* @param {string} equation - 처리할 수식
* @returns {string} $$ 구분자가 제거된 수식
*/
export const removeMathDelimiters = (equation) => {
// Display mode ($$...$$) 처리
if (equation.startsWith('$$') && equation.endsWith('$$')) {
  return equation.replace(/^\$\$(.*)\$\$/s, '$1');
}
// Inline mode ($...$) 처리
if (equation.startsWith('$') && equation.endsWith('$')) {
  return equation.replace(/^\$(.*)\$/s, '$1');
}
return equation;
};

/**
* 수식이 디스플레이 모드인지 확인하는 함수
* @param {string} mathContent - 확인할 수식
* @returns {boolean} 디스플레이 모드 여부
*/
export const isDisplayMath = (mathContent) => {
return mathContent.startsWith('$$');
}; 



// import { 
//     processMathEquation, 
//     splitTextAndMath, 
//     processTableEquation,
//     removeMathDelimiters,
//     isDisplayMath 
//   } from './components/utils/latexParser';

//   // 사용 예시
//   const equation = processMathEquation(rawEquation);
//   const segments = splitTextAndMath(text);
//   const tableEquation = processTableEquation(rawTableEquation);