import React, { useState } from 'react';
import { Bell, User, LogOut } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import logo from '../logo_kyadmin.png';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

function Header() {
  const navigate = useNavigate();
  const { logout, userId, userType } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [passwords, setPasswords] = useState({
    prevPw: '',
    newPw: '',
    confirmPw: ''
  });
  const [error, setError] = useState('');

  const getUserTypeDisplay = (type) => {
    switch(type) {
      case 'manager':
        return '관리자';
      case 'teacher':
        return '선생님';
      default:
        return '';
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/signin');
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    
    const token = localStorage.getItem('accessToken');
    
    if (passwords.newPw !== passwords.confirmPw) {
      setError('새 비밀번호가 일치하지 않습니다.');
      return;
    }

    try {
      const response = await axios.put(`${API_BASE_URL}/api/users/changepw`, 
        {
          prev_pw: passwords.prevPw,
          new_pw: passwords.newPw
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        }
      );

      if (response.data.status === 'success') {
        localStorage.setItem('accessToken', response.data.data.access_token);
        
        setIsModalOpen(false);
        setPasswords({ prevPw: '', newPw: '', confirmPw: '' });
        alert('비밀번호가 성공적으로 변경되었습니다.');
      }
    } catch (error) {
      setError('비밀번호 변경 중 오류가 발생했습니다.');
    }
  };

  return (
    <>
      <header className="bg-[#231F20] text-white shadow-md h-[70px] flex items-center">
        <div className="w-full mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <div className="flex items-center">
            <img className="h-7 w-24" src={logo} alt="로고" />
          </div>
          <div className="flex items-center">
            {/* <button className="p-1 rounded-full text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
              <Bell className="h-6 w-6" />
            </button> */}
            <div className="ml-3 relative flex items-center">
              {/* <div>
                <button className="max-w-xs bg-gray-600 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" id="user-menu" aria-haspopup="true">
                  <span className="sr-only">사용자 메뉴 열기</span>
                  <User className="h-8 w-8 rounded-full" />
                </button>
              </div> */}
              <div className="ml-3">
                <div className="text-sm font-medium leading-none text-gray-300">
                  <span className="text-blue-400 mr-2">[{getUserTypeDisplay(userType)}]</span>
                  <button 
                    onClick={() => setIsModalOpen(true)}
                    className="hover:underline cursor-pointer"
                  >
                    {userId || '사용자 없음'}
                  </button>
                </div>
              </div>
              {logout && (
                <button
                  onClick={handleLogout}
                  className="ml-4 flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <LogOut className="h-5 w-5 mr-1" />
                  로그아웃
                </button>
              )}
            </div>
          </div>
        </div>
      </header>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-96 relative z-50">
            <h2 className="text-xl font-bold mb-4 text-gray-800">비밀번호 변경</h2>
            <form onSubmit={handlePasswordChange}>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">기존 비밀번호</label>
                  <input
                    type="password"
                    value={passwords.prevPw}
                    onChange={(e) => setPasswords({...passwords, prevPw: e.target.value})}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">새 비밀번호</label>
                  <input
                    type="password"
                    value={passwords.newPw}
                    onChange={(e) => setPasswords({...passwords, newPw: e.target.value})}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">새 비밀번호 확인</label>
                  <input
                    type="password"
                    value={passwords.confirmPw}
                    onChange={(e) => setPasswords({...passwords, confirmPw: e.target.value})}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    required
                  />
                </div>
                {error && <p className="text-red-500 text-sm">{error}</p>}
              </div>
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => {
                    setIsModalOpen(false);
                    setPasswords({ prevPw: '', newPw: '', confirmPw: '' });
                    setError('');
                  }}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
                >
                  취소
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
                >
                  변경
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
