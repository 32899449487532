import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { EditorComposer, Editor, Divider } from '../editor';
import ToolbarPlugin from '../editor/plugins/ToolbarPlugin/ToolbarPlugin';
import {
    AlignDropdown,
    BackgroundColorPicker,
    BlockFormatDropdown,
    BoldButton,
    CodeFormatButton,
    CodeLanguageDropdown,
    FloatingLinkEditor,
    FontFamilyDropdown,
    FontSizeDropdown,
    InsertDropdown,
    InsertLinkButton,
    ItalicButton,
    RedoButton,
    TextColorPicker,
    TextFormatDropdown,
    UnderlineButton,
    UndoButton,
} from '../editor/plugins/ToolbarPlugin/components';
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createEquationNode } from '../editor/nodes/EquationNode';
import { useAuth } from '../context/AuthContext';
import { $createImageNode } from '../editor/nodes/ImageNode';
import { 
  processMathEquation, 
  splitTextAndMath, 
  processTableEquation,
  removeMathDelimiters,
  isDisplayMath 
} from '../components/utils/latexParser';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

// EditorComponent 구현
function EditorComponent({ onLoad, onSave }) {
  const [editor] = useLexicalComposerContext();

  const saveEditorState = useCallback(() => {
    const editorState = editor.getEditorState();
    const json = editorState.toJSON();
    onSave(json);
  }, [editor, onSave]);

  const handleMathData = useCallback(async (data) => {
    try {
      editor.update(() => {
        const root = $getRoot();
        root.clear();
        
        let currentParagraph = $createParagraphNode();
        root.append(currentParagraph);

        if (data.mathpix_data?.lined_data) {
          data.mathpix_data.lined_data.forEach(item => {
            if ((item.type !== 'text' && item.type !== 'math' && item.type !== 'table') || 
                item.type === 'chart' || 
                item.type === 'diagram') {
              if (item.content && item.content[0] && 
                  (item.content[0].startsWith('data:image') || item.is_base64)) {
                const imageNode = $createImageNode({
                  altText: 'Solution Image',
                  src: item.content[0],
                  width: item.cnt ? Math.abs(item.cnt[2][0] - item.cnt[0][0]) : undefined,
                  height: item.cnt ? Math.abs(item.cnt[2][1] - item.cnt[0][1]) : undefined,
                  maxWidth: 500,
                  showCaption: false
                });
                currentParagraph.append(imageNode);
              }
            } else if (item.type === 'math' || item.type === 'table') {
              const mathContent = item.content[0];
              let equation = removeMathDelimiters(mathContent);
              
              if (item.type === 'table') {
                equation = processTableEquation(equation);
              }
              
              equation = processMathEquation(equation);
              const equationNode = $createEquationNode(equation, !isDisplayMath(mathContent));
              currentParagraph.append(equationNode);
              
              currentParagraph = $createParagraphNode();
              root.append(currentParagraph);
            } else if (item.type === 'text') {
              item.content.forEach(text => {
                const mathSegments = splitTextAndMath(text);
                mathSegments.forEach(mathSegment => {
                  if (mathSegment.type === 'text') {
                    // 일반 텍스트에서 (숫자) 패턴을 찾아 변환
                    const segments = mathSegment.content.split(/(\(\d+\))/);
                    segments.forEach(segment => {
                      const numberMatch = segment.match(/\((\d+)\)/);
                      if (numberMatch) {
                        const num = parseInt(numberMatch[1]);
                        if (num >= 1 && num <= 5) {
                          const circleNumber = ['①', '②', '③', '④', '⑤'][num - 1];
                          const equationNode = $createEquationNode(circleNumber, true);
                          currentParagraph.append(equationNode);
                        } else {
                          const textNode = $createTextNode(segment);
                          currentParagraph.append(textNode);
                        }
                      } else {
                        const textNode = $createTextNode(segment);
                        currentParagraph.append(textNode);
                      }
                    });
                  } else {
                    const cleanEquation = removeMathDelimiters(mathSegment.content);
                    const equation = processMathEquation(cleanEquation);
                    const equationNode = $createEquationNode(equation, !mathSegment.isDisplay);
                    currentParagraph.append(equationNode);
                  }
                });
              });
              
              currentParagraph = $createParagraphNode();
              root.append(currentParagraph);
            }
          });
        }
      });
    } catch (error) {
      console.error('Error in handleMathData:', error);
    }
  }, [editor]);

  useEffect(() => {
    if (onLoad && typeof onLoad === 'function') {
      onLoad(handleMathData);
    }
  }, [onLoad, handleMathData]);

  return (
    <div>
      <div className="editor-container">
        <Editor>
          <ToolbarPlugin>
            <FontFamilyDropdown />
            <FontSizeDropdown />
            <Divider />
            <BoldButton />
            <ItalicButton />
            <UnderlineButton />
            <CodeFormatButton />
            <InsertLinkButton />
            <TextColorPicker />
            <BackgroundColorPicker />
            <TextFormatDropdown />
            <Divider />
            <InsertDropdown />
            <Divider />
            <AlignDropdown />
          </ToolbarPlugin>
        </Editor>
      </div>
      <div className="mt-4">
        <button
          onClick={saveEditorState}
          className="bg-purple-500 text-white px-4 py-2 rounded"
          disabled={!editor}
        >
          풀이 저장
        </button>
      </div>
    </div>
  );
}

function SolutionRegister() {
  const { getAccessToken, handleApiError } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showOcrButton, setShowOcrButton] = useState(false);
  const [problemId, setProblemId] = useState('');
  const [answer, setAnswer] = useState('');
  const [solutionId, setSolutionId] = useState(null);
  const [status, setStatus] = useState(0);
  const mathDataHandlerRef = useRef(null);
  const canvasRef = useRef(null);

  // URL에서 problem_id와 solution_id 파라미터 가져오기
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const problemIdFromUrl = params.get('problem_id');
    const solutionIdFromUrl = params.get('solution_id');
    if (problemIdFromUrl) {
      setProblemId(problemIdFromUrl);
    }
    if (solutionIdFromUrl) {
      setSolutionId(solutionIdFromUrl);
      setStatus(200); // solution_id가 있으면 이미 정답이 등록된 상태
    }
  }, [location]);

  // fetchWithTokenRefresh 함수 추가
  const fetchWithTokenRefresh = useCallback(async (url, options = {}) => {
    try {
      const token = await getAccessToken();
      if (!token) {
        navigate('/signin');
        return null;
      }
      
      const headers = {
        ...options.headers,
        'Authorization': token,
      };
      
      if (!(options.body instanceof FormData)) {
        headers['Content-Type'] = 'application/json';
      }
      
      const response = await fetch(`${API_BASE_URL}${url}`, {
        ...options,
        headers,
        credentials: 'include',
      });

      if (response.status === 401) {
        handleApiError(new Error('Invalid or expired token'));
        navigate('/signin');
        return null;
      }

      const data = await response.json();
      
      if (data.status_code === 401) {
        handleApiError(new Error('Invalid or expired token'));
        navigate('/signin');
        return null;
      }

      return data;
    } catch (error) {
      console.error('API 호출 에러:', error);
      throw error;
    }
  }, [getAccessToken, navigate, handleApiError]);

  // 이미지 선택 핸들러
  const handleImageSelect = useCallback((file) => {
    setSelectedImage(file);
    setImageUrl(URL.createObjectURL(file));
    setShowOcrButton(false);
  }, []);

  // 이미지 제거
  const handleRemoveImage = useCallback(() => {
    setSelectedImage(null);
    setImageUrl(null);
    setAnswer('');
    setShowOcrButton(false);
    setSolutionId(null);
    
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = '';
    }
  }, []);

  // drawBoundingBoxes 함수 추가
  const drawBoundingBoxes = useCallback((data) => {
    const canvas = canvasRef.current;
    if (!canvas || !imageUrl) return;

    const img = new Image();
    img.src = imageUrl;
    
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      
      ctx.drawImage(img, 0, 0, img.width, img.height);
      
      if (data?.mathpix_data?.lined_data) {
        data.mathpix_data.lined_data.forEach(item => {
          if (item.cnt && item.cnt.length === 4) {
            ctx.strokeStyle = 'red';
            ctx.lineWidth = 2;
            
            const [[x1, y1], [x2, y2], [x3, y3], [x4, y4]] = item.cnt;
            
            ctx.beginPath();
            ctx.moveTo(x1, y1);
            ctx.lineTo(x2, y2);
            ctx.lineTo(x3, y3);
            ctx.lineTo(x4, y4);
            ctx.closePath();
            ctx.stroke();
          }
        });
      }
    };
  }, [imageUrl]);

  // 이미지 드래그 앤 드롭 또는 붙여넣기 핸들러
  const handleImageInput = useCallback(async (event) => {
    let file;
    if (event.type === 'paste') {
      const items = event.clipboardData?.items;
      const imageItem = Array.from(items).find(item => item.type.indexOf('image') !== -1);
      if (imageItem) {
        file = imageItem.getAsFile();
      }
    } else if (event.type === 'drop') {
      event.preventDefault();
      file = event.dataTransfer?.files[0];
    }

    if (file && file.type.startsWith('image/')) {
      handleImageSelect(file);
    }
  }, [handleImageSelect]);

  // 이벤트 리스너 등록
  useEffect(() => {
    document.addEventListener('paste', handleImageInput);
    document.addEventListener('drop', handleImageInput);
    document.addEventListener('dragover', (e) => e.preventDefault());

    return () => {
      document.removeEventListener('paste', handleImageInput);
      document.removeEventListener('drop', handleImageInput);
      document.removeEventListener('dragover', (e) => e.preventDefault());
    };
  }, [handleImageInput]);

  // setMathDataHandler 함수 추가
  const setMathDataHandler = useCallback((handler) => {
    mathDataHandlerRef.current = handler;
  }, []);

  const initialState = () => {
    const paragraph = $createParagraphNode();
    const text = $createTextNode('풀이를 작성해주세요.');
    paragraph.append(text);
    const root = $getRoot();
    root.append(paragraph);
    root.selectEnd();
  };

  const handleImageUpload = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      handleImageSelect(file);
    }
  }, [handleImageSelect]);

  const handleUpload = useCallback(async () => {
    if (!selectedImage || !solutionId) return;

    setIsLoading(true);
    setError(null);
    try {
      const token = await getAccessToken();
      if (!token) {
        navigate('/signin');
        return;
      }

      const formData = new FormData();
      formData.append('image', selectedImage);

      console.log('FormData content:', formData.get('image'));

      const uploadResponse = await fetchWithTokenRefresh(`/api/solutions/${solutionId}/image`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': token
        }
      });

      if (!uploadResponse || uploadResponse.status_code !== 201) {
        throw new Error('이미지 업로드 실패');
      }

      setStatus(200);
      setShowOcrButton(true);
      alert('이미지가 성공적으로 업로드되었습니다.');
    } catch (error) {
      console.error('이미지 업로드 중 오류:', error);
      alert('이미지 업로드에 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  }, [selectedImage, solutionId, fetchWithTokenRefresh, getAccessToken, navigate]);

  const handleOcr = useCallback(async () => {
    if (!solutionId || !mathDataHandlerRef.current) return;

    setIsLoading(true);
    setError(null);
    try {
      const ocrResponse = await fetchWithTokenRefresh(`/api/solutions/${solutionId}/mathpix`, {
        method: 'POST',
        body: JSON.stringify({
          mathpix_data_option: true
        })
      });

      if (!ocrResponse || ocrResponse.status_code !== 201) {
        throw new Error('OCR 처리 실패');
      }

      drawBoundingBoxes(ocrResponse.data);
      await mathDataHandlerRef.current(ocrResponse.data);
      
      setStatus(300);
      alert('OCR 처리가 완료되었습니다.');
    } catch (error) {
      console.error('OCR 처리 중 오류:', error);
      alert('OCR 처리에 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  }, [solutionId, fetchWithTokenRefresh, drawBoundingBoxes]);

  const handleEditorSave = useCallback(async (lexicalJson) => {
    if (!solutionId) return;

    try {
      const response = await fetchWithTokenRefresh(`/api/solutions/${solutionId}/lexical`, {
        method: 'POST',
        body: JSON.stringify({
          lexical_json: lexicalJson
        })
      });

      if (!response || (response.status_code !== 200 && response.status_code !== 201)) {
        throw new Error('에디터 데이터 저장 실패');
      }

      console.log('Editor data saved successfully');
      alert('풀이가 성공적으로 저장되었습니다.');
      
      // 저장된 이전 URL로 이동
      const previousUrl = sessionStorage.getItem('previousUrl');
      
      if (previousUrl) {
        navigate(previousUrl);
      } else {
        // URL에서 problem_id 가져오기
        const params = new URLSearchParams(location.search);
        const problemId = params.get('problem_id');
        
        // URL에서 이전 페이지 번호 추출 시도
        const pageMatch = location.pathname.match(/\/problem-solution\/(\d+)/);
        const pageNumber = pageMatch ? pageMatch[1] : '1';
        
        if (problemId) {
          navigate(`/problem-solution/${pageNumber}?problem_id=${problemId}`);
        } else {
          navigate(`/problem-solution/${pageNumber}`);
        }
      }
    } catch (error) {
      console.error('에디터 데이터 저장 중 오류:', error);
      alert('풀이 저장에 실패했습니다.');
    }
  }, [solutionId, fetchWithTokenRefresh, navigate, location.pathname, location.search]);

  // OCR 데이터 처리 함수
  const processOCRData = useCallback((linedData) => {
    let text = '';
    linedData.forEach(line => {
      if (line.type === 'math') {
        const content = line.content[0];
        if (content.includes('\\[') && content.includes('\\]')) {
          text += content.replace(/\\\[/g, '$$').replace(/\\\]/g, '$$');
        } else if (content.startsWith('$$')) {
          text += content;
        } else {
          text += content;
        }
      } else if (line.type === 'text') {
        text += line.content.join(' ');
      }
      text += '\n';
    });
    return { text };
  }, []);

  // useEffect 수정
  useEffect(() => {
    const fetchSolutionId = async () => {
      if (!problemId) return;

      try {
        const response = await fetchWithTokenRefresh(`/api/solutions/list/${problemId}`);
        
        if (response?.data?.length > 0) {
          // 가장 작은 solution_id를 가진 solution 선택
          const solution = response.data.reduce((prev, current) => 
            prev.solution_id < current.solution_id ? prev : current
          );
          setSolutionId(solution.solution_id);
          setStatus(solution.solution_info.status);
        }
      } catch (error) {
        console.error('솔루션 정보 조회 실패:', error);
        alert('솔루션 정보 조회에 실패했습니다.');
      }
    };

    if (problemId) {
      fetchSolutionId();
    }
  }, [problemId, fetchWithTokenRefresh]);

  // 컴포넌트 마운트 시 이전 URL 저장 로직 수정
  useEffect(() => {
    const referrer = document.referrer;
    if (referrer && referrer.includes('/problem-solution')) {
      // URL에서 도메인 부분을 제거하고 경로만 저장
      const url = new URL(referrer);
      sessionStorage.setItem('previousUrl', `${url.pathname}${url.search}`);
    } else {
      // referrer가 없는 경우 현재 URL의 problem_id를 확인하여 적절한 URL 생성
      const params = new URLSearchParams(location.search);
      const problemId = params.get('problem_id');
      
      // URL에서 이전 페이지 번호 추출 시도
      const previousPath = sessionStorage.getItem('previousUrl');
      const pageMatch = previousPath?.match(/\/problem-solution\/(\d+)/);
      const pageNumber = pageMatch ? pageMatch[1] : '1';
      
      const returnUrl = problemId 
        ? `/problem-solution/${pageNumber}?problem_id=${problemId}`
        : `/problem-solution/${pageNumber}`;
        
      sessionStorage.setItem('previousUrl', returnUrl);
    }
  }, [location.search]);

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 p-6 overflow-y-auto">
          <nav className="mb-6">
            <div className="flex items-center text-sm text-gray-600">
              <Link to="/problem-solution" className="hover:text-purple-600">풀이관리</Link>
              <span className="mx-2">&gt;</span>
              <Link to="/solution-register" className="hover:text-purple-600">풀이등록</Link>
            </div>
          </nav>

          <div>
            {/* Problem ID 입력 필드 제거 */}
            
            {isLoading && (
              <div className="mb-4 text-blue-500">처리 중...</div>
            )}
            {error && (
              <div className="mb-4 text-red-500">{error}</div>
            )}
            
            {/* 이미지 업로드 영역 */}
            <div className="mb-4 space-y-4">
              {status >= 100 && (
                <>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="mb-4"
                  />
                  <p className="text-sm text-gray-600">
                    풀이를 드래그 앤 드롭하거나 붙여넣기(Ctrl+V)할 수 있습니다.
                  </p>
                </>
              )}

              {imageUrl && status >= 100 && (
                <div className="space-y-4">
                  <div className="relative">
                    <img 
                      src={imageUrl} 
                      alt="Selected" 
                      className="max-w-xl h-auto"
                    />
                    <canvas
                      ref={canvasRef}
                      className="max-w-xl h-auto absolute top-0 left-0"
                      style={{ pointerEvents: 'none' }}
                    />
                    <button
                      onClick={handleRemoveImage}
                      className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full"
                    >
                      ×
                    </button>
                  </div>

                  <div className="flex space-x-4">
                    {!showOcrButton ? (
                      <button
                        onClick={handleUpload}
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                        disabled={isLoading || !solutionId}
                      >
                        이미지 업로드
                      </button>
                    ) : (
                      <button
                        onClick={handleOcr}
                        className="bg-green-500 text-white px-4 py-2 rounded"
                        disabled={isLoading}
                      >
                        OCR 처리 시작
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
            
            {/* 에디터 영역 */}
            <div className="[&_.editor-container]:!font-inherit [&_span.editor-equation]:inline-block [&_div.editor-equation]:block">
              <EditorComposer initialEditorState={initialState}>
                <EditorComponent 
                  onLoad={setMathDataHandler} 
                  onSave={handleEditorSave}
                />
              </EditorComposer>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default SolutionRegister;
