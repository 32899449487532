import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ManagerModal from '../components/ManagerManagement/ManagerModal';
import { useAuth } from '../context/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { PenSquare, Trash2 } from 'lucide-react';

function ManagerManagement() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState('add');
  const [selectedManager, setSelectedManager] = useState(null);
  const [managers, setManagers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessToken } = useAuth();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const PER_PAGE = 20;
  const [hasMore, setHasMore] = useState(true);

  const fetchManagers = async (pageNum = 1) => {
    setIsLoading(true);
    try {
      const token = await getAccessToken();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/manager?page=${pageNum}&per_page=${PER_PAGE}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      
      if (data.status === 'error') {
        setManagers([]);
      } else {
        setManagers(data.data);
        setTotalPages(data.total_pages || 1);
        setHasMore(pageNum < data.total_pages);
        setPage(pageNum);
      }
    } catch (error) {
      console.error('관리자 목록 조회 실패:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchManagers();
  }, [page]);

  const handleAddManager = async (managerData) => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/manager`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(managerData),
      });

      if (!response.ok) throw new Error('관리자 추가 실패');
      
      await fetchManagers();
      return '관리자가 성공적으로 추가되었습니다.';
    } catch (error) {
      throw new Error('관리자 추가에 실패했습니다.');
    }
  };

  const handleEditManager = async (managerData) => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/manager`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(managerData),
      });

      if (!response.ok) throw new Error('관리자 정보 수정 실패');
      
      await fetchManagers();
      return '관리자 정보가 성공적으로 수정되었습니다.';
    } catch (error) {
      throw new Error('관리자 정보 수정에 실패했습니다.');
    }
  };

  const handleDeleteManager = async (userId) => {
    if (!window.confirm('정말로 이 관리자를 삭제하시겠습니까?')) {
      return;
    }

    try {
      const token = await getAccessToken();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/manager`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ user_id: userId })
      });

      if (!response.ok) throw new Error('관리자 삭제 실패');
      
      await fetchManagers();
    } catch (error) {
      console.error('관리자 삭제 실패:', error);
    }
  };

  const handleModalOpen = (mode, manager = null) => {
    setModalMode(mode);
    if (mode === 'edit' && manager) {
      setSelectedManager({
        id: manager.id,
        user_id: manager.user_id,
        user_name: manager.user_name,
        charge_work: manager.charge_work
      });
    }
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedManager(null);
    setModalMode('add');
  };

  const handlePageChange = (newPage) => {
    fetchManagers(newPage);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisible = 5;
    let start = Math.max(1, page - Math.floor(maxVisible / 2));
    let end = Math.min(start + maxVisible - 1, totalPages);

    if (end - start + 1 < maxVisible) {
      start = Math.max(1, end - maxVisible + 1);
    }

    if (start > 1) {
      pageNumbers.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className="px-3 py-1 border rounded hover:bg-gray-100"
        >
          1
        </button>
      );
      if (start > 2) {
        pageNumbers.push(<span key="ellipsis1">...</span>);
      }
    }

    for (let i = start; i <= end; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 border rounded ${page === i ? 'bg-[#231F20] text-white' : 'hover:bg-gray-100'}`}
        >
          {i}
        </button>
      );
    }

    if (end < totalPages) {
      if (end < totalPages - 1) {
        pageNumbers.push(<span key="ellipsis2">...</span>);
      }
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className="px-3 py-1 border rounded hover:bg-gray-100"
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
          <div className="container mx-auto px-6 py-8">
            <nav className="mb-6">
              <div className="flex items-center text-sm text-gray-600">
                <Link to="/managers" className="hover:text-[#4ABC85]">관리자관리</Link>
              </div>
            </nav>
            <div className="bg-white shadow-md rounded-lg p-6">
              <div className="mb-4 flex justify-end">
                <button 
                  onClick={() => handleModalOpen('add')}
                  className="bg-[#4ABC85] text-white px-4 py-2 rounded-lg"
                >
                  관리자 추가
                </button>
              </div>

              {isLoading ? (
                <div className="text-center py-4">로딩 중...</div>
              ) : (
                <table className="min-w-full">
                  <thead>
                    <tr className="bg-[#231F20] text-white">
                      <th className="px-6 py-3 text-left">사용자 ID</th>
                      <th className="px-6 py-3 text-left">이름</th>
                      <th className="px-6 py-3 text-left">담당 업무</th>
                      <th className="px-6 py-3 text-left">등록일</th>
                      <th className="px-6 py-3 text-left">수정일</th>
                      <th className="px-6 py-3 text-left">관리</th>
                    </tr>
                  </thead>
                  <tbody>
                    {managers.map((manager) => (
                      <tr key={manager.id} className="border-b">
                        <td className="px-6 py-4">{manager.user_id}</td>
                        <td className="px-6 py-4">{manager.user_name}</td>
                        <td className="px-6 py-4">{manager.charge_work}</td>
                        <td className="px-6 py-4">{new Date(manager.created_at).toLocaleDateString()}</td>
                        <td className="px-6 py-4">{new Date(manager.updated_at).toLocaleDateString()}</td>
                        <td className="px-6 py-4 flex gap-2">
                          <button 
                            className="text-[#4ABC85] flex items-center gap-1"
                            onClick={() => handleModalOpen('edit', manager)}
                          >
                            <PenSquare size={16} />
                            수정
                          </button>
                          <button 
                            className="text-red-500 flex items-center gap-1"
                            onClick={() => handleDeleteManager(manager.user_id)}
                          >
                            <Trash2 size={16} />
                            삭제
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {!isLoading && managers.length > 0 && (
                <div className="flex justify-center mt-4">
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => handlePageChange(page - 1)}
                      disabled={page === 1}
                      className="px-4 py-2 border rounded disabled:opacity-50"
                    >
                      이전
                    </button>
                    {renderPageNumbers()}
                    <button
                      onClick={() => handlePageChange(page + 1)}
                      disabled={!hasMore}
                      className="px-4 py-2 border rounded disabled:opacity-50"
                    >
                      다음
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>

      <ManagerModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={modalMode === 'add' ? handleAddManager : handleEditManager}
        initialData={selectedManager}
        mode={modalMode}
      />
    </div>
  );
}

export default ManagerManagement; 