import React, { useState, useCallback, useEffect } from 'react';
import { createEditor } from 'lexical';
import { $generateHtmlFromNodes } from '@lexical/html';
import PlaygroundNodes from '../editor/nodes/PlaygroundNodes';
import { processMathEquation } from './utils/latexParser';
import { useAuth } from '../context/AuthContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

const ProblemPreview = ({ 
  isOpen, 
  onClose, 
  problemIds = [], 
  showNumbers = false,
  correctList = [],
  problemNumbers = []
}) => {
  const [problems, setProblems] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getAccessToken } = useAuth();

  const fetchWithTokenRefresh = useCallback(async (url) => {
    try {
      const token = await getAccessToken();
      if (!token) {
        throw new Error('인증 토큰이 없습니다.');
      }

      const response = await fetch(`${API_BASE_URL}${url}`, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('API 요청 실패');
      }

      return await response.json();
    } catch (error) {
      console.error('API 호출 에러:', error);
      throw error;
    }
  }, [getAccessToken]);

  const convertLexicalToHtml = useCallback((lexicalData) => {
    const editor = createEditor({
      nodes: [...PlaygroundNodes]
    });

    const processNode = (node) => {
      if (node.type === 'equation') {
        node.equation = processMathEquation(node.equation);
      } else if (node.type === 'box-content') {
        node.className = 'PlaygroundEditorTheme__boxContent';
      }
      if (node.children && Array.isArray(node.children)) {
        node.children = node.children.map(child => processNode(child));
      }
      return node;
    };

    lexicalData.root = processNode(lexicalData.root);
    const editorState = editor.parseEditorState(lexicalData);

    let content = '';
    editor.setEditorState(editorState);
    editor.update(() => {
      content = $generateHtmlFromNodes(editor);
    });

    return content;
  }, []);

  const loadProblems = useCallback(async () => {
    if (!problemIds?.length) return;

    setLoading(true);
    try {
      const problemsData = await Promise.all(
        problemIds.map(async (id, index) => {
          const response = await fetchWithTokenRefresh(
            `/api/problems/${id}?lexical=true&mathpix=true&base64=true`
          );
          return {
            ...response.data,
            isCorrect: correctList[index] ?? null,
            number: problemNumbers[index] ?? (index + 1)
          };
        })
      );

      const processedProblems = problemsData.map((problem, index) => ({
        problem_id: problem.problem_id,
        content: convertLexicalToHtml(problem.lexical_data.lexical_data),
        number: problem.number,
        isCorrect: problem.isCorrect
      }));

      setProblems(processedProblems);
    } catch (error) {
      console.error('문제 로드 실패:', error);
      alert('문제를 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  }, [problemIds, fetchWithTokenRefresh, convertLexicalToHtml, correctList, problemNumbers]);

  useEffect(() => {
    if (isOpen && problemIds?.length) {
      loadProblems();
    }
  }, [isOpen, problemIds, loadProblems]);

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center"
      onClick={handleBackdropClick}
    >
      <div 
        className="bg-white rounded-lg p-6 w-[600px] max-h-[90vh] overflow-y-auto"
        onClick={e => e.stopPropagation()}
      >
        <div className="p-4 border-b flex justify-between items-center">
          <h2 className="text-xl font-bold">문제 미리보기</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>
        
        <div className="flex-1 overflow-y-auto p-4">
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
          ) : (
            <div className="space-y-8">
              {problems.map((problem) => (
                <div key={problem.problem_id} className="problem-container">
                  <div className="text-xs text-gray-500 mb-1">ID: {problem.problem_id}</div>
                  <div className="problem-content-wrapper">
                    {showNumbers && (
                      <div 
                        className="problem-number text-2xl font-bold"
                        style={{ 
                          color: problem.isCorrect === null ? '#000000' :
                                 problem.isCorrect ? '#4ABC85' : '#F9344B',
                          minWidth: '36px',
                          marginRight: '20px'
                        }}
                      >
                        {String(problem.number).padStart(2, '0')}
                      </div>
                    )}
                    <div 
                      className="problem-content"
                      style={{ width: showNumbers ? '432px' : '488px' }}
                      dangerouslySetInnerHTML={{ __html: problem.content }}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <style jsx>{`
        .problem-content *:not(.katex):not(.katex *) {
          font-family: 'NanumGothic', sans-serif !important;
          font-weight: 700 !important;
          font-size: 18px !important;
        }
        .katex {
          font-size: 1em !important;
        }
        .PlaygroundEditorTheme__boxContent {
          border: 1px solid black;
          padding: 10px;
          margin: 10px auto;
          max-width: 100%;
          border-radius: 4px;
          background-color: #ffffff;
        }
        .problem-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .problem-content-wrapper {
          display: flex;
          align-items: flex-start;
          width: 100%;
        }
        .problem-content {
          overflow-wrap: break-word;
          word-break: break-word;
        }
      `}</style>
    </div>
  );
};

export default ProblemPreview;
