import React from 'react';

function ExamListModal({ isOpen, onClose, examList, onSelectExam }) {
  if (!isOpen) return null;

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      onClick={handleBackgroundClick}
    >
      <div 
        className="bg-white p-6 rounded-lg w-3/4 max-h-[80vh] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-xl font-bold mb-4">전체 시험 목록</h2>
        <div className="space-y-4">
          {examList.map((exam, index) => (
            <div
              key={exam.exam_id}
              className="flex items-center space-x-6 p-4 border rounded cursor-pointer hover:bg-gray-50"
              onClick={() => onSelectExam(exam.exam_id, index)}
            >
              <div className="flex-1 px-6 py-2 border-r border-gray-200">
                <p className="text-sm text-gray-500 mb-1">TID</p>
                <p className="font-semibold">{exam.exam_id}</p>
              </div>
              <div className="flex-1 px-6 py-2 border-r border-gray-200">
                <p className="text-sm text-gray-500 mb-1">테스트 상태</p>
                <p className="font-semibold">
                  {exam.exam_status === 200 ? "결과입력필요" : 
                   exam.exam_status === 400 ? "결과입력완료" : 
                   exam.exam_status === 999 ? "결과확정완료" : "상태 미정"}
                </p>
              </div>
              <div className="flex-1 px-6 py-2 border-r border-gray-200">
                <p className="text-sm text-gray-500 mb-1">테스트 유형</p>
                <p className="font-semibold">{exam.exam_type}</p>
              </div>
              <div className="flex-1 px-6 py-2 border-r border-gray-200">
                <p className="text-sm text-gray-500 mb-1">생성일시</p>
                <p className="font-semibold">
                  {new Date(exam.exam_created_at).toLocaleDateString()}
                </p>
              </div>
              <div className="flex-1 px-6 py-2 border-r border-gray-200">
                <p className="text-sm text-gray-500 mb-1">수정일시</p>
                <p className="font-semibold">
                  {new Date(exam.exam_updated_at).toLocaleDateString()}
                </p>
              </div>
              <div className="flex-1 px-6 py-2 border-r border-gray-200">
                <p className="text-sm text-gray-500 mb-1">문제수</p>
                <p className="font-semibold">{exam.problems_count}</p>
              </div>
              <div className="flex-1 px-6 py-2">
                <p className="text-sm text-gray-500 mb-1">난이도</p>
                <p className="font-semibold">{exam.exam_difficulty}</p>
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-gray-200 rounded"
        >
          닫기
        </button>
      </div>
    </div>
  );
}

export default ExamListModal; 