import { processMathEquation } from '../utils/latexParser';
import katex from 'katex';
import 'katex/dist/katex.min.css';

// 브라우저 환경 체크
const isBrowser = typeof window !== 'undefined';

// 정답 높이 계산 함수
const calculateAnswerHeight = (answer) => {
    if (!isBrowser) return 0;
    
    const tempDiv = document.createElement('div');
    tempDiv.style.cssText = `
        position: absolute;
        visibility: hidden;
        width: 300px;
        left: -9999px;
        padding: 6px;
    `;
    
    try {
        katex.render(answer, tempDiv, {
            throwOnError: false,
            displayMode: true,
            strict: false
        });
    } catch (error) {
        tempDiv.textContent = answer;
    }
    
    document.body.appendChild(tempDiv);
    const height = tempDiv.offsetHeight;
    document.body.removeChild(tempDiv);
    
    return height / 10; // 변환 비율 조정
};

// 페이지당 사용 가능한 높이 계산
const getAvailableHeight = (pageIndex) => {
    const PAGE_HEIGHT = 297; // A4 높이 (mm)
    const HEADER_HEIGHT = pageIndex === 0 ? 30 : 30; // 첫 페이지는 더 큰 헤더
    const FOOTER_HEIGHT = 20;
    
    return PAGE_HEIGHT - HEADER_HEIGHT - FOOTER_HEIGHT;
};

export const handleSolutionPDF = async (params) => {
    try {
        const token = localStorage.getItem('accessToken');
        let data;

        if (params.examId && params.stampId) {
            // 기존 방식: examId와 stampId로 요청
            const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/api/exams/${params.stampId}/${params.examId}/problems?include_answer=true`, 
                {
                    headers: { 'Authorization': token }
                }
            );
            data = await response.json();
            
            if (data.status !== 'success') {
                throw new Error('Failed to fetch exam data');
            }
        } else if (params.problemIdList && Array.isArray(params.problemIdList)) {
            // 새로운 방식: problemId 리스트로 요청
            const problemIds = params.problemIdList.join(',');
            const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/api/solutions/answers?problem_ids=${problemIds}`,
                {
                    headers: { 'Authorization': token }
                }
            );
            const responseData = await response.json();
            
            if (responseData.status !== 'success') {
                throw new Error('Failed to fetch problems data');
            }

            // API 응답 구조를 기존 형식과 맞추기
            data = {
                status: 'success',
                data: {
                    problems_info: responseData.data.map((problem, index) => ({
                        problem_id: problem.problem_id,
                        answer: problem.answer
                    }))
                }
            };
        } else {
            throw new Error('Invalid parameters provided');
        }

        if (data.status === 'success') {
            const answers = data.data.problems_info.map((problem, index) => ({
                number: index + 1,
                problem_id: problem.problem_id,
                answer: problem.answer ? processMathEquation(problem.answer) : '-'
            }));

            // 페이지 분배
            const pages = [];
            let currentPage = [];
            let currentHeight = 0;
            let pageIndex = 0;

            answers.forEach((answer) => {
                const rowHeight = Math.max(20, calculateAnswerHeight(answer.answer));
                
                if (currentHeight + rowHeight > getAvailableHeight(pageIndex)) {
                    pages.push(currentPage);
                    currentPage = [];
                    currentHeight = 0;
                    pageIndex++;
                }
                
                currentPage.push(answer);
                currentHeight += rowHeight;
            });

            if (currentPage.length > 0) {
                pages.push(currentPage);
            }

            // HTML 생성
            const style = `
                <style>
                    @import url('https://fonts.googleapis.com/css2?family=Pretendard:wght@200;400;700;900&display=swap');
                    body { 
                        margin: 0; 
                        padding: 20px;
                        background: gray;
                        font-family: 'Pretendard', sans-serif;
                        cursor: pointer;
                    }
                    .page {
                        width: 210mm;
                        height: 297mm;
                        padding: 15mm;
                        margin: 0 auto;
                        background: white;
                        box-shadow: 0 0 10px rgba(0,0,0,0.1);
                        position: relative;
                        box-sizing: border-box !important;
                        display: flex;
                        flex-direction: column;
                    }
                    /* 미리보기에서만 페이지 간격 적용, 프린트 시에는 제거됨 */
                    .page:not(:last-child) {
                        margin-bottom: 20px;  // 브라우저 미리보기에서 페이지 간 간격
                    }
                    .header {
                        margin: -15mm -15mm 10mm -15mm;
                        padding: 15mm 15mm 5mm 15mm;
                        position: relative;
                        width: calc(100% + 30mm);
                        box-sizing: border-box;
                    }
                    .top-row {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-top: 3mm;
                        margin-bottom: 2mm;
                    }
                    .bottom-row {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 0mm;
                        margin-top: 0;  // 기존 음수 마진 제거
                    }
                    .student-info {
                        font-size: 14px;
                        color: #666;
                        text-align: right;
                        margin: 0;
                        min-width: max-content;
                    }
                    .student-name {
                        font-weight: 700;
                        color: #231F20;
                    }
                    .logo {
                        width: 100px;
                        height: 25px;
                        background-image: url('${process.env.PUBLIC_URL}/assets/images/logo_header_solution.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        margin: 0;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                        color-adjust: exact !important;
                    }
                    .header::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 30px;
                        background-image: url('${process.env.PUBLIC_URL}/assets/images/header_solution.png');
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: top center;
                    }
                    .exam-id {
                        font-size: 15px;
                        font-family: 'Pretendard', sans-serif;
                        font-weight: 700;
                        color: #333;
                        margin: 0;
                    }
                    .header h1 {
                        color: #4ABC85 !important;
                        font-size: 30px;
                        font-family: 'Pretendard', sans-serif;
                        font-weight: 900;
                        margin: 0;
                        position: relative;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                        color-adjust: exact !important;
                    }
                    .header h1::after {
                        content: none; /* 기존 after 제거 */
                    }
                    .title-container {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 15px;
                        width: 100%;
                    }
                    .logo-container {
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        justify-content: flex-end;
                        flex: 1;
                    }
                    .header-line {
                        position: absolute;
                        bottom: -5mm;
                        left: 15mm;
                        right: 15mm;
                        border: none;
                        border-top: 1px solid #231F20;
                        margin: 0;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                        margin: 0;
                        padding: 0;
                    }
                    th {
                        padding: 0px 5mm;
                        text-align: center;
                        font-size: 7px;
                        font-weight: bold;
                        color: #666;
                    }
                    /* 문제번호 열 좌측 정렬 강화 */
                    th:first-child,
                    .number-cell {
                        text-align: left !important;
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                    }
                    td {
                        padding: 1.5px 5mm;  // 패딩을 절반으로 축소
                        text-align: center;
                        height: 12px;      // 높이를 절반으로 축소
                        line-height: 1;
                        vertical-align: middle;
                        white-space: nowrap;
                    }
                    /* 각 페이지의 마지막 행의 테두리 제거 */
                    .page tbody tr:last-child td {
                        border-bottom: none;
                    }
                    
                    /* 새로운 행간 구분선 스타일 */
                    tbody tr {
                        position: relative;
                        width: 100%;
                    }
                    
                    tbody tr:not(:last-child)::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 1px;
                        background: #E5E5E5;
                        display: block;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                        color-adjust: exact !important;
                        visibility: visible !important;
                    }
                    .number-cell {
                        font-size: 20px;
                        font-weight: 900;
                        line-height: 1;
                        color: #4ABC85;
                    }
                    .tid-cell {
                        font-size: 16px;
                        font-weight: 200;
                        line-height: 1;
                    }
                    .answer-cell {
                        text-align: left;
                        min-height: 12px;  // 높이를 절반으로 축소
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        white-space: normal;
                        padding: 1.5px 5mm;   // 패딩을 절반으로 축소
                    }
                    .footer {
                        position: absolute;
                        bottom: 15mm;      // HTML은 그대로 유지
                        left: 15mm;
                        right: 15mm;
                        width: calc(100% - 30mm);
                    }
                    .footer-line {
                        border: none;
                        border-top: 1px solid #231F20;
                        margin: 0 0 5mm 0;
                    }
                    .page-number {
                        text-align: center;
                        font-size: 14pt;
                        font-weight: bold;
                    }
                    @media print {
                        body { 
                            background: white;
                            margin: 0;
                            padding: 0;
                        }
                        .page { 
                            width: 210mm;
                            height: 297mm;
                            padding: 15mm;
                            margin: 0;
                            box-shadow: none;
                            page-break-after: always;
                            page-break-inside: avoid;
                            position: relative;
                            overflow: hidden;
                            display: flex;
                            flex-direction: column;
                        }
                        table {
                            flex: none !important;
                            overflow: hidden;
                            page-break-inside: avoid;
                            border-collapse: collapse;
                            width: 100%;
                            height: fit-content !important;
                        }
                        
                        tbody {
                            height: fit-content !important;
                        }
                        
                        tr {
                            page-break-inside: avoid;
                            break-inside: avoid;
                            position: relative;
                            width: 100%;
                            height: 55px !important;  // 55px로 증가
                        }
                        
                        td {
                            padding: 6px 5mm !important;
                            height: 55px !important;      // 55px로 증가
                            line-height: 1.2 !important;
                            vertical-align: middle;
                            max-height: 55px !important;  // 55px로 증가
                        }
                        
                        .answer-cell {
                            min-height: 55px !important;  // 55px로 증가
                            height: 55px !important;      // 55px로 증가
                            max-height: 55px !important;  // 55px로 증가
                            padding: 6px 5mm !important;
                        }
                        .katex-display {
                            margin: 0;
                            white-space: nowrap;
                        }
                        .header::before {
                            -webkit-print-color-adjust: exact !important;
                            print-color-adjust: exact !important;
                            background-image: url('${process.env.PUBLIC_URL}/assets/images/header_solution.png') !important;
                        }
                        .header h1::after {
                            -webkit-print-color-adjust: exact !important;
                            print-color-adjust: exact !important;
                            background-image: url('${process.env.PUBLIC_URL}/assets/images/logo_header_solution.png') !important;
                        }
                        .footer {
                            position: absolute;
                            bottom: 5mm;    // 인쇄 미리보기에서 0mm로 설정
                            left: 15mm;
                            right: 15mm;
                            width: calc(100% - 30mm);
                        }
                        /* 인쇄 시에도 문제번호 열 좌측 정렬 유지 */
                        th:first-child,
                        .number-cell {
                            text-align: left !important;
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                        }
                        .logo {
                            background-image: url('${process.env.PUBLIC_URL}/assets/images/logo_header_solution.png') !important;
                            -webkit-print-color-adjust: exact !important;
                            print-color-adjust: exact !important;
                            color-adjust: exact !important;
                        }
                        .header h1 {
                            color: #4ABC85 !important;
                            -webkit-print-color-adjust: exact !important;
                            print-color-adjust: exact !important;
                            color-adjust: exact !important;
                        }
                    }
                    .problem-content *:not(.katex):not(.katex *) {
                        font-family: 'Pretendard', sans-serif !important;
                        font-weight: 700 !important;
                        font-size: 18px !important;
                    }
                    .katex {
                        font-size: 1em !important;
                    }
                    .print-header-first,
                    .print-footer,
                    .problem-number,
                    .header-left,
                    .header-right {
                        font-family: 'Pretendard', sans-serif;
                    }
                </style>
            `;

            const html = `
                <!DOCTYPE html>
                <html>
                <head>
                    <meta charset="UTF-8">
                    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/katex.min.css">
                    ${style}
                </head>
                <body onclick="window.close()">
                    ${pages.map((pageAnswers, pageIndex) => `
                        <div class="page">
                            <div class="header">
                                <div class="top-row">
                                    <p class="exam-id">TID ${params.examId || 'N/A'}</p>
                                    <div class="logo"></div>
                                </div>
                                <div class="bottom-row">
                                    <h1>빠른정답</h1>
                                    ${params.student_name || params.tree_name || params.edu_ver ? `
                                        <p class="student-info">
                                            ${params.student_name ? `<span class="student-name">${params.student_name}</span>` : ''}
                                            ${params.tree_name ? `${params.student_name ? ' · ' : ''}${params.tree_name}` : ''}
                                            ${params.edu_ver ? `${(params.student_name || params.tree_name) ? ' · ' : ''}${params.edu_ver}` : ''}
                                        </p>
                                    ` : ''}
                                </div>
                                <hr class="header-line">
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th style="width: 40px;">문제 번호</th>
                                        <th style="width: 40px;">TID</th>
                                        <th>정답</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    ${pageAnswers.map(answer => `
                                        <tr>
                                            <td class="number-cell">${String(answer.number).padStart(2, '0')}</td>
                                            <td class="tid-cell">${answer.problem_id}</td>
                                            <td class="answer-cell" data-answer="${answer.answer}"></td>
                                        </tr>
                                    `).join('')}
                                </tbody>
                            </table>
                            <div class="footer">
                                <hr class="footer-line">
                                <div class="page-number">${String(pageIndex + 1).padStart(2, '0')}</div>
                            </div>
                        </div>
                    `).join('')}
                    <script src="https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/katex.min.js"></script>
                    <script>
                        document.querySelectorAll('.answer-cell').forEach(cell => {
                            try {
                                katex.render(cell.dataset.answer, cell, {
                                    throwOnError: false,
                                    displayMode: true,
                                    strict: false
                                });
                            } catch (error) {
                                cell.textContent = cell.dataset.answer;
                            }
                        });
                    </script>
                </body>
                </html>
            `;

            const previewWindow = window.open('', '_blank');
            previewWindow.document.write(html);
            previewWindow.document.close();
        }
    } catch (error) {
        console.error('정답 PDF 생성 실패:', error);
    }
};
