export const handlePrint = () => {

    const printWindow = window.open('', '_blank');

    // 현재 에디터 내용을 복사
    const editorContent = document.querySelector('.editor-container').innerHTML;

    // 헤더 HTML 생성
    const headerHTML = `
          <div class="print-header">
            <div class="header-grid">
              <div class="header-left">
                <p class="subject">공통수학 1</p>
                <p class="chapter">다항식의 정의</p>
                <p class="scope">다항식의 덧셈과 뺄셈 - 곱셈공식을 이용한 식의 전개</p>
              </div>
              <div class="header-right">
                <p class="test-number">No. 11111111</p>
                <p class="student-name">이름 : 홍길동</p>
              </div>
            </div>
          </div>
        `;

    // 푸터 HTML 추가
    const footerHTML = `
          <div class="print-footer">
            <hr class="footer-line" />
            <div class="footer-content">
              <span class="footer-left">카이테스트 · 루트</span>
              <span class="footer-right">공통수학 1 · 다항식의 정의</span>
            </div>
          </div>
        `;

    // 스타일시트에 헤더 스타일 추가
    const headerStyles = `
          /* 헤더 스타일 */
          .print-header {
            margin: 30mm 16mm 20mm 16mm;  // 상단 15mm, 우측 10mm, 하단 20mm, 좌측 10mm 여백
            padding-bottom: 10mm;
          }
          .header-grid {
            display: grid;
            grid-template-columns: 2fr 1fr;
            gap: 5mm;
          }
          .header-right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
          }
          .subject {
            font-size: 16pt;
            font-weight: bold;
            margin: 0;
          }
          .chapter {
            font-size: 32pt;
            font-weight: 900;
            margin: 0;
          }
          .scope {
            font-size: 10pt;
            color: gray;
            margin: 0;
          }
          .test-number{
            font-size: 10pt;
            margin: 0;
         }
          .student-name {
            font-size: 15pt;
            font-weight: bold;
            margin: 0;
          }
    
          /* 푸터 스타일 */
          .print-footer {
            margin: 20mm 10mm 10mm 10mm;
          }
    
          .footer-line {
            border: none;
            border-top: 1px solid #ccc;
            margin-bottom: 5mm;
          }
    
          .footer-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #666;
            font-size: 10pt;
          }
    
          .footer-left, .footer-right {
            font-weight: bold;
          }
        `;

    // 기존 스타일시트 복사 및 헤더 스타일 추가
    const styleSheets = Array.from(document.styleSheets);
    const styleContent = styleSheets
        .map(sheet => {
            try {
                return Array.from(sheet.cssRules)
                    .map(rule => rule.cssText)
                    .join('\n');
            } catch (e) {
                return '';
            }
        })
        .join('\n') + headerStyles + `
            /* 푸터 페이지별 표시를 위한 추가 스타일 */
            @page {
              margin-bottom: 30mm;  /* 페이지 하단 여백 더 축소 */
            }
    
            .print-footer {
              position: fixed;
              bottom: 5mm;
              left: 0;
              right: 0;
              width: 296mm;  /* A4 용지 너비에 맞춤 */
              height: 20mm;  /* 푸터 높이 더 축소 */
              margin: 0 auto;  /* 중앙 정렬 */
            }
    
            .footer-line {
              margin: 0 0 2mm 0;  /* 구분선 마진 더 축소 */
            }
    
            .footer-content {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 10mm;  /* 좌우 여백 증가 */
            }
    
            /* 본문 여백 조정 */
            .editor-container {
              width: 296mm;
              padding-left: 16mm !important;  /* 좌측 패딩 */
              padding-right: 16mm !important;  /* 우측 패딩 */
              margin-bottom: 25mm !important;  /* 본문 하단 여백 증가 */
              padding-bottom: 20mm !important;  /* 추가 패딩 */
            }
    
            /* 마지막 페이지의 본문도 푸터와 겹치지 않도록 */
            .editor-container > div:last-child {
              margin-bottom: 30mm;
            }
    
            @media print {
              .print-footer {
                position: fixed;
                bottom: 5mm;
              }
            }
          `;

    printWindow.document.write(`
          <!DOCTYPE html>
          <html>
          <head>
            <meta charset="UTF-8">
            <title>문제 인쇄</title>
            <style>
              ${styleContent}
              @page {
                size: A4;
                margin: 5mm;
                margin-bottom: 30mm;
              }

              body {
                position: relative;
                min-height: 100vh;
                margin: 0;
                padding-bottom: 30mm;  /* 푸터 높이만큼 본문 영역 확보 */
              }

              .print-footer {
                position: fixed;
                height: 25mm;  /* 푸터 높이 명확히 지정 */
                padding: 0 16mm;
                box-sizing: border-box;
              }
              
              /* 텍스트 관련 스타일 보존 */
              .editor-text {
                white-space: pre-wrap;
                word-break: break-word;
                unicode-bidi: preserve;
              }
              
              /* 괄호 관련 스타일 명시적 지정 */
              .editor-text-content {
                font-family: inherit;
                unicode-bidi: isolate;
                text-rendering: optimizeLegibility;
              }
              
              /* 기존 스타일 유지 */
              .editor-container {
                font-family: inherit;
                text-rendering: optimizeLegibility;
                -webkit-font-smoothing: antialiased;
              }
              
              /* 레이아웃 컨테이너 스타일 */
              .PlaygroundEditorTheme__layoutContainer {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 32mm;
                margin: 4mm 0;
                width: 100%;
                min-width: 0;
              }
 
              
              /* 레이아웃 아이템 스타일 */
              .PlaygroundEditorTheme__layoutItem {
                width: 100%;
                min-width: 0;
                padding: 0;
                box-sizing: border-box;
                page-break-inside: avoid;
                overflow-wrap: break-word;
              }
              
              /* 점선 제거 */
              .PlaygroundEditorTheme__layoutContainer,
              .PlaygroundEditorTheme__layoutItem {
                border: none !important;
                outline: none !important;
              }
              
              /* 문제 번호 스타일 */
              .editor-paragraph:first-child {
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 16px;
              }
              
              /* 기본 스타일 */
              .editor-paragraph { margin: 0; padding: 0; }
              .editor-equation { display: inline-block; }
              div.editor-equation { display: block; }
              
              /* 페이지 나누기 방지 */
              .PlaygroundEditorTheme__layoutContainer {
                page-break-inside: avoid;
              }
    
              /* 내부 레이아웃 컨테이너 특별 처리 */
              .PlaygroundEditorTheme__layoutItem .PlaygroundEditorTheme__layoutContainer {
                width: 100%;
                margin: 2mm 0;
              }
    
              /* 내부 레이아웃 아이템 특별 처리 */
              .PlaygroundEditorTheme__layoutItem .PlaygroundEditorTheme__layoutItem {
                width: 100%;
              }
    
              /* 레이아웃 컨테이너 내부의 모든 요소들이 부모 크기를 넘지 않도록 함 */
              .PlaygroundEditorTheme__layoutContainer * {
                max-width: 100%;
              }
    
              /* 이미지 정렬 관련 스타일 추가 */
              .PlaygroundEditorTheme__image {
                display: block;
                position: relative;
                text-align: center;
                max-width: 100%;
                margin: 0 auto;
              }
    
              .PlaygroundEditorTheme__image img {
                max-width: 100%;
                height: auto;
                margin: 0 auto;
                display: block;
              }
    
              /* 이미지 컨테이너 정렬 스타일 */
              .PlaygroundEditorTheme__imageContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                text-align: center;
              }
    
              @media print {
                /* 헤더/푸터 제거 */
                @page {
                  margin: 5mm;
                }
                /* 페이지 번호 제거 */
                body {
                  margin: 0;
                }
                /* Chrome/Safari/Edge 헤더 푸터 제거 */
                @page {
                  size: A4;
                  margin: 5mm;
                }
                /* Firefox 헤더 푸터 제거 */
                html {
                  height: 100%;
                }
                /* 기타 브라우저 헤더 푸터 제거 */
                head, title {
                  display: none;
                }
    
                /* 리사이즈 핸들러 숨기기 */
                .PlaygroundEditorTheme__layoutItem::after,
                .PlaygroundEditorTheme__layoutContainer::after {
                  display: none !important;
                }
              }
    
              /* 미디어 요소들의 정렬 스타일 명시적 지정 */
              img, svg, video, canvas, audio, iframe, embed, object {
                display: block;
                vertical-align: middle;
                margin: 0 auto;  /* 가운데 정렬을  추가 */
                max-width: 100%;
                height: auto;
              }
    
              /* 이미지 컨테이너 관련 스타일 */
              .PlaygroundEditorTheme__image {
                display: block;
                text-align: center;
                max-width: 100%;
                margin: 0 auto;
              }
    
              .PlaygroundEditorTheme__imageContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
              }
            </style>
            <script>
              window.onload = function() {
                Promise.all(Array.from(document.images)
                  .filter(img => !img.complete)
                  .map(img => new Promise(resolve => {
                    img.onload = img.onerror = resolve;
                  })))
                  .then(() => {
                    // 페이지 수 계산 로직 추가
                    const calculatePages = () => {
                      const A4_HEIGHT_MM = 464; 
                      const headerHeight = document.querySelector('.print-header').offsetHeight * 0.264583;
                      const footerHeight = document.querySelector('.print-footer').offsetHeight * 0.264583;
                      
                      // 최상위 레벨 컨테이너만 선택
                      const editorContainer = document.querySelector('.editor-container > div');
                      const containers = Array.from(editorContainer.querySelectorAll(':scope > .PlaygroundEditorTheme__layoutContainer'));
                      
                      let currentPage = 1;
                      let currentHeight = 0;
                      let containersPerPage = {};
                      
                      // 첫 페이지 가용 높이
                      let availableHeight = A4_HEIGHT_MM - headerHeight - footerHeight;
                      
                      containers.forEach((container, index) => {
                        const containerHeight = container.offsetHeight * 0.264583;
                        
                        if (currentHeight + containerHeight > availableHeight) {
                          currentPage++;
                          currentHeight = containerHeight;
                          availableHeight = A4_HEIGHT_MM - footerHeight;
                        } else {
                          currentHeight += containerHeight;
                        }
                        
                        containersPerPage[currentPage] = (containersPerPage[currentPage] || 0) + 1;
                      });

                      console.log('페이지별 컨테이너 수:', containersPerPage);
                      console.log('총 페이지 수:', currentPage);
                      
                      return {
                        totalPages: currentPage,
                        containersPerPage: containersPerPage
                      };
                    };

                    // 기존 코드 실행
                    const editorContainer = document.querySelector('.editor-container > div');
                    const topLevelContainers = editorContainer.querySelectorAll(':scope > .PlaygroundEditorTheme__layoutContainer');
                    
                    // 실제 문제 수 계산 (입력된 ID 수)
                    const actualProblemCount = Array.from(topLevelContainers)
                      .reduce((count, container) => {
                        return count + Array.from(container.children)
                          .filter(child => child.classList.contains('PlaygroundEditorTheme__layoutItem'))
                          .filter(item => item.textContent.trim() !== '').length;
                      }, 0);
    
                    let problemNumber = 1;
                    
                    // 각 컨테이너(페이지)별로 처리
                    topLevelContainers.forEach((container, containerIndex) => {
                      const items = Array.from(container.children)
                        .filter(child => child.classList.contains('PlaygroundEditorTheme__layoutItem'));
                      
                      items.forEach((item, itemIndex) => {
                        // 마지막 문제이고 홀수 번째일 때는 번호를 부여하지 않음
                        const isLastItem = problemNumber === actualProblemCount;
                        const isOddTotal = actualProblemCount % 2 === 1;
                        const isRightColumn = itemIndex % 2 === 1;
    
                        if (problemNumber <= actualProblemCount && !(isLastItem && isOddTotal && isRightColumn)) {
                          // 내용이 있는 아이템에만 번호 부여
                          if (item.textContent.trim() !== '') {
                            const numberDiv = document.createElement('div');
                            numberDiv.className = 'problem-number';
                            numberDiv.textContent = String(problemNumber).padStart(2, '0');
                            item.insertBefore(numberDiv, item.firstChild);
                            item.classList.add('top-level-item');
                            problemNumber++;
                          }
                        }
                      });
                    });
    
                    setTimeout(() => {
                      window.print();
                      window.close();
                    }, 1000);

                    // 페이지 수 계산 실행
                    calculatePages();
                  });
              }
            </script>
            <style>
              .problem-number {
                font-size: 24px !important;
                font-weight: bold !important;
                margin-bottom: 16px !important;
                display: block !important;
                color: black !important;
                font-family: Arial, sans-serif !important;
              }
    
              /* 최상위 레이아웃 아이템에만 여백 적용 */
              .PlaygroundEditorTheme__layoutItem.top-level-item {
                padding-top: 20px !important;
                margin-top: 30mm !important;
                margin-bottom: 30mm !important;
              }
    
              /* 하위 레이아웃 아이템은 여백 제거 */
              .PlaygroundEditorTheme__layoutItem:not(.top-level-item) {
                padding-top: 0 !important;
              }
            </style>
          </head>
          <body>
            ${headerHTML}
            <div class="editor-container">
              ${editorContent}
            </div>
            ${footerHTML}
          </body>
          </html>
        `);

    printWindow.document.close();

}; 