import { EditorConfig, ElementNode, LexicalNode, SerializedElementNode } from 'lexical';

export type SerializedBoxContentNode = SerializedElementNode;

export class BoxContentNode extends ElementNode {
  static getType(): string {
    return 'box-content';
  }

  static clone(node: BoxContentNode): BoxContentNode {
    return new BoxContentNode(node.__key);
  }

  createDOM(config: EditorConfig): HTMLElement {
    const div = document.createElement('div');
    div.className = 'box-content';
    return div;
  }

  updateDOM(): boolean {
    return false;
  }

  isInline(): boolean {
    return false;
  }

  canBeEmpty(): boolean {
    return false;
  }

  canContainContent(): boolean {
    return true;
  }

  // ContentNode는 shadow root처럼 동작
  isShadowRoot(): boolean {
    return true;
  }

  static importJSON(serializedNode: SerializedBoxContentNode): BoxContentNode {
    return $createBoxContentNode();
  }

  exportJSON(): SerializedBoxContentNode {
    return {
      ...super.exportJSON(),
      type: 'box-content',
      version: 1,
    };
  }
}

export function $createBoxContentNode(): BoxContentNode {
  return new BoxContentNode();
}

export function $isBoxContentNode(
  node: LexicalNode | null | undefined
): node is BoxContentNode {
  return node instanceof BoxContentNode;
}
