import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { useAuth } from '../context/AuthContext';
import PlaygroundNodes from '../editor/nodes/PlaygroundNodes';
import { createEditor } from 'lexical';
import { processMathEquation } from '../components/utils/latexParser';
import { $generateHtmlFromNodes } from '@lexical/html';
import { usePrint } from '../context/PrintContext';
import { handleSolutionPDF } from '../components/SolutionPrint/handleSolutionPDFv2';
import PrintPreview from '../components/printPreview';

// 브라우저 환경 체크 함수 추가
const isBrowser = typeof window !== 'undefined';

const waitForRendering = () => {
  if (!isBrowser) return Promise.resolve();
  return new Promise(resolve => {
    // MathJax 렌더링을 위한 대기 시간 증가
    setTimeout(resolve, 100); // 100ms에서 500ms로 증가
  });
};

const calculateProblemHeight = (problem, index) => {
  if (!isBrowser) return 0;

  const tempDiv = document.createElement('div');
  tempDiv.style.cssText = `
        position: absolute;
        visibility: hidden;
        width: 488px;
        left: -9999px;
        display: block;
        opacity: 0;
    `;

  tempDiv.innerHTML = `
        <div class="problem-item" style="display: block; position: static;">
            <div class="problem-number" style="display: block;">${String(index + 1).padStart(2, '0')}</div>
            <div class="problem-content" style="display: block;">${problem.content}</div>
        </div>
    `;

  document.body.appendChild(tempDiv);
  const height = tempDiv.offsetHeight;
  document.body.removeChild(tempDiv);

  return height / 3.779527559055118;
};


// 인쇄 스타일을 동적으로 추가
const printStyles = document.createElement('style');
printStyles.textContent = `
    @media print {
        @page {
            size: A4;
            margin: 0;
        }
        body {
            margin: 0;
            padding: 0;
            -webkit-print-color-adjust: exact !important;
            print-color-adjust: exact !important;
        }
        .print-header-first {
            -webkit-print-color-adjust: exact !important;
            print-color-adjust: exact !important;
            background-image: url('${process.env.PUBLIC_URL}/assets/images/bg_test.png') !important;
        }
        .problem-content *:not(.katex):not(.katex *) {
            font-family: 'NanumGothic', sans-serif !important;
            font-weight: 700 !important;
            font-size: 18px !important;
        }
        .katex {
            font-size: 1em !important;
        }
        /* 추가: 특정 요소들의 폰트 예외 처리 */
        .print-header-first,
        .print-footer,
        .problem-number,
        .header-left,
        .header-right {
            font-family: initial;
            font-weight: initial;
            font-size: initial;
        }
        .problem-number {
            font-size: 40px !important;
            font-weight: 900 !important;
        }
        .header-left .chapter {
            font-size: 32pt !important;
            font-weight: 900 !important;
        }
    }
`;
document.head.appendChild(printStyles);

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

// ExamInfoForm을 컴포넌트 밖으로 분리
const ExamInfoForm = React.memo(({ examInfo, setExamInfo, studentInfo, setStudentInfo, footerMessages, setFooterMessages }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow mb-6">
      <h4 className="text-lg font-semibold mb-4">시험지 정보</h4>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">교육과정</label>
          <input
            type="text"
            value={examInfo.edu_ver}
            onChange={(e) => setExamInfo(prev => ({ ...prev, edu_ver: e.target.value }))}
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">테스트 ID(TID)</label>
          <input
            type="text"
            value={examInfo.exam_id}
            onChange={(e) => setExamInfo(prev => ({ ...prev, exam_id: e.target.value }))}
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">과목</label>
          <input
            type="text"
            value={examInfo.tree_name}
            onChange={(e) => setExamInfo(prev => ({ ...prev, tree_name: e.target.value }))}
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">학생 이름</label>
          <input
            type="text"
            value={studentInfo.student_name}
            onChange={(e) => setStudentInfo(prev => ({ ...prev, student_name: e.target.value }))}
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">꼬리말(왼쪽 하단)</label>
          <input
            type="text"
            value={footerMessages.left}
            onChange={(e) => setFooterMessages(prev => ({ ...prev, left: e.target.value }))}
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">꼬리말(오른쪽 하단)</label>
          <input
            type="text"
            value={footerMessages.right}
            onChange={(e) => setFooterMessages(prev => ({ ...prev, right: e.target.value }))}
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">메모</label>
          <textarea
            value={footerMessages.memo || ''}
            onChange={(e) => setFooterMessages(prev => ({ ...prev, memo: e.target.value }))}
            className="w-full p-2 border rounded resize-none"
            rows="4"
            maxLength="500"
            placeholder="메모를 입력하세요 (최대 500자)"
          />
          <div className="text-right text-sm text-gray-500 mt-1">
            {`${(footerMessages.memo || '').length}/500`}
          </div>
        </div>
      </div>
    </div>
  );
});

// 날짜 포맷팅 유틸리티 함수 추가
const formatDate = (dateString) => {
  const date = new Date(dateString);
  // KST로 변환 (UTC + 9시간)
  const kstDate = new Date(date.getTime() + (9 * 60 * 60 * 1000));
  
  const year = kstDate.getFullYear();
  const month = String(kstDate.getMonth() + 1).padStart(2, '0');
  const day = String(kstDate.getDate()).padStart(2, '0');
  const hours = String(kstDate.getHours()).padStart(2, '0');
  const minutes = String(kstDate.getMinutes()).padStart(2, '0');
  
  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

function ProblemPrint() {
  const { getAccessToken } = useAuth();
  const navigate = useNavigate();
  const { printData } = usePrint();
  const [loading, setLoading] = useState(false);
  const [problemIds, setProblemIds] = useState(printData.problemIds || '');
  const [examData, setExamData] = useState(null);
  const previewRef = useRef(null);
  const [examInfo, setExamInfo] = useState(printData.examInfo);
  const [studentInfo, setStudentInfo] = useState(printData.studentInfo);
  const [footerMessages, setFooterMessages] = useState({
    ...printData.footerMessages,
    memo: printData.footerMessages?.memo || ''
  });
  const [tempExams, setTempExams] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentExam, setCurrentExam] = useState(null);
  const [printFunction, setPrintFunction] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedExam, setSelectedExam] = useState(null);

  // isErrorNote 값을 콘솔에 출력
  console.log('isErrorNote:', printData.isErrorNote);

  const fetchWithTokenRefresh = useCallback(async (url, options = {}) => {
    try {
      const token = await getAccessToken();
      if (!token) {
        navigate('/signin');
        return null;
      }

      const response = await fetch(`${API_BASE_URL}${url}`, {
        ...options,
        headers: {
          ...options.headers,
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('API 요청 실패');
      }

      return await response.json();
    } catch (error) {
      console.error('API 호출 에러:', error);
      throw error;
    }
  }, [getAccessToken, navigate]);

  const loadProblemsByIds = useCallback(async () => {
    if (!problemIds.trim()) {
      alert('문제 ID를 입력해주세요');
      return;
    }

    setLoading(true);
    try {
      const ids = problemIds.split(',').map(id => id.trim()).filter(Boolean);
      const response = await fetchWithTokenRefresh(`/api/problems/print?problem_ids=${ids.join(',')}`);

      if (!response?.data) {
        throw new Error('문제 데이터를 불러오는데 실패했습니다.');
      }

      // 문제 데이터 구조화
      const problemList = await Promise.all(response.data.map(async (problem, index) => {
        if (!problem?.lexical_data?.lexical_data) {
          throw new Error(`문제 ${problem.problem_id}의 데이터가 올바르지 않습니다.`);
        }

        const editor = createEditor({
          nodes: [...PlaygroundNodes]
        });

        const processNode = (node) => {
          if (node.type === 'equation') {
            node.equation = processMathEquation(node.equation);
          } else if (node.type === 'box-content') {
            node.className = 'PlaygroundEditorTheme__boxContent';
          }
          if (node.children && Array.isArray(node.children)) {
            node.children = node.children.map(child => processNode(child));
          }
          return node;
        };

        const lexicalData = problem.lexical_data.lexical_data;
        lexicalData.root = processNode(lexicalData.root);
        const editorState = editor.parseEditorState(lexicalData);

        let content = '';
        editor.setEditorState(editorState);
        editor.update(() => {
          content = $generateHtmlFromNodes(editor);
        });

        return {
          problem_id: problem.problem_id,
          content,
          startHeight: index === 0 ? 120 : 30,
          useFullColumn: false
        };
      }));

      setExamData({
        status: 'success',
        data: {
          exam_info: examInfo,
          student_info: studentInfo,
          problem_list: problemList
        }
      });

      // footerMessages를 handlePreview에 전달
      handlePreview(problemList, {
        exam_info: examInfo,
        student_info: studentInfo,
        footerMessages: footerMessages
      });

    } catch (error) {
      console.error('문제 불러오기 실패:', error);
      alert(error.message || '문제를 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  }, [problemIds, fetchWithTokenRefresh, examInfo, studentInfo, footerMessages]);

  // API 호출 함수 수정
  const fetchTempExams = useCallback(async (page = 1) => {
    try {
      // parseInt를 사용하여 정수로 변환
      const pageInt = parseInt(page, 10);
      const perPageInt = 20;
      
      const response = await fetchWithTokenRefresh(`/api/customexams/temp?page=${pageInt}&per_page=${perPageInt}`);
      if (response?.status === 'success') {
        setTempExams(response.data);
        setTotalPages(response.total_pages);
        setCurrentPage(response.page);
      }
    } catch (error) {
      console.error('임시 저장 목록 조회 실패:', error);
    }
  }, [fetchWithTokenRefresh]);

  // 컴포넌트 마운트 시 데이터 로드
  useEffect(() => {
    fetchTempExams();
  }, [fetchTempExams]);

  // 템플릿 생성 함수 추가
  const createTemplate = async () => {
    try {
      setLoading(true);
      const requestBody = {
        edu_ver: examInfo.edu_ver,
        tree_name: examInfo.tree_name,
        student_name: studentInfo.student_name,
        exam_ids: examInfo.exam_id, // 단일 exam_id를 사용
        left_footer: footerMessages.left,
        right_footer: footerMessages.right,
        problem_ids: problemIds,
        memo: footerMessages.memo,
        is_errornote: printData.isErrorNote || false
      };

      const response = await fetchWithTokenRefresh('/api/customexams/temp', {
        method: 'POST',
        body: JSON.stringify(requestBody)
      });

      if (response.status === 'success') {
        alert('템플릿이 성공적으로 생성되었습니다.');
        // 템플릿 목록 새로고침
        fetchTempExams(currentPage);
      } else if (response.status_code === 202) {
        // 실패한 문제 ID들에 대한 처리
        const failedProblems = response.data.failed_problems;
        const failedMessages = failedProblems
          .map(p => `문제 ID ${p.problem_id}: ${p.message}`)
          .join('\n');
        alert(`일부 문제 ID가 유효하지 않습니다:\n${failedMessages}`);
      }
    } catch (error) {
      console.error('템플릿 생성 실패:', error);
      alert('템플릿 생성에 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  // 인쇄 미리보기 및 인쇄 처리 함수
  const handlePrintPreview = async (tempExam) => {
    try {
      setLoading(true);
      const ids = tempExam.problem_ids.split(',').map(id => id.trim()).filter(Boolean);
      const response = await fetchWithTokenRefresh(`/api/problems/print?problem_ids=${ids.join(',')}`);

      if (!response?.data) {
        throw new Error('문제 데이터를 불러오는데 실패했습니다.');
      }

      // 문제 데이터 구조화
      const problemList = await Promise.all(response.data.map(async (problem, index) => {
        if (!problem?.lexical_data?.lexical_data) {
          throw new Error(`문제 ${problem.problem_id}의 데이터가 올바르지 않습니다.`);
        }

        const editor = createEditor({
          nodes: [...PlaygroundNodes]
        });

        const processNode = (node) => {
          if (node.type === 'equation') {
            node.equation = processMathEquation(node.equation);
          } else if (node.type === 'box-content') {
            node.className = 'PlaygroundEditorTheme__boxContent';
          }
          if (node.children && Array.isArray(node.children)) {
            node.children = node.children.map(child => processNode(child));
          }
          return node;
        };

        const lexicalData = problem.lexical_data.lexical_data;
        lexicalData.root = processNode(lexicalData.root);
        const editorState = editor.parseEditorState(lexicalData);

        let content = '';
        editor.setEditorState(editorState);
        editor.update(() => {
          content = $generateHtmlFromNodes(editor);
        });

        return {
          problem_id: problem.problem_id,
          content
        };
      }));

      setSelectedExam({
        problems: problemList,
        examInfo: {
          tree_name: tempExam.tree_name,
          edu_ver: tempExam.edu_ver,
          exam_id: tempExam.exam_ids,
          student_name: tempExam.student_name,
          leftFooter: tempExam.left_footer || '',
          rightFooter: tempExam.right_footer || '',
          isErrorNote: tempExam.is_errornote
        }
      });
      setShowPreview(true);

    } catch (error) {
      console.error('문제 불러오기 실패:', error);
      alert(error.message || '문제를 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  // 인쇄 실행 함수
  const handlePrint = () => {
    if (printFunction) {
      printFunction();
    }
  };

  const handlePrintAnswer = (exam) => {
    if (!exam?.problem_ids) {
      alert('문제 ID가 없습니다.');
      return;
    }

    const problemIdList = exam.problem_ids.split(',').map(id => id.trim());
    handleSolutionPDF({
      problemIdList,
      student_name: exam.student_name,
      tree_name: exam.tree_name,
      edu_ver: exam.edu_ver
    });
  };

  const distributeProblems = (problems) => {
    let pages = [];
    let currentPage = { left: [], right: [] };
    let currentPageIndex = 0;
    let problemCounter = 0;

    const getPositions = (pageIndex) => {
      if (pageIndex === 0) {
        return {
          startHeight: 0,
          midHeight: 160,
          singleAreaHeight: 160
        };
      } else {
        return {
          startHeight: 0,
          midHeight: 205,
          singleAreaHeight: 205
        };
      }
    };

    const processPage = (problem, index) => {
      const { startHeight, midHeight, singleAreaHeight } = getPositions(currentPageIndex);
      const problemHeight = calculateProblemHeight(problem, index);

      console.log(`Problem ${index + 1} Height:`, {
        problemId: problem.problem_id,
        heightInMM: problemHeight,
        heightInPX: problemHeight * 3.779527559055118,
        singleAreaHeight,  // 기준값도 함께 출력
        isLongProblem: problemHeight > singleAreaHeight
      });

      // 새 페이지 시작 조건 체크
      if (currentPage.left.length === 2 && currentPage.right.length === 2) {
        pages.push({ ...currentPage });
        currentPage = { left: [], right: [] };
        currentPageIndex++;
        return processPage(problem, index);
      }

      // 문제가 한 영역보다 긴 경우 (긴 문제 처리)
      if (problemHeight > singleAreaHeight) {
        const longProblem = {
          ...problem,
          startHeight: 0,
          height: problemHeight,
          useFullColumn: true,
          area: 'full',
          problemNumber: ++problemCounter
        };

        // 현재 페이지의 상태 확인
        const leftEmpty = currentPage.left.length === 0;
        const rightEmpty = currentPage.right.length === 0;

        // 현재 페이지가 완전히 비어있는 경우
        if (leftEmpty && rightEmpty) {
          currentPage.left = [longProblem];
          currentPage.left[0].column = 'left';
          pages.push({ ...currentPage });
          currentPage = { left: [], right: [] };
          currentPageIndex++;
          return;
        }

        // 오른쪽 열이 비어있고 왼쪽 열에 문제가 있는 경우 (수정된 부분)
        if (rightEmpty && currentPage.left.length > 0) {
          currentPage.right = [longProblem];
          currentPage.right[0].column = 'right';
          pages.push({ ...currentPage });
          currentPage = { left: [], right: [] };
          currentPageIndex++;
          return;
        }

        // 이미 문제가 있는 경우 새 페이지로
        pages.push({ ...currentPage });
        currentPage = { left: [], right: [] };
        currentPageIndex++;
        currentPage.left = [longProblem];
        currentPage.left[0].column = 'left';
        pages.push({ ...currentPage });
        currentPage = { left: [], right: [] };
        currentPageIndex++;
        return;
      }

      // 일반 문제 배치
      const addProblemToPage = (column) => {
        const isTop = column === 'left' ? currentPage.left.length === 0 : currentPage.right.length === 0;
        const height = isTop ? startHeight : midHeight;

        const normalProblem = {
          ...problem,
          startHeight: height,
          height: problemHeight,
          problemNumber: ++problemCounter,
          column: column
        };

        if (column === 'left') {
          currentPage.left.push(normalProblem);
        } else {
          currentPage.right.push(normalProblem);
        }
      };

      // 왼쪽 열부터 채우고, 꽉 차면 오른쪽 열로
      if (currentPage.left.length < 2) {
        addProblemToPage('left');
      } else if (currentPage.right.length < 2) {
        addProblemToPage('right');
      }
    };

    problems.forEach((problem, index) => {
      processPage(problem, index);
    });

    // 마지막 페이지 처리
    if (currentPage.left.length > 0 || currentPage.right.length > 0) {
      pages.push(currentPage);
    }

    return pages;
  };

  const handlePreview = async (problems, data) => {
    if (!isBrowser) return;

    // 기존 스타일 저장
    const originalStyles = document.body.style.cssText;

    // 임시 컨테이너 생성
    const container = document.createElement('div');
    container.style.cssText = `
      position: fixed;
      top: -9999px;
      left: -9999px;
      width: 328mm;
      background: white;
      z-index: -1;
      visibility: hidden;
    `;

    // handlePDFv7.js의 스타일 그대로 복사
    const style = document.createElement('style');
    style.textContent = `
    @media screen {
      body { background: gray; }
      .pdf-container {
        width: 328mm;
        margin: 0 auto;
        background: white;
        box-shadow: 0 0 10px rgba(0,0,0,0.3);
      }
    }
    .print-header-first { 
        margin: 0;
        height: 120mm;
        background-image: url('${process.env.PUBLIC_URL}/assets/images/bg_test.png');
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;
        position: relative;
    }
    
    .print-header-rest {
        height: 15mm;
        background: none;
        margin: 0;
        position: relative;
    }

    .header-content {
      position: absolute;
      top: 16mm;
      left: 16mm;
      right: 16mm;
      z-index: 1;
    }
    .header-grid {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 20px;
    }
    .header-left .subject {
      font-size: 14pt;
      color: #333;  // 가독성을 위해 색상 조정
      margin: 0;
      font-weight: 500;
    }
    .header-left .chapter {
      font-size: 32pt;
      font-weight: 900;
      margin: 8mm 0;  // 여백 추가
      color: #4ABC85;
    }
    .header-left .scope {
      font-size: 10pt;
      color: gray;
      margin: 0;
    }
    .header-right {
      text-align: right;
      position: relative;
    }
    .test-number {
      font-size: 14pt;
      margin: 0;
      // position: absolute;
      top: 5mm;
      right: 68mm;
    }
    .student-name {
      font-size: 18pt;
      font-weight: bold;
      margin-top: 73mm;
      position: absolute;
      right: 30mm;
      color: #000;
    }
    .problem-number {
      font-size: 36px;
      font-weight: 900;
      min-width: 36px;
      white-space: nowrap;
      color: #4ABC85;
      margin: 0;
      line-height: 1;
      position: relative;
    }
    .editor-paragraph {
      position: relative;
    }
    .editor-text-bold {
      font-weight: bold;
    }
    .editor-text-italic {
      font-style: italic;
    }
    .editor-text-underline {
      text-decoration: underline;
    }
    .PlaygroundEditorTheme__paragraph {
      margin: 0;
      position: relative;
    }
    .PlaygroundEditorTheme__text-bold {
      font-weight: bold;
    }
    .page {
      width: 328mm; 
      min-height: 464mm; 
      background: white; 
      box-sizing: border-box;
      padding: 0;
      position: relative;
    }
    .problems-container {
      padding: 0 16mm;
      display: flex;
      flex-direction: row;
      gap: 16mm;
      min-height: 320.5mm;
      justify-content: space-between;
      position: relative;
    }
    .problems-container::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      width: 1px;
      background-color: #ccc;
      transform: translateX(-50%);
    }
    .problems-column {
      flex: 1;
      position: relative;
      display: block;
      width: calc(50% - 8mm);
      min-height: 320.5mm;
      overflow: visible;
    }
    .problem-item {
      width: 100%;
      max-width: 488px;
      overflow-wrap: break-word;
      text-align: left;
      display: flex;
      gap: 28px;
      align-items: flex-start;
      position: absolute;
      left: 0;
    }
    .problem-content .PlaygroundEditorTheme__layoutContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16mm;
      max-width: 100%;
      margin: 8mm 0;
      page-break-inside: avoid;
    }
    .problem-content .PlaygroundEditorTheme__layoutItem {
      width: 100%;
      min-width: 0;
      padding: 0;
      box-sizing: border-box;
      page-break-inside: avoid;
      overflow-wrap: break-word;
      border: none !important;
    }
    .problem-content img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 8mm auto;
    }
    .problem-content .math-element {
      font-size: 0.91em;
      display: inline-block;
      vertical-align: middle;
      margin: 0 2px;
    }
    .page:not(:first-child) .problems-container {
      margin-top: 30mm;
    }
    .PlaygroundEditorTheme__layoutItem > * {
      margin: 2mm 0;
    }
    .PlaygroundEditorTheme__imageContainer {
      text-align: center;
      margin: 8mm 0;
    }
    .PlaygroundEditorTheme__image {
      display: inline-block;
      max-width: 100%;
      vertical-align: middle;
    }
    .print-footer {
      position: absolute;
      bottom: 10mm;
      left: 16mm;
      right: 16mm;
      padding-bottom: 0;
    }
    .problem-content [data-lexical-layout-container] {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, auto);
      max-width: 100%;
    }
    .PlaygroundEditorTheme__boxContent {
      border: 1px solid black;
      padding: 10px;
      margin: 10px auto;
      max-width: 100%;
      border-radius: 4px;
      background-color: #ffffff;
      page-break-inside: avoid;
    }
    .problem-content {
      margin: 0;
      width: 100%;
      overflow-wrap: break-word;
      word-break: break-word;
    }
    .problem-item[data-full-column="true"] {
        height: 150mm;
        z-index: 1;
    }
    /* 첫 페이지 규칙 */
    div.page.first-child .problem-item[data-area="top"] {
        top: 0 !important;
    }
    div.page.first-child .problem-item[data-area="bottom"] {
        top: 160mm !important;
    }

    /* 두 번째 페이지 이후 규칙 */
    div.page:not(.first-child) .problem-item[data-area="top"] {
        top: 0mm !important;
    }
    div.page:not(.first-child) .problem-item[data-area="bottom"] {
        top: 205mm !important;
    }
    /* 첫 페이지의 구분선 */
    .page.first-child .problems-container::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      height: 319.5mm;  // footer까지 내려가도록 높이 증가
      width: 1px;
      background-color: #ccc;
      transform: translateX(-50%);
    }

    /* 두 번째 페이지 이후의 구분선 */
    .page:not(.first-child) .problems-container::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      height: 409mm;  // 문제 영역 높이만큼만
      width: 1px;
      background-color: #ccc;
      transform: translateX(-50%);
    }
    @media print {
        .print-header-first {
            -webkit-print-color-adjust: exact !important;
            print-color-adjust: exact !important;
            background-image: url('${process.env.PUBLIC_URL}/assets/images/bg_test.png') !important;
        }
        .page-break {
            display: none;
        }
        .page {
            margin-bottom: 0;
            box-shadow: none;
            page-break-after: always;  /* 각 페이지 후에 강제 페이지 나눔 */
            page-break-inside: avoid;  /* 페이지 내부 콘텐츠 분리 방지 */
        }
    }

    /* 첫 페이지의 problems-container 높이 (330mm = 1247.24px) */
    .page.first-child .problems-container {
        height: 320.5mm !important;
        min-height: 320.5mm !important;
        max-height: 320.5mm !important;
        margin: 0 !important;
        padding: 0 60.4724px !important;
    }

    /* 두 번째 페이지 이후의 problems-container 높이 */
    .page:not(.first-child) .problems-container {
        height: 410.5mm !important;
        min-height: 410.5mm !important;
        max-height: 410.5mm !important;
        margin-top: 57px !important;
        padding: 0 60.4724px !important;
    }

    /* 공통 스타일 */
    .problems-container {
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-between !important;
        gap: 60.4724px !important;
        position: relative !important;
        box-sizing: border-box !important;
    }

    /* 첫 페이지 푸터 */
    .page.first-child .print-footer {
        position: absolute;
        bottom: 10mm;
        left: 16mm;
        right: 16mm;
        padding-bottom: 0;
    }

    /* 두 번째 페이지부터의 푸터 */
    .page:not(.first-child) .print-footer {
        position: absolute;
        bottom: 10mm;  /* 10mm에서 5mm로 변경 */
        left: 16mm;
        right: 16mm;
        padding-bottom: 0;
    }
  `;

    try {
      // 스타일 적용
      document.head.appendChild(style);
      document.body.appendChild(container);

      const pages = distributeProblems(problems);
      const htmlPages = [];

      for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
        const page = pages[pageIndex];

        container.innerHTML = `
          <div class="page ${pageIndex === 0 ? 'first-child' : ''}">
            ${pageIndex === 0 ? `
              <div class="print-header-first">
                <div class="header-content">
                  <div class="header-grid">
                    <div class="header-left">
                      <p class="subject">${data.exam_info.edu_ver}</p>
                      <p class="chapter" style="color: #4ABC85 !important;">${data.exam_info.tree_name}</p>
                    </div>
                    <div class="header-right">
                      <p class="test-number"> No. ${data.exam_info.test_number}</p>
                      <p class="student-name">${data.student_info.student_name}</p>
                    </div>
                  </div>
                </div>
              </div>
            ` : `
              <div class="print-header-rest">
                <div class="header-content"></div>
              </div>
            `}
            <div class="problems-container">
              <div class="problems-column">
                ${page.left?.map((problem, index) => {
          const problemNumber = problem.problemNumber;
          return generateProblemHTML(problem, problemNumber - 1);
        }).join('')}
              </div>
              <div class="problems-column">
                ${page.right?.map((problem, index) => {
          const problemNumber = problem.problemNumber;
          return generateProblemHTML(problem, problemNumber - 1);
        }).join('')}
              </div>
            </div>
            ${generateFooterHTML(pageIndex, data.footerMessages)}
          </div>
        `;

        await waitForRendering();

        // 현재 페이지의 스타일시트 수집
        const existingStyles = Array.from(document.styleSheets)
          .map(sheet => {
            try {
              return Array.from(sheet.cssRules)
                .map(rule => rule.cssText)
                .join('\n');
            } catch (e) {
              return '';
            }
          })
          .join('\n');

        htmlPages.push(`
          <html>
          <head>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/katex.min.css">
            <style>
              ${existingStyles}
              ${style.textContent}
              .MathJax { font-size: 100% !important; }
              .MathJax_Display { overflow-x: auto; overflow-y: hidden; }
              .katex { font-size: 1em !important; }
              .katex-display { margin: 1em 0; }
            </style>
          </head>
          <body onclick="window.close()">
            ${container.innerHTML}
          </body>
          </html>
        `);
      }

      // HTML 미리보기 창 생성
      const previewWindow = window.open('', '_blank');
      const combinedHTML = `
        <html>
        <head>
          <style>
            @import url('https://hangeul.pstatic.net/hangeul_static/css/nanum-gothic.css');
            ${style.textContent}
            body { 
              margin: 0; 
              padding: 20px; 
              cursor: pointer;
              background: gray;
              -webkit-print-color-adjust: exact !important;
              print-color-adjust: exact !important;
            }
            .problem-content *:not(.katex):not(.katex *) {
              font-family: 'NanumGothic', sans-serif !important;
              font-weight: 700 !important;
              font-size: 18px !important;
            }
            .katex {
              font-size: 1em !important;
            }
            .page { 
              background: white;
              margin-bottom: 20px;
              box-shadow: 0 0 10px rgba(0,0,0,0.1);
            }
            .page-break { 
              height: 20px;
              background: gray;
            }
            .print-header-first,
            .print-footer,
            .problem-number,
            .header-left,
            .header-right {
              font-family: initial;
              font-weight: initial;
              font-size: initial;
            }
            .problem-number {
              font-size: 40px !important;
              font-weight: 900 !important;
            }
            .header-left .chapter {
              font-size: 32pt !important;
              font-weight: 900 !important;
            }
            @media print {
              .print-header-first {
                -webkit-print-color-adjust: exact !important;
                print-color-adjust: exact !important;
                background-image: url('${process.env.PUBLIC_URL}/assets/images/bg_test.png') !important;
              }
              .page-break {
                display: none;
              }
              .page {
                margin-bottom: 0;
                box-shadow: none;
              }
            }
          </style>
        </head>
        <body onclick="window.close()">
          ${htmlPages.join('<div class="page-break"></div>')}
        </body>
        </html>
      `;
      previewWindow.document.write(combinedHTML);
      previewWindow.document.close();

    } finally {
      // 원래 상태로 복구
      document.head.removeChild(style);
      document.body.removeChild(container);
      document.body.style.cssText = originalStyles;
    }
  };

  const generateProblemHTML = (problem) => {
    const isFullColumn = problem.useFullColumn;
    const columnClass = isFullColumn ? 'full-column' : '';

    return `
        <div class="problem-item ${columnClass}" 
             style="position: absolute; top: ${problem.startHeight}mm;">
            <div class="problem-number">
                ${String(problem.problemNumber).padStart(2, '0')}
            </div>
            <div class="problem-content">${problem.content}</div>
        </div>
    `;
  };

  const generateFooterHTML = (pageIndex, footerMessages) => {
    // 디버깅용 로그
    console.log('Generating footer with received messages:', footerMessages);
    
    return `
      <div class="print-footer">
        <hr style="border: none; border-top: 1px solid #ccc; margin-bottom: 5mm;" />
        <div style="position: relative; height: 24pt;">
          <div style="display: flex; justify-content: space-between; align-items: center; color: #666; font-size: 10pt;">
            <span style="font-weight: bold;">${footerMessages?.left || ''}</span>
            <span style="font-weight: bold;">${footerMessages?.right || ''}</span>
          </div>
          <span style="position: absolute; left: 50%; transform: translateX(-50%); font-size: 18pt; font-weight: bold; top: -4pt;">${String(pageIndex + 1).padStart(2, '0')}</span>
        </div>
      </div>
    `;
  };

  // 불러오기 기능 추가
  const handleLoadTempExam = (exam) => {
    // 시험지 정보 설정
    setExamInfo({
      edu_ver: exam.edu_ver,
      exam_id: exam.exam_ids,
      tree_name: exam.tree_name
    });
    
    // 학생 정보 설정
    setStudentInfo({
      student_name: exam.student_name
    });
    
    // 꼬리말 정보 설정
    setFooterMessages({
      left: exam.left_footer || '',
      right: exam.right_footer || '',
      memo: exam.memo || ''
    });
    
    // 문제 ID 설정
    setProblemIds(exam.problem_ids);
  };

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
          {!showPreview ? (
            <div className="container mx-auto px-6 py-8">
              <h3 className="text-gray-700 text-3xl font-medium mb-6">시험지 출력</h3>

              <ExamInfoForm
                examInfo={examInfo}
                setExamInfo={setExamInfo}
                studentInfo={studentInfo}
                setStudentInfo={setStudentInfo}
                footerMessages={footerMessages}
                setFooterMessages={setFooterMessages}
              />

              <div className="mb-6 flex gap-4 items-center">
                <input
                  type="text"
                  value={problemIds}
                  onChange={(e) => setProblemIds(e.target.value)}
                  placeholder="문제 ID들 쉼표(,)로 구분하여 입력하세요"
                  className="px-4 py-2 border rounded-md flex-1"
                />
                <button
                  onClick={createTemplate}
                  disabled={loading}
                  className="bg-[#4ABC85] text-white px-4 py-2 rounded disabled:bg-gray-400"
                >
                  {loading ? '처리중...' : '시험지 템플릿 생성'}
                </button>
              </div>

              {/* 임시 저장 목록 테이블 추가 */}
              <div className="bg-white rounded-lg shadow overflow-x-auto">
                <table className="min-w-full">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">생성일</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">작성자</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">TID</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">과목</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">학생이름</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">작업</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {tempExams.map((exam) => (
                      <tr key={exam.temp_exam_id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {formatDate(exam.created_at)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {exam.author_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {exam.exam_ids}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {exam.tree_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {exam.student_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                          <div className="flex gap-2 justify-end">
                            <button
                              onClick={() => handlePrintPreview(exam)}
                              disabled={loading}
                              className="text-indigo-600 hover:text-indigo-900 bg-indigo-100 px-3 py-1 rounded disabled:bg-gray-300"
                            >
                              {loading ? '처리중...' : '인쇄 미리보기'}
                            </button>
                            <button
                              onClick={() => handleLoadTempExam(exam)}
                              className="text-green-600 hover:text-green-900 bg-green-100 px-3 py-1 rounded"
                            >
                              불러오기
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* 페이지네이션 조건 수정 */}
              {totalPages > 0 && (
                <div className="flex justify-center mt-4 gap-2">
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                    <button
                      key={page}
                      onClick={() => fetchTempExams(page)}
                      className={`px-3 py-1 rounded ${
                        currentPage === page
                          ? 'bg-indigo-600 text-white'
                          : 'bg-white text-indigo-600 hover:bg-indigo-100'
                      }`}
                    >
                      {page}
                    </button>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="container mx-auto px-6 py-8 h-full flex flex-col items-center">
              <div className="w-full max-w-5xl">
                <div className="flex justify-between items-center mb-8">
                  <button
                    onClick={() => setShowPreview(false)}
                    className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                  >
                    뒤로가기
                  </button>
                  <button
                    onClick={handlePrint}
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                  >
                    인쇄하기
                  </button>
                </div>
                <div className="overflow-y-auto flex justify-center">
                  <PrintPreview 
                    problems={selectedExam.problems}
                    mode="preview"
                    scale={0.7}
                    tree_name={selectedExam.examInfo.tree_name}
                    edu_ver={selectedExam.examInfo.edu_ver}
                    student_name={selectedExam.examInfo.student_name}
                    exam_id={selectedExam.examInfo.exam_id}
                    leftFooter={selectedExam.examInfo.leftFooter}
                    rightFooter={selectedExam.examInfo.rightFooter}
                    onPrint={setPrintFunction}
                  />
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default ProblemPrint;