import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { processMathEquation } from '../utils/latexParser';
import katex from 'katex';
import 'katex/dist/katex.min.css';

export const handleSolutionPDF = async (examId, stampId) => {
  try {
    const token = localStorage.getItem('accessToken');
    
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/exams/${stampId}/${examId}/problems?include_answer=true`, 
      {
        headers: { 'Authorization': token }
      }
    );
    const data = await response.json();

    if (data.status === 'success') {
      const answers = data.data.problems_info.map((problem, index) => ({
        number: index + 1,
        problem_id: problem.problem_id,
        answer: problem.answer ? processMathEquation(problem.answer) : '-'
      }));

      // 한 페이지당 보여줄 답안 수를 15개로 줄임
      const answersPerPage = 15;
      const totalPages = Math.ceil(answers.length / answersPerPage);
      const pdf = new jsPDF('p', 'mm', 'a4');

      for (let pageNum = 0; pageNum < totalPages; pageNum++) {
        if (pageNum > 0) {
          pdf.addPage();
        }

        // 현재 페이지의 답안들
        const pageAnswers = answers.slice(
          pageNum * answersPerPage,
          (pageNum + 1) * answersPerPage
        );

        // 임시 div 생성
        const tempDiv = document.createElement('div');
        tempDiv.style.cssText = `
          padding: 20px 20px 40px 20px; /* 하단 패딩 40px로 증가 */
          position: absolute;
          top: -9999px;
          width: 700px;
          background: white;
        `;
        
        // 제목 추가 (페이지 번호 포함)
        const title = document.createElement('h2');
        title.style.cssText = `
          margin-bottom: 20px;
          font-size: 16px;
          text-align: center;
        `;
        title.textContent = `시험 ID: ${examId} - 정답표 (${pageNum + 1}/${totalPages})`;
        tempDiv.appendChild(title);

        // 테이블 생성 시 하단 여백 추가
        const table = document.createElement('table');
        table.style.cssText = `
          width: 300px;
          border-collapse: collapse;
          margin: 0 auto 60px auto; /* 하단 여백 증가 */
          font-size: 11px; /* 폰트 크기 약간 축소 */
          table-layout: fixed;
        `;
        
        // 테이블 헤더
        const thead = document.createElement('thead');
        thead.innerHTML = `
          <tr>
            <th style="border: 1px solid black; padding: 6px; width: 40px; background-color: #f5f5f5;">번호</th>
            <th style="border: 1px solid black; padding: 6px; width: 60px; background-color: #f5f5f5;">문제 ID</th>
            <th style="border: 1px solid black; padding: 6px; width: 150px; background-color: #f5f5f5;">정답</th>
          </tr>
        `;
        table.appendChild(thead);

        // 테이블 본문
        const tbody = document.createElement('tbody');
        pageAnswers.forEach((item) => {
          const tr = document.createElement('tr');
          const answerCell = document.createElement('td');
          answerCell.style.cssText = 'border: 1px solid black; padding: 6px; text-align: center; vertical-align: middle;';
          
          try {
            katex.render(item.answer, answerCell, {
              throwOnError: false,
              displayMode: true,
              strict: false
            });
          } catch (error) {
            console.warn('KaTeX 렌더링 실패:', error);
            answerCell.textContent = item.answer;
          }

          tr.innerHTML = `
            <td style="border: 1px solid black; padding: 6px; text-align: center; background-color: #fafafa;">${String(item.number).padStart(2, '0')}</td>
            <td style="border: 1px solid black; padding: 6px; text-align: center; background-color: #fafafa;">${item.problem_id}</td>
          `;
          tr.appendChild(answerCell);
          tbody.appendChild(tr);
        });
        
        table.appendChild(tbody);
        tempDiv.appendChild(table);

        // 추가 스타일
        const style = document.createElement('style');
        style.textContent = `
          .katex-html {
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateY(-8px);
          }
          .katex-html .frac-line {
            transform: translateY(4px) !important;
          }
        `;
        tempDiv.appendChild(style);

        // body에 임시로 추가
        document.body.appendChild(tempDiv);
        
        // KaTeX 스타일이 적용될 시간을 주기 위한 대기
        await new Promise(resolve => setTimeout(resolve, 500));

        // 현재 페이지 캡처 시 여백 설정
        const canvas = await html2canvas(tempDiv, {
          scale: 2,
          logging: false,
          useCORS: true,
          windowHeight: tempDiv.scrollHeight + 100, // 여백 증가
          height: tempDiv.scrollHeight + 100 // 여백 증가
        });

        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 210; // A4 너비
        const pageHeight = 297; // A4 높이
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        
        // 이미지를 약간 위로 올려서 배치 (상단 여백 조정)
        pdf.addImage(imgData, 'PNG', 0, 15, imgWidth, imgHeight * 0.9); // 크기를 90%로 축소하고 상단 여백 추가
        
        // 임시 div 제거
        document.body.removeChild(tempDiv);
      }
      
      // PDF를 Blob으로 변환하고 새 창에서 열기
      const pdfBlob = pdf.output('blob');
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, '_blank');
    }
  } catch (error) {
    console.error('정답 PDF 생성 실패:', error);
  }
};
