import React from 'react';
import katex from 'katex';
import 'katex/dist/katex.min.css';

// KaTeX 수식 처리를 위한 유틸리티 함수
const processMathEquation = (text) => {
  if (!text) return '';
  
  // 수식이 $로 감싸져 있는지 확인
  if (text.startsWith('$') && text.endsWith('$')) {
    return text.slice(1, -1); // $ 기호 제거
  }
  return text;
};

const PrintPreview = ({ 
  problems = [], 
  mode = 'preview',
  scale = 1,
  onProblemClick = null,
  tree_name = '교재 미리보기',
  edu_ver = '중등 수학',
  student_name = '테스트',
  exam_id = '1234',
  leftFooter = 'SKY 미래연수학학원',
  rightFooter = '오른쪽 푸터',
  onPrint
}) => {
  const [answers, setAnswers] = React.useState([]);

  React.useEffect(() => {
    const fetchAnswers = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const problemIds = problems.map(p => p.problem_id).join(',');
        
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/solutions/answers?problem_ids=${problemIds}`,
          {
            headers: { 'Authorization': token }
          }
        );
        const responseData = await response.json();
        
        if (responseData.status === 'success') {
          // KaTeX로 정답 처리
          const sortedAnswers = problems.map((problem, index) => {
            const answerData = responseData.data.find(a => a.problem_id === problem.problem_id);
            let processedAnswer = '-';
            
            if (answerData?.answer) {
              try {
                const tempDiv = document.createElement('div');
                katex.render(processMathEquation(answerData.answer), tempDiv, {
                  throwOnError: false,
                  displayMode: true,
                  strict: false
                });
                processedAnswer = tempDiv.innerHTML;
              } catch (error) {
                console.error('KaTeX 렌더링 오류:', error);
                processedAnswer = processMathEquation(answerData.answer);
              }
            }

            return {
              number: index + 1,
              problem_id: problem.problem_id,
              answer: processedAnswer
            };
          });
          setAnswers(sortedAnswers);
        }
      } catch (error) {
        console.error('답안 데이터 가져오기 실패:', error);
        setAnswers([]); // 에러 발생 시 답안 초기화
      }
    };

    if (problems.length > 0) {
      fetchAnswers();
    } else {
      setAnswers([]); // problems가 비어있으면 답안 초기화
    }
  }, [problems]);

  // 높이 계산 함수를 컴포넌트 내부로 이동
  const calculateProblemHeight = (problem, index) => {
    const tempDiv = document.createElement('div');
    tempDiv.style.cssText = `
      position: absolute;
      visibility: hidden;
      width: 488px;
      left: -9999px;
      display: block;
      opacity: 0;
    `;

    tempDiv.innerHTML = `
      <div class="problem-item" style="display: block; position: static;">
        <div class="problem-number" style="display: block;">${String(index + 1).padStart(2, '0')}</div>
        <div class="problem-content" style="display: block;">${problem.content}</div>
      </div>
    `;

    document.body.appendChild(tempDiv);
    const height = tempDiv.offsetHeight;
    document.body.removeChild(tempDiv);

    return height / 3.779527559055118;
  };

  const distributeProblems = (problems) => {
    let pages = [];
    let currentPage = { left: [], right: [] };
    let currentPageIndex = 0;
    let problemCounter = 0;

    const getPositions = (pageIndex) => {
      if (pageIndex === 0) {
        return {
          startHeight: 0,
          midHeight: 160,
          singleAreaHeight: 160
        };
      }
      return {
        startHeight: 0,
        midHeight: 205,
        singleAreaHeight: 205
      };
    };

    const processPage = (problem, index) => {
      const { startHeight, midHeight, singleAreaHeight } = getPositions(currentPageIndex);
      const problemHeight = calculateProblemHeight(problem, index);

      // 페이지가 가득 찼는지 먼저 확인
      const isPageFull = 
        (currentPage.left.length === 2 && currentPage.right.length === 2) || 
        (currentPage.left.length === 1 && currentPage.left[0]?.useFullColumn && currentPage.right.length === 2) ||
        (currentPage.right.length === 1 && currentPage.right[0]?.useFullColumn);

      if (isPageFull) {
        pages.push({ ...currentPage });
        currentPage = { left: [], right: [] };
        currentPageIndex++;
        return processPage(problem, index);
      }

      if (problemHeight > singleAreaHeight) {
        const longProblem = {
          ...problem,
          startHeight: 0,
          height: problemHeight,
          useFullColumn: true,
          area: 'full',
          problemNumber: ++problemCounter
        };

        // 긴 문제 배치 로직
        if (currentPage.left.length === 0 && currentPage.right.length === 0) {
          // 페이지가 비어있으면 왼쪽에 배치
          longProblem.column = 'left';
          currentPage.left = [longProblem];
        } else if (currentPage.left.length >= 1 && currentPage.right.length === 0) {
          // 왼쪽에 문제가 있고 오른쪽이 비어있으면 오른쪽에 배치
          longProblem.column = 'right';
          currentPage.right = [longProblem];
        } else {
          // 그 외의 경우(오른쪽에 문제가 있거나, 왼쪽이 비어있고 오른쪽에 문제가 있는 경우)
          // 새 페이지의 왼쪽에 배치
          pages.push({ ...currentPage });
          currentPage = { 
            left: [{ ...longProblem, column: 'left' }], 
            right: [] 
          };
          currentPageIndex++;
        }
        return;
      }

      const addProblemToPage = (column) => {
        const isTop = column === 'left' ? currentPage.left.length === 0 : currentPage.right.length === 0;
        const height = isTop ? startHeight : midHeight;

        const normalProblem = {
          ...problem,
          startHeight: height,
          height: problemHeight,
          problemNumber: ++problemCounter,
          column: column
        };

        if (column === 'left') {
          currentPage.left.push(normalProblem);
        } else {
          currentPage.right.push(normalProblem);
        }
      };

      // 일반 문제 배치 로직
      if (currentPage.left.length === 1 && currentPage.left[0].useFullColumn) {
        // 왼쪽에 긴 문제가 있으면 오른쪽에만 배치
        if (currentPage.right.length < 2) {
          addProblemToPage('right');
        }
      } else if (currentPage.right.length === 1 && currentPage.right[0].useFullColumn) {
        // 오른쪽에 긴 문제가 있으면 새 페이지로
        pages.push({ ...currentPage });
        currentPage = { left: [], right: [] };
        currentPageIndex++;
        return processPage(problem, index);
      } else {
        // 일반적인 경우의 배치 로직
        if (currentPage.left.length < 2) {
          addProblemToPage('left');
        } else if (currentPage.right.length < 2) {
          addProblemToPage('right');
        }
      }
    };

    problems.forEach((problem, index) => {
      processPage(problem, index);
    });

    if (currentPage.left.length > 0 || currentPage.right.length > 0) {
      pages.push(currentPage);
    }

    return pages;
  };

  const pages = distributeProblems(problems);

  const containerStyle = {
    ...(mode === 'preview' ? {
      width: '210mm',
      transform: `scale(${scale})`,
      transformOrigin: 'top left',
      minHeight: '297mm'
    } : {}),
  };

  const answerPageStyles = {
    answersTable: {
      width: '100%',
      borderCollapse: 'collapse',
      margin: '0',
      padding: '0',
      flex: 'none',
      overflow: 'hidden',
      height: 'fit-content',
      boxSizing: 'border-box',
      maxHeight: '205mm', // A4 높이에서 여백과 헤더/푸터 공간을 뺀 값
      pageBreakInside: 'auto' // 페이지 나누기 허용
    },
    tableRow: {
      position: 'relative',
      width: '100%',
      height: '55px',
      pageBreakInside: 'avoid', // 행 중간에서 페이지 나누기 방지
      breakInside: 'avoid',
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '1px',
        background: '#E5E5E5'
      }
    },
    tableHeader: {
      padding: '0px 5mm',
      textAlign: 'center',
      fontSize: '12px',
      fontWeight: 'bold',
      color: '#666',
      height: '30px'
    },
    tableCell: {
      padding: '6px 5mm',
      textAlign: 'center',
      height: '55px',
      lineHeight: '1.2',
      verticalAlign: 'middle',
      maxHeight: '55px',
      whiteSpace: 'nowrap'
    },
    numberCell: {
      fontSize: '20px',
      fontWeight: '900',
      lineHeight: '1',
      color: '#4ABC85',
      textAlign: 'left',
      paddingLeft: '0',
      paddingRight: '0',
      width: '80px'
    },
    tidCell: {
      fontSize: '16px',
      fontWeight: '200',
      lineHeight: '1',
      width: '80px'
    },
    answerCell: {
      textAlign: 'left',
      minHeight: '55px',
      height: '55px',
      maxHeight: '55px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      whiteSpace: 'normal',
      padding: '6px 5mm'
    },
    headerLine: {
      border: 'none',
      borderTop: '1px solid #231F20',
      margin: '20px 0',
      width: '100%'
    },
    logo: {
      width: '100px',
      height: '25px',
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/logo_header_solution.png)`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      margin: '0',
      WebkitPrintColorAdjust: 'exact',
      printColorAdjust: 'exact',
      colorAdjust: 'exact'
    },
    examId: {
      fontSize: '15px',
      fontFamily: 'Pretendard, sans-serif',
      fontWeight: '700',
      color: '#333',
      margin: '0'
    },
    topRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: '3mm',
      marginBottom: '2mm',
      marginTop: '30px'
    },
    bottomRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '10px'
    },
    title: {
      color: '#4ABC85',
      fontSize: '36px',
      fontFamily: 'Pretendard, sans-serif',
      fontWeight: '900',
      margin: '0'
    },
    printHeader: {
      marginBottom: '20px'
    },
    printHeaderRest: {
      position: 'relative',
      marginBottom: '20px',
      padding: '30px 15mm 0 15mm',
      background: `url(${process.env.PUBLIC_URL}/assets/images/header_solution.png) top center/100% 30px no-repeat`,
      WebkitPrintColorAdjust: 'exact',
      printColorAdjust: 'exact',
      colorAdjust: 'exact'
    },
    headerContent: {
      width: '100%',
      position: 'relative',
      zIndex: 1
    },
    answerContainer: {
      padding: '0 15mm',
      width: '100%',
      boxSizing: 'border-box'
    }
  };

  // 한 페이지에 들어갈 수 있는 최대 행 수 계산
  const ANSWER_ROW_HEIGHT = 55; // 각 답안 행의 높이
  const HEADER_HEIGHT = 30; // 헤더 행의 높이
  const AVAILABLE_HEIGHT = 205; // mm 단위의 사용 가능한 높이
  const MM_TO_PX = 3.779527559055118; // 1mm = 3.7795px (96dpi 기준)
  const AVAILABLE_HEIGHT_PX = AVAILABLE_HEIGHT * MM_TO_PX;

  // 헤더를 제외하고 실제 답안이 들어갈 수 있는 최대 행 수
  const MAX_ROWS_PER_PAGE = Math.floor((AVAILABLE_HEIGHT_PX - HEADER_HEIGHT) / ANSWER_ROW_HEIGHT);

  // 정답지 페이지 계산
  const ROWS_PER_PAGE = 25; // 한 페이지당 표시할 정답 수 고정

  // 인쇄 함수 정의
  const handlePrint = React.useCallback(() => {
    const printContent = document.querySelector('.preview-container');
    if (!printContent) {
      console.error('Print content not found');
      return;
    }

    const printWindow = window.open('', '', 'width=800,height=600');
    if (!printWindow) {
      console.error('Could not open print window');
      return;
    }
    
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <link href="https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap" rel="stylesheet">
          <style>
            ${Array.from(document.styleSheets)
              .map(styleSheet => {
                try {
                  return Array.from(styleSheet.cssRules)
                    .map(rule => rule.cssText)
                    .join('\n');
                } catch (e) {
                  console.log('Cannot access stylesheet rules');
                  return '';
                }
              })
              .join('\n')}
            
            @page {
              size: A4;
              margin: 0;
            }
            
            html, body { 
              margin: 0 !important; 
              padding: 0 !important; 
              background: white !important;
              width: 328mm !important;
              height: 464mm !important;
            }
            
            .preview-container {
              width: 328mm !important;
              margin: 0 !important;
              padding: 0 !important;
              transform: none !important;
              position: relative !important;
            }
            
            .page {
              width: 328mm !important;
              min-height: 464mm !important;
              margin: 0 !important;
              padding: 0 !important;
              box-sizing: border-box !important;
              position: relative !important;
              page-break-after: always !important;
            }
            
            .problems-container {
              padding: 0 16mm !important;
              display: flex !important;
              flex-direction: row !important;
              gap: 16mm !important;
              justify-content: space-between !important;
              position: relative !important;
            }
            
            .problem-content *:not(.katex):not(.katex *) {
              font-family: 'Nanum Gothic', sans-serif !important;
              font-weight: 700 !important;
              font-size: 18px !important;
            }
            
            .katex {
              font-size: 1em !important;
            }
            
            @media print {
              html, body {
                width: 328mm !important;
                height: 464mm !important;
              }
              .preview-container {
                width: 328mm !important;
              }
              .no-print {
                display: none !important;
              }
            }
          </style>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/katex@0.16.0/dist/katex.min.css">
        </head>
        <body>
          ${printContent.innerHTML}
        </body>
      </html>
    `);
    
    printWindow.document.close();
    printWindow.focus();
    
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 1000);
  }, []); // 의존성 없음

  // 인쇄 함수를 부모 컴포넌트로 전달만 하고, 실행은 하지 않음
  React.useEffect(() => {
    if (onPrint) {
      onPrint(() => handlePrint); // 함수 레퍼런스만 전달
    }
  }, [handlePrint, onPrint]);

  return (
    <div className="print-preview-wrapper">
      <style>
        {`
          @media screen {
      body { background: gray; }
      .pdf-container {
        width: 328mm;
        margin: 0 auto;
        background: white;
        box-shadow: 0 0 10px rgba(0,0,0,0.3);
      }
    }
    .print-header-first { 
        margin: 0;
        height: 120mm;
        background-image: url('${process.env.PUBLIC_URL}/assets/images/bg_test.png');
        background-size: contain;
        background-position: top center;
        background-repeat: no-repeat;
        position: relative;
    }
    
    .print-header-rest {
        height: 15mm;
        background: none;
        margin: 0;
        position: relative;
    }

    .header-content {
      position: absolute;
      top: 16mm;
      left: 16mm;
      right: 16mm;
      z-index: 1;
    }
    .header-grid {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 20px;
    }
    .header-left .subject {
      font-size: 14pt;
      color: #333;  // 가독성을 위해 색상 조정
      margin: 0;
      font-weight: 500;
    }
    .header-left .chapter {
      font-size: 32pt;
      font-weight: 900;
      margin: 8mm 0;  // 여백 추가
      color: #4ABC85;
    }
    .header-left .scope {
      font-size: 10pt;
      color: gray;
      margin: 0;
    }
    .header-right {
      text-align: right;
      position: relative;
    }
    .test-number {
      font-size: 14pt;
      margin: 0;
      // position: absolute;
      top: 5mm;
      right: 68mm;
    }
    .student-name {
      font-size: 18pt;
      font-weight: bold;
      margin-top: 73mm;
      position: absolute;
      right: 30mm;
      color: #000;
    }
    .problem-number {
      font-size: 36px;
      font-weight: 900;
      min-width: 36px;
      white-space: nowrap;
      color: #4ABC85;
      margin: 0;
      line-height: 1;
      position: relative;
    }
    .editor-paragraph {
      position: relative;
    }
    .editor-text-bold {
      font-weight: bold;
    }
    .editor-text-italic {
      font-style: italic;
    }
    .editor-text-underline {
      text-decoration: underline;
    }
    .PlaygroundEditorTheme__paragraph {
      margin: 0;
      position: relative;
    }
    .PlaygroundEditorTheme__text-bold {
      font-weight: bold;
    }
    .page {
      width: 328mm; 
      min-height: 464mm; 
      background: white; 
      box-sizing: border-box;
      padding: 0;
      position: relative;
    }
    .problems-container {
      padding: 0 16mm;
      display: flex;
      flex-direction: row;
      gap: 16mm;
      min-height: 320.5mm;
      justify-content: space-between;
      position: relative;
    }
    .problems-container::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      width: 1px;
      background-color: #ccc;
      transform: translateX(-50%);
    }
    .problems-column {
      flex: 1;
      position: relative;
      display: block;
      width: calc(50% - 8mm);
      min-height: 320.5mm;
      overflow: visible;
    }
    .problem-item {
      width: 100%;
      max-width: 488px;
      overflow-wrap: break-word;
      text-align: left;
      display: flex;
      gap: 28px;
      align-items: flex-start;
      position: absolute;
      left: 0;
    }
    .problem-content .PlaygroundEditorTheme__layoutContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16mm;
      max-width: 100%;
      margin: 8mm 0;
      page-break-inside: avoid;
    }
    .problem-content .PlaygroundEditorTheme__layoutItem {
      width: 100%;
      min-width: 0;
      padding: 0;
      box-sizing: border-box;
      page-break-inside: avoid;
      overflow-wrap: break-word;
      border: none !important;
    }
    .problem-content img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 8mm auto;
    }
    .problem-content .math-element {
      font-size: 0.91em;
      display: inline-block;
      vertical-align: middle;
      margin: 0 2px;
    }
    .page:not(:first-child) .problems-container {
      margin-top: 30mm;
    }
    .PlaygroundEditorTheme__layoutItem > * {
      margin: 2mm 0;
    }
    .PlaygroundEditorTheme__imageContainer {
      text-align: center;
      margin: 8mm 0;
    }
    .PlaygroundEditorTheme__image {
      display: inline-block;
      max-width: 100%;
      vertical-align: middle;
    }
    .print-footer {
      position: absolute;
      bottom: 10mm;
      left: 16mm;
      right: 16mm;
      padding-bottom: 0;
    }
    .problem-content [data-lexical-layout-container] {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, auto);
      max-width: 100%;
    }
    .PlaygroundEditorTheme__boxContent {
      border: 1px solid black;
      padding: 10px;
      margin: 10px auto;
      max-width: 100%;
      border-radius: 4px;
      background-color: #ffffff;
      page-break-inside: avoid;
    }
    .problem-content {
      margin: 0;
      width: 100%;
      overflow-wrap: break-word;
      word-break: break-word;
    }
    .problem-item[data-full-column="true"] {
        height: 150mm;
        z-index: 1;
    }
    /* 첫 페이지 규칙 */
    div.page.first-child .problem-item[data-area="top"] {
        top: 0 !important;
    }
    div.page.first-child .problem-item[data-area="bottom"] {
        top: 160mm !important;
    }

    /* 두 번째 페이지 이후 규칙 */
    div.page:not(.first-child) .problem-item[data-area="top"] {
        top: 0mm !important;
    }
    div.page:not(.first-child) .problem-item[data-area="bottom"] {
        top: 205mm !important;
    }
    /* 첫 페이지의 구분선 */
    .page.first-child .problems-container::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      height: 319mm;  // footer까지 내려가도록 높이 증가
      width: 1px;
      background-color: #ccc;
      transform: translateX(-50%);
    }

    /* 두 번째 페이지 이후의 구분선 */
    .page:not(.first-child) .problems-container::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      height: 409mm;  // 문제 영역 높이만큼만
      width: 1px;
      background-color: #ccc;
      transform: translateX(-50%);
    }
    @media print {
        .print-header-first {
            -webkit-print-color-adjust: exact !important;
            print-color-adjust: exact !important;
            background-image: url('${process.env.PUBLIC_URL}/assets/images/bg_test.png') !important;
        }
        .page-break {
            display: none;
        }
        .page {
            margin-bottom: 0;
            box-shadow: none;
            page-break-after: always;  /* 각 페이지 후에 강제 페이지 나눔 */
            page-break-inside: avoid;  /* 페이지 내부 콘텐츠 분리 방지 */
        }
    }

    /* 첫 페이지의 problems-container 높이 (330mm = 1247.24px) */
    .page.first-child .problems-container {
        height: 320.5mm !important;
        min-height: 320.5mm !important;
        max-height: 320.5mm !important;
        margin: 0 !important;
        padding: 0 60.4724px !important;
    }

    /* 두 번째 페이지 이후의 problems-container 높이 */
    .page:not(.first-child) .problems-container {
        height: 410.5mm !important;
        min-height: 410.5mm !important;
        max-height: 410.5mm !important;
        margin-top: 57px !important;
        padding: 0 60.4724px !important;
    }

    /* 공통 스타일 */
    .problems-container {
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-between !important;
        gap: 60.4724px !important;
        position: relative !important;
        box-sizing: border-box !important;
    }

    /* 첫 페이지 푸터 */
    .page.first-child .print-footer {
        position: absolute;
        bottom: 10mm;
        left: 16mm;
        right: 16mm;
        padding-bottom: 0;
    }

    /* 두 번째 페이지부터의 푸터 */
    .page:not(.first-child) .print-footer {
        position: absolute;
        bottom: 10mm;  /* 10mm에서 5mm로 변경 */
        left: 16mm;
        right: 16mm;
        padding-bottom: 0;
    }
        `}
      </style>
      <div className="preview-container" style={containerStyle}>
        <style jsx>{`
          .problem-content *:not(.katex):not(.katex *) {
            font-family: 'NanumGothic', sans-serif !important;
            font-weight: 700 !important;
            font-size: 18px !important;
          }
          .katex {
            font-size: 1em !important;
          }
          .test-number {
            right: 70mm;
            height: 30px;
            line-height: 30px;
            margin: 0;
            display: flex;
            align-items: center;
            white-space: nowrap;
          }
          .student-name {
            position: absolute;
            top: 70mm;  /* 원하는 위치로 조정 */
            right: 30mm; /* 오른쪽 여백 */
            height: 30px;
            font-size: 36px;
            font-weight: 700;
            color: #333;
            margin: 0;
            line-height: 1.5;
          }
          .header-grid {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 20px;
          }
        `}</style>
        {pages.map((page, pageIndex) => (
          <div key={pageIndex} className={`page ${pageIndex === 0 ? 'first-child' : ''}`}>
            {/* Header */}
            {pageIndex === 0 ? (
              <div className="print-header-first">
                <div className="header-content">
                  <div className="header-grid">
                    <div className="header-left">
                      <p className="subject">{edu_ver}</p>
                      <p className="chapter" style={{ color: '#4ABC85',fontweight: '700'  }}>{tree_name}</p>
                      {student_name && <p className="student-name">{student_name}</p>}
                    </div>
                    <div className="header-right">
                      <p className="test-number">TID. {exam_id}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="print-header-rest">
                <div className="header-content"></div>
              </div>
            )}

            {/* Problems Container */}
            <div className="problems-container">
              {/* Left Column */}
              <div className="problems-column">
                {page.left?.map((problem, index) => (
                  <ProblemItem 
                    key={problem.problem_id}
                    problem={problem}
                    index={index}
                    mode={mode}
                    onClick={onProblemClick}
                  />
                ))}
              </div>

              {/* Right Column */}
              <div className="problems-column">
                {page.right?.map((problem, index) => (
                  <ProblemItem 
                    key={problem.problem_id}
                    problem={problem}
                    index={index}
                    mode={mode}
                    onClick={onProblemClick}
                  />
                ))}
              </div>
            </div>

            {/* Footer */}
            <div className="print-footer" style={{
              position: 'absolute',
              bottom: '10mm',
              left: 0,
              right: 0,
              width: '100%',
              height: '15mm',
              padding: '0 15mm',
              boxSizing: 'border-box'
            }}>
              <hr style={{ 
                border: 'none', 
                borderTop: '1px solid #ccc', 
                margin: '0 0 5mm 0',
                width: '100%'
              }} />
              <div style={{ 
                position: 'relative', 
                height: '8mm',
                display: 'flex',
                alignItems: 'center'
              }}>
                <div style={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center', 
                  color: '#666', 
                  fontSize: '10pt',
                  width: '100%'
                }}>
                  <span style={{ fontWeight: 'bold' }}>{leftFooter}</span>
                  <span style={{ fontWeight: 'bold' }}>{edu_ver} · {tree_name}</span>
                </div>
                <span style={{ 
                  position: 'absolute', 
                  left: '50%', 
                  transform: 'translateX(-50%)', 
                  fontSize: '18pt', 
                  fontWeight: 'bold',
                  lineHeight: '1'
                }}>
                  {String(pageIndex + 1).padStart(2, '0')}
                </span>
              </div>
            </div>
          </div>
        ))}

        {answers.length > 0 && (
          <>
            {Array.from({ length: Math.ceil(answers.length / ROWS_PER_PAGE) }).map((_, pageIndex) => (
              <div key={pageIndex} className={`page ${pages.length + pageIndex === 0 ? 'first-child' : ''}`} style={{
                position: 'relative',
                width: '1239.69px',
                height: '297mm',
                margin: '0',
                pageBreakAfter: 'always'
              }}>
                <div style={{
                  ...answerPageStyles.printHeaderRest,
                  width: '100%',
                  padding: '30px 15mm 0 15mm'
                }}>
                  <div style={answerPageStyles.headerContent}>
                    <div style={answerPageStyles.topRow}>
                      <p style={answerPageStyles.examId}>TID. {exam_id}</p>
                      <div style={answerPageStyles.logo} />
                    </div>
                    <div style={answerPageStyles.bottomRow}>
                      <h1 style={answerPageStyles.title}>빠른정답</h1>
                      <div>
                        <span style={{ 
                          fontSize: '14px',
                          fontWeight: 'bold',
                          marginRight: '4px'
                        }}>{student_name}</span>
                        <span style={{ 
                          fontSize: '14px',
                          color: '#666'
                        }}>{edu_ver} · {tree_name}</span>
                      </div>
                    </div>
                    <hr style={answerPageStyles.headerLine} />
                  </div>
                </div>

                <div style={{
                  ...answerPageStyles.answerContainer,
                  width: '100%',
                  padding: '0 15mm',
                  minHeight: '205mm',
                  overflow: 'visible'
                }}>
                  <table style={{
                    ...answerPageStyles.answersTable,
                    width: '100%',
                    height: 'auto',
                    maxHeight: 'none'
                  }}>
                    <thead>
                      <tr style={answerPageStyles.tableRow}>
                        <th style={{ ...answerPageStyles.tableHeader, width: '80px', textAlign: 'left', paddingLeft: '0' }}>문제 번호</th>
                        <th style={{ ...answerPageStyles.tableHeader, width: '80px' }}>PID</th>
                        <th style={answerPageStyles.tableHeader}>정답</th>
                      </tr>
                    </thead>
                    <tbody>
                      {answers.slice(pageIndex * ROWS_PER_PAGE, (pageIndex + 1) * ROWS_PER_PAGE).map((answer, index) => (
                        <tr key={answer.problem_id} style={{
                          ...answerPageStyles.tableRow,
                          borderBottom: index === answers.length - 1 ? 'none' : '1px solid #E5E5E5'
                        }}>
                          <td style={{ 
                            ...answerPageStyles.tableCell, 
                            ...answerPageStyles.numberCell,
                            WebkitPrintColorAdjust: 'exact',
                            printColorAdjust: 'exact',
                            colorAdjust: 'exact'
                          }}>
                            {String(answer.number).padStart(2, '0')}
                          </td>
                          <td style={{ 
                            ...answerPageStyles.tableCell, 
                            ...answerPageStyles.tidCell 
                          }}>
                            {answer.problem_id}
                          </td>
                          <td style={{ 
                            ...answerPageStyles.tableCell, 
                            ...answerPageStyles.answerCell 
                          }}>
                            <div 
                              style={{ 
                                margin: 0, 
                                whiteSpace: 'nowrap',
                                fontFamily: 'Pretendard, sans-serif',
                                fontSize: '18px',
                                fontWeight: '700'
                              }}
                              dangerouslySetInnerHTML={{ __html: answer.answer }} 
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Footer */}
                <div className="print-footer" style={{
                  position: 'absolute',
                  bottom: '10mm',
                  left: 0,
                  right: 0,
                  width: '100%',
                  height: '15mm',
                  padding: '0 15mm',
                  boxSizing: 'border-box'
                }}>
                  <hr style={{ 
                    border: 'none', 
                    borderTop: '1px solid #ccc', 
                    margin: '0 0 5mm 0',
                    width: '100%'
                  }} />
                  <div style={{ 
                    position: 'relative', 
                    height: '8mm',
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <div style={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center', 
                      color: '#666', 
                      fontSize: '10pt',
                      width: '100%'
                    }}>
                      <span style={{ fontWeight: 'bold' }}>{leftFooter}</span>
                      <span style={{ fontWeight: 'bold' }}>{edu_ver} · {tree_name}</span>
                    </div>
                    <span style={{ 
                      position: 'absolute', 
                      left: '50%', 
                      transform: 'translateX(-50%)', 
                      fontSize: '18pt', 
                      fontWeight: 'bold',
                      lineHeight: '1'
                    }}>
                      {String(pageIndex + 1).padStart(2, '0')}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

// ProblemItem 컴포넌트
const ProblemItem = ({ problem, index, mode, onClick }) => {
  return (
    <div 
      className="problem-item"
      data-area={index === 0 ? "top" : "bottom"}
      style={{
        top: `${problem.startHeight}mm`,
        cursor: mode === 'preview' && onClick ? 'pointer' : 'default'
      }}
      onClick={() => mode === 'preview' && onClick && onClick(problem)}
    >
      <div className="problem-number">
        {String(problem.problemNumber).padStart(2, '0')}
      </div>
      <div 
        className="problem-content"
        dangerouslySetInnerHTML={{ __html: problem.content }}
      />
    </div>
  );
};

export default PrintPreview;
