import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { EyeOff, Eye, User, Lock } from 'lucide-react';

function SignIn() {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login, getAccessToken } = useAuth();

  useEffect(() => {
    let isMounted = true;
    const checkAuth = async () => {
      try {
        const token = await getAccessToken();
        if (token && isMounted) {
          navigate('/dashboard');
        }
      } catch (error) {
        console.error('인증 확인 중 오류 발생:', error);
      }
    };
    checkAuth();
    return () => {
      isMounted = false;
    };
  }, [navigate, getAccessToken]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    const success = await login(userId, password);
    if (success) {
      navigate('/dashboard');
    } else {
      setError('로그인에 실패했습니다. 아이디와 비밀번호를 확인해주세요.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-white">
      <div className="w-full max-w-md p-8">
        <h1 className="text-4xl font-bold text-center mb-[60px] text-black">KY HUB Console</h1>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label htmlFor="user-id" className="block text-sm font-medium text-[#231F20] mb-1 text-left">아이디</label>
              <div className="relative">
                <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#A3A1A2]" size={20} />
                <input
                  id="user-id"
                  name="userId"
                  type="text"
                  required
                  className={`w-full h-[60px] pl-10 pr-3 bg-[#EEEEEE] border border-[#EEEEEE] rounded-2xl text-sm focus:outline-none focus:border-black focus:bg-opacity-10 focus:bg-black placeholder-[#A3A1A2] focus:ring-black caret-black ${userId ? 'text-[#231F20]' : 'text-[#A3A1A2]'}`}
                  placeholder="아이디를 입력해 주세요"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                />
              </div>
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-[#231F20] mb-1 text-left">비밀번호</label>
              <div className="relative">
                <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#A3A1A2]" size={20} />
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  required
                  className={`w-full h-[60px] pl-10 pr-3 bg-[#EEEEEE] border border-[#EEEEEE] rounded-2xl text-sm focus:outline-none focus:border-black focus:bg-opacity-10 focus:bg-black placeholder-[#A3A1A2] focus:ring-black caret-black ${password ? 'text-[#231F20]' : 'text-[#A3A1A2]'}`}
                  placeholder="비밀번호를 입력해 주세요"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {showPassword ? (
                  <Eye onClick={() => setShowPassword(false)} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#A3A1A2] opacity-40 cursor-pointer" size={20} />
                ) : (
                  <EyeOff onClick={() => setShowPassword(true)} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#A3A1A2] opacity-40 cursor-pointer" size={20} />
                )}
              </div>
            </div>
          </div>

          {error && (
            <div className="text-red-500 text-sm">{error}</div>
          )}

          <div className="mt-8">
            <button
              type="submit"
              className="w-full h-[60px] bg-black text-white rounded-2xl font-bold text-lg hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
            >
              로그인
            </button>
          </div>
        </form>
        <div className="mt-6 text-center text-sm">
          <span className="text-gray-600">아직 회원이 아니신가요? </span>
          <button
            type="button"
            onClick={() => navigate('/signup')}
            className="text-black font-semibold underline"
          >
            회원가입
          </button>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
