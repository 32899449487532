import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext(null);

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(() => localStorage.getItem('accessToken'));
  const [refreshToken, setRefreshToken] = useState(() => localStorage.getItem('refreshToken'));
  const [userNickname, setUserNickname] = useState(() => localStorage.getItem('userNickname'));
  const [userId, setUserId] = useState(() => localStorage.getItem('userId'));
  const [userType, setUserType] = useState(() => localStorage.getItem('userType'));
  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
    } else {
      localStorage.removeItem('accessToken');
    }
  }, [accessToken]);

  useEffect(() => {
    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
    } else {
      localStorage.removeItem('refreshToken');
    }
  }, [refreshToken]);

  useEffect(() => {
    if (userNickname) {
      localStorage.setItem('userNickname', userNickname);
    } else {
      localStorage.removeItem('userNickname');
    }
  }, [userNickname]);

  useEffect(() => {
    if (userId) {
      localStorage.setItem('userId', userId);
    } else {
      localStorage.removeItem('userId');
    }
  }, [userId]);

  useEffect(() => {
    if (userType) {
      localStorage.setItem('userType', userType);
    } else {
      localStorage.removeItem('userType');
    }
  }, [userType]);

  const login = async (userId, userPw) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: userId, user_pw: userPw }),
        credentials: 'include', // 쿠키를 포함하기 위해 추가
      });
      const data = await response.json();
      if (data.status === 'success') {
        setAccessToken(data.data.access_token);
        setRefreshToken(data.data.refresh_token);
        setUserNickname(data.data.user_nickname);
        setUserId(data.data.user_id);
        setUserType(data.data.user_type);
        return true;
      }
      return false;
    } catch (error) {
      console.error('로그인 오류:', error);
      return false;
    }
  };

  const logout = () => {
    setAccessToken(null);
    setRefreshToken(null);
    setUserNickname(null);
    setUserId(null);
    setUserType(null);
    navigate('/signin');
  };

  const refreshAccessToken = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/tokens/access/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh_token: refreshToken }),
      });
      const data = await response.json();
      if (data.status === 'success') {
        setAccessToken(data.data.access_token);
        return true;
      }
      return false;
    } catch (error) {
      console.error('토큰 갱신 오류:', error);
      return false;
    }
  };

  const getAccessToken = async () => {
    // 토큰이 없는 경우 처리
    if (!accessToken && refreshToken) {
      const success = await refreshAccessToken();
      if (!success) {
        logout();
        return null;
      }
    } else if (!accessToken && !refreshToken) {
      return null;
    }

    // JWT 토큰 만료 시간 확인 함수
    const isTokenExpired = (token) => {
      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const payload = JSON.parse(window.atob(base64));
        return payload.exp * 1000 < Date.now();
      } catch (e) {
        return true;
      }
    };

    // 토큰이 만료된 경우에만 검증 API 호출
    if (accessToken && isTokenExpired(accessToken)) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/auth/tokens/access/validate`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });

        if (response.status === 401) {
          logout();
          return null;
        }
      } catch (error) {
        console.error('토큰 검증 오류:', error);
        logout();
        return null;
      }
    }

    return accessToken ? `Bearer ${accessToken}` : null;
  };

  const handleApiError = (error) => {
    // 401 에러와 메시지가 일치하는 경우 처리
    if (error.response?.status === 401 || 
        error.response?.data?.message === 'Invalid or expired token' ||
        error.message === 'Invalid or expired token') {
      setAccessToken(null);
      setRefreshToken(null);
      setUserNickname(null);
      setUserId(null);
      setUserType(null);
      navigate('/signin');
      return true;
    }
    return false;
  };

  return (
    <AuthContext.Provider value={{ 
      login, 
      logout, 
      getAccessToken, 
      userNickname, 
      userId, 
      userType, 
      handleApiError 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
