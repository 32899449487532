import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';

function CreateExamModal({ 
  isOpen, 
  onClose, 
  stampId, 
  onSuccess, 
  selectedAddressesForExam, 
  setSelectedAddressesForExam, 
  examType, 
  setExamType, 
  deltaSelections, 
  setDeltaSelections,
  treeData 
}) {
  const { getAccessToken } = useAuth();
  const [isMinimized, setIsMinimized] = useState(false);
  const [difficulty, setDifficulty] = useState('1.0');
  const [totalProblems, setTotalProblems] = useState(5);
  const [hierarchicalSelections, setHierarchicalSelections] = useState({
    d1: new Set(),
    d2: new Set(),
    d3: new Set(),
    d4: new Set()
  });

  // 유틸리티 함수들
  const getAddressParts = (address) => address.split('/');
  
  const getParentAddress = (address) => {
    const parts = getAddressParts(address);
    return parts.slice(0, -1).join('/');
  };

  const getChildAddresses = (parentAddress, depth) => {
    const depthData = {
      'd1': treeData?.field_info?.d1_y_axis || [],
      'd2': treeData?.field_info?.d2_y_axis || [],
      'd3': treeData?.field_info?.d3_y_axis || [],
      'd4': treeData?.field_info?.d4_y_axis || []
    };

    const children = new Set();
    const parentParts = getAddressParts(parentAddress);

    depthData[depth].forEach(item => {
      const itemAddress = item[2].replace(/_/g, '/');
      const itemParts = getAddressParts(itemAddress);
      
      if (itemParts.slice(0, parentParts.length).join('/') === parentAddress) {
        children.add(itemAddress);
      }
    });

    return children;
  };

  const isAllChildrenSelected = (parentAddress, depth) => {
    const children = getChildAddresses(parentAddress, getNextDepth(depth));
    return Array.from(children).every(child => 
      hierarchicalSelections[getNextDepth(depth)].has(child)
    );
  };

  const getNextDepth = (currentDepth) => {
    const depths = ['d1', 'd2', 'd3', 'd4'];
    const currentIndex = depths.indexOf(currentDepth);
    return depths[currentIndex + 1] || currentDepth;
  };

  const getPreviousDepth = (currentDepth) => {
    const depths = ['d1', 'd2', 'd3', 'd4'];
    const currentIndex = depths.indexOf(currentDepth);
    return depths[currentIndex - 1] || currentDepth;
  };

  const optimizeSelections = () => {
    const optimized = new Set();
    
    // 각 depth별로 최적화
    ['d4', 'd3', 'd2', 'd1'].forEach(depth => {
      hierarchicalSelections[depth].forEach(address => {
        const parentAddress = getParentAddress(address);
        
        // 이미 상위 주소가 선택되었다면 건너뛰기
        if (Array.from(optimized).some(opt => address.startsWith(opt))) {
          return;
        }

        // 모든 형제 주소가 선택되었다면 부모 주소 추가
        if (isAllChildrenSelected(parentAddress, getPreviousDepth(depth))) {
          optimized.add(parentAddress);
        } else {
          optimized.add(address);
        }
      });
    });

    return Array.from(optimized);
  };

  const handleSelectionChange = (address, depth) => {
    setHierarchicalSelections(prev => {
      const newSelections = { ...prev };
      const currentSet = new Set(newSelections[depth]);

      if (currentSet.has(address)) {
        // 선택 해제
        currentSet.delete(address);
        // 하위 주소들도 모두 해제
        ['d2', 'd3', 'd4'].forEach(d => {
          if (d > depth) {
            newSelections[d] = new Set(
              Array.from(newSelections[d]).filter(addr => !addr.startsWith(address))
            );
          }
        });
      } else {
        // 선택
        currentSet.add(address);
        // 하위 주소들 자동 선택
        if (depth !== 'd4') {
          const childAddresses = getChildAddresses(address, getNextDepth(depth));
          childAddresses.forEach(childAddr => {
            newSelections[getNextDepth(depth)].add(childAddr);
          });
        }
      }

      newSelections[depth] = currentSet;
      return newSelections;
    });

    // 최적화된 주소 목록 업데이트
    const optimizedAddresses = optimizeSelections();
    setSelectedAddressesForExam(optimizedAddresses);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const token = await getAccessToken();
      let endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/exams/${stampId}/`;
      let body = {};

      switch (examType) {
        case 'alpha':
          endpoint += 'alpha';
          body = {
            difficulty: parseFloat(difficulty) * 10,
            total_problems: totalProblems
          };
          break;
        case 'beta':
        case 'gamma':
          endpoint += examType;
          if (selectedAddressesForExam.length === 0) {
            alert('최소 1개 이상의 유형을 선택해주세요.');
            return;
          }
          body = {
            pick_address: selectedAddressesForExam,
            total_problems: totalProblems
          };
          break;
        case 'delta':
          endpoint += 'delta';
          if (deltaSelections.length === 0) {
            alert('최소 1개 이상의 중유형과 레벨을 선택해주세요.');
            return;
          }
          body = {
            selections: deltaSelections.map(selection => {
              const [address, level] = selection.split(',');
              return { address, level: parseInt(level) };
            })
          };
          break;
        default:
          return;
      }

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        if (response.status === 409) {
          const data = await response.json();
          alert(`시험 생성 실패: ${data.message}\n실패한 선택: ${data.data?.fail_list?.join(', ') || '없음'}`);
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return;
      }

      alert('시험이 생성되었습니다.');
      onSuccess?.();
      onClose();
    } catch (error) {
      console.error('시험 생성 오류:', error);
      alert('시험 생성 중 오류가 발생했습니다.');
    }
  };

  // 모달이 닫힐 때 상태 초기화
  useEffect(() => {
    if (!isOpen) {
      setHierarchicalSelections({
        d1: new Set(),
        d2: new Set(),
        d3: new Set(),
        d4: new Set()
      });
      setSelectedAddressesForExam([]);
    }
  }, [isOpen]);

  const getDepthFromAddress = (address) => {
    if (!address) return null;
    const parts = address.split('/');
    switch(parts.length) {
      case 2: return 'd1';
      case 3: return 'd2';
      case 4: return 'd3';
      case 5: return 'd4';
      default: return null;
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 pointer-events-none">
      <div className="fixed left-[200px] bottom-24 bg-white rounded-lg p-4 w-[400px] pointer-events-auto shadow-xl">
        <div className="absolute bottom-0 left-6 transform translate-y-full">
          <div className="w-4 h-4 bg-white transform rotate-45 -translate-y-2" />
        </div>
        
        <div className="flex justify-between items-center mb-3">
          <h2 className="text-lg font-bold">테스트 생성</h2>
          <div className="flex gap-2">
            <button 
              onClick={() => setIsMinimized(prev => !prev)} 
              className="text-gray-500 hover:text-gray-700"
            >
              <span className="text-xl">−</span>
            </button>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X size={20} />
            </button>
          </div>
        </div>

        {!isMinimized ? (
          <form onSubmit={handleSubmit} className="space-y-3">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">테스트 유형</label>
              <div className="flex gap-2">
                {[
                  { id: 'alpha', label: 'Alpha' },
                  { id: 'beta', label: 'Beta' },
                  { id: 'gamma', label: 'Gamma' },
                  { id: 'delta', label: 'Delta' }
                ].map(type => (
                  <button
                    key={type.id}
                    type="button"
                    onClick={() => {
                      setExamType(type.id);
                      if (type.id === 'delta') {
                        setSelectedAddressesForExam([]);
                      } else if (type.id === 'alpha') {
                        setSelectedAddressesForExam([]);
                        setDeltaSelections([]);
                      } else {
                        setDeltaSelections([]);
                      }
                    }}
                    className={`flex-1 px-3 py-1.5 rounded text-sm transition-colors ${
                      examType === type.id
                        ? 'bg-[#4ABC85] text-white'
                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                    }`}
                  >
                    {type.label}
                  </button>
                ))}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-3">
              {examType === 'alpha' && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">난이도</label>
                  <input
                    type="number"
                    min="1"
                    max="5"
                    step="0.1"
                    value={difficulty}
                    onChange={(e) => setDifficulty(e.target.value)}
                    className="w-full px-2 py-1.5 border rounded text-sm"
                  />
                  <div className="text-xs text-gray-500 mt-0.5">
                    1.0 ~ 5.0 사이의 값
                  </div>
                </div>
              )}

              {(examType === 'beta' || examType === 'gamma') && (
                <div className="col-span-2">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    선택된 유형 ({selectedAddressesForExam.length}개 선택됨)
                  </label>
                  <div className="border rounded p-2 max-h-[150px] overflow-y-auto">
                    {selectedAddressesForExam.length > 0 ? (
                      <div className="text-sm text-gray-600 flex flex-wrap gap-1">
                        {[...selectedAddressesForExam]
                          .sort((a, b) => a.localeCompare(b))
                          .map((address, idx) => (
                            <span 
                              key={idx} 
                              className="inline-flex items-center bg-gray-100 rounded px-2 py-1 gap-1"
                            >
                              <span className="truncate max-w-[200px]">{address}</span>
                            </span>
                          ))}
                      </div>
                    ) : (
                      <div className="text-sm text-gray-500 text-center py-2">
                        결과 맵에서 유형을 선택해주세요
                      </div>
                    )}
                  </div>
                </div>
              )}

              {examType === 'delta' && (
                <div className="col-span-2">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    선택된 중유형과 레벨 ({deltaSelections.length}개 선택됨)
                  </label>
                  <div className="border rounded p-2 max-h-[150px] overflow-y-auto">
                    {deltaSelections.length > 0 ? (
                      <div className="text-sm text-gray-600 flex flex-wrap gap-1">
                        {deltaSelections.map((selection, idx) => {
                          const [address, level] = selection.split(',');
                          return (
                            <span 
                              key={idx} 
                              className="inline-block bg-gray-100 rounded px-2 py-1"
                            >
                              {address} (Lv.{level})
                              <button 
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDeltaSelections(prev => 
                                    prev.filter(item => item !== selection)
                                  );
                                }}
                                className="ml-1 text-gray-500 hover:text-gray-700"
                              >
                                ×
                              </button>
                            </span>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="text-sm text-gray-500 text-center py-2">
                        결과 맵에서 중유형과 레벨을 선택해주세요
                      </div>
                    )}
                  </div>
                </div>
              )}

              {examType !== 'delta' && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">문제 수</label>
                  <input
                    type="number"
                    min="1"
                    value={totalProblems}
                    onChange={(e) => setTotalProblems(parseInt(e.target.value))}
                    className="w-full px-2 py-1.5 border rounded text-sm"
                  />
                </div>
              )}
            </div>

            <div className="flex justify-end gap-2 mt-3">
              <button
                type="button"
                onClick={onClose}
                className="px-3 py-1.5 text-sm text-gray-600 hover:text-gray-800"
              >
                취소
              </button>
              <button
                type="submit"
                disabled={!examType}
                className="px-3 py-1.5 text-sm bg-[#4ABC85] text-white rounded disabled:opacity-50 hover:bg-[#3da975] transition-colors"
              >
                생성
              </button>
            </div>
          </form>
        ) : (
          <div className="text-sm text-gray-600">
            {examType ? `${examType.toUpperCase()} 시험 생성 중...` : '시험 유형을 선택해주세요'}
          </div>
        )}
      </div>
    </div>
  );
}

export default CreateExamModal;