import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { useAuth } from '../context/AuthContext';

function Dashboard() {
  const { getAccessToken } = useAuth();
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const token = await getAccessToken();
      if (!token) {
        navigate('/signin');
      }
    };
    checkAuth();
  }, [getAccessToken, navigate]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const token = await getAccessToken();
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/dashboard`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('대시보드 데이터를 불러오는데 실패했습니다.');
        }

        const data = await response.json();
        setDashboardData(data);
      } catch (error) {
        console.error('대시보드 데이터 조회 오류:', error);
      }
    };

    fetchDashboardData();
  }, [getAccessToken]);

  const StatCard = ({ title, stats, onClick, clickableStats }) => (
    <div className={`bg-white rounded-lg shadow-md p-6 ${onClick ? 'cursor-pointer hover:bg-gray-50' : ''}`}
      onClick={onClick}
    >
      <h4 className="text-lg font-semibold mb-4 text-gray-800">{title}</h4>
      <div className="space-y-3">
        {Object.entries(stats).map(([key, value]) => {
          const isClickable = clickableStats && clickableStats[key];
          return (
            <div 
              key={key} 
              className={`flex justify-between items-center ${isClickable ? 'cursor-pointer hover:bg-gray-100' : ''}`}
              onClick={(e) => {
                if (isClickable) {
                  e.stopPropagation();
                  isClickable();
                }
              }}
            >
              <span className={`text-gray-600 ${isClickable ? 'hover:text-blue-600' : ''}`}>{key}</span>
              <span className="font-semibold text-gray-800">{value}</span>
            </div>
          );
        })}
      </div>
    </div>
  );

  const handleStudentCardClick = () => {
    window.location.href = '/student/1';
  };

  const handleReservationClick = () => {
    window.location.href = '/reservations';
  };

  const handleExamListClick = () => {
    window.location.href = '/exam-list';
  };

  // const TreeCard = ({ title, trees }) => (
  //   <div className="bg-white rounded-lg shadow-md p-6">
  //     <h4 className="text-lg font-semibold mb-4 text-gray-800">{title}</h4>
  //     <div className="overflow-x-auto">
  //       <table className="min-w-full table-auto">
  //         <thead>
  //           <tr className="bg-gray-50">
  //             <th className="px-4 py-2 text-left">과목</th>
  //             <th className="px-4 py-2 text-left">교육과정</th>
  //             {title === "Closed Tree" && (
  //               <>
  //                 <th className="px-4 py-2 text-left">필드 수</th>
  //                 <th className="px-4 py-2 text-left">출제 가능 문제 수</th>
  //               </>
  //             )}
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {trees.map((tree, index) => (
  //             <tr key={tree.tree_id} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
  //               <td className="px-4 py-2">{tree.tree_name}</td>
  //               <td className="px-4 py-2">{tree.edu_ver}</td>
  //               {title === "Closed Tree" && (
  //                 <>
  //                   <td className="px-4 py-2">{tree.prob_slots}</td>
  //                   <td className="px-4 py-2">{tree.probs_num}</td>
  //                 </>
  //               )}
  //             </tr>
  //           ))}
  //         </tbody>
  //       </table>
  //     </div>
  //   </div>
  // );

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
          <div className="container mx-auto px-6 py-8">
            <h3 className="text-gray-700 text-3xl font-medium">대시보드</h3>
            {dashboardData ? (
              <div className="space-y-6">
                <div className="mt-8 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
                  <StatCard 
                    title="학생" 
                    stats={{
                      "전체 학생 수": dashboardData.data.basic_stats.total_students,
                      "오늘 신규 학생 수": dashboardData.data.basic_stats.new_students
                    }}
                    onClick={handleStudentCardClick}
                  />
                  <StatCard 
                    title="Ky-Test" 
                    stats={{
                      "전체 테스트 수": dashboardData.data.basic_stats.total_exams,
                      "오늘 Ky-Test": dashboardData.data.basic_stats.today_new_exams,
                      "전체 Ky-Test 예약": dashboardData.data.basic_stats.future_rsv,
                      "오늘 Ky-Test 예약": dashboardData.data.basic_stats.today_rsv
                    }}
                    clickableStats={{
                      "전체 시험": handleExamListClick,
                      "오늘 Ky-Test": handleExamListClick,
                      "전체 Ky-Test 예약": handleReservationClick,
                      "오늘 Ky-Test 예약": handleReservationClick
                    }}
                  />
                  {/* <StatCard 
                    title="Problems" 
                    stats={{
                      "등록된 문제 수": dashboardData.data.basic_stats.total_problems,
                      "오늘 작업된 문제 수": dashboardData.data.basic_stats.today_worked_problems
                    }}
                  />
                  <StatCard 
                    title="Solutions" 
                    stats={{
                      "등록된 풀이 수": dashboardData.data.basic_stats.total_solutions,
                      "오늘 작업된 풀이 수": dashboardData.data.basic_stats.today_worked_solutions
                    }}
                  />
                </div>
                
                <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
                  <TreeCard 
                    title="Opened Tree" 
                    trees={dashboardData.data.tree_stats.open_tree_list}
                  />
                  <TreeCard 
                    title="Closed Tree" 
                    trees={dashboardData.data.tree_stats.closed_tree_list}
                  /> */}
                </div>
              </div>
            ) : (
              <div className="mt-8 text-center">
                <p>데이터를 불러오는 중...</p>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
