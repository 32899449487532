import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { useAuth } from '../context/AuthContext';
import { CheckCircle2, Clock, PlayCircle, FilePlus } from 'lucide-react';

function ExamList() {
  const { getAccessToken } = useAuth();
  const navigate = useNavigate();
  const [examList, setExamList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const perPage = 20;
  const [searchStudentId, setSearchStudentId] = useState('');
  const [searchStudentName, setSearchStudentName] = useState('');
  const [searchExamId, setSearchExamId] = useState('');

  useEffect(() => {
    console.log('ExamList useEffect triggered');
    let isMounted = true;

    const fetchExams = async () => {
      try {
        const token = await getAccessToken();
        if (!token) {
          navigate('/signin');
          return;
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/exams/list?page=${currentPage}&per_page=${perPage}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch exams');
        }

        const data = await response.json();
        if (data.status === 'success' && isMounted) {
          setExamList(data.data);
          setTotalItems(Number(data.total_items));
        }
      } catch (error) {
        console.error('Error fetching exams:', error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchExams();

    return () => {
      isMounted = false;
    };
  }, [currentPage, getAccessToken, navigate]);

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        handleReset();
      }
    };

    // 이벤트 리스�� 등록
    document.addEventListener('keydown', handleEscKey);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []); // 의존성 배열이 비어있으므로 마운트/언마운트 시에만 실행

  const totalPages = Math.ceil(totalItems / perPage);
  // console.log('Total Items:', totalItems, 'Total Pages:', totalPages); // 디버깅용 로그

  const handlePageChange = (newPage) => {
    // console.log('Changing to page:', newPage, 'Total pages:', totalPages); // 디버깅용
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      window.scrollTo(0, 0);
    }
  };

  // 페이지네이션 UI 부분
  const renderPagination = () => {
    if (totalPages <= 1) return null;

    const pages = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    // 첫 페이지
    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) pages.push('...');
    }

    // 중간 페이지들
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // 마지막 페이지
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) pages.push('...');
      pages.push(totalPages);
    }

    return (
      <div className="flex justify-center mt-4">
        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`relative inline-flex items-center px-2 py-2 rounded-l-md border text-sm font-medium
              ${currentPage === 1
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'bg-white text-gray-500 hover:bg-gray-50 cursor-pointer'}`}
          >
            이전
          </button>

          {pages.map((page, index) => (
            page === '...' ? (
              <span
                key={`ellipsis-${index}`}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
              >
                ...
              </span>
            ) : (
              <button
                key={page}
                onClick={() => handlePageChange(page)}
                className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium
                  ${currentPage === page
                    ? 'z-10 bg-blue-50 border-blue-500 text-blue-600'
                    : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                  }`}
              >
                {page}
              </button>
            )
          ))}

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`relative inline-flex items-center px-2 py-2 rounded-r-md border text-sm font-medium
              ${currentPage === totalPages
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'bg-white text-gray-500 hover:bg-gray-50 cursor-pointer'}`}
          >
            다음
          </button>
        </nav>
      </div>
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // UTC 시간에 9시간(KST)를 더함
    date.setHours(date.getHours() + 9);

    return date.toLocaleString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const formatDifficulty = (difficulty) => {
    return Math.floor(difficulty / 100 * 100) / 100;
  };

  const handleExamClick = (student_id, stamp_id, exam_id) => {
    navigate(`/student/${student_id}/stamp/${stamp_id}/result/${exam_id}`);
  };

  const handleSearch = async (type) => {
    try {
      const token = await getAccessToken();
      let param = '';

      switch (type) {
        case 'student_id':
          if (!searchStudentId) return;
          param = `student_id=${searchStudentId}`;
          break;
        case 'student_name':
          if (!searchStudentName || !/^[A-Za-z가-힣0-9]{2,10}$/.test(searchStudentName)) {
            alert('학생 이름은 2~10자의 한글, 영문, 숫자만 가능합니다.');
            return;
          }
          param = `student_name=${searchStudentName}`;
          break;
        case 'exam_id':
          if (!searchExamId) return;
          param = `exam_id=${searchExamId}`;
          break;
        default:
          return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/exams/list/filtered?${param}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 404) {
        setExamList([]);
        setTotalItems(0);
        setCurrentPage(1);
        return;
      }

      if (!response.ok) throw new Error('Failed to fetch exams');

      const data = await response.json();
      if (data.status === 'success') {
        setExamList(data.data);
        setTotalItems(Number(data.total_items));
        setCurrentPage(1);
      }
    } catch (error) {
      console.error('Error searching exams:', error);
    }
  };

  // 각 입력 필드에 대한 keyPress 이벤트 핸들러 추가
  const handleKeyDown = (e, type) => {
    if (e.key === 'Enter') {
      handleSearch(type);
    }
  };

  // 초기화 함수 추가
  const handleReset = async () => {
    // 검색 필드 초기화
    setSearchStudentId('');
    setSearchStudentName('');
    setSearchExamId('');

    // 초기 데이터 다시 불러오기
    try {
      const token = await getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/exams/list?page=1&per_page=${perPage}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) throw new Error('Failed to fetch exams');

      const data = await response.json();
      if (data.status === 'success') {
        setExamList(data.data);
        setTotalItems(Number(data.total_items));
        setCurrentPage(1);
      }
    } catch (error) {
      console.error('Error resetting exams:', error);
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
          <div className="container mx-auto px-6 py-8">
            <h3 className="text-gray-700 text-3xl font-medium">테스트 관리</h3>

            <div className="mt-8 mb-4">
              <div className="flex flex-wrap items-center gap-4">
                <div className="flex items-center gap-2">
                  <input
                    type="number"
                    value={searchExamId}
                    onChange={(e) => setSearchExamId(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, 'exam_id')}
                    placeholder="TID"
                    className="px-3 py-2 border rounded-md"
                  />
                  <button
                    onClick={() => handleSearch('exam_id')}
                    className="px-4 py-2 bg-[#4ABC85] text-white rounded-md hover:bg-[#3da975]"
                  >
                    검색
                  </button>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="number"
                    value={searchStudentId}
                    onChange={(e) => setSearchStudentId(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, 'student_id')}
                    placeholder="학생ID"
                    className="px-3 py-2 border rounded-md"
                  />
                  <button
                    onClick={() => handleSearch('student_id')}
                    className="px-4 py-2 bg-[#4ABC85] text-white rounded-md hover:bg-[#3da975]"
                  >
                    검색
                  </button>
                </div>

                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    value={searchStudentName}
                    onChange={(e) => setSearchStudentName(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, 'student_name')}
                    placeholder="학생 이름 (2~10자)"
                    className="px-3 py-2 border rounded-md"
                  />
                  <button
                    onClick={() => handleSearch('student_name')}
                    className="px-4 py-2 bg-[#4ABC85] text-white rounded-md hover:bg-[#3da975]"
                  >
                    검색
                  </button>
                </div>



                <button
                  onClick={handleReset}
                  className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
                  title="ESC를 눌러서 ��기화"
                >
                  초기화
                </button>
              </div>
            </div>

            {isLoading ? (
              <div className="flex justify-center items-center h-64">
                <p>로딩 중...</p>
              </div>
            ) : (
              <>
                <div className="mt-8">
                  <div className="overflow-x-auto bg-white rounded-lg shadow">
                    <table className="min-w-full leading-normal">
                      <thead>
                        <tr>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            TID
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            학생ID
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            학생 정보
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            담당 선생님
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            문항 수 [정답, 오답] (난이도)
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            시험 유형
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            과목
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            수정일
                          </th>
                          <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            상태
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {examList.map((exam) => (
                          <tr key={exam.exam_id}>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              {exam.exam_id}
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              {exam.student_info.student_id}
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <div>
                                <p className="text-gray-900 whitespace-no-wrap">
                                  {exam.student_info.student_name}
                                </p>
                                <p className="text-gray-600 whitespace-no-wrap">
                                  {exam.student_info.school_name} {exam.student_info.school_grade}학년
                                </p>
                              </div>
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              {exam.student_info.in_charge_teacher || '-'}
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              {exam.length} [{exam.correct_count}, {exam.wrong_count}] ({formatDifficulty(exam.difficulty)})
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              {exam.exam_type}
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              {exam.tree_name}
                            </td>
                            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              {formatDate(exam.exam_updated_at)}
                            </td>
                            <td
                              className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-center cursor-pointer hover:bg-gray-50"
                              onClick={() => handleExamClick(exam.student_info.student_id, exam.stamp_id, exam.exam_id)}
                            >
                              {exam.exam_status === 999 ? (
                                <CheckCircle2 className="inline text-green-500" size={20} />
                              ) : exam.exam_status === 400 ? (
                                <PlayCircle className="inline text-orange-500" size={20} />
                              ) : (
                                <FilePlus className="inline text-blue-500" size={20} />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {renderPagination()} {/* 페이지네이션은 여기 하나만 유지 */}
              </>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default ExamList;