import React, { useState, useEffect } from 'react';

function StudentModal({ isOpen, onClose, onSubmit, initialData, mode = 'add' }) {
  const initialFormState = {
    student_name: '',
    school_name: '',
    school_grade: '',
    in_charge_teacher: '',
    student_email: '',
    mobile_number: '',
    parent_mobile_number: '',
    lecture: ''
  };

  const [formData, setFormData] = useState(initialFormState);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    if (isOpen) {
      if (mode === 'edit' && initialData) {
        const editableField = initialData.editField;
        if (editableField) {
          setFormData({
            [editableField]: initialData[editableField]
          });
        } else {
          setFormData(initialData);
        }
      } else {
        setFormData(initialFormState);
      }
    }
  }, [isOpen, initialData, mode]);

  const validateInput = (value, fieldName) => {
    if (fieldName === 'lecture' && value === '') {
      return true;
    }

    if (fieldName === 'student_email') {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(value);
    }
    
    if (fieldName === 'mobile_number' || fieldName === 'parent_mobile_number') {
      const phoneRegex = /^[0-9-]{1,20}$/;
      return phoneRegex.test(value);
    }
    
    const defaultRegex = /^[가-힣a-zA-Z0-9]{1,100}$/;
    return defaultRegex.test(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    const fieldsToValidate = mode === 'edit' 
      ? Object.entries(formData).filter(([key, value]) => {
          if (key === 'lecture') {
            return (value || '') !== (initialData[key] || '');
          }
          return value !== initialData[key];
        })
      : Object.entries(formData);

    if (mode === 'edit' && fieldsToValidate.length === 0) {
      setError('최소 하나의 필드를 수정해야 합니다.');
      return;
    }

    for (const [key, value] of fieldsToValidate) {
      if (!validateInput(value, key)) {
        if (key === 'student_email') {
          setError('올바른 이메일 형식을 입력해주세요.');
        } else if (key === 'mobile_number' || key === 'parent_mobile_number') {
          setError('전화번호는 숫자와 하이픈(-)만 입력 가능합니다.');
        } else {
          setError('입력 필드는 1~100자의 한글, 영문, 숫자만 입력 가능합니다.');
        }
        return;
      }
    }

    try {
      const message = await onSubmit(formData);
      setSuccess(message);
      setTimeout(() => {
        handleClose();
      }, 1500);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleClose = () => {
    setError('');
    setSuccess('');
    setFormData(initialFormState);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg w-96">
        {!success ? (
          <>
            <h2 className="text-xl font-bold mb-4">
              {mode === 'add' ? '학생 추가' : '학생 정보 수정'}
            </h2>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <form onSubmit={handleSubmit}>
              {(mode === 'add' || initialData?.editField === 'student_name') && (
                <div className="mb-4">
                  <label className="block mb-2">학생 이름</label>
                  <input
                    type="text"
                    name="student_name"
                    value={formData.student_name || ''}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded"
                    required={mode === 'add'}
                  />
                </div>
              )}
              {(mode === 'add' || initialData?.editField === 'student_email') && (
                <div className="mb-4">
                  <label className="block mb-2">이메일</label>
                  <input
                    type="email"
                    name="student_email"
                    value={formData.student_email || ''}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded"
                    required={mode === 'add'}
                    disabled={mode === 'edit'}
                  />
                </div>
              )}
              {(mode === 'add' || initialData?.editField === 'mobile_number') && (
                <div className="mb-4">
                  <label className="block mb-2">전화번호</label>
                  <input
                    type="text"
                    name="mobile_number"
                    value={formData.mobile_number || ''}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded"
                    required={mode === 'add'}
                  />
                </div>
              )}
              {(mode === 'add' || initialData?.editField === 'parent_mobile_number') && (
                <div className="mb-4">
                  <label className="block mb-2">학부모 전화번호</label>
                  <input
                    type="text"
                    name="parent_mobile_number"
                    value={formData.parent_mobile_number || ''}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded"
                    required={mode === 'add'}
                  />
                </div>
              )}
              {(mode === 'add' || initialData?.editField === 'school_name') && (
                <div className="mb-4">
                  <label className="block mb-2">학교 이름</label>
                  <input
                    type="text"
                    name="school_name"
                    value={formData.school_name || ''}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded"
                    required={mode === 'add'}
                  />
                </div>
              )}
              {(mode === 'add' || initialData?.editField === 'school_grade') && (
                <div className="mb-4">
                  <label className="block mb-2">학년</label>
                  <input
                    type="text"
                    name="school_grade"
                    value={formData.school_grade || ''}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded"
                    required={mode === 'add'}
                  />
                </div>
              )}
              {(mode === 'add' || initialData?.editField === 'in_charge_teacher') && (
                <div className="mb-4">
                  <label className="block mb-2">담당 선생님</label>
                  <input
                    type="text"
                    name="in_charge_teacher"
                    value={formData.in_charge_teacher || ''}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded"
                    required={mode === 'add'}
                  />
                </div>
              )}
              {(mode === 'add' || initialData?.editField === 'lecture') && (
                <div className="mb-4">
                  <label className="block mb-2">강의</label>
                  <input
                    type="text"
                    name="lecture"
                    value={formData.lecture || ''}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border rounded"
                    required={mode === 'add'}
                  />
                </div>
              )}
              <div className="flex justify-end gap-2">
                <button
                  type="button"
                  onClick={handleClose}
                  className="px-4 py-2 bg-gray-200 rounded"
                >
                  취소
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-[#4ABC85] text-white rounded"
                >
                  {mode === 'add' ? '추가' : '수정'}
                </button>
              </div>
            </form>
          </>
        ) : (
          <div className="text-center py-8">
            <p className="text-green-500 text-lg font-medium">{success}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default StudentModal; 