import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { X } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';
import ProblemPreview from '../ProblemPreview';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

const ProblemSelectModal = ({ 
  onClose, 
  selectedTree, 
  selectedAddress, 
  selectedStep, 
  pathInfo,
  fetchTreeData, 
  fetchWithTokenRefresh,
  handleProblemSelect 
}) => {
  const modalRef = useRef();
  const [modalProblems, setModalProblems] = useState([]);
  const [modalPage, setModalPage] = useState(1);
  const [modalHasMore, setModalHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [existingProblems, setExistingProblems] = useState([]);
  const [filters, setFilters] = useState({
    status: '',
    author: '',
    labels: ''
  });
  const [isFilterMode, setIsFilterMode] = useState(false);
  const [currentLocationProblems, setCurrentLocationProblems] = useState([]);
  const [recentlyModifiedIds, setRecentlyModifiedIds] = useState([]);
  const [previewInfo, setPreviewInfo] = useState({
    isOpen: false,
    problemId: [],
    problemNumber: null,
    isCorrect: null
  });

  const fetchExistingProblems = useCallback(async () => {
    try {
      const response = await fetchWithTokenRefresh(
        `/api/referencefields/${selectedTree}/problems?address=${selectedAddress}&step=${selectedStep + 1}`
      );
      
      if (response && response.status === 'success') {
        setExistingProblems(response.data.problems);
      }
    } catch (error) {
      console.error('기존 문제 목록 불러오기 실패:', error);
      if (error.status === 409) {
        toast.error('닫히지 않은 트리입니다.');
      }
    }
  }, [fetchWithTokenRefresh, selectedTree, selectedAddress, selectedStep]);

  const fetchModalProblems = useCallback(async (resetPage = false, useFilter = false) => {
    if (isLoading) return;
    
    const shouldUseFilter = useFilter || isFilterMode;
    const currentPage = resetPage ? 1 : modalPage;
    
    setIsLoading(true);
    try {
      const params = new URLSearchParams({
        page: currentPage,
        per_page: 20
      });

      if (shouldUseFilter) {
        if (filters.status) params.append('status', filters.status);
        if (filters.author) params.append('author', filters.author);
        if (filters.labels) params.append('labels', filters.labels);
      }

      const hasFilterValues = filters.status || filters.author || filters.labels;
      const endpoint = shouldUseFilter && hasFilterValues ? 
        '/api/problems/filtered' : 
        '/api/problems/';

      const response = await fetchWithTokenRefresh(`${endpoint}?${params}`);
      
      if (!response) return;

      if (response.status === 'success') {
        if (resetPage) {
          setModalProblems(response.data);
          setModalPage(2);
        } else {
          const newProblems = response.data || [];
          setModalProblems(prev => {
            const existingIds = new Set(prev.map(p => p.problem_id));
            const uniqueNewProblems = newProblems.filter(p => !existingIds.has(p.problem_id));
            return [...prev, ...uniqueNewProblems];
          });
          setModalPage(prev => prev + 1);
        }
        setModalHasMore(response.data && response.data.length === 20);
      }
    } catch (error) {
      console.error('문제 목록 불러오기 실패:', error);
      if (error.status === 404) {
        setModalHasMore(false);
      }
    } finally {
      setIsLoading(false);
    }
  }, [modalPage, filters, isLoading, isFilterMode, fetchWithTokenRefresh]);

  const fetchCurrentLocationProblems = useCallback(async () => {
    try {
      const currentProblems = existingProblems.filter(
        ep => ep.address === selectedAddress && ep.step === selectedStep + 1
      );

      // console.log('필터링된 현재 위치 문제들:', currentProblems);

      if (currentProblems.length === 0) {
        setCurrentLocationProblems([]);
        return;
      }

      const problemIds = currentProblems.map(p => p.problem_id).join(',');

      const response = await fetchWithTokenRefresh(
        `/api/problems/filtered?problem_ids=${problemIds}`
      );

      if (response.status === 'success') {
        setCurrentLocationProblems(response.data);
      }
    } catch (error) {
      console.error('현재 위치 문제 정보 조회 실패:', error);
    }
  }, [existingProblems, selectedAddress, selectedStep, fetchWithTokenRefresh]);

  useEffect(() => {
    const loadInitialData = async () => {
      await Promise.all([
        fetchModalProblems(true, false),
        fetchExistingProblems()
      ]);
    };
    loadInitialData();
  }, []);

  useEffect(() => {
    if (isFilterMode) {
      fetchModalProblems(true, true);
    }
  }, [isFilterMode]);

  useEffect(() => {
    fetchCurrentLocationProblems();
  }, [fetchCurrentLocationProblems]);

  const handleScroll = useCallback((e) => {
    const element = e.target;
    if (!modalHasMore || isLoading) return;

    const scrollBottom = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight);
    if (scrollBottom <= 50) {
      fetchModalProblems(false, isFilterMode);
    }
  }, [fetchModalProblems, modalHasMore, isLoading, isFilterMode]);

  const handleProblemDelete = async (problemId) => {
    if (!window.confirm('이 문제를 삭제하시겠습니까?')) return;

    try {
      const response = await fetchWithTokenRefresh(`/api/referencefields/${selectedTree}/problems`, {
        method: 'DELETE',
        body: JSON.stringify({
          problem_id: problemId,
          address: `${selectedAddress}`,
          step: selectedStep + 1
        })
      });

      if (response && response.status === 'success') {
        setRecentlyModifiedIds(prev => [...prev, problemId]);
        toast.success('문제가 성공적으로 삭제되었습니다.');
        await refreshModalContent();
      }
    } catch (error) {
      console.error('문제 삭제 실패:', error);
      toast.error('문제 삭제에 실패했습니다.');
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleSearch = () => {
    const hasFilterValues = filters.status || filters.author || filters.labels;
    if (!hasFilterValues) {
      toast.error('상태, 작성자, 라벨 중 하나 이상을 입력해주세요.');
      return;
    }
    setIsFilterMode(true);
    fetchModalProblems(true, true);
  };

  const handleReset = () => {
    setIsFilterMode(false);
    setFilters({
      status: '',
      author: '',
      labels: ''
    });
    setModalProblems([]);
    setModalPage(1);
    setModalHasMore(true);
    fetchModalProblems(true, false);
  };

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  const handlePreview = async (problemId) => {
    setPreviewInfo({
      isOpen: true,
      problemId: [problemId],
      problemNumber: null,
      isCorrect: null
    });
  };

  const copyToClipboard = (text) => {
    const formattedText = text.replace(/\//g, '_');
    navigator.clipboard.writeText(formattedText);
    toast.success('클립보드에 복사되었습니다.');
  };

  const refreshModalContent = async () => {
    await Promise.all([
      fetchModalProblems(true, isFilterMode),
      fetchCurrentLocationProblems()
    ]);
    setModalPage(2);
    setModalHasMore(true);
  };

  const handleClose = async () => {
    setRecentlyModifiedIds([]);
    await fetchTreeData(selectedTree);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <Toaster position="top-center" />
      <div 
        ref={modalRef} 
        className="bg-white rounded-lg p-6 w-3/4 max-h-[80vh] overflow-y-auto relative"
        onScroll={handleScroll}
      >
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
            <path d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>

        <h2 className="text-xl font-bold mb-4">문제 선택</h2>

        <div className="mb-4 text-sm space-x-2">
          <span>
            <span className="cursor-pointer hover:text-blue-500" 
                  onClick={() => copyToClipboard(pathInfo.depth1.name)}>
              {pathInfo.depth1.name}
            </span>
            <span> (</span>
            <span className="cursor-pointer hover:text-blue-500" 
                  onClick={() => copyToClipboard(pathInfo.depth1.path_id)}>
              {pathInfo.depth1.path_id}
            </span>
            <span>)</span>
          </span>
          <span>|</span>
          <span>
            <span className="cursor-pointer hover:text-blue-500" 
                  onClick={() => copyToClipboard(pathInfo.depth2.name)}>
              {pathInfo.depth2.name}
            </span>
            <span> (</span>
            <span className="cursor-pointer hover:text-blue-500" 
                  onClick={() => copyToClipboard(pathInfo.depth2.path_id)}>
              {pathInfo.depth2.path_id}
            </span>
            <span>)</span>
          </span>
          <span>|</span>
          <span>
            <span className="cursor-pointer hover:text-blue-500" 
                  onClick={() => copyToClipboard(pathInfo.depth3.name)}>
              {pathInfo.depth3.name}
            </span>
            <span> (</span>
            <span className="cursor-pointer hover:text-blue-500" 
                  onClick={() => copyToClipboard(pathInfo.depth3.path_id)}>
              {pathInfo.depth3.path_id}
            </span>
            <span>)</span>
          </span>
          <span>|</span>
          <span>
            <span className="cursor-pointer hover:text-blue-500" 
                  onClick={() => copyToClipboard(pathInfo.depth4.name)}>
              {pathInfo.depth4.name}
            </span>
            <span> (</span>
            <span className="cursor-pointer hover:text-blue-500" 
                  onClick={() => copyToClipboard(pathInfo.depth4.path_id)}>
              {pathInfo.depth4.path_id}
            </span>
            <span>)</span>
          </span>
          <span>|</span>
          <span className="cursor-pointer hover:text-blue-500" 
                onClick={() => copyToClipboard(`${selectedStep + 1}`)}>
            난이도 {selectedStep + 1}
          </span>
        </div>

        <div className="mb-6 flex items-center gap-4">
          <input
            type="text"
            name="author"
            placeholder="작성자"
            value={filters.author}
            onChange={handleFilterChange}
            className="border rounded p-2"
          />
          <input
            type="text"
            name="labels"
            placeholder="라벨"
            value={filters.labels}
            onChange={handleFilterChange}
            className="border rounded p-2"
          />
          <button
            onClick={handleSearch}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            조회
          </button>
          <button
            onClick={handleReset}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
          >
            초기화
          </button>
        </div>

        {currentLocationProblems.length > 0 && (
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-3">현재 위치의 문제 목록</h3>
            <table className="min-w-full mb-6">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-left">ID</th>
                  <th className="px-6 py-3 text-left">상태</th>
                  <th className="px-6 py-3 text-left">작성자</th>
                  <th className="px-6 py-3 text-left">라벨</th>
                  <th className="px-6 py-3 text-left">미리보기</th>
                  <th className="px-6 py-3 text-left">작업</th>
                </tr>
              </thead>
              <tbody>
                {currentLocationProblems.map((problem) => (
                  <tr key={problem.problem_id} 
                      className={`${recentlyModifiedIds.includes(problem.problem_id) ? 'bg-blue-200' : 'bg-yellow-100'}`}>
                    <td className="px-6 py-4">{problem.problem_id}</td>
                    <td className="px-6 py-4">{problem.status}</td>
                    <td className="px-6 py-4">{problem.author}</td>
                    <td className="px-6 py-4">{problem.labels}</td>
                    <td className="px-6 py-4">
                      <button
                        onClick={() => handlePreview(problem.problem_id)}
                        className="text-green-600 hover:text-green-900"
                      >
                        미리보기
                      </button>
                    </td>
                    <td className="px-6 py-4">
                      <button
                        onClick={() => handleProblemDelete(problem.problem_id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        삭제
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <table className="min-w-full">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left">ID</th>
              <th className="px-6 py-3 text-left">상태</th>
              <th className="px-6 py-3 text-left">작성자</th>
              <th className="px-6 py-3 text-left">라벨</th>
              <th className="px-6 py-3 text-left">미리보기</th>
              <th className="px-6 py-3 text-left">작업</th>
            </tr>
          </thead>
          <tbody>
            {modalProblems.map((problem) => {
              const currentAddress = `${selectedAddress}`;
              
              const existingProblemAtCurrentLocation = existingProblems.some(
                ep => ep.problem_id === problem.problem_id && 
                     ep.address === `${selectedAddress}` &&
                     ep.step === selectedStep + 1
              );

              const existingProblemAtOtherLocation = existingProblems.some(
                ep => ep.problem_id === problem.problem_id && 
                     (ep.address !== `${selectedAddress}` || ep.step !== selectedStep + 1)
              );

              return (
                <tr key={problem.problem_id} 
                    className={
                      recentlyModifiedIds.includes(problem.problem_id) ? 'bg-blue-200' :
                      existingProblemAtCurrentLocation ? 'bg-yellow-100' : 
                      existingProblemAtOtherLocation ? 'bg-red-50' : 
                      'hover:bg-gray-50'
                    }>
                  <td className="px-6 py-4">{problem.problem_id}</td>
                  <td className="px-6 py-4">{problem.status}</td>
                  <td className="px-6 py-4">{problem.author}</td>
                  <td className="px-6 py-4">{problem.labels}</td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() => handlePreview(problem.problem_id)}
                      className="text-green-600 hover:text-green-900"
                    >
                      미리보기
                    </button>
                  </td>
                  <td className="px-6 py-4">
                    {existingProblemAtCurrentLocation ? (
                      <button
                        onClick={async () => {
                          await handleProblemDelete(problem.problem_id);
                        }}
                        className="text-red-600 hover:text-red-900"
                      >
                        삭제
                      </button>
                    ) : !existingProblemAtOtherLocation && (
                      <button
                        onClick={async () => {
                          if (!window.confirm('이 문제를 선택하시겠습니까?')) return;
                          await handleProblemSelect(problem.problem_id);
                          setRecentlyModifiedIds(prev => [...prev, problem.problem_id]);
                          await refreshModalContent();
                        }}
                        className="text-blue-600 hover:text-blue-900"
                      >
                        선택
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {isLoading && <div className="text-center py-4">로딩 중...</div>}
        {!isLoading && modalProblems.length === 0 && (
          <div className="text-center py-4">데이터가 없습니다.</div>
        )}
        {isLoading && modalProblems.length > 0 && (
          <div className="text-center py-4">로딩 중...</div>
        )}

        <ProblemPreview
          isOpen={previewInfo.isOpen}
          onClose={() => setPreviewInfo({
            isOpen: false,
            problemId: [],
            problemNumber: null,
            isCorrect: null
          })}
          problemIds={previewInfo.problemId}
          showNumbers={false}
          correctList={[]}
          problemNumbers={[]}
        />
      </div>
    </div>
  );
};

export default ProblemSelectModal;
