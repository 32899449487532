import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ExamListModal from '../components/ExamResult/ExamListModal';
import ResultInputModal from '../components/ExamResult/ResultInputModal';
import { ChevronLeft, Plus, Printer } from 'lucide-react';
import CreateExamModal from '../components/ExamResult/CreateExamModal';
import { handlePDF } from '../components/ProblemPrint/handlePDFv8';
import { handleSolutionPDF } from '../components/SolutionPrint/handleSolutionPDFv2';
import WrongAnswerModal from '../components/ExamResult/WrongAnswerModal';
import ProblemPreview from '../components/ProblemPreview';
import PrintPreview from '../components/printPreview';
import { createEditor } from 'lexical';
import { $generateHtmlFromNodes } from '@lexical/html';
import PlaygroundNodes from '../editor/nodes/PlaygroundNodes';
import { processMathEquation } from '../components/utils/latexParser';

function ExamResult() {
  const { student_id, stamp_id, exam_id } = useParams();
  const { getAccessToken } = useAuth();
  const navigate = useNavigate();
  const [examList, setExamList] = useState([]);
  const [currentExam, setCurrentExam] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedExamIndex, setSelectedExamIndex] = useState(0);
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [treeData, setTreeData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedDepth, setSelectedDepth] = useState('d4');
  const [isCreateExamModalOpen, setIsCreateExamModalOpen] = useState(false);
  const [selectedExamId, setSelectedExamId] = useState(null);
  const [selectedAddressesForExam, setSelectedAddressesForExam] = useState([]);
  const [isHeaderFloating, setIsHeaderFloating] = useState(false);
  const examCardRef = useRef(null);
  const [problemCounts, setProblemCounts] = useState({});
  const [examType, setExamType] = useState('alpha');
  const [deltaSelections, setDeltaSelections] = useState([]);
  const [hierarchicalSelections, setHierarchicalSelections] = useState({
    d1: new Set(),
    d2: new Set(),
    d3: new Set(),
    d4: new Set()
  });
  const modalRef = useRef();
  const [isWrongAnswerModalOpen, setIsWrongAnswerModalOpen] = useState(false);
  const [showOnlyProblems, setShowOnlyProblems] = useState(false);
  const [previewInfo, setPreviewInfo] = useState({
    isOpen: false,
    problemId: [],
    problemNumber: null,
    isCorrect: null
  });
  const [showPrintPreview, setShowPrintPreview] = useState(false);
  const [printFunction, setPrintFunction] = useState(null);
  const [selectedPrintType, setSelectedPrintType] = useState(null);
  const [printProblems, setPrintProblems] = useState([]);

  const colors = {
    100: { color: 'bg-[#F9344B]', score: -6000 },
    101: { color: 'bg-[#FDCECE] opacity-70', score: -3000 },
    200: { color: 'bg-[#4ABC85]', score: 6000 },
    201: { color: 'bg-[#B7E4CE] opacity-70', score: 3000 },
    500: { color: 'bg-[#FAEECE]', score: 0 },
    0: { color: 'after:content-["×"]', score: 0 }
  };

  const depthTitles = {
    'd1': '대단원',
    'd2': '소단원',
    'd3': '대유형',
    'd4': '중유형'
  };

  const calculateLevelScore = (level) => {
    const baseScore = 6000;
    switch(level) {
      case 1: return baseScore;
      case 2: return 1700;
      case 3: return 1100;
      case 4: return 700;
      case 5: return 400;
      default: return 0;
    }
  };

  const calculateTotalScore = () => {
    if (!treeData || !treeData.field_info?.d4_y_axis) return 0;
    
    const d4Count = treeData.field_info.d4_y_axis.length;
    if (d4Count === 0) return 0;

    let totalScore = 0;
    treeData.field_info.d4_y_axis.forEach(item => {
      item[3].forEach((status, levelIndex) => {
        const levelScore = calculateLevelScore(levelIndex + 1) / d4Count;
        const statusScore = colors[status]?.score || 0;
        const scoreRatio = Math.abs(statusScore) / 6000;
        const finalScore = Math.abs(statusScore) > 0 ? 
          (statusScore > 0 ? levelScore * scoreRatio : -levelScore * scoreRatio) : 0;
        totalScore += finalScore;
      });
    });

    return Math.round(totalScore);
  };

  const calculateMaxMinScores = () => {
    if (!treeData || !treeData.field_info?.d4_y_axis) return { max: 0, min: 0 };
    
    const d4Count = treeData.field_info.d4_y_axis.length;
    if (d4Count === 0) return { max: 0, min: 0 };

    let maxScore = 0;
    let minScore = 0;

    treeData.field_info.d4_y_axis.forEach(() => {
      [1, 2, 3, 4, 5].forEach(level => {
        const levelScore = calculateLevelScore(level) / d4Count;
        maxScore += levelScore;
        minScore -= levelScore;
      });
    });

    return {
      max: Math.round(maxScore),
      min: Math.round(minScore)
    };
  };

  const fetchExamList = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/exams/${stamp_id}`, {
        headers: { 'Authorization': token }
      });

      if (response.status === 204) {
        setExamList([]);
        setCurrentExam(null);
        setTreeData(null);
        setSelectedExamIndex(0);
        setSelectedExamId(null);
        setProblemCounts({});
        return;
      }

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      setExamList(data.data);

      // URL에 exam_id가 있는 경우
      if (exam_id) {
        const examIndex = data.data.findIndex(exam => exam.exam_id === parseInt(exam_id));
        if (examIndex !== -1) {
          setSelectedExamIndex(examIndex);
          const targetExam = data.data[examIndex];

          // 상태가 999인지 확인하고 아니면 이전 확정 시험 찾기
          if (targetExam.exam_status !== 999) {
            const previousConfirmedExam = data.data
              .filter(exam =>
                exam.exam_status === 999 &&
                new Date(exam.exam_created_at) < new Date(targetExam.exam_created_at)
              )
              .sort((a, b) => new Date(b.exam_created_at) - new Date(a.exam_created_at))[0];

            if (previousConfirmedExam) {
              fetchExamDetail(targetExam.exam_id); // 카드는 선택한 시험 정보로
              fetchResultMap(previousConfirmedExam.exam_id); // 결과 맵은 이전 확정 시험으로
              return;
            }
          }

          // 999 상태이거나 이전 확정 시험이 없는 경우
          fetchExamDetail(targetExam.exam_id);
          fetchResultMap(targetExam.exam_id);
          return;
        }
      }

      // URL에 exam_id가 없거나 찾지 못한 경우 최신 시험 정보 가져오기
      if (data.data.length > 0) {
        setSelectedExamIndex(0);
        const latestExam = data.data[0];

        if (latestExam.exam_status !== 999) {
          const previousConfirmedExam = data.data
            .filter(exam =>
              exam.exam_status === 999 &&
              new Date(exam.exam_created_at) < new Date(latestExam.exam_created_at)
            )
            .sort((a, b) => new Date(b.exam_created_at) - new Date(a.exam_created_at))[0];

          if (previousConfirmedExam) {
            fetchExamDetail(latestExam.exam_id);
            fetchResultMap(previousConfirmedExam.exam_id);
            return;
          }
        }

        fetchExamDetail(latestExam.exam_id);
      }
    } catch (error) {
      console.error('시험 목록 조회 오류:', error);
    }
  };

  const fetchExamDetail = async (examId) => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/exams/${stamp_id}/${examId}`, {
        headers: { 'Authorization': token }
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      setCurrentExam(data.data);
      fetchResultMap(examId);
    } catch (error) {
      console.error('시험 상세 정보 조회 오류:', error);
    }
  };

  const handleExamChange = (direction) => {
    const newIndex = selectedExamIndex + (direction === 'next' ? -1 : 1);
    if (newIndex >= 0 && newIndex < examList.length) {
      setSelectedExamIndex(newIndex);
      const newExam = examList[newIndex];

      // 상태가 999인지 확인하고 아니면 이전 확정 시험 찾기
      if (newExam.exam_status !== 999) {
        const previousConfirmedExam = examList
          .filter(exam =>
            exam.exam_status === 999 &&
            new Date(exam.exam_created_at) < new Date(newExam.exam_created_at)
          )
          .sort((a, b) => new Date(b.exam_created_at) - new Date(a.exam_created_at))[0];

        if (previousConfirmedExam) {
          fetchExamDetail(newExam.exam_id); // 카드는 선택한 시험 정보로
          fetchResultMap(previousConfirmedExam.exam_id); // 결과 맵은 이전 확정 시험으로
          return;
        }
      }

      // 999 상태이거나 이전 확정 시험이 없는 경우
      fetchExamDetail(newExam.exam_id);
      fetchResultMap(newExam.exam_id);
    }
  };

  const handleSelectExam = (examId, index) => {
    setSelectedExamIndex(index);
    fetchExamDetail(examId);
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetchExamList();
  }, [stamp_id, exam_id]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsHeaderFloating(!entry.isIntersecting);
      },
      {
        threshold: 0,
        rootMargin: '-100px 0px 0px 0px'
      }
    );

    if (examCardRef.current && currentExam) {
      observer.observe(examCardRef.current);
    }

    return () => {
      if (examCardRef.current) {
        observer.unobserve(examCardRef.current);
      }
    };
  }, [currentExam]);

  const getExamStatusText = (status) => {
    switch (status) {
      case 200: return "결과입력필요";
      case 400: return "결과입력완료";
      case 999: return "결과확정완료";
      default: return "상태 미정";
    }
  };

  const formatDateTime = (dateString) => {
    return new Date(dateString).toLocaleString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
  };

  const handleDeleteExam = async () => {
    if (!window.confirm('시험을 삭제하시겠습니까?')) return;

    try {
      const token = await getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/exams/${stamp_id}/${currentExam.exam_info.exam_id}`,
        {
          method: 'DELETE',
          headers: { 'Authorization': token }
        }
      );

      if (!response.ok) {
        if (response.status === 423) {
          alert('확정된 시험은 삭제할 수 없습니다.');
        } else if (response.status === 409) {
          alert('잘못된 시험 정보입니다.');
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return;
      }

      alert('시험이 삭제되었습니다.');
      fetchExamList(); // 시험 목록 새로고침
    } catch (error) {
      console.error('시험 삭제 오류:', error);
      alert('시험 삭제 중 오류가 발생했습니다.');
    }
  };

  const handleReOpenExam = async () => {
    if (!window.confirm('시험의 결과를 취소 하시겠습니까? 결과를 다시 입력할 수 있게 됩니다.')) return;

    try {
      const token = await getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/exams/${stamp_id}/${currentExam.exam_info.exam_id}/re-open`,
        {
          method: 'POST',
          headers: { 'Authorization': token }
        }
      );

      if (!response.ok) {
        if (response.status === 423) {
          alert('이미 결과취소 된 상태입니다.');
        } else if (response.status === 403) {
          alert('권한이 없습니다.');
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return;
      }

      const data = await response.json();
      alert('시험의 결과가 취소 되었습니다.');

      // 이전 시험이 있는 경우 해당 시험의 결과 맵 호출
      if (data.data.prev_exam) {
        fetchResultMap(data.data.prev_exam);
      }

      fetchExamDetail(currentExam.exam_info.exam_id);
    } catch (error) {
      console.error('시험 결과취소 오류:', error);
      alert('시험 결과취소 중 오류가 발생했습니다.');
    }
  };

  const fetchResultMap = async (examId) => {
    if (!examId) return;

    try {
      setLoading(true);
      const token = await getAccessToken();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/resultmap?exam_id=${examId}`, {
        headers: { 'Authorization': token }
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      setTreeData(data.data);
      setError('');
    } catch (error) {
      console.error('시험 결과 맵 불러오기 실패:', error);
      setError('결과과 맵을 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const getParentInfo = (path, depth, item) => {
    // 원본 이름들을 보존
    const originalNames = {
      d1: '',
      d2: '',
      d3: '',
      current: item[1]
    };

    // 경로 처리를 위한 임시 문자열
    const tempPath = path.replace(/\([^)]*\)/g, '');

    const pathParts = tempPath.match(/\d+/g) || [];

    const address = pathParts.join('/');

    const depthData = {
      'd1': treeData.field_info.d1_y_axis || [],
      'd2': treeData.field_info.d2_y_axis || [],
      'd3': treeData.field_info.d3_y_axis || [],
      'd4': treeData.field_info.d4_y_axis || []
    };

    // 각 depth 항목 찾기
    const d1Item = depthData.d1.find(item => {
      const cleanPath = item[2].replace(/\([^)]*\)/g, '');
      const match = cleanPath.includes(`${pathParts[0]}/${pathParts[1]}`);
      if (match) originalNames.d1 = item[1];
      return match;
    });

    const d2Item = depth !== 'd1' ? depthData.d2.find(item => {
      const cleanPath = item[2].replace(/\([^)]*\)/g, '');
      const match = cleanPath.includes(`${pathParts[0]}/${pathParts[1]}/${pathParts[2]}`);
      if (match) originalNames.d2 = item[1];
      return match;
    }) : null;

    const d3Item = (depth === 'd4' || depth === 'd3') ? depthData.d3.find(item => {
      const cleanPath = item[2].replace(/\([^)]*\)/g, '');
      const match = cleanPath.includes(`${pathParts[0]}/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}`);
      if (match) originalNames.d3 = item[1];
      return match;
    }) : null;

    // 경로 정보만 추출
    const d1Parts = d1Item ? d1Item[2].match(/\d+/g) || [] : [];
    const d2Parts = d2Item ? d2Item[2].match(/\d+/g) || [] : [];
    const d3Parts = d3Item ? d3Item[2].match(/\d+/g) || [] : [];
    const currentParts = item[2].match(/\d+/g) || [];

    const paths = {
      d1: d1Parts.length >= 2 ? `(${d1Parts.slice(0, 2).join('_')})` : '',
      d2: d2Parts.length >= 3 ? `(${d2Parts.slice(0, 3).join('_')})` : '',
      d3: d3Parts.length >= 4 ? `(${d3Parts.slice(0, 4).join('_')})` : '',
      d4: currentParts.length >= 5 ? `(${currentParts.slice(0, 5).join('_')})` : ''
    };

    // HTML 특수문자 디코드 함수
    const decodeHtml = (html) => {
      const txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    };

    const result = {
      d1Name: d1Item ? decodeHtml(originalNames.d1) + ` <span class="text-[#4ABC85]">${paths.d1}</span>` : '',
      d2Name: d2Item ? decodeHtml(originalNames.d2) + ` <span class="text-[#4ABC85]">${paths.d2}</span>` : '',
      d3Name: d3Item ? decodeHtml(originalNames.d3) + ` <span class="text-[#4ABC85]">${paths.d3}</span>` : '',
      name: decodeHtml(originalNames.current) + ` <span class="text-[#4ABC85]">${depth === 'd1' ? paths.d1 :
          depth === 'd2' ? paths.d2 :
            depth === 'd3' ? paths.d3 :
              paths.d4
        }</span>`,
      address: address
    };

    return result;
  };

  const getLevelData = () => {
    if (!treeData) return [];

    const depthData = {
      'd1': treeData.field_info.d1_y_axis || [],
      'd2': treeData.field_info.d2_y_axis || [],
      'd3': treeData.field_info.d3_y_axis || [],
      'd4': treeData.field_info.d4_y_axis || []
    };

    const currentDepthData = depthData[selectedDepth].map(item => {
      const parentInfo = getParentInfo(item[2], selectedDepth, item);
      return {
        ...parentInfo,
        status: item[3],
        originalPath: item[2]
      };
    });

    return currentDepthData;
  };

  const getVisibleColumns = () => {
    switch (selectedDepth) {
      case 'd4':
        return ['d1Name', 'd2Name', 'd3Name', 'name'];
      case 'd3':
        return ['d1Name', 'd2Name', 'name'];
      case 'd2':
        return ['d1Name', 'name'];
      case 'd1':
        return ['name'];
      default:
        return ['name'];
    }
  };

  const columnTitles = {
    'd1Name': '대단원',
    'd2Name': '소단원',
    'd3Name': '대유형',
    'name': depthTitles[selectedDepth]
  };

  const renderStatusBox = (status, address, levelIndex) => {
    if (!address) return null;

    const normalizedAddress = address.replace(/_/g, '/');
    const problemCount = isCreateExamModalOpen ?
      problemCounts[normalizedAddress]?.[levelIndex] || 0 :
      null;

    const isDeltaSelected = isCreateExamModalOpen &&
      examType === 'delta' &&
      deltaSelections.includes(`${normalizedAddress},${levelIndex + 1}`);

    // 결과 맵 생성
    const resultMap = new Map(
      currentExam?.result?.map(item => [item.problem_id, item.result]) || []
    );

    // 현재 시험의 모든 문제 번호를 먼저 계산
    const allProblemNumbers = {};
    currentExam?.problems_list?.forEach((problem, index) => {
      const key = `${problem.address},${problem.step}`;
      if (!allProblemNumbers[key]) {
        allProblemNumbers[key] = [];
      }
      
      allProblemNumbers[key].push({
        number: index + 1,
        problemId: problem.problem_id,
        // resultMap에서 직접 결과 조회
        isCorrect: resultMap.get(problem.problem_id) ?? null
      });
    });

    // 현재 칸의 문제 정보 가져오기
    const currentKey = `${normalizedAddress},${levelIndex + 1}`;
    const problemInfos = allProblemNumbers[currentKey];
    const isCurrentProblem = !!problemInfos?.length;

    const handleBoxClick = (e) => {
      e.stopPropagation();
      if (isCurrentProblem && !isCreateExamModalOpen) {
        setPreviewInfo({
          isOpen: true,
          problemId: problemInfos.map(info => info.problemId),
          problemNumber: problemInfos.map(info => info.number),
          isCorrect: problemInfos.map(info => resultMap.get(info.problemId) ?? null)
        });
      } else if (isCreateExamModalOpen && examType === 'delta') {
        handleDeltaSelection(normalizedAddress, levelIndex + 1);
      }
    };

    return (
      <div className="relative">
        <div
          onClick={handleBoxClick}
          className={`
            w-6 h-6 rounded-md 
            ${colors[status]?.color || 'bg-gray-200'}
            ${isDeltaSelected ? 'ring-2 ring-[#4ABC85] ring-offset-2' : ''}
            ${isCurrentProblem ? 'ring-2 ring-black cursor-pointer' : ''}
            flex items-center justify-center
            text-[10px] font-semibold
            ${status === 0 ? 'text-black' : 'text-white'}
            relative
            ${isCreateExamModalOpen && examType === 'delta' ? 'cursor-pointer hover:opacity-80' : ''}
          `}
        >
          {isCreateExamModalOpen ?
            (problemCount > 0 ? problemCount : '') :
            (isCurrentProblem ? 
              <span className="font-bold text-[#000000]">
                {problemInfos.map(info => info.number).join(',')}
              </span> 
              : (status === 0 ? '×' : ''))}
        </div>
      </div>
    );
  };

  const getGroupedData = () => {
    const data = getLevelData();
    
    // 행 고정 모드이고 고정된 행이 있는 경우
    if (isRowFixMode && fixedRows.length > 0) {
      return data.filter(item => 
        fixedRows.some(fixedRow => fixedRow.originalPath === item.originalPath)
      );
    }

    const groupedRows = [];
    let currentGroup = null;

    // 현재 시험의 문제 주소들을 Set으로 저장
    const problemAddresses = new Set(currentExam?.problems_list?.map(p => p.address));

    data.forEach(item => {
      const groupKey = selectedDepth === 'd4'
        ? `${item.d1Name}-${item.d2Name}-${item.d3Name}`
        : selectedDepth === 'd3'
          ? `${item.d1Name}-${item.d2Name}`
          : selectedDepth === 'd2'
            ? `${item.d1Name}`
            : null;

      // originalPath를 사용하여 주소 확인
      const address = item.originalPath;

      // showOnlyProblems가 true일 때는 문제가 있는 행만 포함
      if (!showOnlyProblems || problemAddresses.has(address)) {
        if (groupKey !== currentGroup) {
          currentGroup = groupKey;
          groupedRows.push({
            type: 'group',
            ...item,
            isFirstInGroup: true
          });
        } else {
          groupedRows.push({
            type: 'item',
            ...item,
            isFirstInGroup: false
          });
        }
      }
    });

    return groupedRows;
  };

  const handleShareResult = async (examId) => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/resultmap/issue`, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ exam_id: examId })
      });

      if (response.ok) {
        const { data } = await response.json();
        const shareUrl = `${process.env.REACT_APP_BASE_URL}/result/${data}`;

        await navigator.clipboard.writeText(shareUrl);
        alert('결과 공유 링크가 클립보드에 복사되었습니다.');
      }
    } catch (error) {
      console.error('결과 공유 링크 생성 실패:', error);
      alert('결과 공유 링크 생성에 실패했습니다.');
    }
  };

  const handleOpenResultModal = (isConfirm = false) => {
    if (currentExam?.exam_info?.exam_id) {
      setSelectedExamId(currentExam.exam_info.exam_id);
      setIsConfirming(isConfirm);
      setIsResultModalOpen(true);
    }
  };

  const getDepthFromAddress = (address) => {
    const parts = address.split('/');
    switch (parts.length) {
      case 2: return 'd1';
      case 3: return 'd2';
      case 4: return 'd3';
      case 5: return 'd4';
      default: return null;
    }
  };

  const isAddressSelected = (address) => {
    if (!address) return false;

    // originalPath를 사용하도록 수정
    const normalizedAddress = address.originalPath || address;

    // 직접 선택된 경우
    if (selectedAddressesForExam.includes(normalizedAddress)) return true;

    // 상위 주소에 의해 선택된 경우
    return selectedAddressesForExam.some(selectedAddr =>
      normalizedAddress.startsWith(selectedAddr + '/'));
  };

  const handleTableRowClick = (item) => {
    if (!isCreateExamModalOpen || !(examType === 'beta' || examType === 'gamma')) return;

    // item.originalPath를 사용하여 정확한 경로 가져오기
    const address = item.originalPath;

    if (!address) return;

    const depth = getDepthFromAddress(address);
    if (!depth) return;

    // 현재 주소의 상위 주소들 확인
    const addressParts = address.split('/');
    const parentAddresses = [];
    for (let i = 2; i <= addressParts.length; i++) {
      parentAddresses.push(addressParts.slice(0, i).join('/'));
    }

    // 현재 주소가 선택되어 있는지 확인
    const isCurrentlySelected = selectedAddressesForExam.includes(address);

    // 상위 주소가 선택되어 있는지 확인
    const selectedParent = parentAddresses.find(parentAddr =>
      selectedAddressesForExam.includes(parentAddr)
    );

    setHierarchicalSelections(prev => {
      const newSelections = { ...prev };

      if (selectedParent) {
        // 상위 주소가 선택된 경우, 해당 상위 주소의 선택을 해제하고
        // 모든 하위 주소를 선택 (현재 주소 제외)
        const parentDepth = getDepthFromAddress(selectedParent);
        newSelections[parentDepth].delete(selectedParent);

        // 하위 주소들 선택
        const siblings = getChildAddresses(selectedParent, depth);
        siblings.forEach(sibling => {
          if (sibling !== address) {
            newSelections[depth].add(sibling);
          }
        });
      } else if (isCurrentlySelected) {
        // 선택 해제
        newSelections[depth].delete(address);
      } else {
        // 새로운 선택
        newSelections[depth].add(address);
      }

      return newSelections;
    });

    // selectedAddressesForExam 업데이트
    if (selectedParent) {
      const siblings = Array.from(getChildAddresses(selectedParent, depth));
      setSelectedAddressesForExam(prev => {
        const newSelection = prev.filter(addr => !addr.startsWith(selectedParent));
        return [...newSelection, ...siblings.filter(sibling => sibling !== address)];
      });
    } else if (isCurrentlySelected) {
      setSelectedAddressesForExam(prev =>
        prev.filter(addr => addr !== address && !addr.startsWith(address + '/'))
      );
    } else {
      setSelectedAddressesForExam(prev => {
        const filtered = prev.filter(addr =>
          !addr.startsWith(address + '/') &&
          !address.startsWith(addr + '/')
        );
        return [...filtered, address];
      });
    }
  };

  const getChildAddresses = (parentAddress, depth) => {
    const depthData = {
      'd1': treeData?.field_info?.d1_y_axis || [],
      'd2': treeData?.field_info?.d2_y_axis || [],
      'd3': treeData?.field_info?.d3_y_axis || [],
      'd4': treeData?.field_info?.d4_y_axis || []
    };

    const children = new Set();
    const parentParts = parentAddress.split('/');

    depthData[depth]?.forEach(item => {
      const itemAddress = item[2].replace(/_/g, '/');
      const itemParts = itemAddress.split('/');

      if (itemParts.slice(0, parentParts.length).join('/') === parentAddress) {
        children.add(itemAddress);
      }
    });

    return children;
  };

  const optimizeSelections = () => {
    const newSelection = new Set(selectedAddressesForExam);
    let hasChanges = false;

    // 각 depth별로 확인
    ['d3', 'd2', 'd1'].forEach(depth => {
      const addresses = Array.from(newSelection);
      const groupedByParent = {};

      // 부모 주소별로 그룹화
      addresses.forEach(addr => {
        const addrDepth = getDepthFromAddress(addr);
        if (addrDepth === getNextDepth(depth)) {
          const parentAddr = addr.split('/').slice(0, -1).join('/');
          groupedByParent[parentAddr] = groupedByParent[parentAddr] || new Set();
          groupedByParent[parentAddr].add(addr);
        }
      });

      // 각 부모 주소에 대해 모든 자식이 선택되었는지 확인
      Object.entries(groupedByParent).forEach(([parentAddr, children]) => {
        const allChildren = getChildAddresses(parentAddr, getNextDepth(depth));
        if (allChildren.size === children.size) {
          // 모든 자식이 선택되었다면 자식들을 제거하고 부모를 추가
          children.forEach(child => newSelection.delete(child));
          newSelection.add(parentAddr);
          hasChanges = true;
        }
      });
    });

    if (hasChanges) {
      setSelectedAddressesForExam(Array.from(newSelection));

      // hierarchicalSelections도 업데이트
      setHierarchicalSelections(prev => {
        const newSelections = { ...prev };
        ['d1', 'd2', 'd3', 'd4'].forEach(d => {
          newSelections[d] = new Set(
            Array.from(newSelection).filter(addr =>
              getDepthFromAddress(addr) === d
            )
          );
        });
        return newSelections;
      });
    }
  };

  const getNextDepth = (currentDepth) => {
    const depths = ['d1', 'd2', 'd3', 'd4'];
    const currentIndex = depths.indexOf(currentDepth);
    return depths[currentIndex + 1] || currentDepth;
  };

  const getPreviousDepth = (currentDepth) => {
    const depths = ['d1', 'd2', 'd3', 'd4'];
    const currentIndex = depths.indexOf(currentDepth);
    return depths[currentIndex - 1] || currentDepth;
  };

  const handlePrintExam = async () => {
    if (!currentExam?.problems_list) {
      alert('시험 문제 데이터가 없습니다.');
      return;
    }
    
    try {
      setLoading(true);
      const response = await fetchWithTokenRefresh(
        `/api/problems/print?problem_ids=${currentExam.problems_list.map(p => p.problem_id).join(',')}`
      );

      if (!response?.data) {
        throw new Error('문제 데이터를 불러오는데 실패했습니다.');
      }

      // 문제 데이터 구조화
      const problemList = await Promise.all(response.data.map(async (problem) => {
        if (!problem?.lexical_data?.lexical_data) {
          throw new Error(`문제 ${problem.problem_id}의 데이터가 올바르지 않습니다.`);
        }

        const editor = createEditor({
          nodes: [...PlaygroundNodes]
        });

        const processNode = (node) => {
          if (node.type === 'equation') {
            node.equation = processMathEquation(node.equation);
          } else if (node.type === 'box-content') {
            node.className = 'PlaygroundEditorTheme__boxContent';
          }
          if (node.children && Array.isArray(node.children)) {
            node.children = node.children.map(child => processNode(child));
          }
          return node;
        };

        const lexicalData = problem.lexical_data.lexical_data;
        lexicalData.root = processNode(lexicalData.root);
        const editorState = editor.parseEditorState(lexicalData);

        let content = '';
        editor.setEditorState(editorState);
        editor.update(() => {
          content = $generateHtmlFromNodes(editor);
        });

        return {
          problem_id: problem.problem_id,
          content
        };
      }));

      setPrintProblems(problemList);
      setSelectedPrintType('exam');
      setShowPrintPreview(true);

    } catch (error) {
      console.error('문제 불러오기 실패:', error);
      alert(error.message || '문제를 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const handlePrintSolution = async () => {
    if (!currentExam?.problems_list) {
      alert('시험 문제 데이터가 없습니다.');
      return;
    }

    try {
      setLoading(true);
      const response = await fetchWithTokenRefresh(
        `/api/problems/print/answers?problem_ids=${currentExam.problems_list.map(p => p.problem_id).join(',')}`
      );

      if (!response?.data) {
        throw new Error('답안 데이터를 불러오는데 실패했습니다.');
      }

      setPrintProblems(response.data);
      setSelectedPrintType('solution');
      setShowPrintPreview(true);

    } catch (error) {
      console.error('답안 불러오기 실패:', error);
      alert(error.message || '답안을 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = () => {
    if (printFunction) {
      printFunction();
    }
  };

  const fetchProblemCounts = async (treeId, signal) => {
    if (!treeId) return;

    try {
      const token = await getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/referencefields/${treeId}/count/problems`,
        {
          headers: { 'Authorization': token },
          signal
        }
      );

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      const counts = {};

      Object.entries(data.data.field_problems).forEach(([depthKey, depthData]) => {
        Object.entries(depthData).forEach(([address, levelCounts]) => {
          counts[address] = levelCounts;
        });
      });

      setProblemCounts(counts);
    } catch (error) {
      if (error.name === 'AbortError') {
        return;
      }
      console.error('문제 갯수 조회 오류:', error);
    }
  };

  useEffect(() => {
    if (treeData?.field_info?.d1_y_axis?.[0]?.[2] && isCreateExamModalOpen) {
      const treeId = treeData.field_info.d1_y_axis[0][2].split('/')[0];
      const controller = new AbortController();
      fetchProblemCounts(treeId, controller.signal);
      return () => controller.abort();
    }
  }, [treeData?.field_info?.d1_y_axis?.[0]?.[2], isCreateExamModalOpen]);

  const handleDeltaSelection = (address, level) => {
    const selectionKey = `${address},${level}`;
    setDeltaSelections(prev => {
      const exists = prev.find(item => item === selectionKey);
      if (exists) {
        return prev.filter(item => item !== selectionKey);
      }
      return [...prev, selectionKey];
    });
  };

  const renderTable = () => {
    const groupedData = getGroupedData();

    return (
      <table className="w-full text-sm mb-[400px]">
        <thead>
          <tr>
            {getVisibleColumns().map(column => (
              <th key={column} className="px-2 py-1 text-left">
                <span className="text-sm text-gray-900">{columnTitles[column]}</span>
              </th>
            ))}
            {[1, 2, 3, 4, 5].map(level => (
              <th key={level} className="px-1 py-1 text-center w-8">
                <span className="text-sm text-gray-600">Lv {level}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {groupedData.map((item, index) => {
            const pathMatch = item.name?.match(/\(([\d_]+)\)/);
            const address = pathMatch ? pathMatch[1].split('_').join('/') : null;

            return (
              <tr
                key={index}
                onClick={() => {
                  if (isSelectingRows) {
                    handleRowSelection(item);
                  } else {
                    handleTableRowClick(item);
                  }
                }}
                className={`
                  ${!item.isFirstInGroup ? 'border-t-0' : ''}
                  hover:outline hover:outline-[1px] hover:outline-[#4ABC85] 
                  relative hover:z-10 transition-all duration-150
                  ${isCreateExamModalOpen && (examType === 'beta' || examType === 'gamma') ? 'cursor-pointer' : ''}
                  ${isAddressSelected(item) ? 'bg-green-50' : ''}
                  ${isSelectingRows && selectedRows.some(row => row.originalPath === item.originalPath) ? 'bg-blue-100' : ''}
                  ${isSelectingRows ? 'cursor-pointer' : ''}
                `}
              >
                {getVisibleColumns().map(column => {
                  const content = item.isFirstInGroup || column === 'name' ? item[column] : '';
                  if (!content) return <td key={column} className="px-2 py-1 text-sm text-left" />;

                  // HTML 태그를 포함하는 부분과 일반 텍스트 부분을 분리
                  const [textPart, spanPart] = content.split(/<span/);

                  return (
                    <td key={column} className="px-2 py-1 text-sm text-left">
                      {textPart}
                      {spanPart && (
                        <span
                          className="text-[#4ABC85]"
                          dangerouslySetInnerHTML={{
                            __html: spanPart.replace(/^[^>]*>/, '').replace(/<\/span>$/, '')
                          }}
                        />
                      )}
                    </td>
                  );
                })}
                {item.status.map((status, statusIndex) => (
                  <td key={statusIndex} className="px-1 py-1 text-center">
                    <div className="flex justify-center">
                      {renderStatusBox(
                        status,
                        item.originalPath,
                        statusIndex
                      )}
                    </div>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  // isAllChildrenSelected 유틸리티 함수 추가
  const isAllChildrenSelected = (parentAddress, depth) => {
    if (!parentAddress || !depth) return false;

    const nextDepth = getNextDepth(depth);
    const children = getChildAddresses(parentAddress, nextDepth);

    return Array.from(children).every(child =>
      hierarchicalSelections[nextDepth].has(child)
    );
  };

  // 칩 렌더링을 위한 함수 수정
  const renderChips = () => {
    return selectedAddressesForExam.map((address, idx) => (
      <span
        key={idx}
        className="inline-flex items-center bg-gray-100 rounded px-2 py-1 gap-1"
      >
        <span className="truncate max-w-[200px]">{address}</span>
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            handleRemoveAddress(address);
          }}
          className="ml-1 text-gray-500 hover:text-gray-700"
        >
          ×
        </button>
      </span>
    ));
  };

  // 자동 병합을 위한 useEffect 추가
  useEffect(() => {
    const optimizeSelections = () => {
      const newSelection = new Set(selectedAddressesForExam);
      let hasChanges = false;

      // 각 depth별로 확인
      ['d3', 'd2', 'd1'].forEach(depth => {
        const addresses = Array.from(newSelection);
        const groupedByParent = {};

        // 부모 주소별로 그룹화
        addresses.forEach(addr => {
          const addrDepth = getDepthFromAddress(addr);
          if (addrDepth === getNextDepth(depth)) {
            const parentAddr = addr.split('/').slice(0, -1).join('/');
            groupedByParent[parentAddr] = groupedByParent[parentAddr] || new Set();
            groupedByParent[parentAddr].add(addr);
          }
        });

        // 각 부모 주소에 대해 모든 자식이 선택되었는지 확인
        Object.entries(groupedByParent).forEach(([parentAddr, children]) => {
          const allChildren = getChildAddresses(parentAddr, getNextDepth(depth));
          if (allChildren.size === children.size) {
            // 모든 자식이 선택되었다면 자식들을 제거하고 부모를 추가
            children.forEach(child => newSelection.delete(child));
            newSelection.add(parentAddr);
            hasChanges = true;
          }
        });
      });

      if (hasChanges) {
        setSelectedAddressesForExam(Array.from(newSelection));

        // hierarchicalSelections도 업데이트
        setHierarchicalSelections(prev => {
          const newSelections = { ...prev };
          ['d1', 'd2', 'd3', 'd4'].forEach(d => {
            newSelections[d] = new Set(
              Array.from(newSelection).filter(addr =>
                getDepthFromAddress(addr) === d
              )
            );
          });
          return newSelections;
        });
      }
    };

    optimizeSelections();
  }, [selectedAddressesForExam]);

  // handleRemoveAddress 함수 추가
  const handleRemoveAddress = (addressToRemove) => {
    // hierarchicalSelections 업데이트
    setHierarchicalSelections(prev => {
      const newSelections = { ...prev };
      const depth = getDepthFromAddress(addressToRemove);

      if (depth) {
        newSelections[depth].delete(addressToRemove);
        // 하위 주소들도 모두 제거
        Object.keys(newSelections).forEach(d => {
          if (d > depth) {
            newSelections[d] = new Set(
              Array.from(newSelections[d]).filter(addr =>
                !addr.startsWith(addressToRemove + '/')
              )
            );
          }
        });
      }

      return newSelections;
    });

    // selectedAddressesForExam 업데이트
    setSelectedAddressesForExam(prev =>
      prev.filter(addr =>
        addr !== addressToRemove && !addr.startsWith(addressToRemove + '/')
      )
    );
  };

  // CreateExamModal이 닫힐 때 problemCounts 초기화
  const handleCloseCreateExamModal = () => {
    setIsCreateExamModalOpen(false);
    setSelectedAddressesForExam([]);
    setDeltaSelections([]);
    setExamType('');
    setHierarchicalSelections({
      d1: new Set(),
      d2: new Set(),
      d3: new Set(),
      d4: new Set()
    });
    setProblemCounts({}); // problemCounts 초기화 추가
  };

  // CreateExamModal 열릴 때 문제 행만 보기 off
  const handleCreateExamModalOpen = () => {
    setIsCreateExamModalOpen(true);
    setShowOnlyProblems(false); // 문제 행만 보기 off
    setIsRowFixMode(false); // 행 고정 모드 off
    setFixedRows([]); // 고정된 행 초기화
    setSelectedRows([]); // 선택된 행 초기화
    setIsSelectingRows(false); // 선택 모드 off
  };

  // 새로운 state 추가
  const [isRowFixMode, setIsRowFixMode] = useState(false);
  const [fixedRows, setFixedRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isSelectingRows, setIsSelectingRows] = useState(false);

  // 행 선택 처리 함수 추가
  const handleRowSelection = (item) => {
    if (!isSelectingRows) return;
    
    setSelectedRows(prev => {
      const itemKey = item.originalPath;
      if (prev.some(row => row.originalPath === itemKey)) {
        return prev.filter(row => row.originalPath !== itemKey);
      }
      return [...prev, item];
    });
  };

  // 행 고정 완료 처리 함수
  const handleFixRows = () => {
    if (selectedRows.length === 0) {
      alert('선택된 행이 없습니다.');
      return;
    }
    
    if (window.confirm('선택한 행을 고정하시겠습니까?')) {
      setFixedRows(selectedRows);
      setSelectedRows([]);
      setIsSelectingRows(false);
      setIsRowFixMode(true);
      // 출제 문제만 보기가 켜져있으면 끄기
      if (showOnlyProblems) {
        setShowOnlyProblems(false);
      }
    }
  };

  // 행 고정 해제 함수
  const handleClearFixedRows = () => {
    setFixedRows([]);
    setIsRowFixMode(false);
  };

  // fetchWithTokenRefresh 함수 추가
  const fetchWithTokenRefresh = async (url, options = {}) => {
    try {
      const token = await getAccessToken();
      if (!token) {
        navigate('/signin');
        return null;
      }

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${url}`, {
        ...options,
        headers: {
          ...options.headers,
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('API 요청 실패');
      }

      return await response.json();
    } catch (error) {
      console.error('API 호출 에러:', error);
      throw error;
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <Header />
      {isHeaderFloating && currentExam && (
        <div className="fixed top-16 left-[200px] right-0 flex justify-center z-[100] px-6 py-2">
          <div className="bg-white shadow-lg rounded-full px-8 py-2 transition-all duration-300 max-w-[1200px] w-full">
            <div className="flex items-center justify-between gap-8 text-sm">
            <div>
                <span className="text-gray-500">과목:</span>
                <span className="ml-2 font-semibold">{currentExam.exam_info.tree_name}</span>
              </div>
              <div>
                <span className="text-gray-500">TID:</span>
                <span className="ml-2 font-semibold">
                  {currentExam.exam_info.exam_id}
                  <span className="ml-1 text-gray-500">
                    (회차: {currentExam.exam_info.exam_count})
                  </span>
                </span>
              </div>
              <div>
                <span className="text-gray-500">테스트 유형:</span>
                <span className="ml-2 font-semibold">{currentExam.exam_info.exam_type}</span>
              </div>
              <div>
                <span className="text-gray-500">평균 난이도:</span>
                <span className="ml-2 font-semibold">{currentExam.exam_info.avg_difficulty}</span>
              </div>
              <div>
                <span className="text-gray-500">문제수:</span>
                <span className="ml-2 font-semibold">{currentExam.exam_info.total_problems}
                  <span className="ml-1 text-gray-500">(
                    <span className="text-[#4ABC85]">{currentExam.exam_info.correct_count}</span>, 
                    <span className="text-[#F9344B]">{currentExam.exam_info.wrong_count}</span>
                  )</span>
                </span>
              </div>
              <div>
                <span className="text-gray-500">테스트 상태:</span>
                <span className="ml-2 font-semibold">{getExamStatusText(currentExam.exam_info.exam_status)}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200 relative">
          <div className="fixed left-[200px] bottom-6 z-40 flex items-center gap-2">
            <button
              onClick={() => {
                if (isCreateExamModalOpen) {
                  handleCloseCreateExamModal();
                } else {
                  handleCreateExamModalOpen();
                }
              }}
              className="h-12 px-4 rounded-full bg-[#4ABC85] text-white shadow-lg hover:bg-[#3da975] transition-colors flex items-center justify-center gap-1"
            >
              <span className="font-semibold">Ky</span>
              <Plus size={24} />
            </button>

            <div className="flex gap-1">
              <button
                onClick={() => handleExamChange('prev')}
                disabled={selectedExamIndex === examList.length - 1}
                className="h-12 w-12 rounded-full bg-[#4ABC85] text-white shadow-lg hover:bg-[#3da975] transition-colors flex items-center justify-center disabled:opacity-50 disabled:hover:bg-[#4ABC85]"
              >
                <ChevronLeft size={24} />
              </button>
              <button
                onClick={() => handleExamChange('next')}
                disabled={selectedExamIndex === 0}
                className="h-12 w-12 rounded-full bg-[#4ABC85] text-white shadow-lg hover:bg-[#3da975] transition-colors flex items-center justify-center disabled:opacity-50 disabled:hover:bg-[#4ABC85]"
              >
                <ChevronLeft size={24} className="rotate-180" />
              </button>
            </div>
          </div>

          <div className="container mx-auto px-6 py-8">
            <nav className="mb-6">
              <div className="flex items-center text-sm text-gray-600">
                <Link to="/student" className="hover:text-[#4ABC85]">학생관리</Link>
                <span className="mx-2">&gt;</span>
                <Link to={`/student/${student_id}/stamp`} className="hover:text-[#4ABC85]">교육과정</Link>
                <span className="mx-2">&gt;</span>
                <span className="text-[#4ABC85]">테스트결과</span>
              </div>
            </nav>

            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                {currentExam && (
                  <div ref={examCardRef} className="bg-white rounded-lg shadow p-6">
                    <div className="flex justify-between items-center mb-2">
                      <h2 className="text-2xl font-bold">{currentExam.exam_info.tree_name}</h2>
                      <div className="flex gap-2">
                        <button
                          onClick={() => setIsWrongAnswerModalOpen(true)}
                          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                        >
                          오답노트
                        </button>
                        <button
                          onClick={() => setIsModalOpen(true)}
                          className="px-4 py-2 bg-[#4ABC85] text-white rounded hover:bg-[#3da975] transition-colors"
                        >
                          전체 테스트 보기
                        </button>
                      </div>
                    </div>

                    <div className="flex items-center space-x-6 mb-2">
                      <div className="flex-1 px-6 py-4 border-r border-gray-200">
                        <p className="text-sm text-gray-500 mb-1">학생 이름</p>
                        <p className="font-semibold">{currentExam.student_info.student_name}</p>
                      </div>
                      <div className="flex-1 px-6 py-4 border-r border-gray-200">
                        <p className="text-sm text-gray-500 mb-1">학교</p>
                        <p className="font-semibold">{currentExam.student_info.school_name}</p>
                      </div>
                      <div className="flex-1 px-6 py-4 border-r border-gray-200">
                        <p className="text-sm text-gray-500 mb-1">학년</p>
                        <p className="font-semibold">{currentExam.student_info.school_grade}</p>
                      </div>
                      <div className="flex-1 px-6 py-4">
                        <p className="text-sm text-gray-500 mb-1">담당 선생님</p>
                        <p className="font-semibold">{currentExam.student_info.in_charge_teacher}</p>
                      </div>
                    </div>

                    <div className="flex items-center space-x-6 mb-2">
                      <div className="flex-1 px-6 py-4 border-r border-gray-200">
                        <p className="text-sm text-gray-500 mb-1">TID</p>
                        <p className="font-semibold">
                          {currentExam.exam_info.exam_id}
                          <span className="ml-1 text-gray-500">
                            (회차: {currentExam.exam_info.exam_count})
                          </span>
                        </p>
                      </div>
                      <div className="flex-1 px-6 py-4 border-r border-gray-200">
                        <p className="text-sm text-gray-500 mb-1">교육과정</p>
                        <p className="font-semibold">{currentExam.exam_info.edu_ver}</p>
                      </div>
                      <div className="flex-1 px-6 py-4 border-r border-gray-200">
                        <p className="text-sm text-gray-500 mb-1">테스트 유형</p>
                        <p className="font-semibold">{currentExam.exam_info.exam_type}</p>
                      </div>
                      <div className="flex-1 px-6 py-4 border-r border-gray-200">
                        <p className="text-sm text-gray-500 mb-1">평균 난이도</p>
                        <p className="font-semibold">{currentExam.exam_info.avg_difficulty}</p>
                      </div>
                      <div className="flex-1 px-6 py-4 border-r border-gray-200">
                        <p className="text-sm text-gray-500 mb-1">문제수</p>
                        <p className="font-semibold">{currentExam.exam_info.total_problems}
                          <span className="ml-1 text-gray-500">(
                            <span className="text-[#4ABC85]">{currentExam.exam_info.correct_count}</span>, 
                            <span className="text-[#F9344B]">{currentExam.exam_info.wrong_count}</span>
                          )</span>
                        </p>
                      </div>
                      <div className="flex-1 px-6 py-4 border-r border-gray-200">
                        <p className="text-sm text-gray-500 mb-1">테스트 상태</p>
                        <p className="font-semibold">{getExamStatusText(currentExam.exam_info.exam_status)}</p>
                      </div>
                      <div className="flex-1 px-6 py-4 border-r border-gray-200">
                        <p className="text-sm text-gray-500 mb-1">생성일시</p>
                        <p className="font-semibold">{formatDateTime(currentExam.exam_info.created_at)}</p>
                      </div>
                      <div className="flex-1 px-6 py-4">
                        <p className="text-sm text-gray-500 mb-1">수정일시</p>
                        <p className="font-semibold">{formatDateTime(currentExam.exam_info.updated_at)}</p>
                      </div>
                    </div>

                    <div className="flex justify-end mt-2 space-x-2">
                      {currentExam.exam_info.exam_status === 999 && (
                        <button
                          onClick={handleReOpenExam}
                          className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
                        >
                          결과취소
                        </button>
                      )}
                      {!currentExam.exam_info.is_closed && currentExam.exam_info.exam_status === 200 && (
                        <button
                          onClick={() => handleOpenResultModal(false)}
                          className="px-4 py-2 bg-[#4ABC85] text-white rounded hover:bg-[#3da975]"
                        >
                          결과입력
                        </button>
                      )}
                      {!currentExam.exam_info.is_closed && currentExam.exam_info.exam_status === 400 && (
                        <>
                          <button
                            onClick={() => handleOpenResultModal(false)}
                            className="px-4 py-2 bg-[#4ABC85] text-white rounded hover:bg-[#3da975]"
                          >
                            결과수정
                          </button>
                          <button
                            onClick={() => handleOpenResultModal(true)}
                            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                          >
                            결과확정
                          </button>
                        </>
                      )}
                      {currentExam.exam_info.exam_status !== 999 && (
                        <button
                          onClick={handleDeleteExam}
                          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                        >
                          테스트 삭제
                        </button>
                      )}
                      <div className="flex items-center gap-2">
                        <button
                          onClick={handlePrintExam}
                          className="flex items-center gap-1 px-2 py-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#4ABC85]"
                        >
                          <Printer className="w-4 h-4" />
                          시험지
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-span-12">
                {treeData && (
                  <div className="bg-white rounded-lg shadow p-6">
                    <div className="flex justify-between items-center mb-4">
                      <div>
                        <h3 className="text-xl font-bold">결과 맵</h3>
                        {treeData?.exam_info && (
                          <p className="text-sm text-gray-500 mt-1">
                            TID: {treeData.exam_info.exam_id}
                            {treeData.exam_info.prev_exam && ` (이전 TID: ${treeData.exam_info.prev_exam})`}
                          </p>
                        )}
                      </div>
                      <div className="flex items-center gap-4">
                        {!isCreateExamModalOpen && (
                          <div className="flex items-center gap-2">
                            <span className="text-sm font-semibold">
                              총점: {calculateTotalScore().toLocaleString()}점
                            </span>
                            <span className="text-xs text-gray-500">
                              (
                              <span className="text-[#F9344B]">{calculateMaxMinScores().min.toLocaleString()}</span>
                              ~
                              <span className="text-[#4ABC85]">{calculateMaxMinScores().max.toLocaleString()}</span>
                              )
                            </span>
                            <div className="h-6 border-r border-gray-300 mx-2"></div>
                            <span className="text-sm text-gray-600">출제 문제만 보기</span>
                            <button
                              onClick={() => {
                                setShowOnlyProblems(!showOnlyProblems);
                                if (!showOnlyProblems) {
                                  setIsRowFixMode(false);
                                  setFixedRows([]);
                                  setSelectedRows([]);
                                  setIsSelectingRows(false);
                                }
                              }}
                              className={`
                                relative inline-flex h-6 w-11 items-center rounded-full
                                ${showOnlyProblems ? 'bg-[#4ABC85]' : 'bg-gray-200'}
                                transition-colors duration-200
                              `}
                            >
                              <span
                                className={`
                                  inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200
                                  ${showOnlyProblems ? 'translate-x-6' : 'translate-x-1'}
                                `}
                              />
                            </button>
                            {selectedDepth === 'd4' && (
                              <div className="flex items-center gap-2 ml-2">
                                {isSelectingRows ? (
                                  <>
                                    <button
                                      onClick={handleFixRows}
                                      className="px-3 py-1 bg-[#4ABC85] text-white rounded text-sm hover:bg-[#3da975]"
                                    >
                                      선택 완료
                                    </button>
                                    <button
                                      onClick={() => {
                                        setIsSelectingRows(false);
                                        setSelectedRows([]);
                                      }}
                                      className="px-3 py-1 bg-gray-500 text-white rounded text-sm hover:bg-gray-600"
                                    >
                                      취소
                                    </button>
                                  </>
                                ) : (
                                  <div className="flex items-center gap-2">
                                    <span className="text-sm text-gray-600">행 고정</span>
                                    {isRowFixMode ? (
                                      <button
                                        onClick={handleClearFixedRows}
                                        className="px-3 py-1 bg-red-500 text-white rounded text-sm hover:bg-red-600"
                                      >
                                        고정 해제
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() => setIsSelectingRows(true)}
                                        className={`
                                          relative inline-flex h-6 w-11 items-center rounded-full
                                          ${isRowFixMode ? 'bg-[#4ABC85]' : 'bg-gray-200'}
                                          transition-colors duration-200
                                        `}
                                      >
                                        <span
                                          className={`
                                            inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-200
                                            ${isRowFixMode ? 'translate-x-6' : 'translate-x-1'}
                                          `}
                                        />
                                      </button>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                        <button
                          onClick={() => handleShareResult(currentExam?.exam_info.exam_id)}
                          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 text-sm transition-colors"
                        >
                          결과공유
                        </button>
                      </div>
                    </div>

                    <div className="flex gap-4 mb-4 text-xs">
                      <div className="flex items-center gap-1">
                        <div className="w-3 h-3 rounded bg-[#B7E4CE] opacity-70"></div>
                        <span>Likely Correct</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <div className="w-3 h-3 rounded bg-[#4ABC85]"></div>
                        <span>Correct</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <div className="w-3 h-3 rounded bg-[#FDCECE] opacity-70"></div>
                        <span>Likely Incorrect</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <div className="w-3 h-3 rounded bg-[#F9344B]"></div>
                        <span>Incorrect</span>
                      </div>
                    </div>

                    <div className="flex gap-2 mb-4">
                      {Object.entries(depthTitles).map(([depth, title]) => (
                        <button
                          key={depth}
                          onClick={() => setSelectedDepth(depth)}
                          className={`px-3 py-1 rounded text-sm ${selectedDepth === depth
                              ? 'bg-[#4ABC85] text-white'
                              : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                            }`}
                        >
                          {title}
                        </button>
                      ))}
                    </div>

                    <div className="overflow-x-auto">
                      {renderTable()}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>

      <ExamListModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        examList={examList}
        onSelectExam={handleSelectExam}
      />

      <ResultInputModal
        isOpen={isResultModalOpen}
        onClose={() => {
          setIsResultModalOpen(false);
          setIsConfirming(false);
          setSelectedExamId(null);
        }}
        examId={selectedExamId}
        stampId={stamp_id}
        onRefresh={(examId) => fetchExamDetail(examId)}
        isConfirmMode={isConfirming}
      />

      <CreateExamModal
        ref={modalRef}
        isOpen={isCreateExamModalOpen}
        onClose={handleCloseCreateExamModal}
        stampId={stamp_id}
        onSuccess={() => {
          fetchExamList();
          setIsCreateExamModalOpen(false);
          setSelectedAddressesForExam([]);
          setDeltaSelections([]);
          setExamType('');
          setHierarchicalSelections({
            d1: new Set(),
            d2: new Set(),
            d3: new Set(),
            d4: new Set()
          });
        }}
        selectedAddressesForExam={selectedAddressesForExam}
        setSelectedAddressesForExam={setSelectedAddressesForExam}
        examType={examType}
        setExamType={setExamType}
        deltaSelections={deltaSelections}
        setDeltaSelections={setDeltaSelections}
        treeData={treeData}
        hierarchicalSelections={hierarchicalSelections}
        setHierarchicalSelections={setHierarchicalSelections}
      />

      <WrongAnswerModal
        isOpen={isWrongAnswerModalOpen}
        onClose={() => setIsWrongAnswerModalOpen(false)}
        examList={examList}
        stampId={stamp_id}
        tree_name={currentExam?.exam_info?.tree_name}
        edu_ver={currentExam?.exam_info?.edu_ver}
        student_name={currentExam?.student_info?.student_name}
      />

      <ProblemPreview
        isOpen={previewInfo.isOpen}
        onClose={() => setPreviewInfo({
          isOpen: false,
          problemId: [],
          problemNumber: null,
          isCorrect: null
        })}
        problemIds={previewInfo.problemId}
        showNumbers={!!previewInfo.problemNumber}
        correctList={previewInfo.isCorrect || []}
        problemNumbers={previewInfo.problemNumber || []}
      />

      {showPrintPreview && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center"
          onClick={() => setShowPrintPreview(false)}
        >
          <div 
            className="bg-white rounded-lg w-[70%] h-[90%] p-8 overflow-hidden flex flex-col"
            onClick={e => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-8">
              <button
                onClick={() => setShowPrintPreview(false)}
                className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
              >
                종료
              </button>
              <button
                onClick={handlePrint}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
              >
                인쇄하기
              </button>
            </div>
            <div className="flex-1 overflow-y-auto">
              <div className="flex justify-center">
                <PrintPreview 
                  problems={printProblems}
                  mode="preview"
                  scale={0.7}
                  tree_name={currentExam.exam_info.tree_name}
                  edu_ver={currentExam.exam_info.edu_ver}
                  student_name={currentExam.student_info.student_name}
                  exam_id={currentExam.exam_info.exam_id}
                  leftFooter={`카이테스트 · ${
                    currentExam.exam_info.exam_type === 'alpha' ? 'α' : 
                    currentExam.exam_info.exam_type === 'beta' ? 'β' : 
                    currentExam.exam_info.exam_type === 'gamma' ? 'γ' :
                    currentExam.exam_info.exam_type === 'delta' ? 'δ' : 
                    currentExam.exam_info.exam_type
                  }`}
                  rightFooter={`${currentExam.exam_info.edu_ver} · ${currentExam.exam_info.tree_name}`}
                  onPrint={setPrintFunction}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ExamResult;
