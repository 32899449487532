import React, { useState, useCallback, useEffect } from 'react';
import { EditorComposer, Editor } from '../../editor';
import PlaygroundNodes from '../../editor/nodes/PlaygroundNodes';
import { createEditor } from 'lexical';
import { processMathEquation } from '../utils/latexParser';
import { handlePrint } from '../ProblemPrint/handlePrint';
import { useAuth } from '../../context/AuthContext';

function PrintModal({ isOpen, onClose, examId }) {
  const { getAccessToken } = useAuth();
  const [editorState, setEditorState] = useState(null);
  const [loading, setLoading] = useState(false);

  const createEmptyLayoutTemplate = (problemCount) => {
    const rowCount = Math.ceil(problemCount / 2);
    const template = {
      root: {
        children: [
          {
            children: [],
            direction: null,
            format: "",
            indent: 0,
            type: "paragraph",
            version: 1
          }
        ],
        direction: "ltr",
        format: "",
        indent: 0,
        type: "root",
        version: 1
      }
    };

    for (let i = 0; i < rowCount; i++) {
      template.root.children.push({
        children: Array(2).fill().map(() => ({
          children: [
            {
              children: [],
              direction: "ltr",
              format: "",
              indent: 0,
              type: "paragraph",
              version: 1
            }
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "layout-item",
          version: 1
        })),
        direction: "ltr",
        format: "",
        indent: 0,
        type: "layout-container",
        version: 1,
        templateColumns: "1fr 1fr"
      });
    }

    return template;
  };

  const loadProblem = useCallback(async () => {
    if (!examId) return;

    setLoading(true);
    try {
      const token = await getAccessToken();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/exam/alpha/${examId}/print`, {
        headers: { 'Authorization': token }
      });
      
      const data = await response.json();
      if (data.status !== 'success') throw new Error('문제 로드 실패');

      const problems = data.data.problem_list;
      const layoutTemplate = createEmptyLayoutTemplate(problems.length);
      
      problems.forEach((problem, index) => {
        const lexicalData = problem.lexical_data.lexical_data;
        
        // lexicalData 처리 로직
        const processNode = (node) => {
          if (node.type === 'equation') {
            node.equation = processMathEquation(node.equation);
          }
          if (node.children && Array.isArray(node.children)) {
            node.children = node.children.map(child => processNode(child));
          }
          return node;
        };

        lexicalData.root = processNode(lexicalData.root);

        const row = Math.floor(index / 2) + 1;
        const col = index % 2;
        
        // 내부 레이아웃 처리
        const processContent = (node) => {
          if (!node) return null;
          const newNode = { ...node };
          if (node.children) {
            newNode.children = node.children.map(child => {
              if (child.type === 'layout-container' || child.type === 'layout-item') {
                return {
                  ...child,
                  children: child.children.map(grandChild => processContent(grandChild))
                };
              }
              return processContent(child);
            }).filter(Boolean);
          }
          return newNode;
        };

        const processedChildren = lexicalData.root.children.map(child => 
          processContent(child)
        ).filter(Boolean);

        const layoutItem = {
          children: processedChildren,
          direction: "ltr",
          format: "",
          indent: 0,
          type: "layout-item",
          version: 1
        };

        if (layoutTemplate.root.children[row] && 
            layoutTemplate.root.children[row].children[col]) {
          layoutTemplate.root.children[row].children[col] = layoutItem;
        }
      });

      const editor = createEditor({
        nodes: [...PlaygroundNodes],
        theme: {
          paragraph: 'editor-paragraph',
          text: {
            bold: 'editor-text-bold',
            italic: 'editor-text-italic',
            underline: 'editor-text-underline',
          },
        }
      });

      const editorState = editor.parseEditorState(layoutTemplate);
      setEditorState(editorState);

    } catch (error) {
      console.error('문제 불러오기 실패:', error);
      alert('문제를 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  }, [examId, getAccessToken]);

  // 모달이 열릴 때 문제 로드
  useEffect(() => {
    if (isOpen && examId) {
      loadProblem();
    }
  }, [isOpen, examId, loadProblem]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-[90%] h-[90%] overflow-auto">
        <div className="flex justify-between mb-4">
          <h2 className="text-xl font-bold">시험지 출력</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            닫기
          </button>
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <p>로딩 중...</p>
          </div>
        ) : (
          <>
            <div className="mb-4">
              <button
                onClick={handlePrint}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                인쇄하기
              </button>
            </div>

            <div className="flex justify-center overflow-auto">
              <div 
                className="bg-white rounded-lg shadow-lg my-4"
                style={{
                    width: '328mm',  // 210mm × (150/96)
                    minHeight: '464mm',  // 297mm × (150/96)
                    padding: '16mm',
                    margin: '0 auto',
                    boxSizing: 'border-box',
                    backgroundColor: 'white',
                  }}
              >
                <div 
                  className="[&_.editor-container]:!font-inherit [&_span.editor-equation]:inline-block [&_div.editor-equation]:block"
                  style={{
                    width: '296mm',  // 200mm × (150/96)
                    height: 'auto',
                    overflow: 'visible'
                  }}
                >
                  <style>
                    {`
                      .PlaygroundEditorTheme__layoutContainer {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: 4mm;
                        margin: 4mm 0;
                        width: 100%;
                        min-width: 0;
                      }
                      
                      .PlaygroundEditorTheme__layoutItem {
                        width: 100%;
                        min-width: 0;
                        padding: 0;
                        box-sizing: border-box;
                        page-break-inside: avoid;
                        overflow-wrap: break-word;
                      }

                      .editor-container {
                        height: auto !important;
                        min-height: 0 !important;
                        overflow: visible !important;
                      }
                    `}
                  </style>
                  {editorState && (
                    <EditorComposer initialEditorState={editorState}>
                      <Editor />
                    </EditorComposer>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default PrintModal; 