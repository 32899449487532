import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { useAuth } from '../context/AuthContext';
import SolutionEditModal from '../components/ProblemSolution/SolutionEditModal';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';
import AnswerModal from '../components/ProblemSolution/AnswerModal';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

function ProblemSolution() {
  const { page_number } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { getAccessToken, handleApiError } = useAuth();
  const [problems, setProblems] = useState([]);
  const [page, setPage] = useState(parseInt(page_number) || 1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  // URL 파라미터에서 필터 상태 초기화
  const searchParams = new URLSearchParams(location.search);
  const [filters, setFilters] = useState({
    status: searchParams.get('status') || '',
    author: searchParams.get('author') || '',
    labels: searchParams.get('labels') || ''
  });
  const [problemIdSearch, setProblemIdSearch] = useState(searchParams.get('problem_id') || '');
  
  const [isFilterMode, setIsFilterMode] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const [showModal, setShowModal] = useState(false);
  const [selectedSolution, setSelectedSolution] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const [selectedProblemId, setSelectedProblemId] = useState(null);

  // singleProblemResult 상태 추가
  const [singleProblemResult, setSingleProblemResult] = useState(null);

  // fetchWithTokenRefresh 함수
  const fetchWithTokenRefresh = useCallback(async (url, options = {}) => {
    try {
      const token = await getAccessToken();
      if (!token) {
        navigate('/signin');
        return null;
      }

      const response = await fetch(`${API_BASE_URL}${url}`, {
        ...options,
        headers: {
          ...options.headers,
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (response.status === 401) {
        handleApiError(new Error('Invalid or expired token'));
        return null;
      }

      const data = await response.json();

      if (data.status_code === 401) {
        handleApiError(new Error('Invalid or expired token'));
        return null;
      }

      return data;
    } catch (error) {
      console.error('API 호출 에러:', error);
      if (error.message.includes('token') || error.message.includes('unauthorized')) {
        handleApiError(error);
      }
      throw error;
    }
  }, [getAccessToken, navigate, handleApiError]);

  // API 호출 함수
  const fetchProblems = useCallback(async (pageNum = 1, useFilter = false) => {
    if (loading) return;

    const shouldUseFilter = useFilter || isFilterMode;
    setLoading(true);

    try {
      const params = new URLSearchParams({
        page: pageNum,
        per_page: 20
      });

      if (shouldUseFilter) {
        if (filters.status) params.append('status', filters.status);
        if (filters.author) params.append('author', filters.author);
        if (filters.labels) params.append('labels', filters.labels);
      }

      const hasFilterValues = filters.status || filters.author || filters.labels;
      const endpoint = shouldUseFilter && hasFilterValues ?
        '/api/problems/filtered' :
        '/api/problems/';

      const response = await fetchWithTokenRefresh(`${endpoint}?${params}`);

      if (!response) return;

      if (response.status === 'success') {
        // 문제 ID 목록 생성
        const problemIds = response.data.map(problem => problem.problem_id).join(',');
        
        // 한 번의 API 호출로 모든 솔루션 데이터 가져오기
        const solutionsResponse = await fetchWithTokenRefresh(`/api/solutions/list?problem_ids=${problemIds}`);
        
        // 솔루션 데이터를 problem_id를 키로 하는 맵으로 변환
        const solutionsMap = solutionsResponse?.data?.reduce((acc, item) => {
          acc[item.problem_id] = item.solutions;
          return acc;
        }, {});

        // 문제 데이터와 솔루션 데이터 결합
        const problemsWithSolutions = response.data.map(problem => ({
          ...problem,
          has_solution: (solutionsMap?.[problem.problem_id]?.length ?? 0) > 0 && 
                        solutionsMap?.[problem.problem_id]?.[0]?.solution_info?.status >= 100,
          solution_data: solutionsMap?.[problem.problem_id] ?? []
        }));

        setProblems(problemsWithSolutions);
        setPage(pageNum);
        setTotalPages(response.total_pages);
        setHasMore(pageNum < response.total_pages);
      }
    } catch (error) {
      console.error('문제 목록 불러오기 실패:', error);
    } finally {
      setLoading(false);
    }
  }, [filters, loading, isFilterMode, fetchWithTokenRefresh]);

  // URL 업데이트 함수
  const updateURL = useCallback((newPage, newFilters, newProblemId) => {
    const params = new URLSearchParams();
    
    if (newFilters.labels) params.set('labels', newFilters.labels);
    if (newFilters.status) params.set('status', newFilters.status);
    if (newFilters.author) params.set('author', newFilters.author);
    if (newProblemId) params.set('problem_id', newProblemId);
    
    const queryString = params.toString();
    navigate(`/problem-solution/${newPage}${queryString ? `?${queryString}` : ''}`);
  }, [navigate]);

  // 필터 변경 핸들러 수정
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    const newFilters = { ...filters, [name]: value };
    setFilters(newFilters);
  };

  // 페이지 변경 핸들러 수정
  const handlePageChange = (newPage) => {
    setPage(newPage);
    updateURL(newPage, filters, problemIdSearch);
    fetchProblems(newPage, isFilterMode);
  };

  // 검색 핸들러 수정
  const handleSearch = () => {
    const hasFilterValues = filters.labels;
    if (!hasFilterValues) {
      alert('라벨을 입력해주세요.');
      return;
    }
    setIsFilterMode(true);
    setProblems([]);
    updateURL(1, filters, ''); // problem_id 검색값 초기화
    setProblemIdSearch(''); // ID 검색 입력값 초기화
    setSingleProblemResult(null);
    fetchProblems(1, true);
  };

  // 초기화 핸들러 수정
  const handleReset = () => {
    setIsFilterMode(false);
    const newFilters = {
      status: '',
      author: '',
      labels: ''
    };
    setFilters(newFilters);
    setProblemIdSearch('');
    setSingleProblemResult(null);
    setProblems([]);
    updateURL(1, newFilters, '');
    setHasMore(true);
    fetchProblems(1, false);
  };

  // problem_id 검색 핸들러 수정
  const handleProblemIdSearch = useCallback(async () => {
    if (!problemIdSearch || isNaN(problemIdSearch)) {
      alert('유효한 문제 ID를 입력해주세요.');
      return;
    }

    // 라벨 필터 초기화
    const newFilters = {
      status: '',
      author: '',
      labels: ''
    };
    setFilters(newFilters);
    setIsFilterMode(false);

    setLoading(true);
    try {
      const response = await fetchWithTokenRefresh(`/api/problems/${problemIdSearch}`);
      
      if (!response) return;

      if (response.status === 'success') {
        const solutionsResponse = await fetchWithTokenRefresh(`/api/solutions/list/${problemIdSearch}`);
        
        const problemWithSolution = {
          ...response.data.problem_info,
          problem_id: response.data.problem_id,
          has_solution: (solutionsResponse?.data?.length ?? 0) > 0 && 
                        solutionsResponse?.data?.[0]?.solution_info?.status >= 100,
          solution_data: solutionsResponse?.data ?? []
        };
        
        setProblems([problemWithSolution]);
        setSingleProblemResult(response.data);
        setHasMore(false);
        setTotalPages(1);
        updateURL(1, newFilters, problemIdSearch); // 초기화된 필터로 URL 업데이트
      } else {
        alert('문제를 찾을 수 없습니다.');
        setSingleProblemResult(null);
        setProblems([]);
      }
    } catch (error) {
      console.error('문제 검색 실패:', error);
      alert('문제 검색에 실패했습니다.');
    } finally {
      setLoading(false);
    }
  }, [problemIdSearch, fetchWithTokenRefresh, updateURL]);

  // URL 변경 감지 및 데이터 로드 수정
  useEffect(() => {
    const pageNum = parseInt(page_number) || 1;
    setPage(pageNum);
    
    const searchParams = new URLSearchParams(location.search);
    const urlFilters = {
      status: searchParams.get('status') || '',
      author: searchParams.get('author') || '',
      labels: searchParams.get('labels') || ''
    };
    const urlProblemId = searchParams.get('problem_id');
    
    setFilters(urlFilters);
    setProblemIdSearch(urlProblemId || '');
    
    if (urlProblemId) {
      // problem_id가 URL에 있으면 해당 문제만 검색
      const searchProblemById = async () => {
        setLoading(true);
        try {
          const response = await fetchWithTokenRefresh(`/api/problems/${urlProblemId}`);
          
          if (!response) return;

          if (response.status === 'success') {
            const solutionsResponse = await fetchWithTokenRefresh(`/api/solutions/list/${urlProblemId}`);
            
            const problemWithSolution = {
              ...response.data.problem_info,
              problem_id: response.data.problem_id,
              has_solution: (solutionsResponse?.data?.length ?? 0) > 0 && 
                            solutionsResponse?.data?.[0]?.solution_info?.status >= 100,
              solution_data: solutionsResponse?.data ?? []
            };
            
            setProblems([problemWithSolution]);
            setSingleProblemResult(response.data);
            setHasMore(false);
            setTotalPages(1);
            setIsFilterMode(false); // 필터 모드 해제
          } else {
            alert('문제를 찾을 수 없습니다.');
            setSingleProblemResult(null);
            setProblems([]);
          }
        } catch (error) {
          console.error('문제 검색 실패:', error);
          alert('문제 검색에 실패했습니다.');
        } finally {
          setLoading(false);
        }
      };

      searchProblemById();
    } else if (urlFilters.labels) {
      // 라벨 필터가 URL에 있으면 필터 검색 실행
      setIsFilterMode(true);
      fetchProblems(pageNum, true);
    } else {
      // 둘 다 없으면 일반 목록 조회
      setIsFilterMode(false);
      fetchProblems(pageNum, false);
    }
  }, [location.search, page_number, fetchWithTokenRefresh]);

  // refreshTrigger를 위한 별도의 useEffect
  useEffect(() => {
    if (refreshTrigger > 0) { // 초기 로드 시에는 실행하지 않음
      fetchProblems(page, isFilterMode);
    }
  }, [refreshTrigger]); // refreshTrigger만 의존성으로 포함

  // 키 입력 핸들러
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  // 페이지네이션 렌더링
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisible = 5;
    let start = Math.max(1, page - Math.floor(maxVisible / 2));
    let end = Math.min(start + maxVisible - 1, totalPages);

    if (end - start + 1 < maxVisible) {
      start = Math.max(1, end - maxVisible + 1);
    }

    if (start > 1) {
      pageNumbers.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className="px-3 py-1 border rounded hover:bg-gray-100"
        >
          1
        </button>
      );
      if (start > 2) {
        pageNumbers.push(<span key="ellipsis1">...</span>);
      }
    }

    for (let i = start; i <= end; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 border rounded ${page === i ? 'bg-[#231F20] text-white' : 'hover:bg-gray-100'}`}
        >
          {i}
        </button>
      );
    }

    if (end < totalPages) {
      if (end < totalPages - 1) {
        pageNumbers.push(<span key="ellipsis2">...</span>);
      }
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className="px-3 py-1 border rounded hover:bg-gray-100"
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  // refreshData 함수 수정
  const refreshData = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlProblemId = searchParams.get('problem_id');
    
    if (urlProblemId) {
      // problem_id가 있으면 해당 문제만 다시 검색
      const searchProblemById = async () => {
        setLoading(true);
        try {
          const response = await fetchWithTokenRefresh(`/api/problems/${urlProblemId}`);
          
          if (!response) return;

          if (response.status === 'success') {
            const solutionsResponse = await fetchWithTokenRefresh(`/api/solutions/list/${urlProblemId}`);
            
            const problemWithSolution = {
              ...response.data.problem_info,
              problem_id: response.data.problem_id,
              has_solution: (solutionsResponse?.data?.length ?? 0) > 0 && 
                            solutionsResponse?.data?.[0]?.solution_info?.status >= 100,
              solution_data: solutionsResponse?.data ?? []
            };
            
            setProblems([problemWithSolution]);
            setSingleProblemResult(response.data);
            setHasMore(false);
            setTotalPages(1);
          }
        } catch (error) {
          console.error('문제 검색 실패:', error);
        } finally {
          setLoading(false);
        }
      };

      searchProblemById();
    } else {
      // problem_id가 없으면 현재 필터 상태로 문제 목록 조회
      fetchProblems(page, isFilterMode);
    }
  }, [fetchWithTokenRefresh, page, isFilterMode, location.search]);

  // handleEdit 함수 수정
  const handleEdit = useCallback(async (problemId) => {
    try {
      // 1. 먼저 solution 목록을 가져옴
      const solutionsResponse = await fetchWithTokenRefresh(`/api/solutions/list/${problemId}`);
      
      if (!solutionsResponse || !solutionsResponse.data || solutionsResponse.data.length === 0) {
        alert('해당 문제의 풀이가 없습니다.');
        return;
      }

      // 가장 작은 solution_id를 가진 solution 선택
      const solution = solutionsResponse.data.reduce((prev, current) => 
        prev.solution_id < current.solution_id ? prev : current
      );

      // 2. solution의 status에 따라 파라미터 설정
      const params = new URLSearchParams();
      params.append('base64', 'true');
      
      if (solution.solution_info.status >= 300) {
        params.append('mathpix', 'true');
      }
      
      if (solution.solution_info.status >= 999) {
        params.append('lexical', 'true');
      }

      // 3. solution 상세 정보 가져오기
      const solutionResponse = await fetchWithTokenRefresh(
        `/api/solutions/${solution.solution_id}?${params}`
      );

      if (!solutionResponse) return;

      setSelectedSolution(solutionResponse.data);
      setShowModal(true);
    } catch (error) {
      console.error('해답 데이터 불러오기 실패:', error);
      alert('해답 데이터를 불러오는데 실패했습니다.');
    }
  }, [fetchWithTokenRefresh]);

  // 날짜 포맷 함수 추가 (테이블 위에 추가)
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  // getStatusInfo 함수 수정
  const getStatusInfo = (status) => {
    const statusMap = {
      100: { answer: true, image: false, ocr: false, editor: false },
      200: { answer: true, image: true, ocr: false, editor: false },
      300: { answer: true, image: true, ocr: true, editor: false },
      999: { answer: true, image: true, ocr: true, editor: true }
    };
    return statusMap[status] || { answer: false, image: false, ocr: false, editor: false };
  };

  // 새로운 핸들러 함수들 추가
  const handleAnswerRegister = useCallback(async (problemId) => {
    setSelectedProblemId(problemId);
    setShowAnswerModal(true);
  }, []);

  const handleAnswerEdit = useCallback(async (problemId) => {
    try {
      const solutionsResponse = await fetchWithTokenRefresh(`/api/solutions/list/${problemId}`);
      if (!solutionsResponse || !solutionsResponse.data || solutionsResponse.data.length === 0) {
        alert('해당 문제의 풀이가 없습니다.');
        return;
      }

      const solution = solutionsResponse.data[0];
      setSelectedSolution(solution);
      setShowAnswerModal(true);
    } catch (error) {
      console.error('해답 데이터 불러오기 실패:', error);
      alert('해답 데이터를 불러오는데 실패했습니다.');
    }
  }, [fetchWithTokenRefresh]);

  // URL 변경을 감지하여 sessionStorage 업데이트
  useEffect(() => {
    const currentPath = `${location.pathname}${location.search}`;
    if (location.pathname.includes('/problem-solution')) {
      sessionStorage.setItem('previousUrl', currentPath);
    }
  }, [location.pathname, location.search]);

  // 풀이 버튼 클릭 핸들러 수정 - URL 저장 로직 제거
  const handleSolutionRegister = (problemId) => {
    navigate(`/solution-register?problem_id=${problemId}`);
  };

  // handleUpdate 함수 수정
  const handleUpdate = useCallback(() => {
    // 현재 URL에서 페이지 번호 추출
    const pageMatch = location.pathname.match(/\/problem-solution\/(\d+)/);
    const currentPage = pageMatch ? pageMatch[1] : '1';
    const searchParams = new URLSearchParams(location.search);
    const currentLabels = searchParams.get('labels');
    const currentProblemId = searchParams.get('problem_id');
    
    let newUrl = `/problem-solution/${currentPage}`;
    
    if (currentLabels) {
      newUrl += `?labels=${currentLabels}`;
    } else if (currentProblemId) {
      newUrl += `?problem_id=${currentProblemId}`;
    }
    
    navigate(newUrl);
  }, [location.pathname, location.search, navigate]);

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 p-6 overflow-y-auto">
          <nav className="mb-6">
            <div className="flex items-center text-sm text-gray-600">
              <Link to="/problem-solution" className="hover:text-purple-600">풀이관리</Link>
            </div>
          </nav>
          {/* 필터 섹션 */}
          <div className="mb-6 space-y-4">
            <div className="flex gap-4 mb-4">
              <input
                type="number"
                placeholder="문제 ID 검색"
                value={problemIdSearch}
                onChange={(e) => setProblemIdSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleProblemIdSearch();
                  }
                }}
                className="border rounded p-2 w-[200px]"
                min="1"
              />
              <button
                onClick={handleProblemIdSearch}
                className="bg-[#231F20] text-white px-4 py-2 rounded hover:bg-[#A3A1A2]"
              >
                ID 검색
              </button>
            </div>

            <div className="flex gap-4 justify-between">
              <div className="flex gap-4">
                <input
                  type="text"
                  name="labels"
                  placeholder="라벨"
                  value={filters.labels}
                  onChange={handleFilterChange}
                  onKeyPress={handleKeyPress}
                  className="border rounded p-2 w-[300px]"
                />
                <button
                  onClick={handleSearch}
                  className="bg-[#231F20] text-white px-4 py-2 rounded hover:bg-[#A3A1A2]"
                >
                  조회
                </button>
                <button
                  onClick={handleReset}
                  className="bg-[#D9D9D9] text-white px-4 py-2 rounded"
                >
                  초기화
                </button>
              </div>
            </div>
          </div>

          {/* 테이블 */}
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-sm rounded-lg">
              <thead className="bg-[#231F20]">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">ID</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">상태</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">작성자</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">라벨</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">정답</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">생성일</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">수정일</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">작업</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {loading ? (
                  <tr>
                    <td colSpan="6" className="text-center py-4">로딩 중...</td>
                  </tr>
                ) : (
                  problems.map((problem) => (
                    <tr key={problem.problem_id}>
                      <td className="px-6 py-4 whitespace-nowrap">{problem.problem_id}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex gap-2">
                          <span 
                            className={`h-2 w-2 rounded-full ${
                              getStatusInfo(problem.solution_data?.[0]?.solution_info?.status || 0).answer 
                                ? 'bg-green-500' 
                                : 'bg-red-500'
                            }`} 
                            title="정답" 
                          />
                          <span 
                            className={`h-2 w-2 rounded-full ${
                              getStatusInfo(problem.solution_data?.[0]?.solution_info?.status || 0).image 
                                ? 'bg-green-500' 
                                : 'bg-red-500'
                            }`} 
                            title="이미지" 
                          />
                          <span 
                            className={`h-2 w-2 rounded-full ${
                              getStatusInfo(problem.solution_data?.[0]?.solution_info?.status || 0).ocr 
                                ? 'bg-green-500' 
                                : 'bg-red-500'
                            }`} 
                            title="OCR" 
                          />
                          <span 
                            className={`h-2 w-2 rounded-full ${
                              getStatusInfo(problem.solution_data?.[0]?.solution_info?.status || 0).editor 
                                ? 'bg-green-500' 
                                : 'bg-red-500'
                            }`} 
                            title="에디터" 
                          />
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">{problem.solution_data?.[0]?.solution_info?.author}</td>
                      <td className="px-6 py-4">{problem.labels}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {(() => {
                          const answer = problem.solution_data?.[0]?.solution_info?.answer;
                          if (!answer) return '-';
                          
                          try {
                            return <InlineMath math={answer} />;
                          } catch (error) {
                            console.error('KaTeX 렌더링 에러:', error);
                            return answer;
                          }
                        })()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {formatDate(problem.solution_data?.[0]?.solution_info?.created_at)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {formatDate(problem.solution_data?.[0]?.solution_info?.updated_at)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {problem.solution_data?.[0]?.solution_info?.status >= 100 ? (
                          <>
                            <button
                              onClick={() => handleAnswerEdit(problem.problem_id)}
                              className="bg-[#4ABC85] text-white px-4 py-2 rounded hover:bg-[#A3A1A2] mr-2"
                            >
                              정답 수정
                            </button>
                            {problem.solution_data?.[0]?.solution_info?.status >= 200 ? (
                              <button
                                onClick={() => handleEdit(problem.problem_id)}
                                className="bg-[#4ABC85] text-white px-4 py-2 rounded hover:bg-[#A3A1A2]"
                              >
                                풀이 수정
                              </button>
                            ) : (
                              <button
                                onClick={() => handleSolutionRegister(problem.problem_id)}
                                className="bg-[#231F20] text-white px-4 py-2 rounded hover:bg-[#A3A1A2]"
                              >
                                풀이
                              </button>
                            )}
                          </>
                        ) : (
                          <>
                            <button
                              onClick={() => handleAnswerRegister(problem.problem_id)}
                              className="bg-[#231F20] text-white px-4 py-2 rounded hover:bg-[#A3A1A2] mr-2"
                            >
                              정답
                            </button>
                            <button
                              onClick={() => handleSolutionRegister(problem.problem_id)}
                              className="bg-[#231F20] text-white px-4 py-2 rounded hover:bg-[#A3A1A2] opacity-50 cursor-not-allowed"
                              disabled
                            >
                              풀이
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          {/* 페이지네이션 */}
          {!loading && problems.length > 0 && (
            <div className="flex justify-center mt-4">
              <div className="flex items-center gap-2">
                <button
                  onClick={() => handlePageChange(page - 1)}
                  disabled={page === 1}
                  className="px-4 py-2 border rounded disabled:opacity-50"
                >
                  이전
                </button>
                {renderPageNumbers()}
                <button
                  onClick={() => handlePageChange(page + 1)}
                  disabled={!hasMore}
                  className="px-4 py-2 border rounded disabled:opacity-50"
                >
                  다음
                </button>
              </div>
            </div>
          )}

          {/* 모달을 여기로 이동 */}
          {showModal && selectedSolution && (
            <SolutionEditModal
              solution={selectedSolution}
              onClose={() => {
                setShowModal(false);
                setSelectedSolution(null);
              }}
              onUpdate={refreshData}
            />
          )}

          {showAnswerModal && (
            <AnswerModal
              problemId={selectedProblemId}
              solution={selectedSolution}
              onClose={() => {
                setShowAnswerModal(false);
                setSelectedProblemId(null);
                setSelectedSolution(null);
                refreshData();
              }}
              onUpdate={handleUpdate}
            />
          )}
        </main>
      </div>
    </div>
  );
}

export default ProblemSolution;
