import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { 
  $getSelection, 
  $isRangeSelection, 
  COMMAND_PRIORITY_LOW,
  createCommand,
  $createParagraphNode,
  $getRoot
} from 'lexical';
import { useEffect } from 'react';
import { $createBoxNode, BoxNode } from '../nodes/BoxNode';
import { BoxContentNode } from '../nodes/BoxContentNode';
import { mergeRegister } from '@lexical/utils';
import './BoxPlugin.css';

export const INSERT_BOX_COMMAND = createCommand('INSERT_BOX_COMMAND');

export default function BoxPlugin(): null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([BoxNode, BoxContentNode])) {
      throw new Error('BoxPlugin: BoxNode or BoxContentNode not registered on editor');
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_BOX_COMMAND,
        () => {
          editor.update(() => {
            const selection = $getSelection();
            
            if ($isRangeSelection(selection)) {
              const boxNode = $createBoxNode();
              const paragraph = $createParagraphNode();
              
              // BoxContentNode는 자동으로 생성됨
              boxNode.getFirstChild()?.append(paragraph);

              // 현재 선택된 노드의 위치에 Box 삽입
              const topLevelNode = selection.anchor.getNode().getTopLevelElement();
              if (topLevelNode) {
                topLevelNode.insertAfter(boxNode);
              } else {
                $getRoot().append(boxNode);
              }

              // Box 다음에 빈 paragraph 추가
              boxNode.insertAfter($createParagraphNode());

              // 커서를 Box 내부로 이동
              paragraph.select();
            }
          });
          return true;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor]);

  return null;
}