import React, { useState, useEffect } from 'react';

function CreateExamModal({ isOpen, onClose, onSubmit, treeList }) {
  const [formData, setFormData] = useState({
    tree_id: '',
    total_problems: 1,
    difficulty: 1
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      tree_id: parseInt(formData.tree_id),
      difficulty: formData.difficulty - 1
    });
  };

  return (
    isOpen && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-white p-6 rounded-lg w-96">
          <h2 className="text-xl font-bold mb-4">시험지 생성</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block mb-2">교육과정</label>
              <select 
                className="w-full border rounded p-2"
                value={formData.tree_id}
                onChange={(e) => setFormData({
                  ...formData, 
                  tree_id: parseInt(e.target.value) || ''
                })}
                required
              >
                <option value="">선택하세요</option>
                {treeList
                  .filter(tree => tree.is_closed)
                  .map((tree) => (
                    <option key={tree.tree_id} value={tree.tree_id}>
                      {tree.tree_name} ({tree.edu_ver})
                    </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-2">문제 수 (1-100)</label>
              <input
                type="number"
                min="1"
                max="100"
                className="w-full border rounded p-2"
                value={formData.total_problems}
                onChange={(e) => setFormData({...formData, total_problems: parseInt(e.target.value)})}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">난이도 (1.0-5.0)</label>
              <input
                type="number"
                step="0.1"
                min="1"
                max="5"
                className="w-full border rounded p-2"
                value={formData.difficulty}
                onChange={(e) => setFormData({...formData, difficulty: parseFloat(e.target.value)})}
                required
              />
            </div>
            <div className="flex justify-end gap-2">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 bg-gray-200 rounded"
              >
                취소
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-indigo-500 text-white rounded"
              >
                생성
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  );
}

export default CreateExamModal; 