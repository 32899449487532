import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { useAuth } from '../context/AuthContext';
import ProblemSelectModal from '../components/TestRegister/ProblemSelectModal';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

function TestRegister() {
  const { tree_id } = useParams();
  const navigate = useNavigate();
  const { getAccessToken, handleApiError } = useAuth();
  
  // 기본 state 선언
  const [treeData, setTreeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showProblemModal, setShowProblemModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedStep, setSelectedStep] = useState(null);
  const [visibleDepth, setVisibleDepth] = useState(4);
  const [selectedPathInfo, setSelectedPathInfo] = useState(null);

  // fetchWithTokenRefresh 함수를 먼저 정의
  const fetchWithTokenRefresh = useCallback(async (endpoint, options = {}) => {
    try {
      const accessToken = await getAccessToken();
      const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        ...options,
        headers: {
          ...options.headers,
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      // 응답을 JSON으로 변환
      const data = await response.json();
      
      // 409를 포함한 모든 응답을 정상적으로 반환
      return data;

    } catch (error) {
      handleApiError(error);
      throw error;
    }
  }, [getAccessToken, handleApiError]);

  // organizeDataByPathId 함수 정의
  const organizeDataByPathId = useCallback((treeData) => {
    if (!treeData || !treeData.depth1 || !treeData.depth2 || !treeData.depth3 || !treeData.depth4) return [];

    const organizedData = [];
    let prevD1 = null;
    let prevD2 = null;
    let prevD3 = null;
    let prevD4 = null;

    // depth1 데이터 순회
    treeData.depth1['Y-axis'].forEach(d1Item => {
      const d1PathId = d1Item[2];
      
      // depth2 항목들 찾기
      const d2Items = treeData.depth2['Y-axis'].filter(d2 => 
        d2[2].startsWith(d1PathId + '/')
      );

      d2Items.forEach(d2Item => {
        const d2PathId = d2Item[2];

        // depth3 항목들 찾기
        const d3Items = treeData.depth3['Y-axis'].filter(d3 => 
          d3[2].startsWith(d2PathId + '/')
        );

        d3Items.forEach(d3Item => {
          const d3PathId = d3Item[2];

          // depth4 항목들 찾기
          const d4Items = treeData.depth4['Y-axis'].filter(d4 => 
            d4[2].startsWith(d3PathId + '/')
          );

          d4Items.forEach(d4Item => {
            // 이전 값과 비교하여 같으면 빈 문자열, 다르면 새 값 할당
            const currentD1 = prevD1 === d1Item[1] ? '' : d1Item[1];
            const currentD2 = prevD2 === d2Item[1] ? '' : d2Item[1];
            const currentD3 = prevD3 === d3Item[1] ? '' : d3Item[1];
            const currentD4 = prevD4 === d4Item[1] ? '' : d4Item[1];

            organizedData.push({
              depth1: currentD1,
              depth2: currentD2,
              depth3: currentD3,
              depth4: currentD4,
              pathId: d4Item[2],
              steps: Array(5).fill('')
            });

            // 현재 값을 이전 값으로 저장
            prevD1 = d1Item[1];
            prevD2 = d2Item[1];
            prevD3 = d3Item[1];
            prevD4 = d4Item[1];
          });
        });
      });
    });

    return organizedData;
  }, []);

  const aggregateStepNumbers = useCallback((data, depth) => {
    const problems = treeData?.problems?.field_problems || {};
    const depthKey = `d${depth}_y_axis`;
    
    return data.map(row => {
      const pathParts = row.pathId.split('/');
      const currentPathId = pathParts.slice(0, depth + 1).join('/');
      
      // 해당 경로의 문제 수 배열 가져오기
      const stepCounts = problems[depthKey]?.[currentPathId] || Array(5).fill(0);
      
      return {
        ...row,
        steps: stepCounts
      };
    });
  }, [treeData]);

  // getFilteredData 수를 나중에 정의
  const getFilteredData = useCallback(() => {
    if (!treeData || !treeData.depth1 || !treeData.depth2 || !treeData.depth3 || !treeData.depth4) return [];
    
    // 현재 depth에 맞는 데이터 구성
    const uniqueRows = new Map();
    let prevD1 = '', prevD2 = '', prevD3 = '', prevD4 = '';
    
    // treeData의 현재 depth에 해당하는 데이터 사용
    const currentDepthData = {
      1: treeData.depth1['Y-axis'],
      2: treeData.depth2['Y-axis'],
      3: treeData.depth3['Y-axis'],
      4: treeData.depth4['Y-axis']
    }[visibleDepth];

    if (!currentDepthData) return [];

    // 현재 depth의 데이터를 기준으로 행 구성
    currentDepthData.forEach(item => {
      const pathParts = item[2].split('/');
      const currentDepthPath = pathParts.slice(0, visibleDepth + 1).join('/');
      
      if (!uniqueRows.has(currentDepthPath)) {
        const row = {
          pathId: currentDepthPath,
          steps: Array(5).fill(0)
        };

        // 각 depth별 데이터 찾아서 추가
        if (visibleDepth >= 1) {
          const depth1Path = pathParts.slice(0, 2).join('/');
          const depth1Item = treeData.depth1['Y-axis'].find(d1 => d1[2] === depth1Path);
          row.depth1 = depth1Item && depth1Item[1] !== prevD1 ? depth1Item[1] : '';
          prevD1 = depth1Item ? depth1Item[1] : prevD1;
        }
        
        if (visibleDepth >= 2) {
          const depth2Path = pathParts.slice(0, 3).join('/');
          const depth2Item = treeData.depth2['Y-axis'].find(d2 => d2[2] === depth2Path);
          row.depth2 = depth2Item && depth2Item[1] !== prevD2 ? depth2Item[1] : '';
          prevD2 = depth2Item ? depth2Item[1] : prevD2;
        }
        
        if (visibleDepth >= 3) {
          const depth3Path = pathParts.slice(0, 4).join('/');
          const depth3Item = treeData.depth3['Y-axis'].find(d3 => d3[2] === depth3Path);
          row.depth3 = depth3Item && depth3Item[1] !== prevD3 ? depth3Item[1] : '';
          prevD3 = depth3Item ? depth3Item[1] : prevD3;
        }
        
        if (visibleDepth >= 4) {
          const depth4Path = pathParts.slice(0, 5).join('/');
          const depth4Item = treeData.depth4['Y-axis'].find(d4 => d4[2] === depth4Path);
          row.depth4 = depth4Item && depth4Item[1] !== prevD4 ? depth4Item[1] : '';
          prevD4 = depth4Item ? depth4Item[1] : prevD4;
        }
        
        if (visibleDepth >= 5) {
          row.depth5 = item[1];
        }

        uniqueRows.set(currentDepthPath, row);
      }
    });

    const filteredData = Array.from(uniqueRows.values());
    
    // pathId 기준으로 정렬
    const sortedData = filteredData.sort((a, b) => {
      const partsA = a.pathId.split('/').map(Number);
      const partsB = b.pathId.split('/').map(Number);
      
      for (let i = 0; i < partsA.length; i++) {
        if (partsA[i] !== partsB[i]) {
          return partsA[i] - partsB[i];
        }
      }
      return 0;
    });

    // 문제 수 합산 적용
    return aggregateStepNumbers(sortedData, visibleDepth);
  }, [treeData, visibleDepth, aggregateStepNumbers]);

  // initializeTreeData 함수를 먼저 선언
  const initializeTreeData = useCallback(async (treeId) => {
    try {
      await fetchWithTokenRefresh(`/api/referencefields/${treeId}`, {
        method: 'POST'
      });
    } catch (error) {
      console.error('트리 초기화 실패:', error);
      throw error;
    }
  }, [fetchWithTokenRefresh]);

  // fetchTreeData 함수를 나중에 선언
  const fetchTreeData = useCallback(async (treeId) => {
    if (!treeId) return;
    
    try {
      setLoading(true);
      
      const treeResponse = await fetchWithTokenRefresh(`/api/referencefields/${treeId}`);
      
      if (treeResponse.status === 'error' && treeResponse.status_code === 409) {
        alert('트리를 먼저 확정해주세요.');
        navigate('/testset');
        return;
      }

      if (treeResponse.status === 'success') {
        // 문제 수 정보를 새로운 엔드포인트에서 가져오기
        const countResponse = await fetchWithTokenRefresh(`/api/referencefields/${treeId}/count/problems`);
        setTreeData({
          ...treeResponse.data,
          problems: {
            total_problems: countResponse.data.total_problems,
            field_problems: countResponse.data.field_problems
          }
        });
        setError('');
      }
    } catch (error) {
      console.error('데이터 불러오기 실패:', error);
      setTreeData(null);
      setError('데이터를 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  }, [fetchWithTokenRefresh, navigate]);

  // 선택된 트리 데이터 로드
  useEffect(() => {
    if (tree_id) {
      fetchTreeData(tree_id);
    }
  }, [tree_id, fetchTreeData]);

  // TruncatedText 컴포넌트 수정
  const TruncatedText = ({ text, pathId, showPathId = true }) => {
    // text가 없을 경우 빈 문자열로 처리
    const safeText = text || '';
    const formattedPathId = (pathId && showPathId) ? `(${pathId.replace(/\//g, '_')}) ` : '';
    const displayText = `${formattedPathId}${safeText}`;
    const truncated = displayText.length > 30 ? displayText.slice(0, 30) + '...' : displayText;
    
    return (
      <div className="relative group">
        <span>{truncated}</span>
        {displayText.length > 30 && (
          <div className="absolute hidden group-hover:block bg-gray-800 text-white p-2 rounded z-10 whitespace-nowrap">
            {displayText}
          </div>
        )}
      </div>
    );
  };

  // 숫자 변경 핸들러 수정
  const handleNumberClick = useCallback((rowIndex, stepIndex, address) => {
    if (!tree_id) return;
    
    // 선택된 주소의 경로 정보 구성
    const pathParts = address.split('/');
    const depth1Item = treeData.depth1['Y-axis'].find(d1 => d1[2] === `${pathParts[0]}/${pathParts[1]}`);
    const depth2Item = treeData.depth2['Y-axis'].find(d2 => d2[2] === `${pathParts[0]}/${pathParts[1]}/${pathParts[2]}`);
    const depth3Item = treeData.depth3['Y-axis'].find(d3 => d3[2] === `${pathParts[0]}/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}`);
    const depth4Item = treeData.depth4['Y-axis'].find(d4 => d4[2] === `${pathParts[0]}/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}/${pathParts[4]}`);

    const pathInfo = {
      depth1: {
        name: depth1Item[1],
        path_id: depth1Item[2]
      },
      depth2: {
        name: depth2Item[1],
        path_id: depth2Item[2]
      },
      depth3: {
        name: depth3Item[1],
        path_id: depth3Item[2]
      },
      depth4: {
        name: depth4Item[1],
        path_id: depth4Item[2]
      }
    };

    setSelectedPathInfo(pathInfo);
    setSelectedAddress(address);
    setSelectedStep(stepIndex);
    setShowProblemModal(true);
  }, [tree_id, treeData]);

  // 문제 선택 핸들러
  const handleProblemSelect = async (problemId) => {
    try {
      const response = await fetchWithTokenRefresh(`/api/referencefields/${tree_id}/problems`, {
        method: 'POST',
        body: JSON.stringify({
          problem_id: problemId,
          address: selectedAddress,
          step: selectedStep + 1
        })
      });

      if (response.status === 'success') {
        alert('문제가 성공적으로 등록되었습니다.');
      }
    } catch (error) {
      console.error('문제 등록 실패:', error);
      alert('문제 등록에 실패했습니다.');
    }
  };

  // NumberCell 컴포넌트 수정
  const NumberCell = ({ number, onClick, isClickable }) => (
    <td className="px-6 whitespace-nowrap text-xs">
      <button 
        className={`w-full h-8 rounded ${
          number === 0 ? 'bg-gray-200' : 'bg-blue-500 text-white'
        } hover:bg-blue-600 transition-colors ${
          !isClickable ? 'cursor-not-allowed opacity-70' : ''
        }`}
        onClick={isClickable ? onClick : undefined}
        disabled={!isClickable}
      >
        {number}
      </button>
    </td>
  );

  // 문제 등록 함수 수정
  const registerProblem = async (problemId, address, step) => {
    try {
      const response = await fetchWithTokenRefresh(`/api/referencefields/${tree_id}/problems`, {
        method: 'POST',
        body: JSON.stringify({
          problem_id: problemId,
          address: `${address}`,
          step: step + 1
        })
      });

      if (response && response.status === 'success') {
        alert('문제가 성공적으로 등록되었습니다.');
        setShowProblemModal(false);
        
        // 문제 정보만 새로 받아오기
        const problemsResponse = await fetchWithTokenRefresh(`/api/referencefields/${tree_id}/problems`);
        if (problemsResponse && problemsResponse.status === 'success') {
          setTreeData(prevData => ({
            ...prevData,
            problems: problemsResponse.data
          }));
        }
      }
    } catch (error) {
      console.error('문제 등록 실패:', error);
      alert('문제 등록에 실패했습니다.');
    }
  };

  // DepthButton 컴포넌트 수정
  const DepthButton = ({ depth, label, currentDepth, onClick }) => (
    <button
      className={`px-4 py-2 mr-2 rounded ${
        depth <= currentDepth 
          ? 'bg-[#231F20] text-white' 
          : 'bg-gray-200 text-gray-700'
      } hover:bg-[#231F20] hover:text-white transition-colors`}
      onClick={() => onClick(depth)}
    >
      {label}
    </button>
  );

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
          <div className="container mx-auto px-6 py-8">
            <nav className="mb-2">
              <div className="flex items-center text-sm text-gray-600">
                <Link to="/testset" className="hover:text-[#4ABC85]">문제집 구성</Link>
                <span className="mx-2 text-gray-500">&gt;</span>
                <span className="text-[#4ABC85]">문제 등록</span>
              </div>
            </nav>
            
            <h1 className="text-2xl font-bold text-gray-800 mb-6 flex items-center">
              {treeData?.field_info?.tree_name || ''}
              <span className="ml-2 text-sm text-gray-500">
                {treeData?.field_info?.edu_ver || ''}
              </span>
            </h1>

            {loading ? (
              <div className="text-center py-4">로딩 중...</div>
            ) : treeData ? (
              <div className="bg-white rounded-lg shadow overflow-x-auto">
                <div className="p-4 border-b flex justify-between items-center">
                  <div>
                    <DepthButton depth={1} label="대단원" currentDepth={visibleDepth} onClick={setVisibleDepth} />
                    <DepthButton depth={2} label="소단원" currentDepth={visibleDepth} onClick={setVisibleDepth} />
                    <DepthButton depth={3} label="대유형" currentDepth={visibleDepth} onClick={setVisibleDepth} />
                    <DepthButton depth={4} label="중유형" currentDepth={visibleDepth} onClick={setVisibleDepth} />
                  </div>
                  <div className="text-sm font-medium text-gray-600">
                    총 문제 : {treeData?.problems?.total_problems || 0}
                  </div>
                </div>
                
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {visibleDepth >= 1 && <th className="px-6 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">대단원</th>}
                      {visibleDepth >= 2 && <th className="px-6 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">소단원</th>}
                      {visibleDepth >= 3 && <th className="px-6 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">대유형</th>}
                      {visibleDepth >= 4 && <th className="px-6 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">중유형</th>}
                      {visibleDepth >= 5 && <th className="px-6 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">소유형</th>}
                      <th className="px-6 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">Lv 1</th>
                      <th className="px-6 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">Lv 2</th>
                      <th className="px-6 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">Lv 3</th>
                      <th className="px-6 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">Lv 4</th>
                      <th className="px-6 text-center text-xs font-bold text-gray-500 uppercase tracking-wider">Lv 5</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {getFilteredData().map((row, rowIndex) => (
                      <tr key={rowIndex} className="hover:bg-gray-50">
                        {visibleDepth >= 1 && (
                          <td className="px-6 whitespace-nowrap text-xs">
                            <TruncatedText 
                              text={row.depth1 || ''} 
                              pathId={row.pathId.split('/').slice(0, 2).join('/')}
                              showPathId={row.depth1 !== ''}
                            />
                          </td>
                        )}
                        {visibleDepth >= 2 && (
                          <td className="px-6 whitespace-nowrap text-xs">
                            <TruncatedText 
                              text={row.depth2 || ''} 
                              pathId={row.pathId.split('/').slice(0, 3).join('/')}
                              showPathId={row.depth2 !== ''}
                            />
                          </td>
                        )}
                        {visibleDepth >= 3 && (
                          <td className="px-6 whitespace-nowrap text-xs">
                            <TruncatedText 
                              text={row.depth3 || ''} 
                              pathId={row.pathId.split('/').slice(0, 4).join('/')}
                              showPathId={row.depth3 !== ''}
                            />
                          </td>
                        )}
                        {visibleDepth >= 4 && (
                          <td className="px-6 whitespace-nowrap text-xs">
                            <TruncatedText 
                              text={row.depth4 || ''} 
                              pathId={row.pathId.split('/').slice(0, 5).join('/')}
                              showPathId={row.depth4 !== ''}
                            />
                          </td>
                        )}
                        {visibleDepth >= 5 && (
                          <td className="px-6 whitespace-nowrap text-xs">
                            <TruncatedText text={row.depth5 || ''} />
                          </td>
                        )}
                        {[0, 1, 2, 3, 4].map((stepIndex) => (
                          <NumberCell 
                            key={stepIndex}
                            number={row.steps[stepIndex]}
                            onClick={() => handleNumberClick(rowIndex, stepIndex, row.pathId)}
                            isClickable={visibleDepth >= 4}
                          />
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center py-4">데이터를 불러올 수 없습니다.</div>
            )}
          </div>
        </main>
      </div>
      {showProblemModal && (
        <ProblemSelectModal
          onClose={() => setShowProblemModal(false)}
          selectedTree={tree_id}
          selectedAddress={selectedAddress}
          selectedStep={selectedStep}
          pathInfo={selectedPathInfo}
          fetchTreeData={fetchTreeData}
          fetchWithTokenRefresh={fetchWithTokenRefresh}
          handleProblemSelect={handleProblemSelect}
        />
      )}
    </div>
  );
}

export default TestRegister;