import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { useAuth } from '../context/AuthContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

function TestResult() {
  const { getAccessToken, handleApiError } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { exam_id } = useParams();
  const [treeData, setTreeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [visibleDepth, setVisibleDepth] = useState(4);
  const [examInfo, setExamInfo] = useState(null);
  const [checkedItems, setCheckedItems] = useState({});

  const colors = {
    100: 'bg-[#F9344B]',  // 빨간색
    101: 'bg-[#F9344B] opacity-10',  // 빨간색 10% 투명도
    200: 'bg-[#4ABC85]',  // 초록색
    201: 'bg-[#4ABC85] opacity-10',  // 초록색 10% 투명도
    500: 'bg-[#FAEECE]',      // 빨간색 + 초록색
    0: 'after:content-["×"]'  // x마크
  };

  // organizeDataByPathId 함수를 먼저 선언
  const organizeDataByPathId = useCallback((treeData) => {
    if (!treeData) return [];
    

    const organizedData = [];
    let currentD1 = null;
    let currentD2 = null;
    let currentD3 = null;
    let currentD4 = null;

    // depth1 데이터 순회
    (treeData.d1_y_axis || []).forEach(d1Item => {
      const d1PathId = d1Item[2];
      currentD1 = d1Item[1];
      const d1Steps = d1Item[3] || Array(5).fill('');  // depth1의 steps
      
      // depth2 항목들 찾기
      const d2Items = (treeData.d2_y_axis || []).filter(d2 => 
        d2[2].startsWith(d1PathId + '/')
      );

      d2Items.forEach(d2Item => {
        const d2PathId = d2Item[2];
        currentD2 = d2Item[1];
        const d2Steps = d2Item[3] || Array(5).fill('');  // depth2의 steps

        // depth3 항목들 찾기
        const d3Items = (treeData.d3_y_axis || []).filter(d3 => 
          d3[2].startsWith(d2PathId + '/')
        );

        d3Items.forEach(d3Item => {
          const d3PathId = d3Item[2];
          currentD3 = d3Item[1];
          const d3Steps = d3Item[3] || Array(5).fill('');  // depth3의 steps

          // depth4 항목들 찾기
          const d4Items = (treeData.d4_y_axis || []).filter(d4 => 
            d4[2].startsWith(d3PathId + '/')
          );

          d4Items.forEach(d4Item => {
            const d4PathId = d4Item[2];
            currentD4 = d4Item[1];
            const d4Steps = d4Item[3] || Array(5).fill('');  // depth4의 steps

            // depth5 항목들 찾기
            const d5Items = (treeData.d5_y_axis || []).filter(d5 => 
              d5[2].startsWith(d4PathId + '/')
            );

            d5Items.forEach(d5Item => {
              organizedData.push({
                depth1: currentD1,
                depth2: currentD2,
                depth3: currentD3,
                depth4: currentD4,
                depth5: d5Item[1],
                pathId: d5Item[2],
                steps: {
                  depth1: d1Steps,
                  depth2: d2Steps,
                  depth3: d3Steps,
                  depth4: d4Steps,
                  depth5: d5Item[3] || Array(5).fill('')
                }
              });

              // 다음 행부터는 상위 단원 정보를 비움
              currentD1 = '';
              currentD2 = '';
              currentD3 = '';
              currentD4 = '';
            });

            // depth5가 없는 경우
            if (d5Items.length === 0) {
              organizedData.push({
                depth1: currentD1,
                depth2: currentD2,
                depth3: currentD3,
                depth4: currentD4,
                depth5: '-',
                pathId: d4PathId,
                steps: {
                  depth1: d1Steps,
                  depth2: d2Steps,
                  depth3: d3Steps,
                  depth4: d4Steps,
                  depth5: Array(5).fill('')
                }
              });

              // 다음 행부터는 상위 단원 정보를 비움
              currentD1 = '';
              currentD2 = '';
              currentD3 = '';
              currentD4 = '';
            }
          });
        });
      });
    });

    // 반환 직전에 최종 가공된 데이터 확인
    // console.log('최종 가공된 데이터:', organizedData);
    return organizedData;
  }, []);

  // filterDataByDepth 함수 수정
  const filterDataByDepth = useCallback((data, depth) => {
    if (!Array.isArray(data)) return [];  // 배열이 아닌 경우 빈 배열 반환
    
    return data.filter(row => {
      switch(depth) {
        case 1:
          return row.depth1 !== '';
        case 2:
          return row.depth2 !== '' && row.depth2 !== '-';
        case 3:
          return row.depth3 !== '' && row.depth3 !== '-';
        case 4:
          return row.depth4 !== '' && row.depth4 !== '-';
        case 5:
          return row.depth5 !== '' && row.depth5 !== '-';
        default:
          return true;
      }
    });
  }, []);

  // useEffect 수정
  useEffect(() => {
    if (treeData?.field_info) {
      const organizedData = organizeDataByPathId(treeData.field_info);
      filterDataByDepth(organizedData, visibleDepth);
    }
  }, [treeData, visibleDepth, filterDataByDepth, organizeDataByPathId]);

  // useEffect(() => {
  //   if (treeData?.field_info) {
  //     const organizedData = organizeDataByPathId(treeData.field_info);
  //     console.log('Table PathID Structure:', organizedData.map(row => ({
  //       pathId: row.pathId,
  //       depth1: row.depth1 ? `depth1: ${row.depth1}` : null,
  //       depth2: row.depth2 ? `depth2: ${row.depth2}` : null,
  //       depth3: row.depth3 ? `depth3: ${row.depth3}` : null,
  //       depth4: row.depth4 ? `depth4: ${row.depth4}` : null,
  //       depth5: row.depth5 ? `depth5: ${row.depth5}` : null,
  //     })));
  //   }
  // }, [treeData]);

  const fetchWithTokenRefresh = useCallback(async (url, options = {}) => {
    try {
      const token = await getAccessToken();
      if (!token) {
        navigate('/signin');
        return null;
      }
      
      const response = await fetch(`${API_BASE_URL}${url}`, {
        ...options,
        headers: {
          ...options.headers,
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      const data = await response.json();
      
      if (data.status_code === 401 && data.message === 'Invalid or expired token') {
        handleApiError(new Error('Invalid or expired token'));
        return null;
      }

      return data;
    } catch (error) {
      console.error('API 호출 에러:', error);
      throw error;
    }
  }, [getAccessToken, navigate, handleApiError]);

  // 선택된 트리의 데이터 가져오기
  const fetchExamResult = useCallback(async (examId) => {
    if (!examId) return;
    
    try {
      setLoading(true);
      const response = await fetchWithTokenRefresh(`/api/resultmap?exam_id=${examId}`);
      
      if (response && response.data) {
        setTreeData(response.data);
        setExamInfo(response.data.exam_info);
        setError('');
      }
    } catch (error) {
      console.error('시험 결과 불러오기 실패:', error);
      setError('시험 결과를 불러오는데 실패했습니다.');
    } finally {
      setLoading(false);
    }
  }, [fetchWithTokenRefresh]);

  useEffect(() => {
    if (exam_id) {
      fetchExamResult(exam_id);
    }
  }, [exam_id, fetchExamResult]);

  // 텍스트 축약 컴포넌트
  const TruncatedText = ({ text }) => {
    const truncated = text.length > 10 ? text.slice(0, 10) + '...' : text;
    
    return (
      <div className="relative group">
        <span>{truncated}</span>
        {text.length > 5 && (
          <div className="absolute hidden group-hover:block bg-gray-800 text-white p-2 rounded z-10 whitespace-nowrap">
            {text}
          </div>
        )}
      </div>
    );
  };

  // ColorCell 컴포넌트 수정
  const ColorCell = ({ color, visibleDepth, steps }) => (
    <td className="px-6 whitespace-nowrap text-xs">
      <div 
        className={`w-full h-6 flex items-center justify-center ${colors[steps[`depth${visibleDepth}`][color]] || ''}`}
        style={{ minWidth: '30px' }}
      >
        {steps[`depth${visibleDepth}`][color] === 0 ? '×' : '\u00A0'}
      </div>
    </td>
  );

  // 버튼 컴포넌트
  const DepthButton = ({ depth, label, currentDepth, onClick }) => (
    <button
      className={`px-4 py-2 mr-2 rounded ${
        depth <= currentDepth 
          ? 'bg-[#231F20] text-white' 
          : 'bg-gray-200 text-gray-700'
      } hover:bg-[#A3A1A2] hover:text-white transition-colors`}
      onClick={() => onClick(depth)}
    >
      {label}
    </button>
  );

  // 이전 경로에서 student_id를 추출하는 함수
  const getStudentIdFromPreviousPath = useCallback(() => {
    const pathSegments = location.pathname.split('/');
    const previousPath = document.referrer;
    
    // URL에서 student_id를 추출하는 정규식
    const studentIdMatch = previousPath.match(/\/student\/(\d+)/);
    return studentIdMatch ? studentIdMatch[1] : null;
  }, [location]);

  // 시험지 관리로 돌아가는 함수
  const handleBackToTestPapers = useCallback(() => {
    const studentId = location.state?.studentId;
    if (studentId) {
      navigate(`/test-papers/student/${studentId}`);
    } else {
      navigate('/test-papers'); // fallback
    }
  }, [navigate, location.state]);

  // 체크박스 핸들러 추가
  const handleCheck = useCallback((pathId, depth) => {
    // depth + 1로 경로 자르기
    const targetPath = pathId.split('/').slice(0, depth + 1).join('/');
    console.log('Clicked path:', targetPath);
  }, []);

  // 체크박스 컴포넌트 추가
  const Checkbox = ({ pathId, depth, text }) => {
    const isChecked = checkedItems[pathId] || false;
    
    return text && text !== '-' ? (
      <div className="flex items-center">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => handleCheck(pathId, depth)}
          className="mr-2 h-4 w-4 text-[#4ABC85] border-gray-300 rounded focus:ring-[#4ABC85]"
        />
        <TruncatedText text={text} />
      </div>
    ) : null;
  };

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
          <div className="container mx-auto px-6 py-8">
            <nav className="mb-6">
              <div className="flex items-center text-sm text-gray-600">
                <Link to="/students" className="hover:text-[#4ABC85]">학생관리</Link>
                <span className="mx-2">&gt;</span>
                <button 
                  onClick={handleBackToTestPapers}
                  className="hover:text-[#4ABC85]"
                >
                  시험지 관리
                </button>
                <span className="mx-2">&gt;</span>
                <span className="text-[#4ABC85]">시험 결과</span>
              </div>
            </nav>

            {/* <h3 className="text-gray-700 text-3xl font-medium mb-6">시험 결과</h3> */}
            {error && <div className="bg-red-100 text-red-700 p-3 mb-4 rounded">{error}</div>}
            
            {loading ? (
              <div className="text-center py-4">로딩 중...</div>
            ) : treeData ? (
              <div className="bg-white rounded-lg shadow overflow-x-auto">
                <div className="p-4 border-b">
                  <DepthButton depth={1} label="대단원" currentDepth={visibleDepth} onClick={setVisibleDepth} />
                  <DepthButton depth={2} label="소단원" currentDepth={visibleDepth} onClick={setVisibleDepth} />
                  <DepthButton depth={3} label="대유형" currentDepth={visibleDepth} onClick={setVisibleDepth} />
                  <DepthButton depth={4} label="중유형" currentDepth={visibleDepth} onClick={setVisibleDepth} />
                  {/* <button
                    className="px-4 py-2 mr-2 rounded bg-gray-100 text-gray-400 cursor-not-allowed"
                    disabled
                  >
                    소유형
                  </button> */}
                </div>
                
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {visibleDepth >= 1 && <th className="px-6  text-center text-xs font-bold text-gray-500 uppercase tracking-wider">대단원</th>}
                      {visibleDepth >= 2 && <th className="px-6  text-center text-xs font-bold text-gray-500 uppercase tracking-wider">소단원</th>}
                      {visibleDepth >= 3 && <th className="px-6  text-center text-xs font-bold text-gray-500 uppercase tracking-wider">대유형</th>}
                      {visibleDepth >= 4 && <th className="px-6  text-center text-xs font-bold text-gray-500 uppercase tracking-wider">중유형</th>}
                      {visibleDepth >= 5 && <th className="px-6  text-center text-xs font-bold text-gray-500 uppercase tracking-wider">소유형</th>}
                      <th className="px-6  text-center text-xs font-bold text-gray-500 uppercase tracking-wider">Lv 1</th>
                      <th className="px-6  text-center text-xs font-bold text-gray-500 uppercase tracking-wider">Lv 2</th>
                      <th className="px-6  text-center text-xs font-bold text-gray-500 uppercase tracking-wider">Lv 3</th>
                      <th className="px-6  text-center text-xs font-bold text-gray-500 uppercase tracking-wider">Lv 4</th>
                      <th className="px-6  text-center text-xs font-bold text-gray-500 uppercase tracking-wider">Lv 5</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filterDataByDepth(organizeDataByPathId(treeData?.field_info || {}), visibleDepth).map((row, rowIndex) => (
                      <tr key={rowIndex} className="hover:bg-gray-50">
                        {visibleDepth >= 1 && (
                          <td className="px-6 whitespace-nowrap text-xs">
                            <Checkbox 
                              pathId={row.pathId} 
                              depth={1} 
                              text={row.depth1}
                            />
                          </td>
                        )}
                        {visibleDepth >= 2 && (
                          <td className="px-6 whitespace-nowrap text-xs">
                            <Checkbox 
                              pathId={row.pathId} 
                              depth={2} 
                              text={row.depth2}
                            />
                          </td>
                        )}
                        {visibleDepth >= 3 && (
                          <td className="px-6 whitespace-nowrap text-xs">
                            <Checkbox 
                              pathId={row.pathId} 
                              depth={3} 
                              text={row.depth3}
                            />
                          </td>
                        )}
                        {visibleDepth >= 4 && (
                          <td className="px-6 whitespace-nowrap text-xs">
                            <Checkbox 
                              pathId={row.pathId} 
                              depth={4} 
                              text={row.depth4}
                            />
                          </td>
                        )}
                        {visibleDepth >= 5 && (
                          <td className="px-6 whitespace-nowrap text-xs">
                            <Checkbox 
                              pathId={row.pathId} 
                              depth={5} 
                              text={row.depth5}
                            />
                          </td>
                        )}
                        {[0, 1, 2, 3, 4].map((stepIndex) => (
                          <ColorCell 
                            key={stepIndex}
                            color={stepIndex}
                            visibleDepth={visibleDepth}
                            steps={row.steps}
                          />
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="text-center py-4">데이터를 불러올 수 없습니다.</div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default TestResult;