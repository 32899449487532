import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';

// 스타일 상수 추가
const buttonStyles = {
  base: "px-4 py-1 rounded-md w-20 text-center cursor-pointer transition-colors",
  correct: {
    active: "bg-green-500 text-white",
    inactive: "bg-green-100 text-green-600 hover:bg-green-200"
  },
  incorrect: {
    active: "bg-red-500 text-white",
    inactive: "bg-red-100 text-red-600 hover:bg-red-200"
  }
};

function ResultInputModal({ isOpen, onClose, examId, onRefresh, isConfirmMode }) {
  const { getAccessToken } = useAuth();
  const [problems, setProblems] = useState([]);
  const [results, setResults] = useState({});
  const [isAlreadySubmitted, setIsAlreadySubmitted] = useState(false);
  const [submittedResults, setSubmittedResults] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [isConfirming, setIsConfirming] = useState(false);
  const [solutions, setSolutions] = useState({});

  useEffect(() => {
    if (isOpen && examId) {
      setError(null);
      setIsSubmitting(false);
      setIsAlreadySubmitted(false);
      setProblems([]);
      setResults({});
      setSubmittedResults([]);
      setSolutions({});
      fetchExamDetails();
    }
  }, [isOpen, examId]);

  const fetchSolutions = async (problemIds) => {
    try {
      const token = await getAccessToken();
      const queryParams = `problem_ids=${problemIds.join(',')}`;
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/solutions/list?${queryParams}`, {
        headers: { 'Authorization': token }
      });
      const data = await response.json();

      if (data.status === 'success') {
        const solutionsMap = {};
        data.data.forEach(problem => {
          if (problem.solutions.length > 0) {
            solutionsMap[problem.problem_id] = problem.solutions[0].solution_info.answer;
          }
        });
        setSolutions(solutionsMap);
      }
    } catch (error) {
      console.error('답안 조회 실패:', error);
    }
  };

  const fetchExamDetails = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/exam/alpha/${examId}`, {
        headers: { 'Authorization': token }
      });
      const data = await response.json();

      if (data.data?.problems_list) {
        setProblems(data.data.problems_list);
        const problemIds = data.data.problems_list.map(p => p.problem_id);
        await fetchSolutions(problemIds);

        if (!data.data?.result) {
          setResults({});
        }
      }

      if (data.data?.result != null) {
        setIsAlreadySubmitted(true);
        const resultResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/exam/alpha/${examId}/result`, {
          headers: { 'Authorization': token }
        });
        const resultData = await resultResponse.json();
        setSubmittedResults(resultData.data);
        const initialResults = {};
        resultData.data.forEach(item => {
          initialResults[item.problem_id] = item.result;
        });
        setResults(initialResults);
      }
    } catch (error) {
      console.error('시험 상세 정보 조회 실패:', error);
      setError('시험 정보를 불러오는데 실패했습니다.');
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const token = await getAccessToken();
      const examResultList = Object.entries(results).map(([problem_id, result]) => ({
        problem_id: parseInt(problem_id),
        result
      }));

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/exam/alpha/${examId}/result`, {
        method: 'PATCH',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ exam_result_list: examResultList })
      });

      if (response.ok) {
        onRefresh?.();
        onClose();
      }
    } catch (error) {
      console.error('결과 제출 실패:', error);
      setError('결과 제출에 실패했습니다.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleConfirmResults = async () => {
    const isConfirmed = window.confirm('결과를 확정하시겠습니까? 확정 후에는 수정이 불가능합니다.');
    if (!isConfirmed) return;

    try {
      setIsConfirming(true);

      // 1. 먼저 결과 수정 API 호출
      const examResultList = Object.entries(results).map(([problem_id, result]) => ({
        problem_id: parseInt(problem_id),
        result
      }));

      const token = await getAccessToken();
      const updateResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/exam/alpha/${examId}/result`, {
        method: 'PATCH',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ exam_result_list: examResultList })
      });

      if (!updateResponse.ok) {
        throw new Error('결과 수정에 실패했습니다.');
      }

      // 2. 결과 확정 API 호출
      const confirmResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/exam/alpha/${examId}/close`, {
        method: 'POST',
        headers: {
          'Authorization': token
        }
      });

      if (!confirmResponse.ok) {
        const errorData = await confirmResponse.json();
        throw new Error(errorData.message || '결과 확정에 실패했습니다.');
      }

      onRefresh?.();
      onClose();
    } catch (error) {
      console.error('결과 확정 실패:', error);
      setError(error.message);
    } finally {
      setIsConfirming(false);
    }
  };

  // 모든 문제가 선택되었는지 확인하는 함수
  const isAllProblemsAnswered = () => {
    return problems.every(problem => results[problem.problem_id] !== undefined);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg w-[800px]">
        <h2 className="text-xl font-bold mb-4">
          {isConfirmMode ? '결과 확정' : (isAlreadySubmitted ? '결과 수정' : '문제 결과 입력')}
        </h2>

        {error && (
          <div className="mb-4 p-2 bg-red-100 text-red-700 rounded">
            {error}
          </div>
        )}

        <div className="max-h-[600px] overflow-y-auto">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-2 text-center w-20">번호</th>
                <th className="px-4 py-2 text-center w-24">문제 ID</th>
                <th className="px-4 py-2 text-center w-20">Level</th>
                <th className="px-4 py-2 text-center w-48">채점</th>
                <th className="px-4 py-2 text-center flex-1">정답</th>
              </tr>
            </thead>
            <tbody>
              {problems.map((problem, index) => (
                <tr key={problem.problem_id} className="border-b">
                  <td className="px-4 py-2 text-center w-20">{String(index + 1).padStart(2, '0')}</td>
                  <td className="px-4 py-2 text-center w-24">{problem.problem_id}</td>
                  <td className="px-4 py-2 text-center w-20">{problem.step}</td>

                  <td className="px-4 py-2 w-48">
                    <div className="flex space-x-2 justify-center">
                      <button
                        type="button"
                        className={`${buttonStyles.base} ${results[problem.problem_id] === true
                            ? buttonStyles.correct.active
                            : buttonStyles.correct.inactive
                          }`}
                        onClick={() => setResults(prev => ({
                          ...prev,
                          [problem.problem_id]: true
                        }))}
                      >
                        맞음
                      </button>
                      <button
                        type="button"
                        className={`${buttonStyles.base} ${results[problem.problem_id] === false
                            ? buttonStyles.incorrect.active
                            : buttonStyles.incorrect.inactive
                          }`}
                        onClick={() => setResults(prev => ({
                          ...prev,
                          [problem.problem_id]: false
                        }))}
                      >
                        틀림
                      </button>
                    </div>
                  </td>
                  <td className="px-4 py-2 text-center min-w-[300px]">
                    {solutions[problem.problem_id] && (
                      <InlineMath math={solutions[problem.problem_id]} />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-4 flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
            disabled={isSubmitting || isConfirming}
          >
            취소
          </button>
          <button
            onClick={isConfirmMode ? handleConfirmResults : handleSubmit}
            disabled={!isAllProblemsAnswered() || isSubmitting || isConfirming}
            className={`px-4 py-2 text-white rounded ${isAllProblemsAnswered() && !isSubmitting && !isConfirming
                ? 'bg-[#4ABC85] hover:bg-[#3da975]'
                : 'bg-gray-400 cursor-not-allowed'
              }`}
          >
            {isSubmitting || isConfirming ? '처리 중...' : isConfirmMode ? '확정' : '저장'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResultInputModal; 