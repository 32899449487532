import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import StudentModal from '../components/StudentManagement/StudentModal';
import { useAuth } from '../context/AuthContext';
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';
import { PenSquare, UserPlus } from 'lucide-react';

function StudentManagement() {
  const { page_number } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState('add');
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessToken, userId, userType } = useAuth();
  const [page, setPage] = useState(parseInt(page_number) || 1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [teachers, setTeachers] = useState([]);
  const [showTeacherModal, setShowTeacherModal] = useState(false);
  const [selectedTeacherId, setSelectedTeacherId] = useState('');
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedStudentDetail, setSelectedStudentDetail] = useState(null);
  const searchParams = new URLSearchParams(location.search);
  const [filters, setFilters] = useState({
    student_name: searchParams.get('student_name') || '',
    in_charge_teacher: searchParams.get('in_charge_teacher') || '',
    lecture: searchParams.get('lecture') || ''
  });
  const [isFilterMode, setIsFilterMode] = useState(
    searchParams.get('student_name') || 
    searchParams.get('in_charge_teacher') || 
    searchParams.get('lecture')
  );
  const [showResetConfirm, setShowResetConfirm] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteEmail, setDeleteEmail] = useState('');
  const [deleteError, setDeleteError] = useState('');

  const fetchStudents = async (pageNum = 1, useFilter = false) => {
    try {
      setIsLoading(true);
      const token = await getAccessToken();
      if (!token) {
        navigate('/signin');
        return;
      }

      let url;
      const params = new URLSearchParams({
        page: pageNum,
        per_page: 20
      });

      if (useFilter) {
        if (filters.student_name) params.append('student_name', filters.student_name);
        if (filters.in_charge_teacher) params.append('in_charge_teacher', filters.in_charge_teacher);
        if (filters.lecture) params.append('lecture', filters.lecture);
      }

      if (userType === 'manager') {
        url = `${process.env.REACT_APP_API_BASE_URL}/api/users/student?${params}`;
      } else if (userType === 'teacher') {
        params.append('teacher_id', userId);
        url = `${process.env.REACT_APP_API_BASE_URL}/api/users/student/allocate?${params}`;
      }

      const response = await fetch(url, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        }
      });

      if (response.status === 401) {
        navigate('/signin');
        return;
      }

      if (response.status === 404) {
        setStudents([]);
        setTotalPages(1);
        setHasMore(false);
        setPage(1);
        return;
      }

      if (!response.ok) {
        throw new Error('학생 목록을 불러오는데 실패했습니다.');
      }

      const result = await response.json();
      setStudents(result.data);
      setTotalPages(result.total_pages || 1);
      setHasMore(pageNum < result.total_pages);
      setPage(pageNum);
    } catch (error) {
      console.error('Error fetching students:', error);
      setStudents([]);
      setTotalPages(1);
      setHasMore(false);
      setPage(1);
    } finally {
      setIsLoading(false);
    }
  };

  const updateURL = (pageNum, filterData) => {
    const params = new URLSearchParams();
    
    if (filterData.student_name) params.append('student_name', filterData.student_name);
    if (filterData.in_charge_teacher) params.append('in_charge_teacher', filterData.in_charge_teacher);
    if (filterData.lecture) params.append('lecture', filterData.lecture);
    
    const queryString = params.toString();
    navigate(`/student/${pageNum}${queryString ? `?${queryString}` : ''}`);
  };

  const handleSearch = () => {
    setIsFilterMode(true);
    updateURL(1, filters);
    fetchStudents(1, true);
  };

  const handleReset = () => {
    setIsFilterMode(false);
    setFilters({
      student_name: '',
      in_charge_teacher: '',
      lecture: ''
    });
    navigate('/student/1');
    fetchStudents(1, false);
  };

  const handlePageChange = (newPage) => {
    updateURL(newPage, filters);
    fetchStudents(newPage, isFilterMode);
  };

  useEffect(() => {
    const checkAuthAndFetch = async () => {
      const token = await getAccessToken();
      if (!token) {
        navigate('/signin', { replace: true });
        return;
      }
      const pageNum = parseInt(page_number) || 1;
      setPage(pageNum);
      
      const hasFilters = searchParams.toString().length > 0;
      setIsFilterMode(hasFilters);
      
      setFilters({
        student_name: searchParams.get('student_name') || '',
        in_charge_teacher: searchParams.get('in_charge_teacher') || '',
        lecture: searchParams.get('lecture') || ''
      });
      
      fetchStudents(pageNum, hasFilters);
    };

    checkAuthAndFetch();
  }, [page_number, location.search]);

  const handleAddStudent = async (studentData) => {
    try {
      const token = await getAccessToken();
      if (!token) {
        navigate('/signin');
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/student`, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(studentData)
      });

      if (response.status === 401) {
        navigate('/signin');
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || '학생 추가에 실패했습니다.');
      }

      const result = await response.json();
      if (result.status === 'success') {
        await fetchStudents();
        return '학생이 성공적으로 추가되었습니다.';
      }
    } catch (error) {
      throw error;
    }
  };

  const handleEditStudent = async (studentData) => {
    try {
      const token = await getAccessToken();
      if (!token) {
        navigate('/signin');
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/student`, {
        method: 'PATCH',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          student_id: selectedStudent.student_id,
          ...studentData
        })
      });

      if (response.status === 401) {
        navigate('/signin');
        return;
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || '학생 정보 수정에 실패했습니다.');
      }

      const result = await response.json();
      if (result.status === 'success') {
        await fetchStudents(page, isFilterMode);
        setSelectedStudentDetail({
          ...selectedStudentDetail,
          ...studentData
        });
        setIsModalOpen(false);
        setShowDetailModal(true);
        return '학생 정보가 성공적으로 수정되었습니다.';
      }
    } catch (error) {
      throw error;
    }
  };

  const handleModalOpen = (mode, student = null, fieldName = null) => {
    setModalMode(mode);
    const studentData = {
      ...student,
      editField: fieldName,
      email: student?.student_email,
      phone_number: student?.mobile_number,
      parent_phone_number: student?.parent_mobile_number
    };
    setSelectedStudent(studentData);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedStudent(null);
    setModalMode('add');
    fetchStudents(page, isFilterMode);
    
    if (modalMode === 'edit') {
      setShowDetailModal(true);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisible = 5;
    let start = Math.max(1, page - Math.floor(maxVisible / 2));
    let end = Math.min(start + maxVisible - 1, totalPages);

    if (end - start + 1 < maxVisible) {
      start = Math.max(1, end - maxVisible + 1);
    }

    if (start > 1) {
      pageNumbers.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className="px-3 py-1 border rounded hover:bg-gray-100"
        >
          1
        </button>
      );
      if (start > 2) {
        pageNumbers.push(<span key="ellipsis1">...</span>);
      }
    }

    for (let i = start; i <= end; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 border rounded ${page === i ? 'bg-[#231F20] text-white' : 'hover:bg-gray-100'}`}
        >
          {i}
        </button>
      );
    }

    if (end < totalPages) {
      if (end < totalPages - 1) {
        pageNumbers.push(<span key="ellipsis2">...</span>);
      }
      pageNumbers.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className="px-3 py-1 border rounded hover:bg-gray-100"
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  const fetchTeachers = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/users/teacher?page=1&per_page=20`,
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
          }
        }
      );
      
      if (!response.ok) throw new Error('선생님 목록을 불러오는데 실패했습니다.');
      
      const result = await response.json();
      setTeachers(result.data);
    } catch (error) {
      console.error('Error fetching teachers:', error);
      alert(error.message);
    }
  };

  const handleAllocateTeacher = async (studentId, teacherId) => {
    try {
      const token = await getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/users/student/allocate`,
        {
          method: 'POST',
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            teacher_id: teacherId,
            student_id: studentId
          })
        }
      );

      if (!response.ok) throw new Error('선생님 할당에 실패했습니다.');

      await fetchStudents(page, isFilterMode);
      setShowTeacherModal(false);
      setSelectedTeacherId('');
      setShowDetailModal(true);
      
      const message = teacherId ? '선생님이 성공적으로 할당되었습니다.' : '선생님 할당이 삭제되었습니다.';
      alert(message);
    } catch (error) {
      console.error('Error allocating teacher:', error);
      alert(error.message);
    }
  };

  const handleTeacherModalClose = () => {
    setShowTeacherModal(false);
    setSelectedTeacherId('');
    setShowDetailModal(true);
    fetchStudents(page, isFilterMode);
  };

  const handleRowClick = (student) => {
    setSelectedStudentDetail(student);
    setShowDetailModal(true);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleResetPassword = async (studentId) => {
    try {
      const token = await getAccessToken();
      if (!token) {
        navigate('/signin');
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/auth/resetpw`, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ student_id: parseInt(studentId) })
      });

      if (!response.ok) {
        throw new Error('비밀번호 초기화에 실패했습니다.');
      }

      const result = await response.json();
      if (result.status === 'success') {
        return result.data.temp_pw;
      } else {
        throw new Error(result.message || '비밀번호 초기화에 실패했습니다.');
      }
    } catch (error) {
      throw error;
    }
  };

  const handleDelete = async () => {
    if (deleteEmail !== selectedStudentDetail.student_email) {
      setDeleteError('이메일이 일치하지 않습니다.');
      return;
    }

    try {
      const token = await getAccessToken();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/student`, {
        method: 'DELETE',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          student_id: parseInt(selectedStudentDetail.student_id)
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || '학생 삭제에 실패했습니다.');
      }

      alert('학생이 성공적으로 삭제되었습니다.');
      setShowDetailModal(false);
      setShowDeleteConfirm(false);
      setDeleteEmail('');
      fetchStudents(page, isFilterMode);
    } catch (err) {
      setDeleteError(err.message);
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
          <div className="container mx-auto px-6 py-8">
            <nav className="mb-6">
              <div className="flex items-center text-sm text-gray-600">
                <Link to="/students" className="hover:text-[#4ABC85]">학생관리</Link>
              </div>
            </nav>
            <div className="bg-white shadow-md rounded-lg p-6">
              <div className="mb-6 space-y-4">
                <div className="flex gap-4 justify-between">
                  <div className="flex gap-4">
                    <input
                      type="text"
                      name="student_name"
                      placeholder="학생 이름"
                      value={filters.student_name}
                      onChange={handleFilterChange}
                      onKeyDown={handleKeyPress}
                      className="border rounded p-2"
                    />
                    <input
                      type="text"
                      name="in_charge_teacher"
                      placeholder="담당 선생님"
                      value={filters.in_charge_teacher}
                      onChange={handleFilterChange}
                      onKeyDown={handleKeyPress}
                      className="border rounded p-2"
                    />
                    <input
                      type="text"
                      name="lecture"
                      placeholder="강의"
                      value={filters.lecture}
                      onChange={handleFilterChange}
                      onKeyDown={handleKeyPress}
                      className="border rounded p-2"
                    />
                    <button
                      onClick={handleSearch}
                      className="bg-[#231F20] text-white px-4 py-2 rounded hover:bg-[#A3A1A2]"
                    >
                      조회
                    </button>
                    <button
                      onClick={handleReset}
                      className="bg-[#D9D9D9] text-white px-4 py-2 rounded"
                    >
                      초기화
                    </button>
                  </div>
                </div>
              </div>

              {isLoading ? (
                <div className="text-center py-4">로딩 중...</div>
              ) : (
                <div className="relative">
                  <div className="overflow-x-auto shadow rounded-lg">
                    <table className="w-full" style={{ minWidth: '1300px' }}>
                      <thead>
                        <tr className="bg-[#231F20] text-white">
                          <th className="px-6 py-3 text-left whitespace-nowrap">학생 ID</th>
                          <th className="px-6 py-3 text-left whitespace-nowrap">학생 이름</th>
                          <th className="px-6 py-3 text-left whitespace-nowrap">이메일</th>
                          <th className="px-6 py-3 text-left whitespace-nowrap">학교</th>
                          <th className="px-6 py-3 text-left whitespace-nowrap">학년</th>
                          <th className="px-6 py-3 text-left whitespace-nowrap">담당 선생님</th>
                          <th className="px-6 py-3 text-left whitespace-nowrap">강의</th>
                          <th className="px-6 py-3 text-left whitespace-nowrap">관리</th>
                        </tr>
                      </thead>
                      <tbody>
                        {students.map((student) => (
                          <tr 
                            key={student.student_id} 
                            className="border-b cursor-pointer hover:bg-gray-50"
                            onClick={() => handleRowClick(student)}
                          >
                            <td className="px-6 py-2">{student.student_id}</td>
                            <td className="px-6 py-2 group relative">
                              {student.student_name}
                              {/* {userType === 'manager' && (
                                <PenSquare 
                                  className="w-4 h-4 text-[#4ABC85] hidden group-hover:inline-block absolute top-1/2 -translate-y-1/2 ml-2 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleModalOpen('edit', student, 'student_name');
                                  }}
                                />
                              )} */}
                            </td>
                            <td className="px-6 py-2">{student.student_email}</td>
                            <td className="px-6 py-2">{student.school_name}</td>
                            <td className="px-6 py-2">{student.school_grade}</td>
                            <td className="px-6 py-2">{student.in_charge_teacher}</td>
                            <td className="px-6 py-2">{student.lecture || '-'}</td>
                            <td className="px-6 py-2">
                              <div className="flex gap-2">
                                <button 
                                  className="bg-[#4ABC85] text-white px-3 py-1 rounded hover:bg-[#3da575]"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`/student/${student.student_id}/stamp`);
                                  }}
                                >
                                  Ky Test
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {!isLoading && students.length > 0 && (
                <div className="flex justify-center mt-4">
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => handlePageChange(page - 1)}
                      disabled={page === 1}
                      className="px-4 py-2 border rounded disabled:opacity-50"
                    >
                      이전
                    </button>
                    {renderPageNumbers()}
                    <button
                      onClick={() => handlePageChange(page + 1)}
                      disabled={!hasMore}
                      className="px-4 py-2 border rounded disabled:opacity-50"
                    >
                      다음
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>

      <StudentModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={modalMode === 'add' ? handleAddStudent : handleEditStudent}
        initialData={selectedStudent}
        mode={modalMode}
        selectedStudentDetail={selectedStudentDetail}
      />

      {showDetailModal && selectedStudentDetail && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={() => setShowDetailModal(false)}
        >
          <div 
            className="bg-white p-6 rounded-lg w-[500px]"
            onClick={e => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">
                {selectedStudentDetail.student_name} 학생 상세 정보
              </h2>
              <div className="flex gap-2">
                {userType === 'manager' && (
                  <>
                    <button
                      onClick={() => setShowResetConfirm(true)}
                      className="px-3 py-1 bg-[#F9344B] text-white rounded-lg text-sm"
                    >
                      비밀번호 리셋
                    </button>
                    <button
                      onClick={() => {
                        setShowDetailModal(false);
                        setSelectedStudent(selectedStudentDetail);
                        setShowTeacherModal(true);
                        fetchTeachers();
                      }}
                      className="px-3 py-1 bg-[#4ABC85] text-white rounded-lg text-sm"
                    >
                      선생님 배정
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="space-y-3">
              <div className="grid grid-cols-[1fr,2fr] gap-4">
                <div className="font-semibold flex items-center">이름:</div>
                <div className="flex items-center group">
                  {selectedStudentDetail.student_name}
                  {userType === 'manager' && (
                    <PenSquare 
                      className="w-4 h-4 text-[#4ABC85] hidden group-hover:inline-block ml-2 cursor-pointer"
                      onClick={() => {
                        setShowDetailModal(false);
                        handleModalOpen('edit', selectedStudentDetail, 'student_name');
                      }}
                    />
                  )}
                </div>

                <div className="font-semibold flex items-center">강의:</div>
                <div className="flex items-center group">
                  {selectedStudentDetail.lecture || '-'}
                  {userType === 'manager' && (
                    <PenSquare 
                      className="w-4 h-4 text-[#4ABC85] hidden group-hover:inline-block ml-2 cursor-pointer"
                      onClick={() => {
                        setShowDetailModal(false);
                        handleModalOpen('edit', selectedStudentDetail, 'lecture');
                      }}
                    />
                  )}
                </div>

                <div className="font-semibold flex items-center">이메일:</div>
                <div className="flex items-center group">
                  {selectedStudentDetail.student_email}
                  {userType === 'manager' && (
                    <PenSquare 
                      className="w-4 h-4 text-[#4ABC85] hidden group-hover:inline-block ml-2 cursor-pointer"
                      onClick={() => {
                        setShowDetailModal(false);
                        handleModalOpen('edit', selectedStudentDetail, 'student_email');
                      }}
                    />
                  )}
                </div>

                <div className="font-semibold flex items-center">전화번호:</div>
                <div className="flex items-center group">
                  {selectedStudentDetail.mobile_number}
                  {userType === 'manager' && (
                    <PenSquare 
                      className="w-4 h-4 text-[#4ABC85] hidden group-hover:inline-block ml-2 cursor-pointer"
                      onClick={() => {
                        setShowDetailModal(false);
                        handleModalOpen('edit', selectedStudentDetail, 'mobile_number');
                      }}
                    />
                  )}
                </div>

                <div className="font-semibold flex items-center">학부모 전화번호:</div>
                <div className="flex items-center group">
                  {selectedStudentDetail.parent_mobile_number}
                  {userType === 'manager' && (
                    <PenSquare 
                      className="w-4 h-4 text-[#4ABC85] hidden group-hover:inline-block ml-2 cursor-pointer"
                      onClick={() => {
                        setShowDetailModal(false);
                        handleModalOpen('edit', selectedStudentDetail, 'parent_mobile_number');
                      }}
                    />
                  )}
                </div>

                <div className="font-semibold flex items-center">학교:</div>
                <div className="flex items-center group">
                  {selectedStudentDetail.school_name}
                  {userType === 'manager' && (
                    <PenSquare 
                      className="w-4 h-4 text-[#4ABC85] hidden group-hover:inline-block ml-2 cursor-pointer"
                      onClick={() => {
                        setShowDetailModal(false);
                        handleModalOpen('edit', selectedStudentDetail, 'school_name');
                      }}
                    />
                  )}
                </div>

                <div className="font-semibold flex items-center">학년:</div>
                <div className="flex items-center group">
                  {selectedStudentDetail.school_grade}
                  {userType === 'manager' && (
                    <PenSquare 
                      className="w-4 h-4 text-[#4ABC85] hidden group-hover:inline-block ml-2 cursor-pointer"
                      onClick={() => {
                        setShowDetailModal(false);
                        handleModalOpen('edit', selectedStudentDetail, 'school_grade');
                      }}
                    />
                  )}
                </div>

                <div className="font-semibold flex items-center">유입 경로:</div>
                <div className="flex items-center">
                  {selectedStudentDetail.acq_channel}
                </div>

                <div className="font-semibold flex items-center">추천인:</div>
                <div className="flex items-center">
                  {selectedStudentDetail.referral_id}
                </div>

                <div className="font-semibold flex items-center">등록일:</div>
                <div>{new Date(selectedStudentDetail.created_at).toLocaleDateString()}</div>
                
                <div className="font-semibold flex items-center">수정일:</div>
                <div>{new Date(selectedStudentDetail.updated_at).toLocaleDateString()}</div>
              </div>
            </div>
            <div className="flex justify-end mt-4">
              <div className="flex gap-2 w-full">
                {userType === 'manager' && (
                  <button
                    onClick={() => setShowDeleteConfirm(true)}
                    className="px-4 py-2 bg-[#F9344B] text-white rounded"
                  >
                    삭제
                  </button>
                )}
                <button
                  className="px-4 py-2 bg-gray-200 rounded ml-auto"
                  onClick={() => setShowDetailModal(false)}
                >
                  닫기
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showTeacherModal && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={handleTeacherModalClose}
        >
          <div 
            className="bg-white p-6 rounded-lg w-96"
            onClick={e => e.stopPropagation()}
          >
            <h2 className="text-lg font-semibold mb-4">선생님 할당</h2>
            <select
              className="w-full p-2 border rounded mb-4"
              value={selectedTeacherId}
              onChange={(e) => setSelectedTeacherId(e.target.value)}
            >
              <option value="">선생님을 선택하세요</option>
              {teachers.map((teacher) => (
                <option key={teacher.user_id} value={teacher.user_id}>
                  {teacher.user_name} ({teacher.charge_work})
                </option>
              ))}
            </select>
            <div className="flex justify-end gap-2">
              <button
                className="px-4 py-2 bg-[#F9344B] text-white rounded"
                onClick={() => handleAllocateTeacher(selectedStudent.student_id, null)}
              >
                할당 삭제
              </button>
              <button
                className="px-4 py-2 bg-gray-200 rounded"
                onClick={handleTeacherModalClose}
              >
                취소
              </button>
              <button
                className="px-4 py-2 bg-[#4ABC85] text-white rounded"
                onClick={() => handleAllocateTeacher(selectedStudent.student_id, selectedTeacherId)}
                disabled={!selectedTeacherId}
              >
                할당
              </button>
            </div>
          </div>
        </div>
      )}

      {showResetConfirm && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={() => setShowResetConfirm(false)}
        >
          <div 
            className="bg-white p-6 rounded-lg w-96"
            onClick={e => e.stopPropagation()}
          >
            <h3 className="text-lg font-semibold mb-4">비밀번호 초기화</h3>
            <p className="mb-4">정말 비밀번호를 초기화하시겠습니까?</p>
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setShowResetConfirm(false)}
                className="px-4 py-2 bg-gray-200 rounded"
              >
                취소
              </button>
              <button
                onClick={async () => {
                  try {
                    const newPw = await handleResetPassword(selectedStudentDetail.student_id);
                    setNewPassword(newPw);
                    setShowResetConfirm(false);
                    setShowPasswordPopup(true);
                  } catch (error) {
                    alert(error.message);
                  }
                }}
                className="px-4 py-2 bg-[#F9344B] text-white rounded"
              >
                확인
              </button>
            </div>
          </div>
        </div>
      )}

      {showPasswordPopup && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={() => setShowPasswordPopup(false)}
        >
          <div 
            className="bg-white p-6 rounded-lg w-96"
            onClick={e => e.stopPropagation()}
          >
            <h3 className="text-lg font-semibold mb-4">새 비밀번호</h3>
            <div className="flex items-center gap-2 mb-4">
              <input
                type="text"
                value={newPassword}
                readOnly
                className="flex-1 px-3 py-2 border rounded bg-gray-100"
              />
              <button
                onClick={() => {
                  navigator.clipboard.writeText(newPassword);
                  alert('비밀번호가 클립보드에 복사되었습니다.');
                }}
                className="whitespace-nowrap px-4 py-2 bg-[#4ABC85] text-white rounded"
              >
                복사
              </button>
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => setShowPasswordPopup(false)}
                className="px-4 py-2 bg-gray-200 rounded"
              >
                닫기
              </button>
            </div>
          </div>
        </div>
      )}

      {showDeleteConfirm && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60]"
          onClick={() => {
            setShowDeleteConfirm(false);
            setDeleteEmail('');
            setDeleteError('');
          }}
        >
          <div 
            className="bg-white p-6 rounded-lg w-96"
            onClick={e => e.stopPropagation()}
          >
            <h3 className="text-lg font-semibold mb-4">학생 삭제</h3>
            <p className="mb-4">삭제를 확인하려면 학생의 이메일을 입력하세요:</p>
            <p className="text-sm text-gray-600 mb-2">{selectedStudentDetail.student_email}</p>
            <input
              type="email"
              value={deleteEmail}
              onChange={(e) => setDeleteEmail(e.target.value)}
              className="w-full px-3 py-2 border rounded mb-4"
              placeholder="이메일 입력"
            />
            {deleteError && <p className="text-red-500 text-sm mb-4">{deleteError}</p>}
            <div className="flex justify-end gap-2">
              <button
                onClick={() => {
                  setShowDeleteConfirm(false);
                  setDeleteEmail('');
                  setDeleteError('');
                }}
                className="px-4 py-2 bg-gray-200 rounded"
              >
                취소
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-[#F9344B] text-white rounded"
              >
                삭제
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StudentManagement; 