import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { useAuth } from '../context/AuthContext';
import CreateExamModal from '../components/TestPapers/CreateExamModal';
import PrintModal from '../components/TestPapers/PrintModal';
import ResultInputModal from '../components/TestPapers/ResultInputModal';
import { handlePDF } from '../components/ProblemPrint/handlePDFv2';
import { handleSolutionPDF } from '../components/SolutionPrint/handleSolutionPDF';

function TestPapers() {
  const { getAccessToken } = useAuth();
  const navigate = useNavigate();
  const { student_id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [treeList, setTreeList] = useState([]);
  const [examsList, setExamsList] = useState([]);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [selectedExamId, setSelectedExamId] = useState(null);
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  useEffect(() => {
    const validateTokenAndFetchExams = async () => {
      try {
        const token = await getAccessToken();
        if (!token) {
          navigate('/signin');
          return;
        }

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/auth/tokens/access/validate`, {
          method: 'GET',
          headers: {
            'Authorization': token
          }
        });

        if (response.status === 401) {
          navigate('/signin');
        } else {
          fetchExamsList();
        }
      } catch (error) {
        console.error('토큰 검증 오류:', error);
        navigate('/signin');
      }
    };

    validateTokenAndFetchExams();
  }, [getAccessToken, navigate]);

  const fetchExamsList = async () => {
    try {
      const token = await getAccessToken();
      const url = student_id 
        ? `${process.env.REACT_APP_API_BASE_URL}/api/exam/alpha?student_id=${student_id}`
        : `${process.env.REACT_APP_API_BASE_URL}/api/exam/alpha`;
        
      const response = await fetch(url, {
        headers: { 'Authorization': token }
      });
      const data = await response.json();
      if (data.status === 'success') {
        setExamsList(data.data);
      }
    } catch (error) {
      console.error('시험지 목록 조회 실패:', error);
    }
  };

  const fetchTreeList = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/testset/lists`, {
        headers: { 'Authorization': token }
      });
      const data = await response.json();
      if (data.status === 'success') {
        setTreeList(data.data);
      }
    } catch (error) {
      console.error('교육과정 목록 조회 실패:', error);
    }
  };

  const handleCreateExam = async (formData) => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/exam/alpha`, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...formData,
          student_id: parseInt(student_id)
        })
      });

      if (response.status === 201) {
        setIsModalOpen(false);
        await fetchExamsList();
      }
    } catch (error) {
      console.error('시험지 생성 실패:', error);
    }
  };

  const handleModalOpen = () => {
    fetchTreeList();
    setIsModalOpen(true);
  };

  const handlePrintClick = (examId) => {
    setSelectedExamId(examId);
    setIsPrintModalOpen(true);
  };

  const getStatusInfo = (status) => {
    const statusMap = {
      200: { image: true, ocr: false, editor: false },
      400: { image: true, ocr: true, editor: false },
      999: { image: true, ocr: true, editor: true }
    };
    return statusMap[status] || { image: false, ocr: false, editor: false };
  };

  const handleShareResult = async (examId) => {
    try {
      const token = await getAccessToken();
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/resultmap/issue`, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ exam_id: examId })
      });

      if (response.ok) {
        const { data } = await response.json();
        const shareUrl = `https://kyhub.space/result/${data}`;
        
        await navigator.clipboard.writeText(shareUrl);
        alert('결과 공유 링크가 클립보드에 복사되었습니다.');
      }
    } catch (error) {
      console.error('결과 공유 링크 생성 실패:', error);
      alert('결과 공유 링크 생성에 실패했습니다.');
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
          <div className="container mx-auto px-6 py-8">
            <nav className="mb-6">
              <div className="flex items-center text-sm text-gray-600">
                <Link to="/students" className="hover:text-[#4ABC85]">학생관리</Link>
                <span className="mx-2">&gt;</span>
                <span className="text-[#4ABC85]">시험지 관리</span>
              </div>
            </nav>
            
            <div className="flex justify-end mb-6">
              <button
                onClick={handleModalOpen}
                className="bg-[#4ABC85] text-white px-4 py-2 rounded-lg hover:bg-[#A3A1A2]"
              >
                시험지 생성
              </button>
            </div>
            
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white shadow-sm rounded-lg">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">시험지 ID</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">작성자</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">학생</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">문제 수</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">난이도</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">상태</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">교육과정</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">생성일</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">작업</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {examsList.map((exam) => (
                    <tr key={exam.exam_id}>
                      <td className="px-6 py-4 whitespace-nowrap">{exam.exam_id}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{exam.author}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{exam.student_info.student_name}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{exam.total_problems}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{exam.avg_difficulty.toFixed(1)}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex gap-2">
                          <span 
                            className={`h-2 w-2 rounded-full ${getStatusInfo(exam.exam_status).image ? 'bg-green-500' : 'bg-red-500'}`} 
                            title="이미지" 
                          />
                          <span 
                            className={`h-2 w-2 rounded-full ${getStatusInfo(exam.exam_status).ocr ? 'bg-green-500' : 'bg-red-500'}`} 
                            title="OCR" 
                          />
                          <span 
                            className={`h-2 w-2 rounded-full ${getStatusInfo(exam.exam_status).editor ? 'bg-green-500' : 'bg-red-500'}`} 
                            title="에디터" 
                          />
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">{exam.tree_name} ({exam.edu_ver})</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {new Date(exam.created_at).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex gap-2">
                          <button
                            onClick={() => handlePDF(exam.exam_id)}
                            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                          >
                            PDF
                          </button>
                          <button
                            onClick={() => handleSolutionPDF(exam.exam_id)}
                            className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600"
                          >
                            정답
                          </button>
                          {exam.exam_status === 200 && (
                            <button
                              onClick={() => {
                                setSelectedExamId(exam.exam_id);
                                setIsResultModalOpen(true);
                              }}
                              className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
                            >
                              결과입력
                            </button>
                          )}
                          {exam.exam_status === 400 && (
                            <>
                              <button
                                onClick={() => {
                                  setSelectedExamId(exam.exam_id);
                                  setIsResultModalOpen(true);
                                }}
                                className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
                              >
                                결과수정
                              </button>
                              <button
                                onClick={() => {
                                  setSelectedExamId(exam.exam_id);
                                  setIsResultModalOpen(true);
                                  setIsConfirming(true);
                                }}
                                className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700"
                              >
                                결과확정
                              </button>
                            </>
                          )}
                          {exam.exam_status === 999 && (
                            <>
                              <button
                                onClick={() => navigate(`/test-result/${exam.exam_id}`, {
                                  state: { studentId: student_id }
                                })}
                                className="bg-purple-500 text-white px-3 py-1 rounded hover:bg-purple-600"
                              >
                                결과조회
                              </button>
                              <button
                                onClick={() => handleShareResult(exam.exam_id)}
                                className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                              >
                                결과공유
                              </button>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <CreateExamModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              onSubmit={handleCreateExam}
              treeList={treeList}
            />

            <PrintModal
              isOpen={isPrintModalOpen}
              onClose={() => setIsPrintModalOpen(false)}
              examId={selectedExamId}
            />

            <ResultInputModal
              isOpen={isResultModalOpen}
              onClose={() => {
                setIsResultModalOpen(false);
                setIsConfirming(false);
              }}
              examId={selectedExamId}
              onRefresh={fetchExamsList}
              isConfirmMode={isConfirming}
            />
          </div>
        </main>
      </div>
    </div>
  );
}

export default TestPapers; 