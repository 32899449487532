import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { useAuth } from '../context/AuthContext';
import { useNavigate, Link } from 'react-router-dom';

function ReservationManagement() {
  const [allReservations, setAllReservations] = useState({});
  const [currentReservations, setCurrentReservations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessToken, userType } = useAuth();
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [startDate, setStartDate] = useState(new Date());

  const shiftDates = async (direction) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + (direction === 'next' ? 1 : -1));
    const newSelectedDate = newDate.toISOString().split('T')[0];
    setSelectedDate(newSelectedDate);
    setStartDate(newDate);

    await fetchReservations(newDate);
  };

  const fetchReservations = async (date) => {
    try {
      setIsLoading(true);
      const targetDate = new Date(date);
      targetDate.setHours(0, 0, 0, 0);
      const timestamp = Math.floor(targetDate.getTime() / 1000);

      const token = await getAccessToken();
      if (!token) {
        navigate('/signin');
        return;
      }

      const url = `${process.env.REACT_APP_API_BASE_URL}/api/users/reservations?rsv_date=${timestamp}`;

      const response = await fetch(url, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error('예약 목록을 불러오는데 실패했습니다.');
      }

      const result = await response.json();
      const groupedReservations = (result.data || []).reduce((acc, reservation) => {
        const date = new Date(reservation.rsv_time * 1000).toISOString().split('T')[0];
        if (!acc[date]) acc[date] = [];
        acc[date].push(reservation);
        return acc;
      }, {});

      setAllReservations(groupedReservations);
      setCurrentReservations(groupedReservations[selectedDate] || []);
    } catch (error) {
      console.error('Error fetching reservations:', error);
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const today = new Date();
    fetchReservations(today);
  }, []);

  useEffect(() => {
    setCurrentReservations(allReservations[selectedDate] || []);
  }, [selectedDate, allReservations]);

  const renderCalendarDays = () => {
    const days = [];
    const weekdays = ['일', '월', '화', '수', '목', '금', '토'];

    for (let i = 0; i < 7; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);
      const dateStr = date.toISOString().split('T')[0];
      const reservationCount = (allReservations[dateStr] || []).length;

      days.push(
        <div 
          key={dateStr}
          onClick={() => setSelectedDate(dateStr)}
          className={`cursor-pointer p-2 text-center border-r flex-1 ${
            dateStr === selectedDate ? 'bg-[#4ABC85] text-white' : ''
          }`}
        >
          <div className="text-sm">{`${date.getDate()}일(${weekdays[date.getDay()]})`}</div>
          <div className="font-bold">{reservationCount}</div>
        </div>
      );
    }
    return days;
  };

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
          <div className="container mx-auto px-6 py-8">
            <nav className="mb-6">
              <div className="flex items-center text-sm text-gray-600">
                <Link to="/reservations" className="hover:text-[#4ABC85]">예약관리</Link>
              </div>
            </nav>
            
            <div className="bg-white shadow-md rounded-lg p-6">
              <div className="mb-4">
                <div className="flex items-center justify-between mb-4">
                  <button 
                    onClick={() => shiftDates('prev')}
                    className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
                  >
                    &lt;
                  </button>
                  <div className="text-xl font-bold">
                    {`${startDate.getFullYear()}년 ${startDate.getMonth() + 1}월`}
                  </div>
                  <button 
                    onClick={() => shiftDates('next')}
                    className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
                  >
                    &gt;
                  </button>
                </div>
                <div className="w-full">
                  <div className="flex border-l border-gray-200">
                    {renderCalendarDays()}
                  </div>
                </div>
              </div>

              {isLoading ? (
                <div className="text-center py-4">로딩 중...</div>
              ) : (
                <table className="min-w-full">
                  <thead>
                    <tr className="bg-[#231F20] text-white">
                      <th className="px-6 py-3 text-left">학생 ID</th>
                      <th className="px-6 py-3 text-left">학생 이름</th>
                      <th className="px-6 py-3 text-left">이메일</th>
                      <th className="px-6 py-3 text-left">전화번호</th>
                      <th className="px-6 py-3 text-left">학부모 전화번호</th>
                      <th className="px-6 py-3 text-left">학교</th>
                      <th className="px-6 py-3 text-left">학년</th>
                      <th className="px-6 py-3 text-left">예약 시간</th>
                      {userType === 'manager' && (
                        <th className="px-6 py-3 text-left">작업</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {currentReservations.map((reservation) => (
                      <tr key={reservation.student_id} className="border-b">
                        <td className="px-6 py-4">{reservation.student_id}</td>
                        <td className="px-6 py-4">{reservation.student_name}</td>
                        <td className="px-6 py-4">{reservation.student_email}</td>
                        <td className="px-6 py-4">{reservation.mobile_number}</td>
                        <td className="px-6 py-4">{reservation.parent_mobile_number}</td>
                        <td className="px-6 py-4">{reservation.school_name}</td>
                        <td className="px-6 py-4">{reservation.school_grade}</td>
                        <td className="px-6 py-4">
                          {new Date(reservation.rsv_time * 1000).toLocaleTimeString('ko-KR', {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false
                          })}
                        </td>
                        {userType === 'manager' && (
                          <td className="px-6 py-4">
                            <button 
                              className="text-[#F9344B]"
                              onClick={() => navigate(`/student/${reservation.student_id}/stamp`)}
                            >
                              시험
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ReservationManagement;