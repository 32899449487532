import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ChevronRight, ChevronDown } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

function TestsetComponent({ data, tree_id, fetchTreeData, onPriorityUpdate, onCloseableCheck, onClose }) {
    // console.log('TestsetComponent received data:', data);  // 추가된 로그

    const { getAccessToken } = useAuth();
    const [expandedNodes, setExpandedNodes] = useState(new Set());
    const [addingTo, setAddingTo] = useState(null);
    const [newComponentData, setNewComponentData] = useState({
        comp_name: '',
        priority: '0',
    });
    const [isAddingRoot, setIsAddingRoot] = useState(false);
    const [editingNode, setEditingNode] = useState(null);
    const [editData, setEditData] = useState({
        comp_name: '',
        priority: '',
    });
    const [showDeleteAllConfirm, setShowDeleteAllConfirm] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
    const [isAllExpanded, setIsAllExpanded] = useState(false);

    const structure = useMemo(() => data.structure || [], [data.structure]);

    const isValidData = useMemo(() => Array.isArray(structure) && structure.length > 0, [structure]);

    const getUniqueId = useCallback((node) => `${node.path_id}-${node.comp_name}`, []);

    const getAllNodeIds = useCallback((nodes) => {
        let ids = [];
        nodes.forEach(node => {
            ids.push(getUniqueId(node));
            if (node.components && node.components.length > 0) {
                ids = [...ids, ...getAllNodeIds(node.components)];
            }
        });
        return ids;
    }, [getUniqueId]);

    useEffect(() => {
        if (isAllExpanded) {
            setExpandedNodes(new Set(getAllNodeIds(structure)));
        } else {
            setExpandedNodes(new Set());
        }
    }, [isAllExpanded, getAllNodeIds, structure]);

    const toggleNode = useCallback((uniqueId) => {
        setExpandedNodes(prev => {
            const newSet = new Set(prev);
            if (newSet.has(uniqueId)) {
                newSet.delete(uniqueId);
            } else {
                newSet.add(uniqueId);
            }
            return newSet;
        });
    }, []);

    const getStylesByDepth = (depth) => {
        switch (depth) {
            case 1: return 'font-bold text-lg';
            case 2: return 'font-semibold text-base';
            case 3: return 'font-medium text-sm';
            case 4: return 'text-sm';
            default: return 'text-sm';
        }
    };

    const getTagStylesByDepth = (depth) => {
        switch (depth) {
            case 1: return 'bg-[#4ABC85] text-white';
            case 2: return 'bg-[#231F20] text-white';
            case 3: return 'bg-[#A3A1A2] text-black';
            case 4: return 'bg-[#D9D9D9] text-black';
            default: return '';
        }
    };

    const getDepthLabel = (depth) => {
        switch (depth) {
            case 1: return '대단원';
            case 2: return '소단원';
            case 3: return '대유형';
            case 4: return '중유형';
            default: return '';
        }
    };

    const handleAddClick = (node) => {
        const uniqueId = getUniqueId(node);
        setAddingTo(prevAddingTo => prevAddingTo === uniqueId ? null : uniqueId);
        setNewComponentData({
            comp_name: '',
            priority: '0',
        });
    };

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const fetchWithAuth = async (url, options = {}) => {
        const token = await getAccessToken();
        if (!token) {
            throw new Error('인증 토큰이 없습니다.');
        }
        return fetch(`${API_BASE_URL}${url}`, {
            ...options,
            headers: {
                ...options.headers,
                'Authorization': token,
                'Content-Type': 'application/json',
            },
            credentials: 'include', // 쿠키를 포함하기 위해 추가
        });
    };

    const handleAddSubmit = async (node) => {
        if (newComponentData.comp_name.trim()) {
            const newComponent = {
                comp_name: newComponentData.comp_name.trim(),
                depth: node.depth + 1,
                priority: parseInt(newComponentData.priority),
                parent_component: {
                    depth: node.depth,
                    order: node.order,
                    path_id: node.path_id,
                    priority: node.priority,
                    comp_name: node.comp_name
                }
            };
            try {
                const response = await fetchWithAuth(`/api/testset/${tree_id}/components/create`, {
                    method: 'POST',
                    body: JSON.stringify(newComponent),
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                fetchTreeData(tree_id);
                setAddingTo(null);
                setNewComponentData({
                    comp_name: '',
                    priority: '0',
                });
            } catch (error) {
                console.error('컴포넌트 추가 중 오류 발생:', error);
            }
        }
    };

    const handleAddRootComponent = async () => {
        if (newComponentData.comp_name.trim()) {
            const newComponent = {
                comp_name: newComponentData.comp_name.trim(),
                depth: 1,
                priority: parseInt(newComponentData.priority),
                // 최상위 컴포넌트는 parent_component가 없음
            };
            try {
                const response = await fetchWithAuth(`/api/testset/${tree_id}/components/create`, {
                    method: 'POST',
                    body: JSON.stringify(newComponent),
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                fetchTreeData(tree_id);
                setIsAddingRoot(false);
                setNewComponentData({
                    comp_name: '',
                    priority: '0',
                });
            } catch (error) {
                console.error('루트 컴포넌트 추가 중 오류 발생:', error);
            }
        }
    };

    const handleEditClick = (node) => {
        const uniqueId = getUniqueId(node);
        setEditingNode(prevEditingNode => prevEditingNode === uniqueId ? null : uniqueId);
        setEditData({
            comp_name: node.comp_name,
            priority: node.priority.toString(),
        });
    };

    const handleEditSubmit = async (node) => {
        const updatedComponent = {
            comp_name: editData.comp_name.trim() || undefined,
            priority: editData.priority ? parseInt(editData.priority) : undefined,
            path_id: node.path_id,
            order: node.order,
            depth: node.depth,
        };
        try {
            const response = await fetchWithAuth(`/api/testset/${tree_id}/components/update`, {
                method: 'PATCH',
                body: JSON.stringify(updatedComponent),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            fetchTreeData(tree_id);
            setEditingNode(null);
        } catch (error) {
            console.error('컴포넌트 수정 중 오류 발생:', error);
        }
    };

    const handleDeleteClick = (node) => {
        // console.log("Delete button clicked for node:", node);
        const uniqueId = getUniqueId(node);
        // console.log("Setting showDeleteConfirm to:", uniqueId);
        setShowDeleteConfirm(uniqueId);
    };

    const confirmDelete = async (node) => {
        // console.log("Confirming delete for node:", node);
        try {
            const response = await fetchWithAuth(`/api/testset/${tree_id}/components/delete`, {
                method: 'DELETE',
                body: JSON.stringify({
                    path_id: node.path_id,
                    order: node.order,
                    depth: node.depth
                }),
            });
            // console.log("Delete API response:", response);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            console.log("Delete successful, fetching updated tree data");
            fetchTreeData(tree_id);
            setShowDeleteConfirm(null);
        } catch (error) {
            console.error('컴포넌트 삭제 중 오류 발생:', error);
        }
    };

    const handleDeleteAllClick = () => {
        setShowDeleteAllConfirm(true);
    };

    const confirmDeleteAll = async () => {
        try {
            const response = await fetchWithAuth(`/api/testset/${tree_id}/components/deleteall`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            fetchTreeData(tree_id);
            setShowDeleteAllConfirm(false);
        } catch (error) {
            console.error('모든 컴포넌트 삭제 중 오류 발생:', error);
        }
    };

    const findNodeById = (nodes, id) => {
        // console.log("Searching for node with id:", id);
        // console.log("Current nodes:", nodes);
        for (let node of nodes) {
            const nodeId = getUniqueId(node);
            // console.log("Checking node:", nodeId);
            if (nodeId === id) {
                // console.log("Node found:", node);
                return node;
            }
            if (node.components) {
                const found = findNodeById(node.components, id);
                if (found) return found;
            }
        }
        console.log("Node not found in this level");
        return null;
    };

    const renderTreeNode = useCallback((node) => {
        const uniqueId = getUniqueId(node);
        // console.log(`Rendering node: ${uniqueId}, depth: ${node.depth}`);
        const isExpanded = expandedNodes.has(uniqueId);
        const hasChildren = node.components && node.components.length > 0;
        const titleStyle = getStylesByDepth(node.depth);
        const tagStyle = getTagStylesByDepth(node.depth);
        const isAddingToThisNode = addingTo === uniqueId;
        const isEditingThisNode = editingNode === uniqueId;

        const handleCopyPathId = () => {
            const formattedPathId = node.path_id
                .replace(/\//g, '_')  // 모든 슬래시를 언더바로 변경
                .replace(/_$/, '');   // 마지막 언더바 제거
            const finalString = `${formattedPathId}_${node.order}`;
            navigator.clipboard.writeText(finalString);
        };

        const indentStyle = {
            marginLeft: `${(node.depth - 1) * 20}px`
        };

        return (
            <div key={uniqueId} className="mb-2">
                <div className="flex items-center" style={indentStyle}>
                    {hasChildren && (
                        <button
                            onClick={() => toggleNode(uniqueId)}
                            className="w-6 h-6 flex items-center justify-center text-gray-400 focus:outline-none mr-2"
                        >
                            {isExpanded ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
                        </button>
                    )}
                    {!hasChildren && <div className="w-6 mr-2"></div>}
                    <span className={`${tagStyle} text-xs px-1 py-0.5 rounded-md mr-2`}>
                        {getDepthLabel(node.depth)}
                    </span>
                    <span className={`${titleStyle} text-black mr-2`}>
                        {node.comp_name} ({node.priority})
                    </span>
                    <div className="flex items-center">
                        {node.depth === 4 && (
                            <span
                                onClick={handleCopyPathId}
                                className="text-green-500 hover:text-green-700 mr-2 cursor-pointer"
                            >
                                {`${node.path_id.replace(/\//g, '_').replace(/_$/, '')}_${node.order}`}
                            </span>
                        )}
                        <button
                            onClick={() => handleEditClick(node)}
                            className="text-yellow-500 hover:text-yellow-700 mr-2"
                        >
                            수정
                        </button>
                        {node.depth < 4 && (
                            <button
                                onClick={() => handleAddClick(node)}
                                className="text-blue-500 hover:text-blue-700 mr-2"
                            >
                                추가
                            </button>
                        )}
                        <button
                            onClick={() => handleDeleteClick(node)}
                            className="text-red-500 hover:text-red-700"
                        >
                            삭제
                        </button>
                    </div>
                </div>
                {isEditingThisNode && (
                    <div className="mt-2 ml-4 flex flex-col">
                        <input
                            type="text"
                            value={editData.comp_name}
                            onChange={(e) => setEditData({...editData, comp_name: e.target.value})}
                            className="border border-gray-300 rounded px-2 py-1 mb-2"
                            placeholder="컴포넌트 이름"
                        />
                        <input
                            type="number"
                            value={editData.priority}
                            onChange={(e) => setEditData({...editData, priority: e.target.value})}
                            className="border border-gray-300 rounded px-2 py-1 mb-2"
                            placeholder="우선순위"
                        />
                        <button
                            onClick={() => handleEditSubmit(node)}
                            className="bg-yellow-500 text-white px-2 py-1 rounded"
                        >
                            수정 완료
                        </button>
                    </div>
                )}
                {isAddingToThisNode && (
                    <div className="mt-2 ml-4 flex flex-col">
                        <input
                            type="text"
                            value={newComponentData.comp_name}
                            onChange={(e) => setNewComponentData({...newComponentData, comp_name: e.target.value})}
                            className="border border-gray-300 rounded px-2 py-1 mb-2"
                            placeholder="새 컴포넌트 이름"
                        />
                        <input
                            type="number"
                            value={newComponentData.priority}
                            onChange={(e) => setNewComponentData({...newComponentData, priority: e.target.value})}
                            className="border border-gray-300 rounded px-2 py-1 mb-2"
                            placeholder="우선순위"
                        />
                        <button
                            onClick={() => handleAddSubmit(node)}
                            className="bg-blue-500 text-white px-2 py-1 rounded"
                        >
                            추가
                        </button>
                    </div>
                )}
                {isExpanded && hasChildren && node.depth < 4 && (
                    <div className="mt-2">
                        {node.components
                            .sort((a, b) => a.order - b.order)
                            .map((childNode) => renderTreeNode(childNode))}
                    </div>
                )}
            </div>
        );
    }, [expandedNodes, addingTo, editingNode, getUniqueId, toggleNode, handleEditClick, handleAddClick, handleDeleteClick]);

    const navigate = useNavigate();

    const handleProblemArrangement = () => {
        navigate(`/test-register/${tree_id}`);
    };

    const handleClose = async () => {
        try {
            onClose();
        } catch (error) {
            console.error('테스트셋 확정 프로세스 중 오류 발생:', error);
        }
    };

    return (
        <div className="tree-component p-4">
            <div className="mb-4 flex items-center">
                <button
                    onClick={() => setIsAddingRoot(!isAddingRoot)}
                    className="bg-green-500 text-white px-3 py-2 rounded mr-2"
                >
                    {isAddingRoot ? '취소' : '대단원 추가'}
                </button>
                <button
                    onClick={handleDeleteAllClick}
                    className="bg-red-500 text-white px-3 py-2 rounded mr-2"
                >
                    전체 삭제
                </button>
                <button
                    onClick={() => setIsAllExpanded(!isAllExpanded)}
                    className="bg-blue-500 text-white px-3 py-2 rounded mr-2"
                >
                    {isAllExpanded ? '모두 접기' : '모두 펼치기'}
                </button>
                <button 
                    className="bg-purple-500 text-white px-3 py-2 rounded mr-2" 
                    onClick={onPriorityUpdate}
                >
                    중요도 갱신
                </button>
                <button 
                    className="bg-yellow-500 text-white px-3 py-2 rounded mr-2" 
                    onClick={onCloseableCheck}
                >
                    확정 확인
                </button>
                <button 
                    className="bg-indigo-500 text-white px-3 py-2 rounded mr-2" 
                    onClick={handleClose}
                >
                    확정
                </button>
                <button 
                    className="bg-orange-500 text-white px-3 py-2 rounded" 
                    onClick={handleProblemArrangement}
                >
                    문제편성
                </button>
            </div>
            {isAddingRoot && (
                <div className="mt-2 flex flex-col">
                    <input
                        type="text"
                        value={newComponentData.comp_name}
                        onChange={(e) => setNewComponentData({ ...newComponentData, comp_name: e.target.value })}
                        className="border border-gray-300 rounded px-2 py-1 mb-2"
                        placeholder="새 컴포넌트 이름"
                    />
                    <input
                        type="number"
                        value={newComponentData.priority}
                        onChange={(e) => setNewComponentData({ ...newComponentData, priority: e.target.value })}
                        className="border border-gray-300 rounded px-2 py-1 mb-2"
                        placeholder="우선순위"
                    />
                    <button
                        onClick={handleAddRootComponent}
                        className="bg-blue-500 text-white px-2 py-1 rounded"
                    >
                        추가
                    </button>
                </div>
            )}
            {isValidData ? (
                structure.sort((a, b) => a.order - b.order).map((node) => renderTreeNode(node))
            ) : (
                <p>데이터가 없거나 올바르지 않습니다.</p>
            )}
            {showDeleteConfirm !== null && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center" onClick={() => setShowDeleteConfirm(null)}>
                    <div className="bg-white p-6 rounded-lg" onClick={(e) => e.stopPropagation()}>
                        <h2 className="text-xl font-bold mb-4">삭제 확인</h2>
                        <p>이 컴포넌트를 삭제하시겠습니까? 이 작업은 절대 되돌릴 수 없습니다.</p>
                        <div className="flex justify-end mt-4">
                            <button
                                onClick={() => setShowDeleteConfirm(null)}
                                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                            >
                                취소
                            </button>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    const node = findNodeById(structure, showDeleteConfirm);
                                    if (node) {
                                        confirmDelete(node);
                                    }
                                }}
                                className="bg-red-500 text-white px-4 py-2 rounded"
                            >
                                삭제
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showDeleteAllConfirm && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg">
                        <h2 className="text-xl font-bold mb-4">전체 삭제 확인</h2>
                        <p>정말로 모든 컴포넌트를 삭제하시겠습니까? 이 작업은 절대 되돌릴 수 없습니다.</p>
                        <div className="flex justify-end mt-4">
                            <button
                                onClick={() => setShowDeleteAllConfirm(false)}
                                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                            >
                                취소
                            </button>
                            <button
                                onClick={confirmDeleteAll}
                                className="bg-red-500 text-white px-4 py-2 rounded"
                            >
                                삭제
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
export default TestsetComponent;
