import { EditorConfig, ElementNode, LexicalNode, SerializedElementNode, $parseSerializedNode } from 'lexical';
import { $createBoxContentNode } from './BoxContentNode';

export type SerializedBoxNode = SerializedElementNode;

export class BoxNode extends ElementNode {
  static getType(): string {
    return 'box';
  }

  static clone(node: BoxNode): BoxNode {
    return new BoxNode(node.__key);
  }

  createDOM(config: EditorConfig): HTMLElement {
    const div = document.createElement('div');
    div.className = 'box-container';
    return div;
  }

  updateDOM(): boolean {
    return false;
  }

  isInline(): boolean {
    return false;
  }

  canBeEmpty(): boolean {
    return true;
  }

  canContainContent(): boolean {
    return true;
  }

  static importJSON(serializedNode: SerializedBoxNode): BoxNode {
    const node = new BoxNode();
    node.__children = [];
    
    if (serializedNode.children && serializedNode.children.length > 0) {
      const existingContent = serializedNode.children[0];
      const parsedContent = $parseSerializedNode(existingContent);
      if (parsedContent) {
        node.__children = [parsedContent];
      }
    } else {
      const content = $createBoxContentNode();
      node.__children = [content];
    }
    
    return node;
  }

  exportJSON(): SerializedBoxNode {
    return {
      ...super.exportJSON(),
      type: 'box',
      version: 1,
    };
  }
}

export function $createBoxNode(): BoxNode {
  const box = new BoxNode();
  const content = $createBoxContentNode();
  box.append(content);
  return box;
}

export function $isBoxNode(node: LexicalNode | null | undefined): node is BoxNode {
  return node instanceof BoxNode;
}